<script setup lang="ts">
import { computed } from 'vue';
import { format } from 'date-fns';
import type { ApexOptions } from 'apexcharts';
import type { PhysicalMeasurement } from '@/types/extended';
import type { Trainee, Measuree } from '@/types';
import { physicalAssessmentMetrics, type BasicRanges } from './physical-assessment-metrics';
import BaseAssessmentChart from './base-assessment-chart.vue';
import HealthStatusPanel from './health-status-panel.vue';

const props = defineProps<{
  physicalMeasurements: PhysicalMeasurement[];
  userable: Trainee | Measuree;
}>();

function formatDate(date: string): string {
  return format(new Date(date), 'dd/MM/yyyy');
}

const chartData = computed(() => {
  const dates = props.physicalMeasurements.map((pm) => formatDate(pm.createdAt));
  const assessments = props.physicalMeasurements.map((pm) => pm.physicalAssessment);

  return {
    dates,
    fatFreeMassIndex: assessments.map((assessment) => assessment.fatFreeMassIndex),
  };
});

const currentIndex = computed(() => {
  if (props.physicalMeasurements.length === 0) return null;

  return props.physicalMeasurements[props.physicalMeasurements.length - 1].physicalAssessment.fatFreeMassIndex;
});

/* eslint-disable no-magic-numbers,complexity */
const healthCategory = computed(() => {
  if (!currentIndex.value || !props.userable.sex) return null;

  const ranges = physicalAssessmentMetrics.fatFreeMassIndex.detailedRanges![props.userable.sex] as BasicRanges;
  const index = currentIndex.value;

  if (props.userable.sex === 'male') {
    if (index > 22.3) return ranges.optimal;
    if (index > 20.8) return ranges.moderate;

    return ranges.unhealthy;
  }

  if (index > 18.0) return ranges.optimal;
  if (index > 16.9) return ranges.moderate;

  return ranges.unhealthy;
});

const objective = computed(() => {
  if (!currentIndex.value || !props.userable.sex || !healthCategory.value) return null;

  if (healthCategory.value.id === 'optimal') return null;

  const isMale = props.userable.sex === 'male';
  const isUnhealthy = healthCategory.value.id === 'unhealthy';

  const objectives = {
    male: {
      unhealthy: { value: 20.8, percentile: 50, isHigherBetter: true },
      default: { value: 22.3, percentile: 75, isHigherBetter: true },
    },
    female: {
      unhealthy: { value: 16.9, percentile: 50, isHigherBetter: true },
      default: { value: 18.0, percentile: 75, isHigherBetter: true },
    },
  };

  if (isMale) {
    return isUnhealthy ? objectives.male.unhealthy : objectives.male.default;
  }

  return isUnhealthy ? objectives.female.unhealthy : objectives.female.default;
});
/* eslint-enable no-magic-numbers,complexity */

const recommendation = computed(() => {
  if (!healthCategory.value) return null;

  return physicalAssessmentMetrics.fatFreeMassIndex.recommendations?.[healthCategory.value.id];
});

const chartOptions = computed<ApexOptions>(() => ({
  chart: {
    type: 'line' as const,
    toolbar: { show: false },
    fontFamily: 'Inter var, sans-serif',
  },
  stroke: { curve: 'smooth', width: 3 },
  colors: ['#3B82F6'],
  xaxis: {
    categories: chartData.value.dates,
  },
  yaxis: {
    title: { text: 'kg/m²' },
    decimalsInFloat: 1,
  },
  dataLabels: {
    enabled: true,
    formatter: (value: number) => `${value.toFixed(1)}`,
    offsetY: -6,
    background: {
      enabled: false,
    },
  },
}));

const chartSeries = computed(() => [{
  name: 'IMLG',
  data: chartData.value.fatFreeMassIndex,
}]);

</script>

<template>
  <div class="col-span-2 grid grid-cols-1 sm:grid-cols-2">
    <BaseAssessmentChart
      title="Índice de masa libre de grasa"
      metric-key="fatFreeMassIndex"
      :chart-options="chartOptions"
      :chart-series="chartSeries"
      class="rounded-b-none sm:rounded-b-lg sm:rounded-r-none"
    />

    <HealthStatusPanel
      title="Índice de masa libre de grasa"
      :userable="userable"
      :current-value="currentIndex"
      :health-category="healthCategory"
      :recommendation="recommendation ?? null"
      unit=" kg/m²"
      :importance="physicalAssessmentMetrics.fatFreeMassIndex.importance ?? null"
      :objective="objective"
      status-color-scheme="success-warning"
    />
  </div>
</template>
