<script setup lang="ts">
import { ref, computed } from 'vue';
import { format } from 'date-fns';
import { watchDebounced } from '@vueuse/core';
import type { MealAttributes } from '@/types';
import type { NutritionObjectiveAttributes } from '@/types/extended';
import { BaseButton, BaseInput, BaseLabel, MarkdownEditor } from '@/components';
import { DEFAULT_VALUES } from './nutrition-plan-default-values';

const props = defineProps<{
  modelValue: NutritionObjectiveAttributes;
  index: number;
}>();

const emit = defineEmits<{
  'update:modelValue': [value: NutritionObjectiveAttributes];
  'remove': [];
}>();

const form = ref<NutritionObjectiveAttributes>({
  ...props.modelValue,
  startAt: format(new Date(props.modelValue.startAt), "yyyy-MM-dd'T'HH:mm"),
  mealsAttributes: props.modelValue.mealsAttributes.map(meal => ({
    ...meal,
  })),
});

watchDebounced(form, () => {
  emit('update:modelValue', form.value);
}, { deep: true, debounce: 300 });

function updateMeal(mealIndex: number, updates: Partial<MealAttributes>) {
  const updatedMeals = [...(form.value.mealsAttributes ?? [])];
  updatedMeals[mealIndex] = { ...updatedMeals[mealIndex], ...updates };

  form.value = {
    ...form.value,
    mealsAttributes: updatedMeals,
  };
}

function addMeal() {
  const newMeal: MealAttributes = {
    name: '',
    recommendations: DEFAULT_VALUES.mealRecommendations,
  };

  form.value = {
    ...form.value,
    mealsAttributes: [...(form.value.mealsAttributes ?? []), newMeal],
  };
}

function removeMeal(mealIndex: number) {
  const meal = form.value.mealsAttributes?.[mealIndex];
  if (!meal) return;

  if (meal.id) {
    form.value = {
      ...form.value,
      mealsAttributes: form.value.mealsAttributes?.map((m, i) =>
        (i === mealIndex ? { ...m, _destroy: true } : m),
      ),
    };
  } else {
    form.value = {
      ...form.value,
      mealsAttributes: form.value.mealsAttributes?.filter((_, i) => i !== mealIndex),
    };
  }
}

const filteredMeals = computed(() => form.value.mealsAttributes?.filter(m => !m._destroy));
</script>

<template>
  <div class="overflow-hidden rounded-lg border border-gray-200 bg-white shadow">
    <div class="px-4 py-5 sm:p-6">
      <div class="mb-4 flex items-center justify-between">
        <h3 class="text-base font-medium text-gray-900">
          Objetivo {{ index + 1 }}
        </h3>
        <BaseButton
          type="button"
          variant="danger"
          @click="emit('remove')"
        >
          Eliminar objetivo
        </BaseButton>
      </div>

      <div class="mb-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
        <div class="flex flex-col gap-1">
          <BaseLabel
            label="Fecha de inicio"
            :name="`nutritionObjectivesAttributes[${index}].startAt`"
            required
          />
          <BaseInput
            v-model="form.startAt"
            :name="`nutritionObjectivesAttributes[${index}].startAt`"
            type="datetime-local"
          />
        </div>
        <div class="sm:col-span-2">
          <BaseLabel
            label="Descripción"
            :name="`nutritionObjectivesAttributes[${index}].description`"
            required
          />
          <MarkdownEditor
            v-model="form.description"
            :name="`nutritionObjectivesAttributes[${index}].description`"
            rows="3"
          />
        </div>
      </div>

      <!-- Meals -->
      <div class="space-y-4">
        <h4 class="text-sm font-medium text-gray-900">
          Comidas
        </h4>

        <div
          v-for="(meal, mealIndex) in filteredMeals"
          :key="mealIndex"
          class="rounded-lg border border-gray-200 bg-gray-50 p-4"
        >
          <div class="mb-4 flex items-center justify-between">
            <h5 class="text-sm font-medium text-gray-900">
              Comida {{ mealIndex + 1 }}
            </h5>
            <BaseButton
              type="button"
              variant="danger"
              @click="removeMeal(mealIndex)"
            >
              Eliminar comida
            </BaseButton>
          </div>

          <div class="grid grid-cols-1 gap-4">
            <div class="flex flex-col gap-1">
              <BaseLabel
                label="Nombre"
                :name="`nutritionObjectivesAttributes[${index}].mealsAttributes[${mealIndex}].name`"
                required
              />
              <BaseInput
                :model-value="meal.name"
                :name="`nutritionObjectivesAttributes[${index}].mealsAttributes[${mealIndex}].name`"
                type="text"
                @input="updateMeal(mealIndex, { name: ($event.target as HTMLInputElement).value })"
              />
            </div>
            <div class="flex flex-col gap-1">
              <BaseLabel
                label="Recomendaciones"
                :name="`nutritionObjectivesAttributes[${index}].mealsAttributes[${mealIndex}].recommendations`"
                required
              />
              <MarkdownEditor
                :model-value="meal.recommendations"
                :name="`nutritionObjectivesAttributes[${index}].mealsAttributes[${mealIndex}].recommendations`"
                rows="3"
                @update:model-value="updateMeal(mealIndex, { recommendations: $event })"
              />
            </div>
          </div>
        </div>
        <BaseButton
          type="button"
          variant="secondary"
          @click="addMeal"
        >
          Agregar comida
        </BaseButton>
      </div>
    </div>
  </div>
</template>
