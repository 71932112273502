import type { PhysicalMeasurement, PhysicalMeasurementAttributes } from '@/types/physical-measurement';
import { api } from './fetch-wrapper';

export const physicalMeasurementsApi = {
  async getAll(params: Record<string, unknown> = {}): Promise<PhysicalMeasurement[]> {
    const response = await api.get({ url: '/api/internal/physical_measurements', params });

    return response as PhysicalMeasurement[];
  },

  async get(id: number): Promise<PhysicalMeasurement> {
    const response = await api.get({ url: `/api/internal/physical_measurements/${id}` });

    return response as PhysicalMeasurement;
  },

  async create(attributes: PhysicalMeasurementAttributes): Promise<PhysicalMeasurement> {
    const response = await api.post({
      url: '/api/internal/physical_measurements',
      body: { physicalMeasurement: attributes },
    });

    return response as PhysicalMeasurement;
  },

  async update(id: number, attributes: Partial<PhysicalMeasurementAttributes>): Promise<PhysicalMeasurement> {
    const response = await api.patch({
      url: `/api/internal/physical_measurements/${id}`,
      body: { physicalMeasurement: attributes },
    });

    return response as PhysicalMeasurement;
  },
};
