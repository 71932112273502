<script setup lang="ts">
import { computed } from 'vue';
import type { PhysicalMeasurement } from '@/types/extended';
import type { ApexOptions } from 'apexcharts';
import BaseAssessmentChart from './base-assessment-chart.vue';

const props = defineProps<{
  physicalMeasurements: PhysicalMeasurement[];
}>();

/* eslint-disable no-magic-numbers */
function calculateSomatotypeCoordinates(endo: number, meso: number, ecto: number): [number, number] {
  const sqrt3 = Math.sqrt(3) / 2;
  const x = -endo * sqrt3 + ecto * sqrt3;
  const y = -endo * 0.5 + meso - ecto * 0.5;

  return [x, y];
}
/* eslint-enable no-magic-numbers */

const chartData = computed(() => {
  const assessments = props.physicalMeasurements.map((pm) => pm.physicalAssessment);
  const coordinates = assessments.map((assessment) =>
    calculateSomatotypeCoordinates(assessment.endomorphy, assessment.mesomorphy, assessment.ectomorphy),
  );

  return { coordinates };
});

const chartOptions = computed<ApexOptions>(() => ({
  chart: {
    type: 'scatter' as const,
    zoom: { enabled: false },
    toolbar: { show: false },
    fontFamily: 'Inter var, sans-serif',
    height: 350,
    width: 350,
  },
  colors: ['#3B82F6'],
  xaxis: {
    title: { text: 'X' },
    min: -10,
    max: 10,
    tickAmount: 10,
    decimalsInFloat: 0,
  },
  yaxis: {
    title: { text: 'Y' },
    min: -10,
    max: 15,
    tickAmount: 10,
    decimalsInFloat: 0,
  },
  grid: {
    xaxis: { lines: { show: true } },
    yaxis: { lines: { show: true } },
  },
  annotations: {
    xaxis: [
      { x: 0, strokeDashArray: 4, borderColor: '#9C27B0' },
    ],
    yaxis: [
      { y: 0, strokeDashArray: 4, borderColor: '#9C27B0' },
    ],
    points: [
      {
        x: -8.66,
        y: -5,
        marker: { size: 0 },
        label: {
          text: 'Endomorfo',
          style: { color: '#fff', background: '#F59E0B' },
        },
      },
      {
        x: 8.66,
        y: -5,
        marker: { size: 0 },
        label: {
          text: 'Ectomorfo',
          style: { color: '#fff', background: '#3B82F6' },
        },
      },
      {
        x: 0,
        y: 10,
        marker: { size: 0 },
        label: {
          text: 'Mesomorfo',
          style: { color: '#fff', background: '#10B981' },
        },
      },
    ],
    shapes: [
      {
        type: 'path',
        path: 'M -8.66 -5 Q 0 12 8.66 -5 Q 0 -8 -8.66 -5',
        borderColor: '#9C27B0',
        borderWidth: 1,
        strokeDashArray: 4,
        fillOpacity: 0,
      },
    ],
  },
  markers: {
    size: 6,
    strokeWidth: 2,
    strokeColors: '#fff',
    hover: {
      size: 8,
    },
  },
}));

const chartSeries = computed(() => [{
  name: 'Somatotipo',
  data: chartData.value.coordinates,
}]);
</script>

<template>
  <BaseAssessmentChart
    title="Somatotipo"
    metric-key="somatotype"
    :chart-options="chartOptions"
    :chart-series="chartSeries"
  />
</template>
