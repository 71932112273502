<script setup lang="ts">
import { computed } from 'vue';
import { format } from 'date-fns';
import { useQuery } from '@tanstack/vue-query';
import type { Trainee } from '@/types/extended';
import { calBookingsApi } from '@/api';

const props = defineProps<{
  trainee: Trainee;
}>();

const bookingsQueryParams = computed(() => ({
  q: {
    'attendee_id_eq': props.trainee.id,
    'attendee_type_eq': 'Trainee',
    's': ['start_time desc'],
  },
}));

const { data: bookings } = useQuery({
  queryKey: ['cal-bookings', bookingsQueryParams],
  queryFn: () => calBookingsApi.getAll(bookingsQueryParams.value),
  initialData: [],
});

const organizerTypes = {
  'Trainer': 'Entrenamiento',
  'Measurer': 'Medición',
  'Nutritionist': 'Nutrición',
};

const organizerColors = {
  'Trainer': 'bg-green-100 text-green-800',
  'Measurer': 'bg-blue-100 text-blue-800',
  'Nutritionist': 'bg-yellow-100 text-yellow-800',
};
</script>

<template>
  <div class="flex flex-col gap-y-4">
    <h2 class="text-lg font-semibold">
      Reuniones
    </h2>
    <div class="overflow-hidden rounded-lg border border-gray-200 bg-white shadow">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th class="px-6 py-3 text-left text-sm font-semibold text-gray-900">
              Fecha
            </th>
            <th class="px-6 py-3 text-left text-sm font-semibold text-gray-900">
              Organizador
            </th>
            <th class="px-6 py-3 text-left text-sm font-semibold text-gray-900">
              Tipo
            </th>
            <th class="px-6 py-3 text-left text-sm font-semibold text-gray-900">
              Hora
            </th>
          </tr>
        </thead>
        <tbody
          v-if="bookings?.length"
          class="divide-y divide-gray-200"
        >
          <tr
            v-for="booking in bookings"
            :key="booking.id"
            class="hover:bg-gray-50"
          >
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
              {{ format(new Date(booking.startTime), 'dd/MM/yyyy') }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
              {{ booking.organizer.fullName }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
              <span
                :class="organizerColors[booking.organizerType]"
                class="inline-flex items-center rounded-full px-2 py-1 text-xs font-medium"
              >
                {{ organizerTypes[booking.organizerType] }}
              </span>
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
              {{ format(new Date(booking.startTime), 'HH:mm') }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              colspan="4"
              class="px-6 py-4 text-center text-sm text-gray-900"
            >
              No hay reuniones
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
