<script setup lang="ts">
import { computed } from 'vue';
import { format } from 'date-fns';
import { useQuery } from '@tanstack/vue-query';
import type { MealCheck, Trainee } from '@/types';
import type { NutritionObjective } from '@/types/extended';
import { nutritionPlansApi } from '@/api';

const props = defineProps<{
  trainee: Trainee;
}>();

const { data: nutritionPlans } = useQuery({
  queryKey: ['nutrition-plans', props.trainee.id],
  queryFn: () => nutritionPlansApi.getAll({
    q: {
      'trainee_id_eq': props.trainee.id,
    },
  }),
  initialData: [],
});

const meals = computed(() => {
  const objectives: NutritionObjective[] = nutritionPlans.value?.flatMap((plan) => plan.nutritionObjectives);

  return objectives?.flatMap((objective) => objective.meals);
});

const mealChecks = computed<MealCheck[]>(() => {
  const checks = meals.value?.flatMap((meal) => meal.mealChecks);

  return checks?.sort((a, b) => new Date(b.checkedAt).getTime() - new Date(a.checkedAt).getTime());
});

const statusLabels = {
  failed: 'No cumplido',
  mixed: 'Parcialmente cumplido',
  completed: 'Cumplido',
};

const statusColors = {
  failed: 'text-red-700 bg-red-50',
  mixed: 'text-yellow-700 bg-yellow-50',
  completed: 'text-green-700 bg-green-50',
};

function getMealName(mealId: number) {
  return meals?.value?.find((meal) => meal.id === mealId)?.name;
}
</script>

<template>
  <div class="flex flex-col gap-y-4">
    <h2 class="text-lg font-semibold">
      Reportes de comidas
    </h2>
    <div class="overflow-hidden rounded-lg border border-gray-200 bg-white shadow">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th class="px-6 py-3 text-left text-sm font-semibold text-gray-900">
              Fecha
            </th>
            <th class="px-6 py-3 text-left text-sm font-semibold text-gray-900">
              Comida
            </th>
            <th class="px-6 py-3 text-left text-sm font-semibold text-gray-900">
              Estado
            </th>
            <th class="px-6 py-3 text-left text-sm font-semibold text-gray-900">
              Comentario
            </th>
          </tr>
        </thead>
        <tbody
          v-if="mealChecks?.length"
          class="divide-y divide-gray-200"
        >
          <tr
            v-for="check in mealChecks"
            :key="check.id"
            class="hover:bg-gray-50"
          >
            <td class="px-6 py-4 text-sm text-gray-900">
              {{ format(new Date(check.checkedAt), 'dd/MM/yyyy') }}
            </td>
            <td class="px-6 py-4 text-sm">
              {{ getMealName(check.mealId) }}
            </td>
            <td class="px-6 py-4 text-sm">
              <span
                class="inline-flex rounded-lg px-2 py-1 text-xs font-medium"
                :class="statusColors[check.status]"
              >
                {{ statusLabels[check.status] }}
              </span>
            </td>
            <td class="px-6 py-4 text-sm text-gray-900">
              {{ check.comment }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              colspan="4"
              class="px-6 py-4 text-center text-sm text-gray-900"
            >
              No hay reportes de comidas
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
