<script setup lang="ts">
import { ref, onMounted } from 'vue';
import type { TimeSlot } from '@/types/cal';
import { calApi } from '@/api/cal';
import CalendarWeek from '../calendar-week.vue';
import TimeSlots from '../time-slots.vue';

interface Props {
  selectedSlot: TimeSlot | null;
  eventTypeId: number;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'update:selectedSlot', slot: TimeSlot): void;
  (e: 'continue'): void;
}>();

const isLoadingSlots = ref(false);
const availableSlots = ref<Record<string, TimeSlot[]>>({});
const currentWeek = ref<Date[]>([]);
const selectedDate = ref<Date | null>(null);

function formatDateToString(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}${month}${day}`;
}

function getFirstAvailableDate(): Date {
  const availableDates = Object.keys(availableSlots.value).sort();
  if (availableDates.length === 0) return new Date();

  const firstDate = availableDates[0];
  const year = parseInt(firstDate.substring(0, 4), 10);
  const month = parseInt(firstDate.substring(4, 6), 10) - 1;
  const day = parseInt(firstDate.substring(6, 8), 10);

  return new Date(year, month, day);
}

// eslint-disable-next-line max-statements, complexity
function generateWeekDays(date: Date): Date[] {
  const week: Date[] = [];
  const start = new Date(date);

  if (currentWeek.value.length === 0) {
    const firstAvailable = getFirstAvailableDate();
    if (firstAvailable > start) {
      start.setTime(firstAvailable.getTime());
    }
  }

  const dayOfWeek = start.getDay();
  const diff = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
  start.setDate(start.getDate() + diff);

  if (currentWeek.value.length === 0 && Object.keys(availableSlots.value).some(dateStr => dateStr <= formatDateToString(start))) {
    start.setDate(start.getDate() - 7);
  }

  for (let i = 0; i < 7; i++) {
    week.push(new Date(start));
    start.setDate(start.getDate() + 1);
  }

  return week;
}

function getAvailableSlotsForDate(date: Date): TimeSlot[] {
  const dateStr = formatDateToString(date);

  return availableSlots.value[dateStr] || [];
}

// eslint-disable-next-line max-statements
async function fetchAvailableSlots(): Promise<void> {
  isLoadingSlots.value = true;
  try {
    const startTime = new Date();
    const endTime = new Date();
    endTime.setDate(endTime.getDate() + 14);

    const response = await calApi.getAvailableSlots({
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
      eventTypeId: props.eventTypeId,
    });

    availableSlots.value = response.data.slots;

    // Find the first date with available slots
    const availableDates = Object.keys(response.data.slots)
      .filter(dateStr => response.data.slots[dateStr].length > 0)
      .sort();

    if (availableDates.length > 0) {
      const firstDate = availableDates[0];
      const year = parseInt(firstDate.substring(0, 4), 10);
      const month = parseInt(firstDate.substring(4, 6), 10) - 1;
      const day = parseInt(firstDate.substring(6, 8), 10);
      const firstAvailableDate = new Date(year, month, day);

      // If current week has no slots, navigate to the week containing the first available slot
      if (currentWeek.value.length > 0) {
        const hasAvailableSlotsInCurrentWeek = currentWeek.value.some(date => {
          const dateStr = formatDateToString(date);
          return response.data.slots[dateStr]?.length > 0;
        });

        if (!hasAvailableSlotsInCurrentWeek) {
          currentWeek.value = generateWeekDays(firstAvailableDate);
        }
      }

      selectedDate.value = firstAvailableDate;
    }
  } catch (error) {
    console.error('Error fetching slots:', error);
  } finally {
    isLoadingSlots.value = false;
  }
}

function navigateWeek(direction: 'prev' | 'next'): void {
  const newDate = new Date(currentWeek.value[0]);
  newDate.setDate(newDate.getDate() + (direction === 'next' ? 7 : -7));
  currentWeek.value = generateWeekDays(newDate);
  emit('update:selectedSlot', null as unknown as TimeSlot);
  selectedDate.value = null;
  void fetchAvailableSlots();
}

async function handleSlotSelect(slot: TimeSlot): Promise<void> {
  emit('update:selectedSlot', slot);
}

onMounted(async () => {
  await fetchAvailableSlots();
  const firstAvailable = getFirstAvailableDate();
  currentWeek.value = generateWeekDays(firstAvailable);
  // Fetch again to get slots for the current week
  await fetchAvailableSlots();
});
</script>

<template>
  <div class="rounded-xl border border-gray-200 bg-white p-6 shadow-sm md:p-8">
    <h2 class="mb-8 text-3xl font-bold text-gray-900">
      Selecciona fecha y hora
    </h2>

    <div
      v-if="isLoadingSlots"
      class="flex justify-center"
    >
      <div class="size-8 animate-spin rounded-full border-4 border-gray-300 border-t-black" />
    </div>

    <template v-else>
      <div class="space-y-6">
        <CalendarWeek
          v-model:selectedDate="selectedDate"
          :current-week="currentWeek"
          :available-slots="availableSlots"
          @navigate="navigateWeek"
        />

        <div
          v-if="!currentWeek.some(date => getAvailableSlotsForDate(date).length > 0)"
          class="rounded-lg bg-yellow-50 p-4"
        >
          <div class="flex">
            <div class="shrink-0">
              <svg
                class="size-5 text-yellow-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-yellow-800">
                No hay horarios disponibles
              </h3>
              <div class="mt-2 text-sm text-yellow-700">
                <p>No hay horarios disponibles para esta semana. Por favor, selecciona otra semana.</p>
              </div>
            </div>
          </div>
        </div>

        <TimeSlots
          v-if="selectedDate && getAvailableSlotsForDate(selectedDate).length > 0"
          :selected-date="selectedDate"
          :available-slots="getAvailableSlotsForDate(selectedDate)"
          :selected-slot="selectedSlot"
          @select="handleSlotSelect"
        />

        <div
          v-if="selectedSlot"
          class="mt-6"
        >
          <button
            type="button"
            class="w-full rounded-lg bg-black px-4 py-3 text-white transition duration-300 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            @click="$emit('continue')"
          >
            Continuar
          </button>
        </div>
      </div>
    </template>
  </div>
</template>
