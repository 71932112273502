<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import { loadMercadoPago } from '@mercadopago/sdk-js';
import { paymentsApi } from '@/api/payments';
import BaseImage from '@/components/base-image.vue';
import { Calendar, PenLine, XCircle } from 'lucide-vue-next';
import type { TimeSlot } from '@/types/cal';
import type { ContactFormData, PaymentBrickController, PaymentBrickSettings } from '../types';

interface Props {
  selectedSlot: TimeSlot;
  contactInfo: ContactFormData;
  preferenceId: string;
  amount: number;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'back'): void;
  (e: 'back-to-slot'): void;
  (e: 'complete'): void;
}>();

const paymentBrickController = ref<PaymentBrickController | null>(null);
const paymentError = ref<string | null>(null);

async function initializePayment(): Promise<void> {
  paymentError.value = null;
  await loadMercadoPago();
  // @ts-expect-error MercadoPago is loaded globally
  const mp = new window.MercadoPago('APP_USR-248cf9ea-6fb3-4ccb-9c01-49894a4944cb', {
    locale: 'es-CL',
  });

  const bricksBuilder = mp.bricks();

  const settings: PaymentBrickSettings = {
    initialization: {
      amount: props.amount,
      preferenceId: props.preferenceId,
    },
    customization: {
      visual: {
        style: {
          theme: 'default',
          customVariables: {
            formPadding: '10px',
            formHorizontalPadding: '10px',
            formVerticalPadding: '16px',
          },
        },
      },
      paymentMethods: {
        // mercadoPago: 'all',
        ticket: 'all',
        atm: 'all',
        onboardingCredits: 'all',
        walletPurchase: 'all',
        debitCard: 'all',
        creditCard: 'all',
        maxInstallments: 1,
      },
    },
    callbacks: {
      onReady: () => {
        console.log('Payment brick ready');
      },
      onSubmit: async ({ formData }: { formData: unknown }) => {
        paymentError.value = null;
        try {
          const paymentResponse = await paymentsApi.processPayment(formData);
          if (paymentResponse.success) {
            emit('complete');
          } else {
            paymentError.value = 'El pago fue rechazado. Por favor, intenta con otro método de pago.';
          }
        } catch (error) {
          paymentError.value = 'Hubo un error procesando el pago. Por favor, intenta nuevamente.';
        }
      },
      onError: (error: unknown) => {
        paymentError.value = 'Hubo un error con el formulario de pago. Por favor, recarga la página.';
      },
    },
  };

  paymentBrickController.value = await bricksBuilder.create(
    'payment',
    'paymentBrick_container',
    settings,
  );
}

onMounted(() => {
  void initializePayment();
});

onUnmounted(() => {
  if (paymentBrickController.value) {
    paymentBrickController.value.unmount();
    paymentBrickController.value = null;
  }
});
</script>

<template>
  <div class="rounded-xl border border-gray-200 bg-white px-0 py-6 shadow-sm md:p-8">
    <h2 class="mx-6 mb-8 text-3xl font-bold text-gray-900">
      Confirmar cita
    </h2>

    <!-- Selected Slot Section -->
    <div class="mx-6 mb-8 flex items-start gap-4 rounded-lg border border-gray-100 bg-white p-4 shadow-sm">
      <div class="shrink-0">
        <div class="flex size-10 items-center justify-center rounded-full bg-black text-white">
          <Calendar class="size-5" />
        </div>
      </div>
      <div class="flex-1">
        <div class="flex items-center justify-between">
          <h4 class="text-lg font-medium text-gray-900">
            Medición antropométrica
          </h4>
          <button
            type="button"
            class="inline-flex items-center gap-1.5 rounded-md px-2 py-1 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-black"
            @click="$emit('back-to-slot')"
          >
            <PenLine class="size-4" />
            <span class="hidden sm:inline">Cambiar horario</span>
          </button>
        </div>
        <div class="mt-2 space-y-1">
          <p class="text-base capitalize text-gray-600">
            {{ new Date(selectedSlot.time || selectedSlot.startTime || '').toLocaleDateString('es-CL', {
              weekday: 'long',
              day: 'numeric',
              month: 'long'
            }) }}
          </p>
          <p class="text-base font-medium text-gray-900">
            {{ new Date(selectedSlot.time || selectedSlot.startTime || '').toLocaleTimeString('es-CL', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            }) }} hrs
          </p>
          <div class="text-base text-gray-600">
            {{ contactInfo.address }}, {{ contactInfo.commune }}
          </div>
        </div>
      </div>
    </div>

    <!-- Payment Error Alert -->
    <div
      v-if="paymentError"
      class="mx-6 mb-8 flex items-start gap-3 rounded-lg border border-red-200 bg-red-50 p-4"
    >
      <XCircle class="size-5 shrink-0 text-red-600" />
      <div class="flex-1 text-sm text-red-600">
        {{ paymentError }}
      </div>
    </div>

    <!-- Payment Section -->
    <div>
      <!-- Price Section -->
      <div class="border-b border-gray-100 p-6">
        <div class="flex items-center justify-between">
          <div class="flex items-baseline gap-1">
            <span class="text-2xl font-medium text-gray-900">$</span>
            <span class="text-3xl font-bold text-gray-900">13.990</span>
          </div>
          <span class="rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800">
            Pago único
          </span>
        </div>
        <p class="mt-1 text-base text-gray-600">
          Medición de composición corporal a domicilio
        </p>
      </div>

      <!-- Divider -->
      <div class="h-px border-b border-gray-100" />

      <!-- MercadoPago logo -->
      <div class="mx-4 my-6 flex justify-start">
        <BaseImage
          src="mercadopago.webp"
          alt="MercadoPago"
          class="h-10"
        />
      </div>

      <!-- Payment Brick Container -->
      <div
        id="paymentBrick_container"
        class="w-full p-2"
      />
    </div>
  </div>
</template>

<style>
/* Add custom styles for MercadoPago iframe if needed */
#paymentBrick_container {
  min-height: 500px;
}
</style>
