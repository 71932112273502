<script setup lang="ts">
import { ref, computed, provide, toRef } from 'vue';
import { useQuery, useQueryClient } from '@tanstack/vue-query';
import { format, startOfWeek, addWeeks, subWeeks, parseISO, isEqual, endOfWeek } from 'date-fns';
import { useUrlSearchParams } from '@vueuse/core';
import { ChevronLeft, ChevronRight } from 'lucide-vue-next';
import type { Routine, WorkoutPlan, Trainee } from '@/types/extended';
import { routinesApi } from '@/api';
import { BaseMenu, BaseButton, RoutineForm } from '@/components';
import RoutineGeneratorModal from './routine-generator-modal.vue';
import NewRoutineModal from './new-routine-modal.vue';
import CloneAllRoutinesModal from './clone-all-routines-modal.vue';
import PublishAllRoutinesModal from './publish-all-routines-modal.vue';
import TraineeInfo from './trainee-info.vue';
import DeleteAllRoutinesModal from './delete-all-routines-modal.vue';

interface Props {
  workoutPlan: WorkoutPlan;
  routines: Routine[];
  trainee: Trainee;
}

const props = defineProps<Props>();

provide('trainee', toRef(props.trainee));
provide('trainerId', toRef(props.workoutPlan.trainerId));

const searchParams = useUrlSearchParams('history');

const date = ref(
  searchParams.date ?
    parseISO(searchParams.date as string) :
    new Date(),
);
const weekBounds = computed(() => {
  const weekStart = startOfWeek(date.value, { weekStartsOn: 1 });
  const weekEnd = endOfWeek(date.value, { weekStartsOn: 1 });

  return { start: weekStart, end: weekEnd };
});

const routineQueryParams = computed(() => ({
  q: {
    'workout_plan_id_eq': props.workoutPlan.id,
    'scheduled_at_gteq': weekBounds.value.start.toISOString(),
    'scheduled_at_lteq': weekBounds.value.end.toISOString(),
  },
}));

const { data: weekRoutines, isFetching: isFetchingRoutines } = useQuery({
  queryKey: ['routines', routineQueryParams],
  queryFn: () => routinesApi.getAll(routineQueryParams.value),
  initialData: props.routines,
  staleTime: 1000,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
});

const queryClient = useQueryClient();
function handleRoutineUpdate(updatedRoutine: Routine) {
  queryClient.setQueryData(
    ['routines', routineQueryParams],
    (previous: Routine[]) => previous.map(routine => (routine.id === updatedRoutine.id ? updatedRoutine : routine)),
  );
}

const daysOfWeek = ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'];

const groupedRoutines = computed(() => {
  const groups: { [key: string]: Routine[] } = {};
  weekRoutines.value.forEach(routine => {
    const day = format(new Date(routine.scheduledAt), 'EEEE');
    if (!groups[day]) {
      groups[day] = [];
    }
    groups[day].push(routine);
  });

  return groups;
});

const daysWithRoutines = computed(
  () => daysOfWeek.filter(day => groupedRoutines.value[day] && groupedRoutines.value[day].length > 0),
);

const weekRange = computed(() =>
  `${format(weekBounds.value.start, 'd MMM')} - ${format(weekBounds.value.end, 'd MMM yyyy')}`,
);

function invalidateWeekRoutines() {
  queryClient.invalidateQueries({ queryKey: ['routines', routineQueryParams] });
}

function updateUrlAndInvalidateWeekRoutines() {
  searchParams.date = format(date.value, 'yyyy-MM-dd');
  invalidateWeekRoutines();
}

function handlePreviousWeek() {
  date.value = subWeeks(date.value, 1);
  updateUrlAndInvalidateWeekRoutines();
}

function handleNextWeek() {
  date.value = addWeeks(date.value, 1);
  updateUrlAndInvalidateWeekRoutines();
}

const isCurrentWeek = computed(() =>
  isEqual(
    startOfWeek(date.value, { weekStartsOn: 1 }),
    startOfWeek(new Date(), { weekStartsOn: 1 }),
  ),
);

function handleSetCurrentWeek() {
  date.value = new Date();
  updateUrlAndInvalidateWeekRoutines();
}

const isCloneAllRoutinesModalOpen = ref(false);
const isPublishAllRoutinesModalOpen = ref(false);
const isDeleteAllRoutinesModalOpen = ref(false);
const isRoutineGeneratorModalOpen = ref(false);
const addRoutineFrom = ref<'scratch' | 'template' | undefined>(undefined);

const allRoutinesPublished = computed(() => weekRoutines.value.every(routine => routine.isPublished));

</script>

<template>
  <div class="flex flex-col gap-4">
    <h2 class="text-2xl font-bold">
      {{ props.workoutPlan.name }}
    </h2>
    <TraineeInfo :trainee="props.trainee" />
    <div class="mb-2 flex items-center justify-between">
      <div class="flex items-center">
        <h2 class="mr-4 w-48 text-lg font-bold">
          {{ weekRange }}
        </h2>
        <BaseButton
          variant="ghost"
          size="sm"
          @click="handlePreviousWeek"
        >
          <ChevronLeft class="size-5" />
        </BaseButton>
        <BaseButton
          variant="ghost"
          size="sm"
          class="mr-2"
          @click="handleNextWeek"
        >
          <ChevronRight class="size-5" />
        </BaseButton>
        <BaseButton
          v-if="!isCurrentWeek"
          variant="ghost"
          size="sm"
          @click="handleSetCurrentWeek"
        >
          Hoy
        </BaseButton>
      </div>
      <div class="flex gap-2">
        <BaseMenu
          :items="[
            {
              label: 'Publicar rutinas de la semana',
              onClick: () => { isPublishAllRoutinesModalOpen = true },
              disabled: allRoutinesPublished
            },
            {
              label: 'Clonar rutinas de la semana',
              onClick: () => { isCloneAllRoutinesModalOpen = true }
            },
            {
              label: 'Eliminar rutinas de la semana',
              onClick: () => { isDeleteAllRoutinesModalOpen = true }
            },
          ]"
        >
          <template #button>
            <BaseButton variant="secondary">
              Acciones en masa
            </BaseButton>
          </template>
        </BaseMenu>
        <BaseMenu
          :items="[
            { label: 'Usar rutina vacía', onClick: () => { addRoutineFrom = 'scratch'; } },
            { label: 'Usar una plantilla', onClick: () => { addRoutineFrom = 'template'; } },
            { label: 'Generar con IA', onClick: () => { isRoutineGeneratorModalOpen = true; } },
          ]"
        >
          <template #button>
            <BaseButton variant="secondary">
              Agregar rutina
            </BaseButton>
          </template>
        </BaseMenu>
        <NewRoutineModal
          v-if="addRoutineFrom !== undefined"
          :workout-plan-id="props.workoutPlan.id"
          :from="addRoutineFrom"
          @close="addRoutineFrom = undefined"
        />
        <RoutineGeneratorModal
          :workout-plan-id="props.workoutPlan.id"
          :is-open="isRoutineGeneratorModalOpen"
          @close="isRoutineGeneratorModalOpen = false"
        />
        <CloneAllRoutinesModal
          :is-open="isCloneAllRoutinesModalOpen"
          :routines="weekRoutines"
          :workout-plan-id="props.workoutPlan.id"
          @close="isCloneAllRoutinesModalOpen = false"
        />
        <PublishAllRoutinesModal
          :is-open="isPublishAllRoutinesModalOpen"
          :routines="weekRoutines"
          @close="isPublishAllRoutinesModalOpen = false"
          @published="invalidateWeekRoutines"
        />
        <DeleteAllRoutinesModal
          :is-open="isDeleteAllRoutinesModalOpen"
          :routines="weekRoutines"
          @close="isDeleteAllRoutinesModalOpen = false"
          @deleted="invalidateWeekRoutines"
        />
      </div>
    </div>
    <div class="relative">
      <div
        v-if="isFetchingRoutines"
        class="flex w-full gap-4 overflow-x-auto pb-4"
      >
        <div
          v-for="day in daysOfWeek"
          :key="day"
          class="flex w-96 flex-none flex-col gap-2"
        >
          <h3 class="text-center font-semibold">
            {{ day }}
          </h3>
          <div
            class="rounded border border-gray-200 bg-white p-4 text-sm shadow-sm"
          >
            <div class="animate-pulse space-y-2">
              <div class="h-8 w-3/4 rounded bg-gray-200" />
              <div class="h-8 w-1/2 rounded bg-gray-200" />
              <div class="h-8 w-2/3 rounded bg-gray-200" />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="daysWithRoutines.length > 0"
        ref="weekContainer"
        class="flex w-full gap-4 overflow-x-auto pb-4"
      >
        <div
          v-for="day in daysWithRoutines"
          :key="day"
          class="flex w-96 flex-none flex-col gap-2"
        >
          <h3 class="text-center font-semibold">
            {{ day }}
          </h3>
          <div
            v-for="routine in groupedRoutines[day] || []"
            :key="routine.id"
            class="rounded border border-gray-200 bg-white p-4 text-sm shadow-sm"
          >
            <RoutineForm
              :routine="routine"
              :workout-plan-id="props.workoutPlan.id"
              @update="handleRoutineUpdate"
            />
          </div>
        </div>
      </div>
      <div
        v-else
        class="flex h-full items-center justify-center"
      >
        <p class="text-gray-500">
          No hay rutinas para esta semana
        </p>
      </div>
    </div>
  </div>
</template>
