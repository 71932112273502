<script setup lang="ts">
import { differenceInYears } from 'date-fns';
import type { PhysicalMeasurement, Measuree } from '@/types';
import { BackButton, BaseButton, PhysicalMeasurementsList } from '@/components';
import { Plus, PhoneOutgoing, MessageCircleCode, MapPin } from 'lucide-vue-next';

interface Props {
  measuree: Measuree;
  physicalMeasurements: PhysicalMeasurement[];
}

const props = defineProps<Props>();

function getAge(birthdate: string | null) {
  return birthdate ? differenceInYears(new Date(), new Date(birthdate)) : null;
}

function getSex(sex: string | null) {
  if (!sex) return null;

  return {
    male: 'Masculino',
    female: 'Femenino',
  }[sex];
}

function getWhatsAppUrl(phoneNumber: string | null): string | undefined {
  if (!phoneNumber) return undefined;

  return `https://wa.me/${phoneNumber}`;
}

function getMapUrl(address: string | null): string | undefined {
  if (!address) return undefined;
  const cleanedAddress = address.replace(/\s*\(.*?\)\s*/g, '');

  return `https://google.com/maps/search/${cleanedAddress}`;
}

function handleNewPhysicalMeasurement() {
  window.location.href = `/measurees/${props.measuree.id}/physical_measurements/new`;
}
</script>

<template>
  <div class="space-y-4">
    <BackButton href="/cal_bookings" />
    <div class="space-y-6">
      <!-- Header -->
      <div class="flex items-end justify-between gap-4">
        <div class="space-y-2">
          <h1 class="text-3xl font-semibold text-gray-900">
            {{ props.measuree.fullName }}
          </h1>
          <div class="flex flex-col gap-2">
            <span
              class="inline-flex w-32 items-center justify-center rounded-full bg-gray-100 px-2.5 py-1 text-xs font-medium text-gray-800"
            >
              Sin suscripción
            </span>
          </div>
        </div>
        <BaseButton
          type="button"
          class="inline-flex items-center gap-x-2"
          @click="handleNewPhysicalMeasurement"
        >
          <Plus class="size-4" />
          Crear medición
        </BaseButton>
      </div>

      <!-- Info Cards -->
      <div class="grid gap-4 sm:grid-cols-2">
        <div class="overflow-hidden rounded-lg border border-gray-200 bg-white shadow">
          <div class="border-b border-gray-200 bg-gray-50 px-4 py-3">
            <h3 class="text-sm font-medium text-gray-500">
              Acciones
            </h3>
          </div>
          <div class="flex flex-col gap-3 p-4">
            <a
              v-if="getMapUrl(props.measuree.address)"
              :href="getMapUrl(props.measuree.address)"
              target="_blank"
              rel="noopener noreferrer"
              class="inline-flex items-center justify-start gap-x-2 whitespace-nowrap rounded-md bg-slate-100 px-5 py-3 font-medium text-slate-900 hover:bg-slate-100/80 dark:bg-slate-800 dark:text-slate-50 dark:hover:bg-slate-800/80"
            >
              <MapPin class="size-5" /> Ver en mapa
            </a>
            <a
              v-if="getWhatsAppUrl(props.measuree.phoneNumber)"
              :href="getWhatsAppUrl(props.measuree.phoneNumber)"
              target="_blank"
              rel="noopener noreferrer"
              class="inline-flex items-center justify-start gap-x-2 whitespace-nowrap rounded-md bg-slate-100 px-5 py-3 font-medium text-slate-900 hover:bg-slate-100/80 dark:bg-slate-800 dark:text-slate-50 dark:hover:bg-slate-800/80"
            >
              <MessageCircleCode class="size-5" /> WhatsApp
            </a>
            <a
              v-if="props.measuree.phoneNumber"
              :href="`tel:${props.measuree.phoneNumber}`"
              class="inline-flex items-center justify-start gap-x-2 whitespace-nowrap rounded-md bg-slate-100 px-5 py-3 font-medium text-slate-900 hover:bg-slate-100/80 dark:bg-slate-800 dark:text-slate-50 dark:hover:bg-slate-800/80"
            >
              <PhoneOutgoing class="size-5" /> Llamar
            </a>
          </div>
        </div>
      </div>

      <div class="grid gap-4 sm:grid-cols-2">
        <div class="overflow-hidden rounded-lg border border-gray-200 bg-white shadow">
          <div class="border-b border-gray-200 bg-gray-50 px-4 py-3">
            <h3 class="text-sm font-medium text-gray-500">
              Información personal
            </h3>
          </div>
          <div class="space-y-3 px-4 py-3">
            <div>
              <span class="text-sm text-gray-500">Email:</span>
              <span class="ml-2 text-base font-medium text-gray-900">{{ props.measuree.email }}</span>
            </div>
            <div>
              <span class="text-sm text-gray-500">Sexo:</span>
              <span class="ml-2 text-base font-medium text-gray-900">{{ getSex(props.measuree.sex) || 'No proporcionado' }}</span>
            </div>
            <div>
              <span class="text-sm text-gray-500">Celular:</span>
              <span class="ml-2 text-base font-medium text-gray-900">
                {{ props.measuree.phoneNumber }}
              </span>
            </div>
            <div>
              <span class="text-sm text-gray-500">Edad:</span>
              <span class="ml-2 text-base font-medium text-gray-900">
                {{ props.measuree.birthdate ? `${getAge(props.measuree.birthdate)} años` : 'No proporcionada' }}
              </span>
            </div>
            <div>
              <span class="text-sm text-gray-500">Dirección:</span>
              <span class="ml-2 text-base font-medium text-gray-900">
                {{ props.measuree.address }}
              </span>
            </div>
          </div>
        </div>

        <div class="overflow-hidden rounded-lg border border-gray-200 bg-white shadow">
          <div class="border-b border-gray-200 bg-gray-50 px-4 py-3">
            <h3 class="text-sm font-medium text-gray-500">
              Mediciones
            </h3>
          </div>
          <div class="p-4">
            <PhysicalMeasurementsList
              :physical-measurements="props.physicalMeasurements"
              :trainees="[]"
              :measurees="[props.measuree]"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
