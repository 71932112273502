<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue';

interface Props {
  calUrl: string;
  view: 'mobile' | 'desktop';
  nextUrl: string;
  queryParams?: Record<string, string>;
}

const props = defineProps<Props>();

// Create unique ID based on view type
const calendarId = `cal-inline-${props.view}`;
const namespace = `evaluacion-de-composicion-corporal-${props.view}`;

const queryString = props.queryParams ?
  `?${new URLSearchParams(props.queryParams).toString()}` :
  '';

function extractCalPath(url: string): string {
  try {
    // Extract path from full Cal.com URL
    const urlObject = new URL(url);

    return `${urlObject.pathname.replace(/^\//, '')}${queryString}`;
  } catch {
    return `${url}${queryString}`;
  }
}

/* eslint-disable */
// Ensure Cal.com script is only loaded once
function loadCalScript(): Promise<void> {
  return new Promise((resolve) => {
    if ((window as any).Cal?.loaded) {
      resolve();

      return;
    }

    (function (C, A, L) {
      const p = function (a: any, ar: any) {
        a.q.push(ar);
      };
      const d = C.document;
      C.Cal =
        C.Cal ||
        function () {
          const cal = C.Cal;
          const ar = arguments;
          if (!cal.loaded) {
            cal.ns = {};
            cal.q = cal.q || [];
            d.head.appendChild(d.createElement('script')).src = A;
            cal.loaded = true;
          }
          if (ar[0] === L) {
            const api = function () {
              p(api, arguments);
            };
            const namespace = ar[1];
            api.q = api.q || [];
            if (typeof namespace === 'string') {
              cal.ns[namespace] = cal.ns[namespace] || api;
              p(cal.ns[namespace], ar);
              p(cal, ['initNamespace', namespace]);
            } else p(cal, ar);

            return;
          }
          p(cal, ar);
        };
    })(window, 'https://app.cal.com/embed/embed.js', 'init');
    resolve();
  });
}

// Initialize Cal.com embed after component is mounted
onMounted(async () => {
  await loadCalScript();

  (window as any).Cal('init', namespace, { origin: 'https://cal.com' })
  ;(window as any).Cal.ns[namespace]('inline', {
    elementOrSelector: `#${calendarId}`,
    config: { layout: 'month_view' },
    calLink: extractCalPath(props.calUrl),
  })

  ;(window as any).Cal.ns[namespace]('ui', {
    hideEventTypeDetails: false,
    layout: 'month_view',
  });

  // Add booking completed listener
  (window as any).Cal.ns[namespace]('on', {
    action: 'bookingSuccessful',
    callback: () => {
      window.location.href = props.nextUrl;
    },
  });
});

// Clean up Cal instance when component is unmounted
onUnmounted(() => {
  if ((window as any).Cal?.ns?.[namespace]) {
    delete (window as any).Cal.ns[namespace];
  }
});
/* eslint-enable */
</script>

<template>
  <div
    :id="calendarId"
    class="size-full overflow-auto"
  />
</template>
