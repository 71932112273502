<script setup lang="ts">
import { ref, computed, watch } from 'vue';

type Country = {
  code: string;
  name: string;
  prefix: string;
  format: string;
  pattern: RegExp;
};

const COUNTRIES: Country[] = [
  // LATAM
  { code: 'CL', name: 'Chile', prefix: '56', format: '9XXXXXXXX', pattern: /^569\d{8}$/ },
  { code: 'AR', name: 'Argentina', prefix: '54', format: '9XXXXXXXXX', pattern: /^549\d{8}$/ },
  { code: 'PE', name: 'Perú', prefix: '51', format: '9XXXXXXXX', pattern: /^519\d{8}$/ },
  { code: 'CO', name: 'Colombia', prefix: '57', format: '3XXXXXXXXX', pattern: /^573\d{9}$/ },
  { code: 'MX', name: 'México', prefix: '52', format: '1XXXXXXXXX', pattern: /^521\d{9}$/ },
  { code: 'BR', name: 'Brasil', prefix: '55', format: 'XXXXXXXXXXX', pattern: /^55\d{10,11}$/ },
  { code: 'UY', name: 'Uruguay', prefix: '598', format: '9XXXXXXX', pattern: /^5989\d{7}$/ },
  // North America
  { code: 'US', name: 'United States', prefix: '1', format: 'XXXXXXXXXX', pattern: /^1\d{10}$/ },
  { code: 'CA', name: 'Canada', prefix: '1', format: 'XXXXXXXXXX', pattern: /^1\d{10}$/ },
  // Europe
  { code: 'ES', name: 'España', prefix: '34', format: 'XXXXXXXXX', pattern: /^34\d{9}$/ },
  { code: 'UK', name: 'United Kingdom', prefix: '44', format: 'XXXXXXXXXX', pattern: /^44\d{10}$/ },
  { code: 'DE', name: 'Germany', prefix: '49', format: 'XXXXXXXXXXX', pattern: /^49\d{10,11}$/ },
  { code: 'FR', name: 'France', prefix: '33', format: 'XXXXXXXXX', pattern: /^33\d{9}$/ },
  { code: 'IT', name: 'Italy', prefix: '39', format: 'XXXXXXXXXX', pattern: /^39\d{10}$/ },
];

const props = defineProps<{
  modelValue: string;
  required?: boolean;
  id?: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const selectedCountry = ref<Country>(COUNTRIES[0]);
const phoneNumber = ref('');

function parsePhoneNumber(value: string) {
  // First try to find a matching country prefix
  const country = COUNTRIES.find(c => value.startsWith(`+${c.prefix}`));

  if (country) {
    selectedCountry.value = country;
    // Remove the prefix and + sign to get the number
    phoneNumber.value = value.slice(country.prefix.length + 1);
  }
}

// Initialize from modelValue if it exists
if (props.modelValue) {
  parsePhoneNumber(props.modelValue);
}

// Watch for changes in modelValue
watch(() => props.modelValue, (newValue) => {
  if (newValue) {
    parsePhoneNumber(newValue);
  }
});

const isValid = computed(() => {
  if (!selectedCountry.value) return false;
  const cleanNumber = phoneNumber.value.replace(/\s/g, '');

  switch (selectedCountry.value.code) {
  case 'CL': return cleanNumber.length === 9;
  case 'AR': return cleanNumber.length === 10;
  case 'PE': return cleanNumber.length === 9;
  case 'CO': return cleanNumber.length === 10;
  case 'MX': return cleanNumber.length === 10;
  case 'BR': return cleanNumber.length >= 10 && cleanNumber.length <= 11;
  case 'UY': return cleanNumber.length === 8;
  case 'US':
  case 'CA': return cleanNumber.length === 10;
  case 'ES': return cleanNumber.length === 9;
  case 'UK': return cleanNumber.length === 10;
  case 'DE': return cleanNumber.length >= 10 && cleanNumber.length <= 11;
  case 'FR': return cleanNumber.length === 9;
  case 'IT': return cleanNumber.length === 10;
  default: return cleanNumber.length >= 8;
  }
});

function handleInput(event: Event) {
  const input = event.target as HTMLInputElement;
  phoneNumber.value = input.value;

  // Remove spaces and emit with country code
  const cleanNumber = phoneNumber.value.replace(/\s/g, '');
  const newValue = `+${selectedCountry.value.prefix}${cleanNumber}`;
  emit('update:modelValue', newValue);
}

function handleCountryChange(event: Event) {
  const select = event.target as HTMLSelectElement;
  const country = COUNTRIES.find(c => c.code === select.value);
  if (!country) return;

  selectedCountry.value = country;

  // Re-emit with new country code
  const cleanNumber = phoneNumber.value.replace(/\s/g, '');
  const newValue = `+${country.prefix}${cleanNumber}`;
  emit('update:modelValue', newValue);
}
</script>

<template>
  <div class="relative">
    <div class="flex gap-2">
      <select
        class="mt-1 w-32 rounded-md border border-gray-300 p-2 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
        :value="selectedCountry.code"
        @change="handleCountryChange"
      >
        <option
          v-for="country in COUNTRIES"
          :key="country.code"
          :value="country.code"
        >
          {{ country.code }} (+{{ country.prefix }})
        </option>
      </select>

      <input
        :id="id"
        v-model="phoneNumber"
        type="tel"
        :required="required"
        :placeholder="selectedCountry.format"
        class="mt-1 w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
        :class="{ 'border-red-300': phoneNumber && !isValid }"
        @input="handleInput"
      >
    </div>
  </div>
</template>
