import type { SurveyResponse } from '@/types';
import type { Lead } from '@/types/extended';
import { api } from './axios-wrapper';

export const leadsApi = {
  async create(surveyResponse: Partial<SurveyResponse>) {
    const path = '/api/internal/leads';

    const response = await api({
      method: 'post',
      url: path,
      data: {
        lead: {
          email: surveyResponse.email,
          firstName: surveyResponse.firstName,
          country: surveyResponse.country,
          referralSource: surveyResponse.referralSource,
          bodyCompositionSurveyResponsesAttributes: [{
            sex: surveyResponse.sex,
            age: surveyResponse.age,
            height: surveyResponse.height,
            weight: surveyResponse.weight,
            bodyType: surveyResponse.bodyType,
            exerciseFrequency: surveyResponse.exerciseFrequency,
            hasBodyFatMeasurement: Boolean(surveyResponse.hasBodyFatMeasurement),
            bodyFatPercentage: surveyResponse.bodyFatPercentage,
            bodyFatMeasurementMethod: surveyResponse.bodyFatMeasurementMethod,
            estimatedBodyFatOption: surveyResponse.estimatedBodyFatOption,
            hasWaistMeasurement: Boolean(surveyResponse.hasWaistMeasurement),
            waistCircumference: surveyResponse.waistCircumference,
            estimatedWaistOption: surveyResponse.estimatedWaistOption,
            hasMusclemassMeasurement: Boolean(surveyResponse.hasMusclemassMeasurement),
            musclemassKg: surveyResponse.muscleMassKg ? Number(surveyResponse.muscleMassKg) : null,
            musclemassMeasurementMethod: surveyResponse.musclemassMeasurementMethod,
            estimatedMusclemassOption: surveyResponse.estimatedMusclemassOption,
            isCurrentlyTraining: Boolean(surveyResponse.isCurrentlyTraining),
            currentTrainingDuration: surveyResponse.currentTrainingDuration,
            hasTrainedBefore: Boolean(surveyResponse.hasTrainedBefore),
            previousTrainingDuration: surveyResponse.previousTrainingDuration,
            strengthTrainingDuration: surveyResponse.strengthTrainingDuration,
          }],
        },
      },
    });

    return response.data as Lead;
  },
};
