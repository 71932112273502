<script setup lang="ts">
import { ref } from 'vue';
import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/vue-query';
import { format } from 'date-fns';
import { nutritionPlansApi } from '@/api/nutrition-plans';
import type { NutritionPlan } from '@/types/extended';
import { BaseButton, BaseInput, BaseLabel, BaseModal } from '@/components';

const props = defineProps<{
  nutritionPlan: NutritionPlan;
  isOpen: boolean;
}>();

const emit = defineEmits<{
  close: [isSuccess: boolean];
}>();

function defaultDate(date: string) {
  return format(new Date(date), "yyyy-MM-dd'T'HH:mm");
}

const startAt = ref(defaultDate(props.nutritionPlan.endAt));

const {
  isError,
  isPending,
  isSuccess,
  error: cloneNutritionPlanError,
  mutate: cloneNutritionPlan,
  reset: resetCloneNutritionPlan,
} = useMutation({
  mutationFn: (attributes: { startAt: string }) => nutritionPlansApi.clone({
    nutritionPlanId: props.nutritionPlan.id,
    startAt: attributes.startAt,
  }),
});

function handleClose() {
  startAt.value = defaultDate(props.nutritionPlan.startAt);
  const isCloneNutritionPlanSuccess = isSuccess.value;
  resetCloneNutritionPlan();
  emit('close', isCloneNutritionPlanSuccess);
}
</script>

<template>
  <BaseModal
    :open="isOpen"
    title="Clonar plan de nutrición"
    @close="handleClose"
  >
    <div
      v-if="isSuccess"
      class="flex flex-col gap-8"
    >
      <span>Plan de nutrición clonado exitosamente</span>
      <BaseButton @click="handleClose">
        Cerrar
      </BaseButton>
    </div>
    <div
      v-else-if="isPending"
      class="flex flex-col gap-8"
    >
      Cargando...
    </div>
    <div
      v-else-if="isError"
      class="flex flex-col gap-8"
    >
      <span>Ocurrió un error al clonar el plan de nutrición</span>
      <span
        v-if="cloneNutritionPlanError instanceof AxiosError"
        class="whitespace-pre-wrap text-sm"
      >
        {{ cloneNutritionPlanError.response?.data }}
      </span>
      <BaseButton @click="resetCloneNutritionPlan">
        Reintentar
      </BaseButton>
    </div>
    <div
      v-else
      class="flex flex-col gap-8"
    >
      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Fecha de inicio"
          name="newNutritionPlan.startAt"
          required
        />
        <BaseInput
          v-model="startAt"
          name="newNutritionPlan.startAt"
          type="datetime-local"
        />
      </div>
      <div class="flex justify-end gap-2">
        <BaseButton
          variant="outline"
          @click="handleClose"
        >
          Cancelar
        </BaseButton>
        <BaseButton
          :disabled="startAt === ''"
          @click="cloneNutritionPlan({ startAt })"
        >
          Clonar plan
        </BaseButton>
      </div>
    </div>
  </BaseModal>
</template>
