import React, { useCallback } from 'react';
import type { ChannelSort, StreamChat, ChannelFilters } from 'stream-chat';
import {
  Chat,
  Channel,
  ChannelList,
  ChannelHeader,
  LoadingIndicator,
  MessageList,
  MessageInput,
  Window,
  useCreateChatClient,
  type ChannelSearchFunctionParams,
} from 'stream-chat-react';
import 'stream-chat-react/dist/css/v2/index.css';
import '@/css/chat.css';
import { chatTokensApi } from '@/api';

const defaultLanguage = 'es';

function ChatLayout({ userId, appKey }: { userId: string; appKey: string }) {
  const getToken = useCallback(
    async () => await chatTokensApi.create({ userId }),
    [userId],
  );

  const filters = { members: { $in: [userId] }, type: 'messaging', archived: false };
  const options = { presence: true, state: true };
  const sort: ChannelSort = [{ 'pinned_at': 1 }, { 'last_message_at': -1 }, { 'updated_at': -1 }];

  const client = useCreateChatClient({
    apiKey: appKey,
    tokenOrProvider: getToken,
    userData: { id: userId },
  });

  async function customSearchFunction(props: ChannelSearchFunctionParams,
    event: { target: { value: string; }; },
    chatClient: StreamChat) {
    // eslint-disable-next-line react/prop-types
    const { setResults, setSearching, setQuery } = props;
    const value = event.target.value;
    const searchFilters: ChannelFilters = {
      ...(value ? { 'member.user.name': { $autocomplete: value } } : {}),
      members: { $in: [chatClient.userID ?? ''] },
    };

    setSearching(true);
    setQuery(value);
    const channels = await chatClient.queryChannels(searchFilters);
    setResults(channels);
    setSearching(false);
  }

  if (!client) return <LoadingIndicator />;

  return (
    <Chat
      client={client}
      defaultLanguage={defaultLanguage}
    >
      <ChannelList
        filters={filters}
        options={options}
        sort={sort}
        showChannelSearch
        additionalChannelSearchProps={{
          searchFunction: (params, event) => customSearchFunction(params, event, client),
        }}
      />
      <Channel>
        <Window>
          <ChannelHeader />
          <MessageList />
          <MessageInput grow audioRecordingEnabled />
        </Window>
      </Channel>
    </Chat>
  );
}

export { ChatLayout };
