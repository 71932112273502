import type { Booking, BookingPayload, AvailableSlots, AvailableSlotsParams } from '@/types';
import { api } from './axios-wrapper';

export const calApi = {
  async getAvailableSlots(params: AvailableSlotsParams) {
    const response = await api({
      method: 'get',
      url: '/api/internal/cal/available_slots',
      params: Object.fromEntries(
        Object.entries(params).map(([key, value]) => [
          key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`),
          value,
        ]),
      ),
    });

    return response.data as AvailableSlots;
  },

  async createBooking(booking: BookingPayload) {
    const response = await api({
      method: 'post',
      url: '/api/internal/cal/bookings',
      data: { booking },
    });

    return response.data as Booking;
  },
};
