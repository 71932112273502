<script setup lang="ts">
import { computed } from 'vue';
import {
  ClipboardCheck,
  Dumbbell,
  Utensils,
  Timer,
  Gauge,
  TrendingUp,
  HelpCircle,
  Activity,
  Scale,
  Apple,
  Egg,
} from 'lucide-vue-next';
import type { SurveyResponse, SurveyResult } from '@/types/body-composition-survey';

const props = defineProps<{
  result: SurveyResult
  response: Partial<SurveyResponse>
}>();

const encodedEmail = encodeURIComponent(props.response?.email || '');

const emit = defineEmits<{
  (e: 'open-explanation-modal', type: 'strength' | 'cardio' | 'calories' | 'protein'): void
}>();

// Parse the action plan text into sections
const parsedActionPlan = computed(() => {
  const description = getDescriptionText();

  return {
    description: description.split('\n'),
    exercise: getExerciseRecommendations(),
    nutrition: getNutritionRecommendations(),
  };
});

function getDescriptionText() {
  const quadrant = props.result.quadrant;
  const hasHighVatRisk = props.result.visceralFatProbability >= 0.7;
  const hasModerateVatRisk = props.result.visceralFatProbability >= 0.4;
  const vatRisk = hasHighVatRisk ? 'high' : hasModerateVatRisk ? 'moderate' : 'low';
  const calorieAdjustment = getCalorieAdjustment();

  switch (quadrant) {
  case 'one':
    return [
      'Tu objetivo principal es <strong>desarrollar masa muscular mientras reduces grasa corporal</strong>.',
      vatRisk === 'high' ?
        `Con tu nivel de riesgo de grasa visceral, implementaremos un déficit calórico del ${calorieAdjustment}% mientras realizamos entrenamiento de fuerza.` :
        `Te enfocarás en un déficit calórico del ${calorieAdjustment}% manteniendo una alta ingesta de proteína.`,
      'El entrenamiento de fuerza será fundamental para mantener y desarrollar masa muscular durante el déficit calórico.',
    ].join('\n');
  case 'two':
    return [
      'Tu objetivo es <strong>reducir grasa corporal mientras mantienes tu masa muscular actual</strong>.',
      vatRisk === 'high' ?
        `Con tu nivel de riesgo de grasa visceral, implementaremos un déficit calórico del ${calorieAdjustment}% realizando entrenamiento de fuerza.` :
        `Te centrarás en un déficit calórico del ${calorieAdjustment}%.`,
      'El entrenamiento de fuerza será clave para preservar tu masa muscular durante la pérdida de grasa.',
    ].join('\n');
  case 'three':
    return [
      'Tu prioridad es <strong>desarrollar masa muscular manteniendo un peso saludable</strong>.',
      vatRisk === 'high' ?
        'Debido a tu riesgo de grasa visceral, nos enfocaremos en recomposici��n corporal con calorías de mantenimiento.' :
        `Te enfocarás en un superávit calórico del ${calorieAdjustment}% con alta proteína.`,
      'El entrenamiento de fuerza progresivo será el pilar de tu programa para maximizar el desarrollo muscular.',
    ].join('\n');
  case 'four':
    return [
      'Según la información proporcionada, <strong>estimamos que tu composición corporal es saludable</strong>.',
      vatRisk === 'high' ?
        `Sin embargo, debido a tu riesgo de grasa visceral, implementaremos un déficit calórico del ${calorieAdjustment}%.` :
        'Tienes dos posibles objetivos: <strong>(1) mantener tu condición actual o (2) incrementar más tu masa muscular</strong>.',
      'Incrementar tu masa muscular te ayudará a tener una mejor salud de largo plazo y a reducir el riesgo de sarcopenia en la vejez.',
      'El plan que te proponemos abajo es para incrementar tu masa muscular.',
    ].join('\n');
  default:
    return '';
  }
}

function getExerciseRecommendations() {
  return [
    props.result.strengthFrequency,
    props.result.strengthFocus,
    props.result.strengthSets,
    `${props.result.cardioFrequency} de cardio (${props.result.cardioDuration})`,
    props.result.cardioType,
    props.result.cardioNote,
    `Objetivo diario de ${props.result.dailySteps}`,
  ];
}

function getNutritionRecommendations() {
  const hasHighVatRisk = props.result.visceralFatProbability >= 0.7;
  const quadrant = props.result.quadrant;

  const recommendations = [
    `Proteína ${quadrant === 'two' ? 'moderada-alta' : 'alta'}: ${props.result.totalProteinRangeMin}-${props.result.totalProteinRangeMax}g por día`,
    `Calorías: ${props.result.totalCalorieRangeMin}-${props.result.totalCalorieRangeMax}`,
    quadrant === 'four' ? 'Distribuye proteína en 4-5 comidas' : '4 comidas con proteína al día',
  ];

  if (hasHighVatRisk) {
    recommendations.push('Aumenta el consumo de fibra (25-35g/día)');
  } else {
    switch (quadrant) {
    case 'two':
      recommendations.push('Control de porciones');
      break;
    case 'three':
      recommendations.push('Timing nutricional óptimo');
      break;
    case 'four':
      recommendations.push('Aumenta carbohidratos alrededor del entrenamiento');
      break;
    }
  }

  return recommendations;
}

function getCalorieAdjustment() {
  return props.result.nutritionAdjustment;
}

// Get calorie strategy based on the nutrition strategy
const calorieStrategy = computed(() => {
  switch (props.result.nutritionStrategy) {
  case 'deficit':
    return `Déficit calórico del ${props.result.nutritionAdjustment}% para pérdida de grasa`;
  case 'surplus':
    return `Superávit calórico del ${props.result.nutritionAdjustment}% para ganancia muscular`;
  case 'maintenance':
    return 'Balance calórico para mantener peso';
  default:
    return 'Balance calórico personalizado';
  }
});
</script>

<template>
  <div class="rounded-xl border border-gray-200 bg-white p-4 shadow-sm md:p-8">
    <div class="flex items-center gap-2 text-gray-900">
      <ClipboardCheck class="size-5 md:size-6" />
      <h3 class="text-xl font-semibold md:text-2xl">
        Tu plan de acción
      </h3>
    </div>

    <div class="mt-4 space-y-2 md:mt-6">
      <p
        v-for="(line, index) in parsedActionPlan.description"
        :key="index"
        class="text-base text-gray-700 md:text-lg"
      >
        <span v-html="line" />
      </p>
    </div>

    <!-- Exercise Section -->
    <div class="mt-6 md:mt-8">
      <div class="flex items-center gap-2 text-gray-900">
        <Dumbbell class="size-4 md:size-5" />
        <h4 class="text-lg font-semibold md:text-xl">
          Plan de entrenamiento
        </h4>
      </div>

      <div class="mt-4 grid gap-4 sm:grid-cols-2 sm:gap-6">
        <!-- Strength Training Card -->
        <div class="rounded-xl border border-blue-100 bg-blue-50 p-4 md:p-6">
          <div class="mb-3 flex items-center justify-between md:mb-4">
            <div class="flex items-center gap-2">
              <div class="flex size-8 items-center justify-center rounded-lg bg-blue-100 md:size-10">
                <Dumbbell class="size-4 text-blue-600 md:size-5" />
              </div>
              <h5 class="text-base font-medium text-gray-900 md:text-lg">
                Fuerza
              </h5>
            </div>
            <button
              type="button"
              class="flex items-center gap-1 text-xs font-medium text-blue-600 hover:text-blue-700 md:text-sm"
              @click="emit('open-explanation-modal', 'strength')"
            >
              <HelpCircle class="size-3 md:size-4" />
              ¿Por qué?
            </button>
          </div>

          <div class="space-y-2 md:space-y-3">
            <div class="flex items-start gap-2 rounded-lg bg-white p-2 md:gap-3 md:p-3">
              <Timer class="mt-0.5 size-3 text-blue-600 md:size-4" />
              <div>
                <p class="text-sm font-medium text-gray-900 md:text-base">
                  Frecuencia
                </p>
                <p class="text-xs text-gray-600 md:text-sm">
                  {{ result.strengthFrequency }}
                </p>
              </div>
            </div>

            <div class="flex items-start gap-2 rounded-lg bg-white p-2 md:gap-3 md:p-3">
              <Gauge class="mt-0.5 size-3 text-blue-600 md:size-4" />
              <div>
                <p class="text-sm font-medium text-gray-900 md:text-base">
                  Series y Repeticiones
                </p>
                <p class="text-xs text-gray-600 md:text-sm">
                  {{ result.strengthSets }}
                </p>
              </div>
            </div>

            <div class="flex items-start gap-2 rounded-lg bg-white p-2 md:gap-3 md:p-3">
              <TrendingUp class="mt-0.5 size-3 text-blue-600 md:size-4" />
              <div>
                <p class="text-sm font-medium text-gray-900 md:text-base">
                  Enfoque
                </p>
                <p class="text-xs text-gray-600 md:text-sm">
                  {{ result.strengthFocus }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Cardio Card -->
        <div class="rounded-xl border border-green-100 bg-green-50 p-4 md:p-6">
          <div class="mb-3 flex items-center justify-between md:mb-4">
            <div class="flex items-center gap-2">
              <div class="flex size-8 items-center justify-center rounded-lg bg-green-100 md:size-10">
                <Activity class="size-4 text-green-600 md:size-5" />
              </div>
              <h5 class="text-base font-medium text-gray-900 md:text-lg">
                Cardio
              </h5>
            </div>
            <button
              type="button"
              class="flex items-center gap-1 text-xs font-medium text-green-600 hover:text-green-700 md:text-sm"
              @click="emit('open-explanation-modal', 'cardio')"
            >
              <HelpCircle class="size-3 md:size-4" />
              ¿Por qué?
            </button>
          </div>

          <div class="space-y-2 md:space-y-3">
            <div class="flex items-start gap-2 rounded-lg bg-white p-2 md:gap-3 md:p-3">
              <Timer class="mt-0.5 size-3 text-green-600 md:size-4" />
              <div>
                <p class="text-sm font-medium text-gray-900 md:text-base">
                  Frecuencia y Duración
                </p>
                <p class="text-xs text-gray-600 md:text-sm">
                  {{ result.cardioFrequency }} de cardio ({{ result.cardioDuration }})
                </p>
              </div>
            </div>

            <div class="flex items-start gap-2 rounded-lg bg-white p-2 md:gap-3 md:p-3">
              <Gauge class="mt-0.5 size-3 text-green-600 md:size-4" />
              <div>
                <p class="text-sm font-medium text-gray-900 md:text-base">
                  Tipo
                </p>
                <p class="text-xs text-gray-600 md:text-sm">
                  {{ result.cardioType }}
                </p>
              </div>
            </div>

            <div class="flex items-start gap-2 rounded-lg bg-white p-2 md:gap-3 md:p-3">
              <TrendingUp class="mt-0.5 size-3 text-green-600 md:size-4" />
              <div>
                <p class="text-sm font-medium text-gray-900 md:text-base">
                  Nota
                </p>
                <p class="text-xs text-gray-600 md:text-sm">
                  {{ result.cardioNote }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Nutrition Section -->
    <div class="mt-6 md:mt-8">
      <div class="flex items-center gap-2 text-gray-900">
        <Utensils class="size-4 md:size-5" />
        <h4 class="text-lg font-semibold md:text-xl">
          Plan de nutrición
        </h4>
      </div>

      <div class="mt-4 grid gap-4 sm:grid-cols-2 sm:gap-6">
        <!-- Calories Card -->
        <div class="rounded-xl border border-purple-100 bg-purple-50 p-4 md:p-6">
          <div class="mb-3 flex items-center justify-between md:mb-4">
            <div class="flex items-center gap-2">
              <div class="flex size-8 items-center justify-center rounded-lg bg-purple-100 md:size-10">
                <Scale class="size-4 text-purple-600 md:size-5" />
              </div>
              <h5 class="text-base font-medium text-gray-900 md:text-lg">
                Calorías
              </h5>
            </div>
            <button
              type="button"
              class="flex items-center gap-1 text-xs font-medium text-purple-600 hover:text-purple-700 md:text-sm"
              @click="emit('open-explanation-modal', 'calories')"
            >
              <HelpCircle class="size-3 md:size-4" />
              ¿Por qué?
            </button>
          </div>

          <div class="space-y-2 md:space-y-3">
            <div class="flex items-start gap-2 rounded-lg bg-white p-2 md:gap-3 md:p-3">
              <Apple class="mt-0.5 size-3 text-purple-600 md:size-4" />
              <div>
                <p class="text-sm font-medium text-gray-900 md:text-base">
                  Calorías Diarias
                </p>
                <p class="text-xs text-gray-600 md:text-sm">
                  {{ result.totalCalorieRangeMin }}-{{ result.totalCalorieRangeMax }} kcal
                </p>
              </div>
            </div>

            <div class="flex items-start gap-2 rounded-lg bg-white p-2 md:gap-3 md:p-3">
              <Gauge class="mt-0.5 size-3 text-purple-600 md:size-4" />
              <div>
                <p class="text-sm font-medium text-gray-900 md:text-base">
                  Distribución
                </p>
                <p class="text-xs text-gray-600 md:text-sm">
                  {{ parsedActionPlan.nutrition[0] }}
                </p>
              </div>
            </div>

            <div class="flex items-start gap-2 rounded-lg bg-white p-2 md:gap-3 md:p-3">
              <TrendingUp class="mt-0.5 size-3 text-purple-600 md:size-4" />
              <div>
                <p class="text-sm font-medium text-gray-900 md:text-base">
                  Estrategia
                </p>
                <p class="text-xs text-gray-600 md:text-sm">
                  {{ calorieStrategy }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Protein Card -->
        <div class="rounded-xl border border-orange-100 bg-orange-50 p-4 md:p-6">
          <div class="mb-3 flex items-center justify-between md:mb-4">
            <div class="flex items-center gap-2">
              <div class="flex size-8 items-center justify-center rounded-lg bg-orange-100 md:size-10">
                <Egg class="size-4 text-orange-600 md:size-5" />
              </div>
              <h5 class="text-base font-medium text-gray-900 md:text-lg">
                Proteína
              </h5>
            </div>
            <button
              type="button"
              class="flex items-center gap-1 text-xs font-medium text-orange-600 hover:text-orange-700 md:text-sm"
              @click="emit('open-explanation-modal', 'protein')"
            >
              <HelpCircle class="size-3 md:size-4" />
              ¿Por qué?
            </button>
          </div>

          <div class="space-y-2 md:space-y-3">
            <div class="flex items-start gap-2 rounded-lg bg-white p-2 md:gap-3 md:p-3">
              <Scale class="mt-0.5 size-3 text-orange-600 md:size-4" />
              <div>
                <p class="text-sm font-medium text-gray-900 md:text-base">
                  Consumo Diario
                </p>
                <p class="text-xs text-gray-600 md:text-sm">
                  {{ result.totalProteinRangeMin }}-{{ result.totalProteinRangeMax }}g por día
                </p>
              </div>
            </div>

            <div class="flex items-start gap-2 rounded-lg bg-white p-2 md:gap-3 md:p-3">
              <Scale class="mt-0.5 size-3 text-orange-600 md:size-4" />
              <div>
                <p class="text-sm font-medium text-gray-900 md:text-base">
                  Por Kg de Peso
                </p>
                <p class="text-xs text-gray-600 md:text-sm">
                  {{ (result.totalProteinRangeMin / (response.weight || 70)).toFixed(1) }}-{{ (result.totalProteinRangeMax / (response.weight || 70)).toFixed(1) }}g/kg
                </p>
              </div>
            </div>

            <div class="flex items-start gap-2 rounded-lg bg-white p-2 md:gap-3 md:p-3">
              <TrendingUp class="mt-0.5 size-3 text-orange-600 md:size-4" />
              <div>
                <p class="text-sm font-medium text-gray-900 md:text-base">
                  Recomendación
                </p>
                <p class="text-xs text-gray-600 md:text-sm">
                  {{ parsedActionPlan.nutrition[2] }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Disclaimer -->
    <div class="mt-6 rounded-lg border border-gray-300 bg-gray-50 p-4 text-sm text-gray-600 md:mx-0 md:p-6">
      <p>
        <strong>Importante:</strong> Estos resultados son estimaciones basadas en la información proporcionada.
        Las recomendaciones no sustituyen el consejo de un profesional de la salud.
        Con <strong>Kapso</strong> un equipo de profesionales te entrega una evaluación personalizada y recomendaciones adaptadas a tus necesidades.
      </p>
    </div>

    <!-- Kapso CTA -->
    <div class="mt-8 text-center">
      <a
        :href="`/onboarding/checkout/personalized?email=${encodedEmail}`"
        class="group inline-flex items-center justify-center space-x-2 rounded-lg bg-black px-6 py-3 text-base font-medium text-white shadow-lg transition-all duration-300 hover:bg-gray-800 hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 md:px-8 md:py-4 md:text-lg"
      >
        <span>Implementar plan con Kapso</span>
        <TrendingUp
          class="size-4 transition-transform duration-200 group-hover:translate-x-1 md:size-5"
        />
      </a>
    </div>
  </div>
</template>
