export type HealthCategory = {
  id: 'optimal' | 'moderate' | 'unhealthy';
  range: string;
  label: string;
};

export type AgeRanges = {
  young: {
    optimal: HealthCategory;
    moderate: HealthCategory;
    unhealthy: HealthCategory;
  };
  middle: {
    optimal: HealthCategory;
    moderate: HealthCategory;
    unhealthy: HealthCategory;
  };
  senior: {
    optimal: HealthCategory;
    moderate: HealthCategory;
    unhealthy: HealthCategory;
  };
};

export type BasicRanges = {
  optimal: HealthCategory;
  moderate: HealthCategory;
  unhealthy: HealthCategory;
};

export type MetricDescription = {
  description: string;
  healthyRange?: string;
  detailedRanges?: {
    male: BasicRanges | AgeRanges;
    female: BasicRanges | AgeRanges;
  };
  recommendations?: {
    optimal: string;
    moderate: string;
    unhealthy: string;
  };
  importance?: string;
};

export type MetricDescriptions = {
  [key: string]: MetricDescription;
};

export const physicalAssessmentMetrics: MetricDescriptions = {
  bodyMassIndex: {
    description: 'El Índice de Masa Corporal (IMC) es una medida que relaciona el peso con la altura. ' +
      'Es útil para evaluar si el peso está dentro de rangos saludables.',
    healthyRange: '18.5 - 24.9 kg/m²',
  },
  waistToHipRatio: {
    description: 'El Índice Cintura-Cadera (ICC) es la relación entre la circunferencia de la cintura y la cadera. ' +
      'Es un indicador de la distribución de la grasa corporal.',
    healthyRange: 'Hombres: < 0.9, Mujeres: < 0.8',
    detailedRanges: {
      male: {
        optimal: { id: 'optimal', range: '< 0.90', label: 'Óptimo' },
        moderate: { id: 'moderate', range: '0.90 - 0.99', label: 'Moderado' },
        unhealthy: { id: 'unhealthy', range: '≥ 1.00', label: 'No saludable' },
      },
      female: {
        optimal: { id: 'optimal', range: '< 0.80', label: 'Óptimo' },
        moderate: { id: 'moderate', range: '0.80 - 0.85', label: 'Moderado' },
        unhealthy: { id: 'unhealthy', range: '≥ 0.86', label: 'No saludable' },
      },
    },
    recommendations: {
      optimal: 'Excelente distribución de grasa corporal. Mantén tus hábitos saludables actuales para conservar ' +
        'este nivel óptimo que contribuye a tu salud cardiovascular.',
      moderate: 'Tu distribución de grasa corporal está en un rango intermedio. Considera implementar mejoras ' +
        'en tu dieta y ejercicio para optimizar este indicador.',
      unhealthy: 'Es importante tomar acción. Considera implementar cambios en tu estilo de vida como aumentar la ' +
        'actividad física, especialmente ejercicios que trabajen el core, y mejorar tu dieta reduciendo el consumo ' +
        'de azúcares refinados y grasas saturadas.',
    },
    importance: 'Un ICC elevado es un predictor significativo de mayor riesgo de mortalidad por todas las causas y ' +
      'enfermedad cardiovascular (ECV). Un ICC superior a 1.0 indica mayor grasa abdominal en relación con la ' +
      'circunferencia de la cadera, lo que se correlaciona con mayores riesgos para la salud.\n\n' +
      'Estudios han demostrado que:\n' +
      '• Cada aumento de 0.1 unidades en el ICC se asocia con un aumento del 20% en el riesgo de mortalidad por ' +
      'todas las causas.\n' +
      '• Por cada aumento de 0.01 unidades en el ICC, el riesgo relativo de un evento cardiovascular aumenta ' +
      'en un 5%.\n\n' +
      'Rangos de referencia:\n' +
      'Hombres:\n' +
      '• Óptimo: < 0.90\n' +
      '• Moderado: 0.90 - 0.99\n' +
      '• No saludable: ≥ 1.00\n\n' +
      'Mujeres:\n' +
      '• Óptimo: < 0.80\n' +
      '• Moderado: 0.80 - 0.85\n' +
      '• No saludable: ≥ 0.86',
  },
  fatMassPercentage: {
    description: 'Muestra la proporción de masa grasa en el cuerpo, ' +
      'expresada como porcentaje del peso total.',
    healthyRange: 'Hombres: < 15.5%, Mujeres: < 25.0%',
    detailedRanges: {
      male: {
        optimal: { id: 'optimal', range: '< 15.5%', label: 'Óptimo' },
        moderate: { id: 'moderate', range: '15.5% - 20.2%', label: 'Moderado' },
        unhealthy: { id: 'unhealthy', range: '> 20.2%', label: 'No saludable' },
      },
      female: {
        optimal: { id: 'optimal', range: '< 25.0%', label: 'Óptimo' },
        moderate: { id: 'moderate', range: '25.0% - 32.3%', label: 'Moderado' },
        unhealthy: { id: 'unhealthy', range: '> 32.3%', label: 'No saludable' },
      },
    },
    recommendations: {
      optimal: 'Excelente porcentaje de grasa corporal. Mantén tus hábitos actuales de alimentación y ejercicio ' +
        'para conservar este nivel óptimo de grasa corporal.',
      moderate: 'Tu porcentaje de grasa corporal está en un rango intermedio. Considera implementar mejoras en tu ' +
        'rutina de ejercicios y alimentación para optimizar este indicador.',
      unhealthy: 'Considera implementar cambios en tu estilo de vida para reducir el porcentaje de grasa ' +
        'corporal. Esto puede incluir una combinación de ejercicio regular (especialmente entrenamiento de ' +
        'fuerza), una dieta equilibrada con déficit calórico moderado y hábitos saludables de sueño.',
    },
    importance: 'El porcentaje de grasa corporal es un indicador crucial de salud metabólica y riesgo ' +
      'cardiovascular. Estudios científicos recientes han demostrado que:\n\n' +
      '• Las personas con exceso de grasa visceral tienen un 20% más de riesgo de mortalidad\n' +
      '• El riesgo de mortalidad cardiovascular aumenta un 46% en personas con alta adiposidad visceral\n' +
      '• El riesgo de mortalidad por cáncer es un 22% mayor en personas con exceso de grasa corporal\n\n' +
      'Rangos de referencia (basados en datos poblacionales para adultos 20-59 años):\n\n' +
      'Hombres:\n' +
      '• Óptimo: < 15.5% (percentil 25)\n' +
      '• Moderado: 15.5% - 20.2% (percentil 25-50)\n' +
      '• No saludable: > 20.2% (por encima del percentil 50)\n\n' +
      'Mujeres:\n' +
      '• Óptimo: < 25.0% (percentil 25)\n' +
      '• Moderado: 25.0% - 32.3% (percentil 25-50)\n' +
      '• No saludable: > 32.3% (por encima del percentil 50)',
  },
  fatFreeMassIndex: {
    description: 'El Índice de Masa Libre de Grasa (IMLG) es similar al IMC pero considera solo la masa magra. ' +
      'Es útil para evaluar el desarrollo muscular.',
    healthyRange: 'Varía según sexo y edad',
    detailedRanges: {
      male: {
        optimal: { id: 'optimal', range: '> 22.3', label: 'Óptimo' },
        moderate: { id: 'moderate', range: '20.8 - 22.3', label: 'Moderado' },
        unhealthy: { id: 'unhealthy', range: '< 20.8', label: 'No saludable' },
      },
      female: {
        optimal: { id: 'optimal', range: '> 18.0', label: 'Óptimo' },
        moderate: { id: 'moderate', range: '16.9 - 18.0', label: 'Moderado' },
        unhealthy: { id: 'unhealthy', range: '< 16.9', label: 'No saludable' },
      },
    },
    recommendations: {
      optimal: 'Excelente nivel de masa muscular. Continúa con tu rutina actual de entrenamiento y alimentación ' +
        'para mantener este nivel óptimo de masa muscular.',
      moderate: 'Tu masa muscular está en un rango intermedio. Considera aumentar la intensidad de tu ' +
        'entrenamiento de fuerza y optimizar tu ingesta de proteínas para mejorar este indicador.',
      unhealthy: 'Considera implementar un programa de entrenamiento de fuerza progresivo junto con una dieta rica ' +
        'en proteínas para aumentar tu masa muscular. Un mayor índice de masa libre de grasa está asociado con mejor ' +
        'salud y longevidad.',
    },
    importance: 'El Índice de Masa Libre de Grasa (IMLG) es un indicador crucial de salud y longevidad. ' +
      'Estudios a largo plazo han demostrado que las personas con mayor masa libre de grasa tienen un 30% menos ' +
      'de riesgo de mortalidad comparado con aquellos con niveles bajos.\n\n' +
      'Un IMLG más alto:\n' +
      '• Mejora el metabolismo y la salud general\n' +
      '• Reduce el riesgo de enfermedades crónicas\n' +
      '• Aumenta la fuerza y la capacidad funcional\n' +
      '• Contribuye a una mejor calidad de vida en el envejecimiento\n\n' +
      'Rangos de referencia (basados en datos poblacionales para adultos 20-59 años):\n\n' +
      'Hombres:\n' +
      '• Óptimo: > 22.3 kg/m² (percentil 75)\n' +
      '• Moderado: 20.8 - 22.3 kg/m² (percentil 50-75)\n' +
      '• No saludable: < 20.8 kg/m² (por debajo del percentil 50)\n\n' +
      'Mujeres:\n' +
      '• Óptimo: > 18.0 kg/m² (percentil 75)\n' +
      '• Moderado: 16.9 - 18.0 kg/m² (percentil 50-75)\n' +
      '• No saludable: < 16.9 kg/m² (por debajo del percentil 50)',
  },
  fatAndFatFreeMass: {
    description: 'Compara la masa grasa total con la masa libre de grasa en kilogramos, ' +
      'permitiendo ver la evolución de ambos componentes.',
  },
  somatotype: {
    description: 'El somatotipo representa la forma corporal en tres dimensiones: ' +
      'endomorfia (adiposidad), mesomorfia (muscularidad) y ectomorfia (linealidad).',
  },
  bodyFatPercentage: {
    description: 'Porcentaje del peso corporal total que corresponde a la masa grasa. ' +
      'Es un indicador clave de la composición corporal.',
    healthyRange: 'Hombres: 10-20%, Mujeres: 20-30%',
  },
  fatMass: {
    description: 'Cantidad total de masa grasa en el cuerpo, medida en kilogramos. ' +
      'Incluye tanto la grasa esencial como la de reserva.',
  },
  fatFreeMass: {
    description: 'Masa corporal total excluyendo la grasa, incluyendo músculos, huesos y órganos. ' +
      'Es un indicador importante de la masa metabólicamente activa.',
  },
  skeletalMuscleMass: {
    description: 'Masa total del músculo esquelético, el tipo de músculo que se puede ' +
      'controlar voluntariamente. Es un componente clave de la masa libre de grasa.',
  },
  endomorphy: {
    description: 'Componente del somatotipo que representa la adiposidad relativa. ' +
      'Valores más altos indican mayor tendencia al almacenamiento de grasa.',
  },
  mesomorphy: {
    description: 'Componente del somatotipo que representa el desarrollo músculo-esquelético. ' +
      'Valores más altos indican mayor masa muscular y robustez ósea.',
  },
  ectomorphy: {
    description: 'Componente del somatotipo que representa la linealidad relativa. ' +
      'Valores más altos indican una estructura más delgada y alargada.',
  },
  gripStrength: {
    description: 'Fuerza de agarre, medida en kilogramos. ' +
      'Es un indicador de la fuerza muscular general y la función física.',
    healthyRange: 'Varía según edad y sexo',
    detailedRanges: {
      male: {
        young: {
          optimal: { id: 'optimal', range: '> 56.0', label: 'Óptimo' },
          moderate: { id: 'moderate', range: '49.5 - 56.0', label: 'Moderado' },
          unhealthy: { id: 'unhealthy', range: '< 49.5', label: 'No saludable' },
        },
        middle: {
          optimal: { id: 'optimal', range: '> 50.0', label: 'Óptimo' },
          moderate: { id: 'moderate', range: '44.2 - 50.0', label: 'Moderado' },
          unhealthy: { id: 'unhealthy', range: '< 44.2', label: 'No saludable' },
        },
        senior: {
          optimal: { id: 'optimal', range: '> 43.6', label: 'Óptimo' },
          moderate: { id: 'moderate', range: '38.6 - 43.6', label: 'Moderado' },
          unhealthy: { id: 'unhealthy', range: '< 38.6', label: 'No saludable' },
        },
      },
      female: {
        young: {
          optimal: { id: 'optimal', range: '> 34.5', label: 'Óptimo' },
          moderate: { id: 'moderate', range: '30.7 - 34.5', label: 'Moderado' },
          unhealthy: { id: 'unhealthy', range: '< 30.7', label: 'No saludable' },
        },
        middle: {
          optimal: { id: 'optimal', range: '> 30.4', label: 'Óptimo' },
          moderate: { id: 'moderate', range: '26.5 - 30.4', label: 'Moderado' },
          unhealthy: { id: 'unhealthy', range: '< 26.5', label: 'No saludable' },
        },
        senior: {
          optimal: { id: 'optimal', range: '> 26.6', label: 'Óptimo' },
          moderate: { id: 'moderate', range: '23.5 - 26.6', label: 'Moderado' },
          unhealthy: { id: 'unhealthy', range: '< 23.5', label: 'No saludable' },
        },
      },
    },
    recommendations: {
      optimal: 'Excelente nivel de fuerza. Mantén tu rutina actual de entrenamiento de fuerza para conservar ' +
        'esta capacidad funcional que contribuye a tu independencia y calidad de vida.',
      moderate: 'Tu nivel de fuerza está en un rango intermedio. Considera aumentar la intensidad de tu ' +
        'entrenamiento de fuerza para mejorar este indicador.',
      unhealthy: 'Considera incorporar ejercicios de fuerza progresivos, especialmente para el tren superior. ' +
        'Una mayor fuerza de agarre está asociada con mejor salud general, menor riesgo de caídas y mayor ' +
        'independencia funcional.',
    },
    importance: 'La fuerza de agarre es un indicador clave de la salud general. ' +
      'Por cada 5 kg menos en fuerza de agarre, el riesgo de mortalidad aumenta en un 16%.\n\n' +
      'Es un predictor importante de:\n' +
      '• Densidad ósea y riesgo de fracturas: una mayor fuerza de agarre está asociada con una mejor densidad ósea, ' +
      'lo que reduce el riesgo de fracturas, especialmente en personas mayores.\n' +
      '• Capacidad funcional e independencia en actividades diarias: la fuerza de agarre está directamente ' +
      'relacionada con la capacidad para realizar tareas cotidianas, como abrir frascos, cargar bolsas o levantarse ' +
      'de una silla.\n' +
      '• Longevidad y calidad de vida: esto se debe a que la fuerza de agarre refleja una musculatura más saludable, ' +
      'mejor condición cardiovascular y menor riesgo de enfermedades crónicas.\n\n' +
      'Rangos de referencia por edad:\n\n' +
      'Hombres 20-40 años:\n' +
      '• Óptimo: > 56.0 kg (percentil 75)\n' +
      '• Moderado: 49.5 - 56.0 kg (percentil 50-75)\n' +
      '• No saludable: < 49.5 kg (por debajo del percentil 50)\n\n' +
      'Hombres 41-60 años:\n' +
      '• Óptimo: > 50.0 kg (percentil 75)\n' +
      '• Moderado: 44.2 - 50.0 kg (percentil 50-75)\n' +
      '• No saludable: < 44.2 kg (por debajo del percentil 50)\n\n' +
      'Hombres > 60 años:\n' +
      '• Óptimo: > 43.6 kg (percentil 75)\n' +
      '• Moderado: 38.6 - 43.6 kg (percentil 50-75)\n' +
      '• No saludable: < 38.6 kg (por debajo del percentil 50)\n\n' +
      'Mujeres 20-40 años:\n' +
      '• Óptimo: > 34.5 kg (percentil 75)\n' +
      '• Moderado: 30.7 - 34.5 kg (percentil 50-75)\n' +
      '• No saludable: < 30.7 kg (por debajo del percentil 50)\n\n' +
      'Mujeres 41-60 años:\n' +
      '• Óptimo: > 30.4 kg (percentil 75)\n' +
      '• Moderado: 26.5 - 30.4 kg (percentil 50-75)\n' +
      '• No saludable: < 26.5 kg (por debajo del percentil 50)\n\n' +
      'Mujeres > 60 años:\n' +
      '• Óptimo: > 26.6 kg (percentil 75)\n' +
      '• Moderado: 23.5 - 26.6 kg (percentil 50-75)\n' +
      '• No saludable: < 23.5 kg (por debajo del percentil 50)',
  },
  sumOfSkinfolds: {
    description: 'La suma de pliegues cutáneos es un indicador de la adiposidad subcutánea total. ' +
      'Se muestran dos sumas: la de 6 pliegues (tríceps, subescapular, suprailiaco, abdominal, muslo y pantorrilla) ' +
      'y la de 8 pliegues (añade bíceps y cresta ilíaca).',
  },
  correctedGirths: {
    description: 'Los perímetros corregidos descuentan el tejido adiposo subcutáneo, ' +
      'proporcionando una mejor estimación del tejido muscular y óseo subyacente.',
  },
};

export const bodyCompositionQuadrants = {
  descriptions: {
    first: 'Alta masa grasa y baja masa muscular',
    second: 'Alta masa grasa y masa muscular óptima',
    third: 'Masa grasa óptima y baja masa muscular',
    fourth: 'Masa grasa óptima y masa muscular óptima',
  },
  analysis: {
    first: 'Tus resultados indican un exceso de grasa corporal con masa muscular por debajo de lo óptimo.',
    second: 'Tus resultados indican un exceso de grasa corporal, aunque tienes una masa muscular óptima.',
    third: 'Tus resultados indican una masa grasa óptima, pero tu masa muscular está por debajo de lo óptimo.',
    fourth: 'Tus resultados indican una buena composición corporal, con niveles óptimos de grasa y músculo.',
  },
  recommendations: {
    first: [
      'Desarrolla masa muscular mientras reduces grasa corporal.',
      'Enfócate en un déficit calórico moderado manteniendo una alta ingesta de proteína.',
      'El entrenamiento de fuerza es fundamental para mantener y desarrollar masa muscular ' +
        'durante el déficit calórico.',
    ],
    second: [
      'Reduce grasa corporal mientras mantienes tu masa muscular actual.',
      'Concéntrate en un déficit calórico moderado.',
      'El entrenamiento de fuerza es clave para preservar tu masa muscular durante la pérdida de grasa.',
    ],
    third: [
      'Desarrolla masa muscular manteniendo un peso saludable.',
      'Enfócate en un superávit calórico moderado con alta proteína.',
      'El entrenamiento de fuerza progresivo debe ser el pilar de tu programa para maximizar el ' +
        'desarrollo muscular.',
    ],
    fourth: [
      'Tu composición corporal es saludable.',
      'Considera mantener tu condición actual o incrementar más tu masa muscular.',
      'Incrementar tu masa muscular contribuirá a una mejor salud a largo plazo y a reducir el ' +
        'riesgo de sarcopenia en la vejez.',
    ],
  },
};
