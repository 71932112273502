<script setup lang="ts">
import { computed } from 'vue';
import { format } from 'date-fns';
import { useQuery } from '@tanstack/vue-query';
import { ArrowRight, ExternalLink } from 'lucide-vue-next';
import type { PhysicalMeasurement, Trainee } from '@/types';
import { physicalMeasurementsApi } from '@/api/physical-measurements';
import { BaseLink } from '@/components';

const props = defineProps<{
  trainee: Trainee;
}>();

const measurementsQueryParams = computed(() => ({
  q: {
    'userable_id_eq': props.trainee.id,
    'userable_type_eq': 'Trainee',
    's': ['measured_at desc'],
  },
}));

const { data: measurements } = useQuery({
  queryKey: ['trainee-physical-measurements', measurementsQueryParams],
  queryFn: () => physicalMeasurementsApi.getAll(measurementsQueryParams.value),
});

function calculateImc(measurement: PhysicalMeasurement) {
  if (!measurement.weight || !measurement.height) return '-';

  const heightInMeters = measurement.height / 100;

  return (measurement.weight / (heightInMeters * heightInMeters)).toFixed(1);
}

const measurementsWithMetrics = computed(() => measurements.value?.map((measurement) => ({
  ...measurement,
  metrics: {
    weight: measurement.weight?.toFixed(1),
    imc: calculateImc(measurement),
  },
})));

const physicalAssessmentsLink = computed(() => {
  const userableType = props.trainee.userableType.toLowerCase();

  if (props.trainee.slug) {
    return `/${userableType}s/${props.trainee.slug}/physical_assessments`;
  }

  return `/${userableType}s/${props.trainee.id}/physical_assessments`;
});
</script>

<template>
  <div class="flex flex-col gap-y-8">
    <h2 class="text-lg font-semibold">
      Mediciones físicas
    </h2>
    <div>
      <BaseLink
        :href="physicalAssessmentsLink"
        target="_blank"
      >
        Ir al reporte de composición corporal <ExternalLink class="size-4" />
      </BaseLink>
    </div>

    <div class="overflow-hidden rounded-lg border border-gray-200 bg-white shadow">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th class="px-6 py-3 text-left text-sm font-semibold text-gray-900">
              Fecha
            </th>
            <th class="px-6 py-3 text-left text-sm font-semibold text-gray-900">
              Peso (kg)
            </th>
            <th class="px-6 py-3 text-left text-sm font-semibold text-gray-900">
              IMC
            </th>
            <th />
          </tr>
        </thead>
        <tbody
          v-if="measurementsWithMetrics?.length"
          class="divide-y divide-gray-200"
        >
          <tr
            v-for="measurement in measurementsWithMetrics"
            :key="measurement.id"
            class="hover:bg-gray-50"
          >
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
              {{ format(new Date(measurement.measuredAt), 'dd/MM/yyyy') }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
              {{ measurement.metrics.weight }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
              {{ measurement.metrics.imc }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
              <BaseLink :href="`trainees/${props.trainee.id}/physical_measurements/${measurement.id}`">
                Ver <ArrowRight class="size-4" />
              </BaseLink>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              colspan="4"
              class="px-6 py-4 text-center text-sm text-gray-900"
            >
              No hay mediciones todavía
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
