<script setup lang="ts">
import { computed } from 'vue';

const WEEKDAYS = {
  0: 'DOM.',
  1: 'LUN.',
  2: 'MAR.',
  3: 'MIÉ.',
  4: 'JUE.',
  5: 'VIE.',
  6: 'SÁB.',
};

const props = defineProps<{
  currentWeek: Date[];
  selectedDate: Date | null;
  availableSlots: Record<string, any[]>;
}>();

const emit = defineEmits<{
  (e: 'update:selectedDate', date: Date): void;
  (e: 'navigate', direction: 'prev' | 'next'): void;
}>();

function formatDateToString(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}${month}${day}`;
}

function getAvailableSlotsForDate(date: Date): any[] {
  const dateStr = formatDateToString(date);

  return props.availableSlots[dateStr] || [];
}

function isDateSelected(date: Date): boolean {
  return props.selectedDate?.toDateString() === date.toDateString();
}

const MONTHS = {
  0: 'enero',
  1: 'febrero',
  2: 'marzo',
  3: 'abril',
  4: 'mayo',
  5: 'junio',
  6: 'julio',
  7: 'agosto',
  8: 'septiembre',
  9: 'octubre',
  10: 'noviembre',
  11: 'diciembre',
};

const weekRange = computed(() => {
  if (props.currentWeek.length === 0) return '';

  const firstDay = props.currentWeek[0];
  const lastDay = props.currentWeek[6];

  // Full format for desktop
  const fullFormat = `Semana del ${firstDay.getDate()} de ${MONTHS[firstDay.getMonth()]} al ${lastDay.getDate()} de ${MONTHS[lastDay.getMonth()]}`;

  // Short format for mobile
  const shortFormat = `${firstDay.getDate()} ${MONTHS[firstDay.getMonth()].slice(0, 3)} - ${lastDay.getDate()} ${MONTHS[lastDay.getMonth()].slice(0, 3)}`;

  return { full: fullFormat, short: shortFormat };
});
</script>

<template>
  <div class="mb-8">
    <div class="flex items-center justify-between">
      <h3 class="text-base text-gray-600 md:text-lg">
        <span class="hidden md:inline">{{ weekRange.full }}</span>
        <span class="md:hidden">{{ weekRange.short }}</span>
      </h3>
      <div class="flex gap-2">
        <button
          class="rounded-lg p-1.5 text-gray-600 hover:bg-gray-100 md:p-2"
          @click="emit('navigate', 'prev')"
        >
          <span class="sr-only">Semana anterior</span>
          <svg
            class="size-4 md:size-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <button
          class="rounded-lg p-1.5 text-gray-600 hover:bg-gray-100 md:p-2"
          @click="emit('navigate', 'next')"
        >
          <span class="sr-only">Siguiente semana</span>
          <svg
            class="size-4 md:size-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>

    <div class="mt-4 grid grid-cols-7 gap-1 md:gap-2">
      <div
        v-for="date in currentWeek"
        :key="date.toISOString()"
        class="flex flex-col items-center"
      >
        <span class="mb-1 text-xs font-medium text-gray-600 md:text-sm">{{ WEEKDAYS[date.getDay()] }}</span>
        <button
          class="flex size-9 items-center justify-center rounded-lg text-base md:size-12 md:text-lg"
          :class="{
            'bg-black text-white': isDateSelected(date),
            'text-black hover:bg-gray-100': !isDateSelected(date) && getAvailableSlotsForDate(date).length > 0,
            'cursor-not-allowed text-gray-300': getAvailableSlotsForDate(date).length === 0
          }"
          :disabled="getAvailableSlotsForDate(date).length === 0"
          @click="emit('update:selectedDate', date)"
        >
          {{ date.getDate() }}
        </button>
      </div>
    </div>
  </div>
</template>
