<script setup lang="ts">
import { AlertTriangle, ExternalLink } from 'lucide-vue-next';
import type { SurveyResponse, SurveyResult } from '@/types/body-composition-survey';
import { useBodyComposition } from '@/composables/use-body-composition';

const props = defineProps<{
  result: SurveyResult
  response: Partial<SurveyResponse>
}>();

const encodedEmail = encodeURIComponent(props.response?.email || '');

const emit = defineEmits<{
  (e: 'open-risk-modal', type: 'overnourished' | 'under_muscled' | 'visceral_fat'): void
}>();

const { probabilityToText } = useBodyComposition();
</script>

<template>
  <div class="flex flex-col rounded-xl border border-gray-200 bg-white p-4 shadow-sm md:p-8">
    <div>
      <div class="flex items-center gap-2 text-gray-900">
        <AlertTriangle class="size-5 text-yellow-500 md:size-6" />
        <h3 class="text-xl font-semibold md:text-2xl">
          Riesgos Potenciales
        </h3>
      </div>
      <div class="mt-6 space-y-4 md:mt-8 md:space-y-6">
        <!-- Visceral Fat Risk -->
        <div>
          <div class="mb-2 flex items-center justify-between">
            <span class="text-sm font-medium text-gray-700 md:text-base">Riesgo Alta Grasa Visceral</span>
            <span class="text-sm text-gray-600 md:text-base">{{ probabilityToText(result.visceralFatProbability) }}</span>
          </div>
          <div class="h-2 rounded-full bg-yellow-100">
            <div
              class="h-2 rounded-full bg-yellow-500"
              :style="{ width: `${result.visceralFatProbability * 100}%` }"
            />
          </div>
          <button
            class="mt-2 flex w-full items-center justify-center gap-1 rounded-lg border border-gray-200 bg-white px-2 py-1 text-xs font-medium text-gray-700 transition-colors hover:bg-gray-50 md:px-3 md:py-1.5 md:text-sm"
            @click="emit('open-risk-modal', 'visceral_fat')"
          >
            <ExternalLink class="size-3 md:size-4" />
            Ver riesgos asociados
          </button>
        </div>

        <!-- Low Muscle Mass Risk -->
        <div>
          <div class="mb-2 flex items-center justify-between">
            <span class="text-sm font-medium text-gray-700 md:text-base">Riesgo Baja Masa Muscular</span>
            <span class="text-sm text-gray-600 md:text-base">{{ probabilityToText(result.lowMuscleMassProbability) }}</span>
          </div>
          <div class="h-2 rounded-full bg-yellow-100">
            <div
              class="h-2 rounded-full bg-yellow-500"
              :style="{ width: `${result.lowMuscleMassProbability * 100}%` }"
            />
          </div>
          <button
            class="mt-2 flex w-full items-center justify-center gap-1 rounded-lg border border-gray-200 bg-white px-2 py-1 text-xs font-medium text-gray-700 transition-colors hover:bg-gray-50 md:px-3 md:py-1.5 md:text-sm"
            @click="emit('open-risk-modal', 'under_muscled')"
          >
            <ExternalLink class="size-3 md:size-4" />
            Ver riesgos asociados
          </button>
        </div>

        <!-- Overnourished Risk -->
        <div v-if="result.nourishmentStatus === 'overnourished'">
          <div class="mb-2 flex items-center justify-between">
            <span class="text-sm font-medium text-gray-700 md:text-base">Exceso de grasa corporal</span>
            <span class="text-sm text-gray-600 md:text-base">Alto</span>
          </div>
          <div class="h-2 rounded-full bg-yellow-100">
            <div
              class="h-2 rounded-full bg-yellow-500"
              style="width: 80%"
            />
          </div>
          <button
            class="mt-2 flex w-full items-center justify-center gap-1 rounded-lg border border-gray-200 bg-white px-2 py-1 text-xs font-medium text-gray-700 transition-colors hover:bg-gray-50 md:px-3 md:py-1.5 md:text-sm"
            @click="emit('open-risk-modal', 'overnourished')"
          >
            <ExternalLink class="size-3 md:size-4" />
            Ver riesgos asociados
          </button>
        </div>
      </div>
    </div>

    <div class="mt-auto rounded-lg bg-gray-50 p-4 text-sm text-gray-600">
      <p>
        Resultados basados en la información proporcionada. Obtén una evaluación profesional
        a domicilio y seguimiento personalizado con Kapso.
      </p>
      <!-- Kapso CTA -->
      <div class="mt-8 text-center">
        <a
          :href="`/onboarding/checkout/personalized?email=${encodedEmail}`"
          class="group inline-flex w-full items-center justify-center space-x-2 rounded-lg bg-black px-6 py-3 text-base font-medium text-white shadow-lg transition-all duration-300 hover:bg-gray-800 hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 md:px-8 md:py-4 md:text-lg"
        >
          <span>Empezar con Kapso</span>
        </a>
      </div>
    </div>
  </div>
</template>
