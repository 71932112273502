import type { PhysicalMeasurementBooking } from '@/types/physical-measurement-booking';
import { api } from './axios-wrapper';

export const physicalMeasurementBookingsApi = {
  async getAll() {
    const response = await api.get<PhysicalMeasurementBooking[]>('/api/internal/physical_measurement_bookings');

    return response.data;
  },

  async update(id: number, data: Partial<PhysicalMeasurementBooking>) {
    const response = await api.patch<PhysicalMeasurementBooking>(
      `/api/internal/physical_measurement_bookings/${id}`,
      data,
    );

    return response.data;
  },
};
