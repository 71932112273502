<script setup lang="ts">
import { ref, computed } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { AlertTriangle, Check } from 'lucide-vue-next';
import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/yup';
import * as yup from 'yup';
import type { Trainee as BaseTrainee, Measuree } from '@/types';
import type { Trainee as ExtendedTrainee } from '@/types/extended';
import { traineesApi, measureesApi } from '@/api';
import { BaseButton, BaseInput, BaseLabel, BaseSelect } from '@/components';

type User = BaseTrainee | Measuree;
type ApiResponse = ExtendedTrainee | Measuree;

const props = defineProps<{
  user: User;
}>();

const emit = defineEmits<{
  success: [];
}>();

const queryClient = useQueryClient();

type BasicInfoFormValues = {
  birthdate: string;
  sex: 'male' | 'female' | '';
};

const basicInfoSchema = toTypedSchema(
  yup.object().shape({
    birthdate: yup.string().required('La fecha de nacimiento es obligatoria'),
    sex: yup.string().oneOf(['male', 'female'], 'El sexo es obligatorio').required('El sexo es obligatorio'),
  }),
);

const initialValues = ref<BasicInfoFormValues>({
  birthdate: props.user.birthdate ?? '',
  sex: (props.user.sex as 'male' | 'female' | '') ?? '',
});

const { handleSubmit, values } = useForm<BasicInfoFormValues>({
  validationSchema: basicInfoSchema,
  initialValues: initialValues.value,
});

const {
  isError,
  isPending,
  isIdle,
  isSuccess,
  mutate: saveBasicInfo,
  error: saveBasicInfoError,
  reset: resetMutation,
} = useMutation<ApiResponse, Error, { birthdate: string; sex: 'male' | 'female' }>({
  mutationFn: async (data) => {
    const response = props.user.userableType === 'Trainee' ?
      await traineesApi.update(props.user.id, data) :
      await measureesApi.update(props.user.id, data);

    return response;
  },
  onSuccess: () => {
    const queryKey = props.user.userableType.toLowerCase();
    queryClient.invalidateQueries({ queryKey: [queryKey] });
    initialValues.value = values;
    emit('success');
  },
});

const buttonText = computed(() => {
  if (isIdle.value || isError.value) return 'Siguiente';
  if (isPending.value) return 'Guardando...';
  if (isSuccess.value) return 'Guardado';

  return 'Siguiente';
});

const buttonClass = computed(() => {
  if (isIdle.value) return 'bg-black text-white hover:bg-gray-800';
  if (isPending.value || isError.value) return 'bg-gray-300 text-gray-500 cursor-not-allowed';
  if (isSuccess.value) return 'bg-green-500 text-white hover:bg-green-600';

  return 'bg-black text-white hover:bg-gray-800';
});

const isSubmitDisabled = computed(() => isPending.value || isSuccess.value);

const onSubmit = handleSubmit((data) => {
  saveBasicInfo(data as { birthdate: string; sex: 'male' | 'female' });
});
</script>

<template>
  <div class="rounded-lg border border-gray-200 bg-white shadow">
    <div class="px-4 py-5 sm:p-6">
      <h2 class="mb-4 text-lg font-medium text-gray-900">
        Información básica
      </h2>

      <form
        v-if="isIdle"
        class="space-y-4"
        @submit.prevent="onSubmit"
      >
        <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
          <div class="flex flex-col gap-1">
            <BaseLabel
              label="Fecha de nacimiento"
              name="birthdate"
              required
            />
            <BaseInput
              v-model="values.birthdate"
              name="birthdate"
              type="datetime-local"
              placeholder="YYYY-MM-DD"
            />
          </div>
          <div class="flex flex-col gap-1">
            <BaseLabel
              label="Sexo"
              name="sex"
              required
            />
            <BaseSelect
              v-model="values.sex"
              name="sex"
              :options="[{ id: 'female', name: 'Femenino' }, { id: 'male', name: 'Masculino' }]"
            />
          </div>
        </div>

        <div class="flex justify-end">
          <BaseButton
            type="submit"
            :disabled="isSubmitDisabled"
            :class="buttonClass"
          >
            {{ buttonText }}
          </BaseButton>
        </div>
      </form>

      <div
        v-else-if="isError"
        class="flex flex-col gap-4"
      >
        <div class="mt-4 flex flex-col gap-4 rounded-md bg-red-50 p-4">
          <div class="flex max-w-full">
            <AlertTriangle class="shrink-0 text-red-400" />
            <div class="ml-3">
              <h3 class="text-sm font-medium text-red-800">
                Error al actualizar la información básica
              </h3>
              <div class="mt-2 text-sm text-red-700">
                <p>{{ saveBasicInfoError }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end">
          <BaseButton
            type="button"
            @click="resetMutation"
          >
            Volver al formulario
          </BaseButton>
        </div>
      </div>

      <div
        v-else-if="isSuccess"
        class="mt-4"
      >
        <div class="rounded-md bg-green-50 p-4">
          <div class="flex">
            <div class="shrink-0">
              <Check class="text-green-400" />
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-green-800">
                Información básica actualizada exitosamente
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
