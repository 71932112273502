<script setup lang="ts">
import { ref, computed, toRef, onBeforeUnmount } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { format } from 'date-fns';
import { AlertTriangle, Check } from 'lucide-vue-next';
import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/yup';
import * as yup from 'yup';
import { useStorage, watchDebounced } from '@vueuse/core';
import type { Measurer, Trainee, Measuree } from '@/types';
import type { PhysicalMeasurement, PhysicalMeasurementAttributes } from '@/types/physical-measurement';
import { physicalMeasurementsApi } from '@/api/physical-measurements';
import { BackButton, BaseButton, BaseInput, BaseLabel, BaseSelect, BaseTextarea } from '@/components';
import { BasicInfoForm } from '@/components/shared';
import { useBeforeUnloadWarning } from '@/composables/before-unload-warning';

const props = defineProps<{
  physicalMeasurement?: PhysicalMeasurement;
  userable: Trainee | Measuree;
  measurers: Measurer[];
  measurerId?: number;
}>();

const queryClient = useQueryClient();
const needsBasicInfo = computed(() => !props.userable.birthdate || !props.userable.sex);
const showFullForm = ref(!needsBasicInfo.value);
const measurer = ref(props.measurers.find((m) => m.id === props.measurerId));

const isEditing = computed(() => props.physicalMeasurement !== undefined);

const physicalMeasurement = toRef(() => props.physicalMeasurement);

const physicalMeasurementSchema = toTypedSchema(
  yup.object().shape({
    measuredAt: yup.string().required('La fecha de medición es obligatoria'),
    measurerId: yup.number().required('El medidor es obligatorio'),
    weight: yup.number().required('La masa corporal es obligatoria'),
    height: yup.number().min(100, 'La talla debe ser mayor a 100 cm').required('La talla es obligatoria'),
    relaxedArmCircumference: yup.number().required('La circunferencia del brazo relajado es obligatoria'),
    flexedArmCircumference: yup.number().required('La circunferencia del brazo flexionado es obligatoria'),
    waistCircumference: yup.number().required('La circunferencia de la cintura es obligatoria'),
    hipCircumference: yup.number().required('La circunferencia de la cadera es obligatoria'),
    thighCircumference: yup.number().required('La circunferencia del muslo es obligatoria'),
    calfCircumference: yup.number().required('La circunferencia de la pantorrilla es obligatoria'),
    tricepsSkinfold: yup.number().required('El pliegue del tríceps es obligatorio'),
    tricepsSkinfold2: yup.number().nullable(),
    tricepsSkinfold3: yup.number().nullable(),
    subscapularSkinfold: yup.number().required('El pliegue subescapular es obligatorio'),
    subscapularSkinfold2: yup.number().nullable(),
    subscapularSkinfold3: yup.number().nullable(),
    bicepsSkinfold: yup.number().required('El pliegue del bíceps es obligatorio'),
    bicepsSkinfold2: yup.number().nullable(),
    bicepsSkinfold3: yup.number().nullable(),
    iliacCrestSkinfold: yup.number().required('El pliegue de la cresta ilíaca es obligatorio'),
    iliacCrestSkinfold2: yup.number().nullable(),
    iliacCrestSkinfold3: yup.number().nullable(),
    suprailiacSkinfold: yup.number().required('El pliegue suprailiaco es obligatorio'),
    suprailiacSkinfold2: yup.number().nullable(),
    suprailiacSkinfold3: yup.number().nullable(),
    abdominalSkinfold: yup.number().required('El pliegue abdominal es obligatorio'),
    abdominalSkinfold2: yup.number().nullable(),
    abdominalSkinfold3: yup.number().nullable(),
    thighSkinfold: yup.number().required('El pliegue del muslo es obligatorio'),
    thighSkinfold2: yup.number().nullable(),
    thighSkinfold3: yup.number().nullable(),
    calfSkinfold: yup.number().required('El pliegue de la pantorrilla es obligatorio'),
    calfSkinfold2: yup.number().nullable(),
    calfSkinfold3: yup.number().nullable(),
    humerusBreadth: yup.number().required('El diámetro del húmero es obligatorio'),
    femurBreadth: yup.number().required('El diámetro del fémur es obligatorio'),
    gripStrength: yup.number().required('La fuerza de agarre es obligatoria'),
    notes: yup.string().nullable(),
  }),
);

const storageKey = computed(() =>
  (isEditing.value ?
    `physical-measurement-form-${physicalMeasurement.value?.id}` :
    'physical-measurement-form-new'),
);

const storedFormData = useStorage<Partial<PhysicalMeasurementAttributes>>(
  storageKey.value,
  null,
  localStorage,
  {
    serializer: {
      read: (v: string | null) => (v ? JSON.parse(v) : null),
      write: (v: Partial<PhysicalMeasurementAttributes> | null) => JSON.stringify(v),
    },
  },
);

function getMeasuredAt() {
  let date = new Date();
  if (physicalMeasurement.value?.measuredAt) {
    date = new Date(physicalMeasurement.value.measuredAt);
  } else if (storedFormData.value?.measuredAt) {
    date = new Date(storedFormData.value.measuredAt);
  }

  return format(date, "yyyy-MM-dd'T'HH:mm");
}

const initialValues = ref<Partial<PhysicalMeasurementAttributes>>({
  measuredAt: getMeasuredAt(),
  measurerId: storedFormData.value?.measurerId ?? props.measurerId,
  weight: storedFormData.value?.weight ?? physicalMeasurement.value?.weight,
  height: storedFormData.value?.height ?? physicalMeasurement.value?.height,
  relaxedArmCircumference:
    storedFormData.value?.relaxedArmCircumference ??
    physicalMeasurement.value?.relaxedArmCircumference,
  flexedArmCircumference:
    storedFormData.value?.flexedArmCircumference ??
    physicalMeasurement.value?.flexedArmCircumference,
  waistCircumference: storedFormData.value?.waistCircumference ?? physicalMeasurement.value?.waistCircumference,
  hipCircumference: storedFormData.value?.hipCircumference ?? physicalMeasurement.value?.hipCircumference,
  thighCircumference: storedFormData.value?.thighCircumference ?? physicalMeasurement.value?.thighCircumference,
  calfCircumference: storedFormData.value?.calfCircumference ?? physicalMeasurement.value?.calfCircumference,
  tricepsSkinfold: storedFormData.value?.tricepsSkinfold ?? physicalMeasurement.value?.tricepsSkinfold,
  tricepsSkinfold2: storedFormData.value?.tricepsSkinfold2 ?? physicalMeasurement.value?.tricepsSkinfold2,
  tricepsSkinfold3: storedFormData.value?.tricepsSkinfold3 ?? physicalMeasurement.value?.tricepsSkinfold3,
  subscapularSkinfold: storedFormData.value?.subscapularSkinfold ?? physicalMeasurement.value?.subscapularSkinfold,
  subscapularSkinfold2: storedFormData.value?.subscapularSkinfold2 ?? physicalMeasurement.value?.subscapularSkinfold2,
  subscapularSkinfold3: storedFormData.value?.subscapularSkinfold3 ?? physicalMeasurement.value?.subscapularSkinfold3,
  bicepsSkinfold: storedFormData.value?.bicepsSkinfold ?? physicalMeasurement.value?.bicepsSkinfold,
  bicepsSkinfold2: storedFormData.value?.bicepsSkinfold2 ?? physicalMeasurement.value?.bicepsSkinfold2,
  bicepsSkinfold3: storedFormData.value?.bicepsSkinfold3 ?? physicalMeasurement.value?.bicepsSkinfold3,
  iliacCrestSkinfold: storedFormData.value?.iliacCrestSkinfold ?? physicalMeasurement.value?.iliacCrestSkinfold,
  iliacCrestSkinfold2: storedFormData.value?.iliacCrestSkinfold2 ?? physicalMeasurement.value?.iliacCrestSkinfold2,
  iliacCrestSkinfold3: storedFormData.value?.iliacCrestSkinfold3 ?? physicalMeasurement.value?.iliacCrestSkinfold3,
  suprailiacSkinfold: storedFormData.value?.suprailiacSkinfold ?? physicalMeasurement.value?.suprailiacSkinfold,
  suprailiacSkinfold2: storedFormData.value?.suprailiacSkinfold2 ?? physicalMeasurement.value?.suprailiacSkinfold2,
  suprailiacSkinfold3: storedFormData.value?.suprailiacSkinfold3 ?? physicalMeasurement.value?.suprailiacSkinfold3,
  abdominalSkinfold: storedFormData.value?.abdominalSkinfold ?? physicalMeasurement.value?.abdominalSkinfold,
  abdominalSkinfold2: storedFormData.value?.abdominalSkinfold2 ?? physicalMeasurement.value?.abdominalSkinfold2,
  abdominalSkinfold3: storedFormData.value?.abdominalSkinfold3 ?? physicalMeasurement.value?.abdominalSkinfold3,
  thighSkinfold: storedFormData.value?.thighSkinfold ?? physicalMeasurement.value?.thighSkinfold,
  thighSkinfold2: storedFormData.value?.thighSkinfold2 ?? physicalMeasurement.value?.thighSkinfold2,
  thighSkinfold3: storedFormData.value?.thighSkinfold3 ?? physicalMeasurement.value?.thighSkinfold3,
  calfSkinfold: storedFormData.value?.calfSkinfold ?? physicalMeasurement.value?.calfSkinfold,
  calfSkinfold2: storedFormData.value?.calfSkinfold2 ?? physicalMeasurement.value?.calfSkinfold2,
  calfSkinfold3: storedFormData.value?.calfSkinfold3 ?? physicalMeasurement.value?.calfSkinfold3,
  humerusBreadth: storedFormData.value?.humerusBreadth ?? physicalMeasurement.value?.humerusBreadth,
  femurBreadth: storedFormData.value?.femurBreadth ?? physicalMeasurement.value?.femurBreadth,
  gripStrength: storedFormData.value?.gripStrength ?? physicalMeasurement.value?.gripStrength,
  notes: storedFormData.value?.notes ?? physicalMeasurement.value?.notes,
});

const { handleSubmit, values, setFieldValue } = useForm<PhysicalMeasurementAttributes>({
  validationSchema: physicalMeasurementSchema,
  initialValues: initialValues.value,
  keepValuesOnUnmount: true,
});

watchDebounced(
  values,
  (newValues) => {
    storedFormData.value = { ...newValues };
  },
  { deep: true, debounce: 500 },
);

onBeforeUnmount(() => {
  storedFormData.value = null;
});

const isFormChanged = computed(() => {
  const currentValues = JSON.stringify(values, Object.keys(values).sort());
  const initialFormValues = JSON.stringify(initialValues.value, Object.keys(initialValues.value).sort());

  return currentValues !== initialFormValues;
});

useBeforeUnloadWarning(isFormChanged);

function upsertPhysicalMeasurement(data: PhysicalMeasurementAttributes) {
  const attributes = {
    ...data,
    userableId: props.userable.id,
    userableType: props.userable.userableType,
  };

  if (props.physicalMeasurement) {
    return physicalMeasurementsApi.update(props.physicalMeasurement.id, attributes);
  }

  return physicalMeasurementsApi.create(attributes);
}

const {
  isError,
  isPending,
  isIdle,
  isSuccess,
  mutate: savePhysicalMeasurement,
  error: savePhysicalMeasurementError,
  reset: resetMutation,
} = useMutation({
  mutationFn: (data: PhysicalMeasurementAttributes) => upsertPhysicalMeasurement(data),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['physical-measurements'] });
    initialValues.value = { ...values };
    measurer.value = props.measurers.find((m) => m.id === values.measurerId);
    storedFormData.value = null;
  },
});

const buttonText = computed(() => {
  if (!isFormChanged.value) return 'Sin cambios';
  if (isIdle.value || isError.value) return 'Guardar';
  if (isPending.value) return 'Guardando...';
  if (isSuccess.value) return 'Guardado';

  return 'Guardar';
});

const buttonClass = computed(() => {
  if (!isFormChanged.value) return 'bg-gray-300 text-gray-500';
  if (isIdle.value) return 'bg-black text-white hover:bg-gray-800';
  if (isPending.value || isError.value) return 'bg-gray-300 text-gray-500 cursor-not-allowed';
  if (isSuccess.value) return 'bg-green-500 text-white hover:bg-green-600';

  return 'bg-black text-white hover:bg-gray-800';
});

const isSubmitDisabled = computed(() => !isFormChanged.value || isPending.value || isSuccess.value);

const onSubmit = handleSubmit((data: PhysicalMeasurementAttributes) => {
  savePhysicalMeasurement(data);
});

function handleBack() {
  window.history.back();
}

function handleResetMutation() {
  resetMutation();
}

function handleBasicInfoSuccess() {
  showFullForm.value = true;
}
</script>

<template>
  <div class="mb-4">
    <div class="flex flex-col gap-4">
      <BackButton
        href="#"
        @click.prevent="handleBack"
      />
      <div class="mb-6 flex items-end justify-between">
        <h1 class="text-2xl font-semibold">
          {{ isEditing ? 'Editar' : 'Crear' }} medición - {{ userable.fullName }}
        </h1>
        <template v-if="showFullForm">
          <BaseButton
            type="submit"
            :disabled="isSubmitDisabled"
            :class="buttonClass"
            @click="onSubmit"
          >
            {{ buttonText }}
          </BaseButton>
        </template>
      </div>
    </div>

    <div v-if="needsBasicInfo && !showFullForm">
      <div class="mb-8">
        <div class="rounded-lg border border-gray-200 bg-white p-4">
          <p class="mb-4 text-gray-700">
            Antes de tomar las mediciones, necesitamos algunos datos básicos.
          </p>
          <BasicInfoForm
            :user="userable"
            @success="handleBasicInfoSuccess"
          />
        </div>
      </div>
    </div>

    <template v-if="showFullForm">
      <form
        v-if="isIdle"
        class="space-y-8"
        @submit.prevent="onSubmit"
      >
        <div class="rounded-lg border border-gray-200 bg-white shadow">
          <div class="px-4 py-5 sm:p-6">
            <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
              <div class="flex flex-col gap-1">
                <BaseLabel
                  label="Fecha de medición"
                  name="measuredAt"
                  required
                />
                <BaseInput
                  v-model="values.measuredAt"
                  name="measuredAt"
                  type="datetime-local"
                />
              </div>
              <div class="flex flex-col gap-1">
                <BaseLabel
                  label="Medidor"
                  name="measurerId"
                  required
                />
                <BaseSelect
                  :model-value="values.measurerId"
                  name="measurerId"
                  :options="measurers"
                  @update:model-value="setFieldValue('measurerId', Number($event))"
                />
              </div>
              <div class="flex flex-col gap-1">
                <BaseLabel
                  label="Nota para nutricionista"
                  name="notes"
                />
                <BaseTextarea
                  v-model="values.notes"
                  name="notes"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="rounded-lg border border-gray-200 bg-white shadow">
          <div class="px-4 py-5 sm:p-6">
            <h2 class="mb-4 text-lg font-medium text-gray-900">
              Antropometría básica
            </h2>
            <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
              <div class="flex flex-col gap-1">
                <BaseLabel
                  label="Talla [cm]"
                  name="height"
                  required
                />
                <BaseInput
                  :model-value="values.height"
                  name="height"
                  type="number"
                  step="0.1"
                  @update:model-value="setFieldValue('height', $event)"
                />
              </div>
              <div class="flex flex-col gap-1">
                <BaseLabel
                  label="Masa corporal [kg]"
                  name="weight"
                  required
                />
                <BaseInput
                  :model-value="values.weight"
                  name="weight"
                  type="number"
                  step="0.1"
                  @update:model-value="setFieldValue('weight', $event)"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="rounded-lg border border-gray-200 bg-white shadow">
          <div class="px-4 py-5 sm:p-6">
            <h2 class="mb-4 text-lg font-medium text-gray-900">
              Circunferencias
            </h2>
            <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
              <div class="flex flex-col gap-1">
                <BaseLabel
                  label="Brazo relajado [cm]"
                  name="relaxedArmCircumference"
                  required
                />
                <BaseInput
                  v-model="values.relaxedArmCircumference"
                  name="relaxedArmCircumference"
                  type="number"
                  step="0.1"
                />
              </div>
              <div class="flex flex-col gap-1">
                <BaseLabel
                  label="Brazo flexionado [cm]"
                  name="flexedArmCircumference"
                  required
                />
                <BaseInput
                  v-model="values.flexedArmCircumference"
                  name="flexedArmCircumference"
                  type="number"
                  step="0.1"
                />
              </div>
              <div class="flex flex-col gap-1">
                <BaseLabel
                  label="Cintura [cm]"
                  name="waistCircumference"
                  required
                />
                <BaseInput
                  v-model="values.waistCircumference"
                  name="waistCircumference"
                  type="number"
                  step="0.1"
                />
              </div>
              <div class="flex flex-col gap-1">
                <BaseLabel
                  label="Cadera máxima [cm]"
                  name="hipCircumference"
                  required
                />
                <BaseInput
                  v-model="values.hipCircumference"
                  name="hipCircumference"
                  type="number"
                  step="0.1"
                />
              </div>
              <div class="flex flex-col gap-1">
                <BaseLabel
                  label="Muslo medio [cm]"
                  name="thighCircumference"
                  required
                />
                <BaseInput
                  v-model="values.thighCircumference"
                  name="thighCircumference"
                  type="number"
                  step="0.1"
                />
              </div>
              <div class="flex flex-col gap-1">
                <BaseLabel
                  label="Pantorrilla [cm]"
                  name="calfCircumference"
                  required
                />
                <BaseInput
                  v-model="values.calfCircumference"
                  name="calfCircumference"
                  type="number"
                  step="0.1"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="rounded-lg border border-gray-200 bg-white shadow">
          <div class="px-4 py-5 sm:p-6">
            <h2 class="mb-4 text-lg font-medium text-gray-900">
              Pliegues cutáneos
            </h2>
            <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
              <div class="flex flex-col gap-1">
                <BaseLabel
                  label="Bíceps [mm]"
                  name="bicepsSkinfold"
                  required
                />
                <div class="flex gap-2">
                  <BaseInput
                    v-model="values.bicepsSkinfold"
                    name="bicepsSkinfold"
                    type="number"
                    step="0.1"
                    placeholder="Requerida"
                  />
                  <BaseInput
                    v-model="values.bicepsSkinfold2"
                    name="bicepsSkinfold2"
                    type="number"
                    step="0.1"
                    placeholder="Opcional"
                  />
                  <BaseInput
                    v-model="values.bicepsSkinfold3"
                    name="bicepsSkinfold3"
                    type="number"
                    step="0.1"
                    placeholder="Opcional"
                  />
                </div>
              </div>
              <div class="flex flex-col gap-1">
                <BaseLabel
                  label="Tríceps [mm]"
                  name="tricepsSkinfold"
                  required
                />
                <div class="flex gap-2">
                  <BaseInput
                    v-model="values.tricepsSkinfold"
                    name="tricepsSkinfold"
                    type="number"
                    step="0.1"
                    placeholder="Requerida"
                  />
                  <BaseInput
                    v-model="values.tricepsSkinfold2"
                    name="tricepsSkinfold2"
                    type="number"
                    step="0.1"
                    placeholder="Opcional"
                  />
                  <BaseInput
                    v-model="values.tricepsSkinfold3"
                    name="tricepsSkinfold3"
                    type="number"
                    step="0.1"
                    placeholder="Opcional"
                  />
                </div>
              </div>
              <div class="flex flex-col gap-1">
                <BaseLabel
                  label="Subescapular [mm]"
                  name="subscapularSkinfold"
                  required
                />
                <div class="flex gap-2">
                  <BaseInput
                    v-model="values.subscapularSkinfold"
                    name="subscapularSkinfold"
                    type="number"
                    step="0.1"
                    placeholder="Requerida"
                  />
                  <BaseInput
                    v-model="values.subscapularSkinfold2"
                    name="subscapularSkinfold2"
                    type="number"
                    step="0.1"
                    placeholder="Opcional"
                  />
                  <BaseInput
                    v-model="values.subscapularSkinfold3"
                    name="subscapularSkinfold3"
                    type="number"
                    step="0.1"
                    placeholder="Opcional"
                  />
                </div>
              </div>
              <div class="flex flex-col gap-1">
                <BaseLabel
                  label="Cresta ilíaca [mm]"
                  name="iliacCrestSkinfold"
                  required
                />
                <div class="flex gap-2">
                  <BaseInput
                    v-model="values.iliacCrestSkinfold"
                    name="iliacCrestSkinfold"
                    type="number"
                    step="0.1"
                    placeholder="Requerida"
                  />
                  <BaseInput
                    v-model="values.iliacCrestSkinfold2"
                    name="iliacCrestSkinfold2"
                    type="number"
                    step="0.1"
                    placeholder="Opcional"
                  />
                  <BaseInput
                    v-model="values.iliacCrestSkinfold3"
                    name="iliacCrestSkinfold3"
                    type="number"
                    step="0.1"
                    placeholder="Opcional"
                  />
                </div>
              </div>
              <div class="flex flex-col gap-1">
                <BaseLabel
                  label="Suprailiaco [mm]"
                  name="suprailiacSkinfold"
                  required
                />
                <div class="flex gap-2">
                  <BaseInput
                    v-model="values.suprailiacSkinfold"
                    name="suprailiacSkinfold"
                    type="number"
                    step="0.1"
                    placeholder="Requerida"
                  />
                  <BaseInput
                    v-model="values.suprailiacSkinfold2"
                    name="suprailiacSkinfold2"
                    type="number"
                    step="0.1"
                    placeholder="Opcional"
                  />
                  <BaseInput
                    v-model="values.suprailiacSkinfold3"
                    name="suprailiacSkinfold3"
                    type="number"
                    step="0.1"
                    placeholder="Opcional"
                  />
                </div>
              </div>
              <div class="flex flex-col gap-1">
                <BaseLabel
                  label="Abdominal [mm]"
                  name="abdominalSkinfold"
                  required
                />
                <div class="flex gap-2">
                  <BaseInput
                    v-model="values.abdominalSkinfold"
                    name="abdominalSkinfold"
                    type="number"
                    step="0.1"
                    placeholder="Requerida"
                  />
                  <BaseInput
                    v-model="values.abdominalSkinfold2"
                    name="abdominalSkinfold2"
                    type="number"
                    step="0.1"
                    placeholder="Opcional"
                  />
                  <BaseInput
                    v-model="values.abdominalSkinfold3"
                    name="abdominalSkinfold3"
                    type="number"
                    step="0.1"
                    placeholder="Opcional"
                  />
                </div>
              </div>
              <div class="flex flex-col gap-1">
                <BaseLabel
                  label="Muslo [mm]"
                  name="thighSkinfold"
                  required
                />
                <div class="flex gap-2">
                  <BaseInput
                    v-model="values.thighSkinfold"
                    name="thighSkinfold"
                    type="number"
                    step="0.1"
                    placeholder="Requerida"
                  />
                  <BaseInput
                    v-model="values.thighSkinfold2"
                    name="thighSkinfold2"
                    type="number"
                    step="0.1"
                    placeholder="Opcional"
                  />
                  <BaseInput
                    v-model="values.thighSkinfold3"
                    name="thighSkinfold3"
                    type="number"
                    step="0.1"
                    placeholder="Opcional"
                  />
                </div>
              </div>
              <div class="flex flex-col gap-1">
                <BaseLabel
                  label="Pantorrilla [mm]"
                  name="calfSkinfold"
                  required
                />
                <div class="flex gap-2">
                  <BaseInput
                    v-model="values.calfSkinfold"
                    name="calfSkinfold"
                    type="number"
                    step="0.1"
                    placeholder="Requerida"
                  />
                  <BaseInput
                    v-model="values.calfSkinfold2"
                    name="calfSkinfold2"
                    type="number"
                    step="0.1"
                    placeholder="Opcional"
                  />
                  <BaseInput
                    v-model="values.calfSkinfold3"
                    name="calfSkinfold3"
                    type="number"
                    step="0.1"
                    placeholder="Opcional"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="rounded-lg border border-gray-200 bg-white shadow">
          <div class="px-4 py-5 sm:p-6">
            <h2 class="mb-4 text-lg font-medium text-gray-900">
              Diámetros óseos
            </h2>
            <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
              <div class="flex flex-col gap-1">
                <BaseLabel
                  label="Húmero [cm]"
                  name="humerusBreadth"
                  required
                />
                <BaseInput
                  v-model="values.humerusBreadth"
                  name="humerusBreadth"
                  type="number"
                  step="0.1"
                />
              </div>
              <div class="flex flex-col gap-1">
                <BaseLabel
                  label="Fémur [cm]"
                  name="femurBreadth"
                  required
                />
                <BaseInput
                  v-model="values.femurBreadth"
                  name="femurBreadth"
                  type="number"
                  step="0.1"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="rounded-lg border border-gray-200 bg-white shadow">
          <div class="px-4 py-5 sm:p-6">
            <h2 class="mb-4 text-lg font-medium text-gray-900">
              Fuerza
            </h2>
            <div class="flex flex-col gap-1">
              <BaseLabel
                label="Fuerza de agarre [kg]"
                name="gripStrength"
                required
              />
              <BaseInput
                v-model="values.gripStrength"
                name="gripStrength"
                type="number"
                step="0.1"
              />
            </div>
          </div>
        </div>

        <div class="flex justify-end">
          <BaseButton
            type="submit"
            :disabled="isSubmitDisabled"
            :class="buttonClass"
          >
            {{ buttonText }}
          </BaseButton>
        </div>
      </form>
      <div
        v-else-if="isError"
        class="flex flex-col gap-4"
      >
        <div
          class="mt-4 flex flex-col gap-4 rounded-md bg-red-50 p-4"
        >
          <div class="flex max-w-full">
            <AlertTriangle class="shrink-0 text-red-400" />
            <div class="ml-3">
              <h3 class="text-sm font-medium text-red-800">
                Error al {{ isEditing ? 'actualizar' : 'crear' }} la medición
              </h3>
              <div class="mt-2 text-sm text-red-700">
                <p>{{ savePhysicalMeasurementError }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end">
          <BaseButton
            type="button"
            @click="handleResetMutation"
          >
            Volver al formulario
          </BaseButton>
        </div>
      </div>

      <div
        v-else-if="isSuccess"
        class="mt-4 space-y-8"
      >
        <div class="rounded-md bg-green-50 p-4">
          <div class="flex">
            <div class="shrink-0">
              <Check class="text-green-400" />
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-green-800">
                Medición {{ isEditing ? 'actualizada' : 'creada' }} exitosamente
              </h3>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
