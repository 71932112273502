<script setup lang="ts">
import { computed } from 'vue';
import { format, parseISO } from 'date-fns';
import type { MealCheck } from '@/types';
import type { Meal } from '@/types/extended';

const props = defineProps<{
  meals: Meal[];
  mealChecks: MealCheck[];
  date: Date;
}>();

const statusEmoji = {
  failed: '❌',
  mixed: '🙂',
  completed: '✅',
} as const;

const statusText = {
  failed: 'Fallado',
  mixed: 'Mas o menos',
  completed: 'Completado',
} as const;

function getMealName(mealId: number) {
  return props.meals.find(meal => meal.id === mealId)?.name;
}

const sortedMealChecks = computed(() =>
  [...props.mealChecks].sort((a, b) =>
    parseISO(a.checkedAt).getTime() - parseISO(b.checkedAt).getTime(),
  ),
);
</script>

<template>
  <div class="rounded-lg border border-gray-200 bg-white p-4">
    <h3 class="mb-4 text-lg font-medium">
      {{ format(date, 'd MMMM yyyy') }}
    </h3>
    <div
      v-if="mealChecks.length > 0"
      class="space-y-4"
    >
      <div
        v-for="check in sortedMealChecks"
        :key="check.id"
        class="rounded-lg border border-gray-100 bg-gray-50 p-4"
      >
        <div class="flex items-center gap-2">
          <span class="text-xl">{{ statusEmoji[check.status] }}</span>
          <h4 class="font-medium">
            {{ getMealName(check.mealId) }}
          </h4>
        </div>
        <div class="mt-2 space-y-1 text-sm text-gray-600">
          <p>
            <span class="font-medium">Hora:</span>
            {{ format(new Date(check.checkedAt), 'HH:mm') }}
          </p>
          <p>
            <span class="font-medium">Nivel de logro:</span>
            {{ statusText[check.status] }}
          </p>
          <p v-if="check.comment">
            <span class="font-medium">Comentario:</span>
            {{ check.comment }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-else
      class="py-4 text-center text-sm text-gray-500"
    >
      No existen registros de comidas para esta fecha.
    </div>
  </div>
</template>
