<script setup lang="ts">
import { ref, computed, type FunctionalComponent, type Ref } from 'vue';
import { Menu, X, Users, MessageSquare, Dumbbell, Apple, Brain, Ruler, Calendar } from 'lucide-vue-next';
import { useStreamChat } from '@/composables/stream-chat';

const props = defineProps<{
  userId: string;
  userableType: string;
  streamChatAppKey: string;
  controllerPath: string;
}>();

const { unreadChannels } = useStreamChat(props.userId, props.streamChatAppKey);

const CONTROLLER_PATHS_WITH_SIDEBAR = [
  'trainees',
  'exercises',
  'routines',
  'workouts',
  'chats',
  'nutrition_plans',
  'physical_measurements',
  'assistant',
  'home',
  'cal_bookings',
  'measurees',
];
const CONTROLLER_PATHS_WITH_FULL_SIZE = [
  'routines',
  'chats',
  'assistant',
];

const hasSidebar = ref(CONTROLLER_PATHS_WITH_SIDEBAR.includes(props.controllerPath));

const isSidebarOpen = ref(false);
const isFullSize = computed(() => !hasSidebar.value || CONTROLLER_PATHS_WITH_FULL_SIZE.includes(props.controllerPath));

function toggleSidebar() {
  isSidebarOpen.value = !isSidebarOpen.value;
}

type SidebarItem = {
  name: string;
  path: string;
  controllerPath: string;
  icon: FunctionalComponent;
  badge?: Ref<number>;
};

const sidebarItems = computed<SidebarItem[]>(() => {
  const tabs = {
    trainees: {
      name: 'Trainees',
      path: '/trainees',
      controllerPath: 'trainees',
      icon: Users,
    },
    chats: {
      name: 'Chats',
      path: '/chats',
      controllerPath: 'chats',
      icon: MessageSquare,
      badge: unreadChannels,
    },
    physicalMeasurements: {
      name: 'Mediciones',
      path: '/physical_measurements',
      controllerPath: 'physical_measurements',
      icon: Ruler,
    },
    nutrition: {
      name: 'Nutrición',
      path: '/nutrition_plans',
      controllerPath: 'nutrition_plans',
      icon: Apple,
    },
    assistant: {
      name: 'Kap AI',
      path: '/assistant',
      controllerPath: 'assistant',
      icon: Brain,
    },
    exercises: {
      name: 'Ejercicios',
      path: '/exercises',
      controllerPath: 'exercises',
      icon: Dumbbell,
    },
    calBookings: {
      name: 'Agenda',
      path: '/cal_bookings',
      controllerPath: 'cal_bookings',
      icon: Calendar,
    },
  };

  const items = [];

  if (props.userableType === 'Trainer') {
    items.push(
      tabs.trainees,
      tabs.chats,
      tabs.exercises,
      tabs.physicalMeasurements,
      tabs.assistant,
    );
  } else if (props.userableType === 'Nutritionist') {
    items.push(
      tabs.nutrition,
      tabs.chats,
      tabs.physicalMeasurements,
      tabs.trainees,
      tabs.assistant,
    );
  } else if (props.userableType === 'Measurer') {
    items.push(
      tabs.calBookings,
      tabs.physicalMeasurements,
    );
  } else if (props.userableType === 'Measuree') {
    items.push(
      tabs.physicalMeasurements,
    );
  }

  return items;
});
</script>

<template>
  <div class="flex h-full">
    <template v-if="hasSidebar">
      <aside
        class="absolute left-0 top-0 z-40 h-screen bg-white pt-14 shadow-md transition-all duration-300"
        :class="[
          'sm:translate-x-0',
          isSidebarOpen ? 'w-48 translate-x-0' : '-translate-x-full sm:w-14 sm:translate-x-0',
          'sm:block',
        ]"
      >
        <div class="m-2 h-full overflow-y-auto">
          <ul class="space-y-2 font-medium">
            <li
              v-for="item in sidebarItems"
              :key="item.name"
            >
              <a
                :href="item.path"
                class="flex items-center rounded-lg p-2 text-gray-900 hover:bg-gray-100"
                :class="[
                  { 'bg-gray-100': item.controllerPath === props.controllerPath },
                  isSidebarOpen ? 'justify-start' : 'sm:justify-center',
                ]"
              >
                <div class="relative flex items-center">
                  <component
                    :is="item.icon"
                    class="size-5 shrink-0"
                  />
                  <span
                    v-if="item.badge && item.badge.value > 0"
                    class="absolute -right-2 -top-2 inline-flex h-5 min-w-5 shrink-0 items-center justify-center rounded-full bg-red-500 px-1 text-xs font-semibold text-white"
                  >
                    {{ item.badge.value }}
                  </span>
                </div>
                <span
                  class="ml-3 whitespace-nowrap text-sm transition-opacity duration-300"
                  :class="{ 'sm:hidden': !isSidebarOpen, 'block': isSidebarOpen }"
                >
                  {{ item.name }}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </aside>
      <button
        type="button"
        class="fixed left-0 top-0 z-50 m-2 rounded-md bg-white p-2 transition-colors duration-200 hover:bg-gray-100"
        @click="toggleSidebar"
      >
        <span class="sr-only">Toggle sidebar</span>
        <Menu
          v-if="!isSidebarOpen"
          class="size-6"
        />
        <X
          v-else
          class="size-6"
        />
      </button>
    </template>
    <main
      class="h-screen flex-1 overflow-y-auto transition-all duration-300"
      :class="{ 'sm:ml-48': isSidebarOpen, 'sm:ml-14': !isSidebarOpen && hasSidebar }"
    >
      <template v-if="isFullSize">
        <slot />
      </template>
      <div
        v-else
        class="mx-auto max-w-7xl p-4 pt-14 sm:p-6 sm:pt-14"
      >
        <slot />
      </div>
    </main>
  </div>
</template>
