<script setup lang="ts">
import { ref, toRef } from 'vue';
import { useField } from 'vee-validate';
import { useTextareaAutosize } from '@vueuse/core';
import MarkdownRenderer from './markdown-renderer.vue';

defineOptions({
  inheritAttrs: false,
});

interface Props {
  modelValue?: string
  placeholder?: string
  name: string
  required?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  placeholder: '',
});

const name = toRef(props, 'name');

const {
  meta,
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
} = useField(name, undefined, {
  initialValue: props.modelValue,
  syncVModel: true,
});

const textareaRef = ref<HTMLTextAreaElement>();

const { textarea } = useTextareaAutosize({
  element: textareaRef,
  input: inputValue,
});

function handleInput(event: Event) {
  const target = event.target as HTMLTextAreaElement;
  handleChange(target.value);
}
</script>

<template>
  <div
    class="flex flex-col gap-1"
    :class="$attrs.class"
  >
    <div
      class="flex h-min overflow-hidden rounded border bg-gray-50"
      :class="[errorMessage && meta.touched ? 'border-red-600' : 'border-slate-200']"
    >
      <textarea
        ref="textarea"
        :value="inputValue"
        :placeholder="placeholder"
        :name="name"
        class="h-full w-1/2 resize-none bg-gray-50 p-5 font-mono text-sm outline-none"
        :class="[errorMessage && meta.touched ? 'text-red-600' : '']"
        @input="handleInput"
        @blur="handleBlur"
      />
      <!-- eslint-disable vue/no-v-html -->
      <MarkdownRenderer
        class="h-full w-1/2 overflow-auto border-l border-gray-200 bg-white p-5"
        :markdown="inputValue"
      />
      <!-- eslint-enable vue/no-v-html -->
    </div>
    <p
      v-show="errorMessage && meta.touched"
      class="text-right text-xs text-red-600"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
