<script setup lang="ts">
import { ref, computed } from 'vue';
import { trackEvent } from '@/utils/analytics';
import type { SurveyResponse } from '@/types/body-composition-survey';

import BasicInfo from './basic-information.vue';
import BodyType from './body-type.vue';
import ExerciseFrequency from './exercise-frequency.vue';
import BodyFat from './body-fat-assessment.vue';
import WaistMeasurement from './waist-measurement.vue';
import MuscleMass from './muscle-mass-assessment.vue';
import ContactInfo from './contact-information.vue';
import Results from './results.vue';

const surveyResponse = ref<Partial<SurveyResponse>>({});
const currentStepIndex = ref(0);

interface Step {
  id: string;
  title: string;
  component: any;
}

const steps: Step[] = [
  { id: 'basicInfo', title: 'Información Básica', component: BasicInfo },
  { id: 'bodyType', title: 'Tipo de Cuerpo', component: BodyType },
  { id: 'exerciseFrequency', title: 'Nivel de Actividad', component: ExerciseFrequency },
  { id: 'bodyFat', title: 'Evaluación de Grasa Corporal', component: BodyFat },
  { id: 'waistMeasurement', title: 'Medición de Cintura', component: WaistMeasurement },
  { id: 'muscleMass', title: 'Evaluación de Masa Muscular', component: MuscleMass },
  { id: 'contactInfo', title: 'Información de Contacto', component: ContactInfo },
  { id: 'results', title: 'Resultados', component: Results },
];

const currentComponent = computed(() => steps[currentStepIndex.value]?.component);
const progress = computed(() => ((currentStepIndex.value + 1) / steps.length) * 100);
const isFirstStep = computed(() => currentStepIndex.value === 0);
const isLastStep = computed(() => currentStepIndex.value === steps.length - 1);

function handleStart() {
  currentStepIndex.value = 0;
}

function handleNext(data: Partial<SurveyResponse>) {
  surveyResponse.value = {
    ...surveyResponse.value,
    ...data,
  };

  const currentStep = steps[currentStepIndex.value];
  trackEvent('body_composition_survey_step_completed', {
    'step_id': currentStep.id,
    'step_title': currentStep.title,
    'step_index': currentStepIndex.value,
    'total_steps': steps.length,
    'progress_percentage': progress.value,
  });

  if (!isLastStep.value) {
    currentStepIndex.value++;
    const nextStep = steps[currentStepIndex.value];
    trackEvent('body_composition_survey_step_started', {
      'step_id': nextStep.id,
      'step_title': nextStep.title,
      'step_index': currentStepIndex.value,
      'total_steps': steps.length,
      'progress_percentage': progress.value,
    });
  }
}

function handleBack() {
  if (!isFirstStep.value) {
    const currentStep = steps[currentStepIndex.value];
    trackEvent('body_composition_survey_step_back', {
      'from_step_id': currentStep.id,
      'from_step_title': currentStep.title,
      'from_step_index': currentStepIndex.value,
      'total_steps': steps.length,
    });

    currentStepIndex.value--;

    const previousStep = steps[currentStepIndex.value];
    trackEvent('body_composition_survey_step_started', {
      'step_id': previousStep.id,
      'step_title': previousStep.title,
      'step_index': currentStepIndex.value,
      'total_steps': steps.length,
      'progress_percentage': progress.value,
    });
  }
}
</script>

<template>
  <div
    class="mx-auto"
    :class="{
      'max-w-3xl': currentComponent !== Results,
      'max-w-7xl': currentComponent === Results
    }"
  >
    <div>
      <!-- Progress Bar -->
      <div class="mb-8">
        <div class="flex items-center justify-between">
          <div class="text-sm font-medium text-gray-900">
            {{ Math.round(progress) }}%
          </div>
          <div class="text-sm font-medium text-gray-500">
            {{ steps[currentStepIndex].title }}
          </div>
        </div>
        <div class="mt-2 h-2 w-full rounded-full bg-gray-200">
          <div
            class="h-2 rounded-full bg-black transition-all duration-500"
            :style="{ width: `${progress}%` }"
          />
        </div>
      </div>

      <!-- Current Step -->
      <div>
        <component
          :is="currentComponent"
          :response="surveyResponse"
          @next="handleNext"
          @back="handleBack"
        />
      </div>
    </div>
  </div>
</template>
