<script setup lang="ts">
import { computed } from 'vue';
import { TrendingUp } from 'lucide-vue-next';
import type { Trainee, Measuree } from '@/types';
import type { HealthCategory } from './physical-assessment-metrics';

const props = defineProps<{
  userable: Trainee | Measuree;
  healthCategory?: HealthCategory;
}>();

const onboardingCheckoutUrl = computed(() => {
  const encodedEmail = encodeURIComponent(props.userable.email);

  return `/onboarding/checkout/personalized?email=${encodedEmail}&referral_source=measurement`;
});
</script>

<template>
  <div
    v-if="userable.userableType === 'Measuree' && healthCategory?.id !== 'optimal'"
    class="flex justify-center"
  >
    <a
      :href="onboardingCheckoutUrl"
      class="inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md bg-black px-6 py-3 text-sm font-normal text-white ring-offset-white transition-colors hover:bg-black focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2"
    >
      Mejorar con Kapso <TrendingUp class="size-4" />
    </a>
  </div>
</template>
