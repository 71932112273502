<script setup lang="ts">
import { computed, ref } from 'vue';
import {
  format,
  parseISO,
  isEqual,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  getDay,
  addDays,
  isSameDay,
  isWithinInterval,
} from 'date-fns';
import { daysInWeek } from 'date-fns/constants';
import { es } from 'date-fns/locale';
import { ChevronLeft, ChevronRight } from 'lucide-vue-next';
import type { MealCheck } from '@/types';
import type { Meal } from '@/types/extended';
import MealCheckDetails from './meal-check-details.vue';

const props = defineProps<{
  meals: Meal[];
  mealChecks: MealCheck[];
  startAt: string;
  endAt: string;
}>();

const selectedDate = ref<Date | null>(null);

const statusEmoji = {
  failed: '❌',
  mixed: '🙂',
  completed: '✅',
} as const;

const WEEKDAYS = ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'];

const monthsWithChecks = computed(() => {
  const months = new Set(
    props.mealChecks.map(check => format(parseISO(check.checkedAt), 'yyyy-MM')),
  );

  return Array.from(months)
    .map(monthStr => parseISO(`${monthStr}-01`))
    .sort((a, b) => b.getTime() - a.getTime());
});

const currentMonthIndex = ref(0);

const currentMonth = computed(() => monthsWithChecks.value[currentMonthIndex.value]);

const canGoToPreviousMonth = computed(() => currentMonthIndex.value < monthsWithChecks.value.length - 1);
const canGoToNextMonth = computed(() => currentMonthIndex.value > 0);

function goToPreviousMonth() {
  if (canGoToPreviousMonth.value) {
    currentMonthIndex.value++;
    selectedDate.value = null;
  }
}

function goToNextMonth() {
  if (canGoToNextMonth.value) {
    currentMonthIndex.value--;
    selectedDate.value = null;
  }
}

function calculatePaddingDays(start: Date, end: Date) {
  let startPadding = getDay(start) - 1;
  if (startPadding === -1) startPadding = daysInWeek - 1;

  const paddingStart = Array.from({ length: startPadding }, (_, i) =>
    addDays(start, -(startPadding - i)),
  );

  let endPadding = daysInWeek - getDay(end);
  if (endPadding === daysInWeek) endPadding = 0;

  const paddingEnd = Array.from({ length: endPadding }, (_, i) =>
    addDays(end, i + 1),
  );

  return { paddingStart, paddingEnd };
}

const calendarDays = computed(() => {
  if (!currentMonth.value) return [];

  const start = startOfMonth(currentMonth.value);
  const end = endOfMonth(currentMonth.value);
  const days = eachDayOfInterval({ start, end });
  const { paddingStart, paddingEnd } = calculatePaddingDays(start, end);

  return [...paddingStart, ...days, ...paddingEnd];
});

function isPaddingDay(date: Date) {
  return date.getMonth() !== currentMonth.value?.getMonth();
}

function isWithinPlanDuration(date: Date) {
  return isWithinInterval(date, {
    start: parseISO(props.startAt),
    end: parseISO(props.endAt),
  });
}

function getChecksForDate(date: Date) {
  return props.mealChecks
    .filter(check =>
      isSameDay(parseISO(check.checkedAt), date),
    )
    .sort((a, b) =>
      parseISO(a.checkedAt).getTime() - parseISO(b.checkedAt).getTime(),
    );
}

function handleDateClick(date: Date) {
  if (selectedDate.value && isEqual(selectedDate.value, date)) {
    selectedDate.value = null;
  } else {
    selectedDate.value = date;
  }
}

const selectedMealChecks = computed(() => {
  if (!selectedDate.value) return [];

  return getChecksForDate(selectedDate.value);
});
</script>

<template>
  <div class="rounded-lg border border-gray-200 bg-white">
    <div class="border-b border-gray-200 px-4 py-3">
      <h2 class="text-lg font-medium text-gray-900">
        Seguimiento
      </h2>
    </div>

    <div class="space-y-8 p-4">
      <div
        v-if="currentMonth"
        class="space-y-4"
      >
        <div class="flex items-center justify-between">
          <button
            type="button"
            class="rounded-lg p-1 text-gray-800 hover:bg-gray-100 hover:text-gray-900 disabled:opacity-30"
            :disabled="!canGoToPreviousMonth"
            @click="goToPreviousMonth"
          >
            <ChevronLeft class="size-5" />
          </button>

          <h3 class="text-center font-medium capitalize text-gray-900">
            {{ format(currentMonth, 'MMMM yyyy', { locale: es }) }}
          </h3>

          <button
            type="button"
            class="rounded-lg p-1 text-gray-800 hover:bg-gray-100 hover:text-gray-900 disabled:opacity-30"
            :disabled="!canGoToNextMonth"
            @click="goToNextMonth"
          >
            <ChevronRight class="size-5" />
          </button>
        </div>

        <div class="rounded-lg border border-gray-200">
          <!-- Weekday headers -->
          <div class="grid grid-cols-7 border-b border-gray-200 bg-gray-50">
            <div
              v-for="weekday in WEEKDAYS"
              :key="weekday"
              class="p-2 text-center text-sm font-medium text-gray-700"
            >
              {{ weekday }}
            </div>
          </div>

          <!-- Calendar grid -->
          <div class="grid grid-cols-7 divide-x divide-y divide-gray-200">
            <button
              v-for="date in calendarDays"
              :key="format(date, 'yyyy-MM-dd')"
              type="button"
              class="min-h-16 p-2 "
              :class="{
                'bg-gray-50/50 hover:bg-gray-50': isPaddingDay(date),
                'bg-blue-100/50 hover:bg-blue-100': !isPaddingDay(date) && isWithinPlanDuration(date),
                'rounded-lg ring-2 ring-inset ring-blue-500': selectedDate && isSameDay(selectedDate, date)
              }"
              @click="handleDateClick(date)"
            >
              <div class="flex flex-col items-center gap-1">
                <span
                  class="text-sm"
                  :class="{
                    'font-medium text-gray-900': !isPaddingDay(date),
                    'text-gray-400': isPaddingDay(date)
                  }"
                >
                  {{ format(date, 'd') }}
                </span>
                <div class="flex flex-wrap justify-center gap-0.5">
                  <span
                    v-for="check in getChecksForDate(date)"
                    :key="check.id"
                    class="text-xs"
                  >
                    {{ statusEmoji[check.status] }}
                  </span>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <p
        v-else
        class="text-sm text-gray-600"
      >
        No hay seguimientos para este plan.
      </p>
      <MealCheckDetails
        v-if="selectedDate"
        :meals="props.meals"
        :meal-checks="selectedMealChecks"
        :date="selectedDate"
      />
    </div>
  </div>
</template>
