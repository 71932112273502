<script setup lang="ts">
import { ref, onUnmounted } from 'vue';
import { ExternalLink, Info } from 'lucide-vue-next';
import BaseModal from '../base-modal.vue';

const props = defineProps<{
  subscriptionUrl: string
}>();

const isRedirectModalOpen = ref(false);
const countdown = ref(5);
let countdownInterval: number | undefined;

function handleCheckoutClick() {
  isRedirectModalOpen.value = true;
  countdown.value = 5;

  countdownInterval = window.setInterval(() => {
    countdown.value -= 1;
    if (countdown.value <= 0) {
      window.clearInterval(countdownInterval);
      window.location.href = props.subscriptionUrl;
    }
  }, 1000);
}

onUnmounted(() => {
  if (countdownInterval) {
    window.clearInterval(countdownInterval);
  }
});
</script>

<template>
  <div class="flex flex-col space-y-4">
    <button
      class="flex w-full cursor-pointer items-center justify-center gap-x-2 rounded-lg bg-black px-4 py-3 text-center text-white transition duration-300 hover:bg-gray-800"
      @click="handleCheckoutClick"
    >
      <span>Suscribirme con MercadoPago</span>
      <ExternalLink class="size-4" />
    </button>

    <div class="my-4 border-b border-gray-200" />
    <p class="text-sm text-gray-700">
      Luego de completar tu información en MercadoPago, te redirigiremos a
      continuar el proceso en nuestra página.
    </p>

    <BaseModal
      :open="isRedirectModalOpen"
      title="Redirigiendo a MercadoPago..."
      @close="isRedirectModalOpen = false"
    >
      <div class="flex flex-col space-y-6">
        <div class="flex w-full flex-col space-y-4 rounded-xl bg-gradient-to-br from-blue-100/90 via-blue-50/60 to-gray-100/80 p-5 shadow-sm">
          <div class="flex items-center gap-x-3">
            <div class="shrink-0">
              <Info class="size-5 text-gray-600" />
            </div>
            <div>
              <h3 class="text-base font-medium text-gray-900">
                Importante
              </h3>
              <p class="mt-1 text-sm text-gray-600">
                Después de ingresar tus datos, MercadoPago te redirigirá de vuelta a nuestra página para continuar con el proceso.
              </p>
            </div>
          </div>
        </div>

        <div class="text-center text-sm text-gray-500">
          Redirigiendo en {{ countdown }} segundo{{ countdown !== 1 ? 's' : '' }}...
        </div>
      </div>
    </BaseModal>
  </div>
</template>
