<script setup lang="ts">
import { ref, computed } from 'vue';
import type { SurveyResponse } from '@/types/body-composition-survey';

const props = defineProps<{
  response: Partial<SurveyResponse>
}>();

const emit = defineEmits<{
  (e: 'next', data: Partial<SurveyResponse>): void
  (e: 'back'): void
}>();

const formData = ref({
  estimatedMusclemassOption: props.response.estimatedMusclemassOption || '',
});

const showError = ref(false);

const estimationOptions = [
  {
    value: 'very_low',
    title: 'Muy Baja',
    description: 'Músculos pequeños, sin definición visible. Apariencia delgada o poca masa muscular.',
  },
  {
    value: 'low',
    title: 'Baja',
    description: 'Músculos ligeramente desarrollados. Definición apenas visible en ciertas áreas.',
  },
  {
    value: 'moderate',
    title: 'Moderada',
    description: 'Músculos bien desarrollados con definición visible al flexionar. Apariencia tonificada.',
  },
  {
    value: 'high',
    title: 'Alta',
    description: 'Músculos grandes y definidos. Apariencia musculosa incluso sin flexionar.',
  },
  {
    value: 'very_high',
    title: 'Muy Alta',
    description: 'Músculos excepcionalmente grandes y altamente definidos. Apariencia de fisicoculturista.',
  },
];

function handleSubmit() {
  if (!formData.value.estimatedMusclemassOption) {
    showError.value = true;

    return;
  }

  showError.value = false;
  emit('next', {
    hasMusclemassMeasurement: false,
    estimatedMusclemassOption: formData.value.estimatedMusclemassOption,
  });
}
</script>

<template>
  <div class="space-y-8">
    <div class="text-center">
      <h2 class="text-3xl font-bold text-gray-900">
        Evaluación de Masa Muscular
      </h2>
    </div>

    <form
      class="space-y-12"
      @submit.prevent="handleSubmit"
    >
      <div>
        <label class="mb-2 block text-xl font-medium text-gray-900">
          Selecciona la opción que mejor coincida con tu nivel actual de masa muscular
        </label>
        <div class="space-y-4">
          <div
            v-for="option in estimationOptions"
            :key="option.value"
            role="button"
            tabindex="0"
            :class="[
              'flex w-full cursor-pointer flex-col rounded-lg p-4 text-left',
              formData.estimatedMusclemassOption === option.value
                ? 'border-2 border-black bg-gray-50 font-medium text-gray-900'
                : 'bg-gray-50 hover:bg-gray-100',
              { 'border-2 border-red-300': showError && !formData.estimatedMusclemassOption }
            ]"
            @click="formData.estimatedMusclemassOption = option.value"
            @keypress.enter="formData.estimatedMusclemassOption = option.value"
          >
            <h3
              class="mb-1 text-lg text-gray-900"
              :class="{ 'font-medium text-gray-900': formData.estimatedMusclemassOption === option.value }"
            >
              Masa Muscular {{ option.title }}
            </h3>
            <p
              class="text-sm text-gray-600"
              :class="{ 'text-gray-900': formData.estimatedMusclemassOption === option.value }"
            >
              {{ option.description }}
            </p>
          </div>
        </div>
      </div>

      <!-- Navigation Buttons -->
      <div class="grid grid-cols-2 gap-4">
        <button
          type="button"
          class="flex justify-center rounded-lg border border-gray-300 bg-white px-4 py-3 text-sm font-medium text-gray-700 shadow-sm transition duration-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          @click="emit('back')"
        >
          Atrás
        </button>
        <button
          type="submit"
          class="w-full rounded-lg bg-black px-4 py-3 text-white transition duration-300 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          :disabled="!formData.estimatedMusclemassOption"
        >
          Continuar
        </button>
      </div>
    </form>
  </div>
</template>
