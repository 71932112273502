import { serialize } from 'object-to-formdata';
import type { Exercise, ExerciseAttributes } from '@/types/extended';
import { api } from './axios-wrapper';

export interface ExerciseSearchParams {
  nameCont?: string;
  difficultyEq?: 'beginner' | 'intermediate' | 'advanced';
  kindEq?: 'strength' | 'mobility' | 'stretching' | 'cardio' | 'plyometric';
  muscleGroupsNameIn?: string[];
  equipmentNameEq?: string;
  movementPatternNameEq?: string;
  s?: string[];
}

export const exercisesApi = {
  async search(params: ExerciseSearchParams) {
    const transformedParams = {
      ...params,
      s: params.s?.[0],
    };

    const response = await api({
      method: 'get',
      url: '/api/internal/exercises',
      params: {
        'q[name_cont]': transformedParams.nameCont,
        'q[difficulty_eq]': transformedParams.difficultyEq,
        'q[kind_eq]': transformedParams.kindEq,
        'q[muscle_groups_name_in][]': transformedParams.muscleGroupsNameIn,
        'q[equipment_name_eq]': transformedParams.equipmentNameEq,
        'q[movement_pattern_name_eq]': transformedParams.movementPatternNameEq,
        'q[s]': transformedParams.s,
      },
    });

    return response.data as Exercise[];
  },

  async getAll(ids?: number[]) {
    const response = await api({
      method: 'get',
      url: '/api/internal/exercises',
      params: { q: { 'id_in': ids } },
    });

    return response.data as { id: number; name: string }[];
  },

  async get(id: number) {
    const response = await api({
      method: 'get',
      url: `/api/internal/exercises/${id}`,
    });

    return response.data as Exercise;
  },

  async create(exerciseAttributes: ExerciseAttributes) {
    const formData = serialize({ exercise: exerciseAttributes }, { indices: true });

    const response = await api({
      method: 'post',
      url: '/api/internal/exercises',
      data: formData,
    });

    return response.data as Exercise;
  },

  async update(id: number, exerciseAttributes: ExerciseAttributes) {
    const formData = serialize({ exercise: exerciseAttributes }, { indices: true });

    const response = await api({
      method: 'put',
      url: `/api/internal/exercises/${id}`,
      data: formData,
    });

    return response.data as Exercise;
  },
};
