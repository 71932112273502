<script setup lang="ts">
import { ref } from 'vue';

interface Props {
  currentStep: number;
  completedSteps: number[];
}

const props = defineProps<Props>();
const isStepsOpen = ref(false);

const steps = [
  { id: 1, name: 'Formulario de onboarding' },
  { id: 2, name: 'Agendar mediciones' },
  { id: 3, name: 'Agendar con nutricionista' },
  { id: 4, name: 'Agendar con entrenador' },
  { id: 5, name: 'Configurar contraseña' },
  { id: 6, name: 'Descargar aplicación' },
];

function toggleSteps() {
  isStepsOpen.value = !isStepsOpen.value;
}
</script>

<template>
  <div>
    <!-- Mobile View -->
    <div class="block w-full md:hidden">
      <!-- Single step view -->
      <div
        v-if="!isStepsOpen"
        class="mb-4 flex cursor-pointer items-center justify-between"
        @click="toggleSteps"
      >
        <div class="flex items-center gap-x-3">
          <span
            class="flex size-8 items-center justify-center rounded-full text-white"
            :class="{
              'bg-gray-900': currentStep === steps[currentStep - 1].id,
              'bg-gray-700': completedSteps.includes(steps[currentStep - 1].id)
            }"
          >
            <span v-if="completedSteps.includes(steps[currentStep - 1].id)">
              <svg
                class="size-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            <span v-else>{{ currentStep }}</span>
          </span>
          <span class="text-sm font-medium">{{ steps[currentStep - 1].name }}</span>
        </div>
        <svg
          class="size-5 text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>

      <!-- All steps list -->
      <div
        v-else
        class="mb-4"
      >
        <div class="space-y-4">
          <div
            v-for="(step, index) in steps"
            :key="step.id"
            class="flex items-center justify-between gap-x-3"
          >
            <div
              class="flex items-center gap-x-3"
              :class="{
                'opacity-50': step.id > currentStep && !completedSteps.includes(step.id)
              }"
            >
              <span
                class="flex size-8 items-center justify-center rounded-full"
                :class="{
                  'bg-gray-900 text-white': step.id === currentStep,
                  'bg-gray-700 text-white': completedSteps.includes(step.id),
                  'bg-gray-200 text-gray-600': !completedSteps.includes(step.id) && step.id !== currentStep
                }"
              >
                <span v-if="completedSteps.includes(step.id)">
                  <svg
                    class="size-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <span v-else>{{ step.id }}</span>
              </span>
              <span class="text-sm font-medium">{{ step.name }}</span>
            </div>
            <!-- Show close button only on first item -->
            <button
              v-if="index === 0"
              class="text-gray-600"
              @click="toggleSteps"
            >
              <svg
                class="size-5 rotate-180"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <!-- Mobile content -->
      <div class="mt-6">
        <slot name="mobile" />
      </div>
    </div>

    <!-- Desktop View -->
    <div class="hidden w-full md:grid md:grid-cols-[300px_1fr] md:gap-8">
      <div class="space-y-4">
        <div
          v-for="(step, stepIdx) in steps"
          :key="step.id"
          class="relative flex items-center"
          :class="{ 'opacity-50': step.id > currentStep && !completedSteps.includes(step.id) }"
        >
          <!-- Vertical line -->
          <div
            v-if="stepIdx !== steps.length - 1"
            class="absolute left-4 top-8 h-full w-0.5"
            :class="{
              'bg-gray-900': steps[stepIdx + 1].id === currentStep,
              'bg-gray-700': completedSteps.includes(steps[stepIdx + 1].id),
              'bg-gray-200': !completedSteps.includes(steps[stepIdx + 1].id) && steps[stepIdx + 1].id !== currentStep
            }"
          />

          <div class="relative flex items-center gap-x-3 py-2">
            <span
              class="flex size-8 items-center justify-center rounded-full"
              :class="{
                'bg-gray-900 text-white': step.id === currentStep,
                'bg-gray-700 text-white': completedSteps.includes(step.id),
                'bg-gray-200 text-gray-600': !completedSteps.includes(step.id) && step.id !== currentStep
              }"
            >
              <span v-if="completedSteps.includes(step.id)">
                <svg
                  class="size-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
              <span v-else>{{ step.id }}</span>
            </span>
            <span class="text-sm font-medium">{{ step.name }}</span>
          </div>
        </div>
      </div>

      <!-- Desktop content -->
      <div class="min-h-[200px]">
        <slot name="desktop" />
      </div>
    </div>
  </div>
</template>
