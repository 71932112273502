<script setup lang="ts">
import { computed } from 'vue'

type Props = {
  src: string
  alt?: string
  class?: string
  width?: string | number
  height?: string | number
}

const props = withDefaults(defineProps<Props>(), {
  alt: '',
  class: '',
  width: undefined,
  height: undefined
})

const imageUrl = computed(() => {
  try {
    return new URL(`../../assets/images/${props.src}`, import.meta.url).href
  } catch (error) {
    // If the URL construction fails, return the original src
    // This allows for external URLs to work as well
    return props.src
  }
})
</script>

<template>
  <img
    :src="imageUrl"
    :alt="alt"
    :class="class"
    :width="width"
    :height="height"
  />
</template>
