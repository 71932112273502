<script setup lang="ts">
import { computed } from 'vue';
import { Info } from 'lucide-vue-next';
import { format } from 'date-fns';
import { BaseTooltip } from '@/components';
import type { PhysicalMeasurement } from '@/types/extended';
import { physicalAssessmentMetrics } from './physical-assessment-metrics';

const props = defineProps<{
  physicalMeasurements: PhysicalMeasurement[];
}>();

function formatDate(date: string): string {
  return format(new Date(date), 'dd/MM/yyyy');
}

type AssessmentTableRow = {
  date: string;
  assessment: Record<string, string>;
};

const assessmentColumnLabels = {
  bodyMassIndex: { label: 'IMC', unit: 'kg/m²' },
  waistToHipRatio: { label: 'ICC', unit: '' },
  bodyFatPercentage: { label: 'Grasa Corporal', unit: '%' },
  fatMass: { label: 'Masa Grasa', unit: 'kg' },
  fatFreeMass: { label: 'Masa Libre de Grasa', unit: 'kg' },
  fatFreeMassIndex: { label: 'IMLG', unit: 'kg/m²' },
  skeletalMuscleMass: { label: 'Masa Muscular Esquelética', unit: 'kg' },
  endomorphy: { label: 'Endomorfia', unit: '' },
  mesomorphy: { label: 'Mesomorfia', unit: '' },
  ectomorphy: { label: 'Ectomorfia', unit: '' },
  gripStrength: { label: 'Fuerza de Agarre', unit: 'kg' },
};

const tableData = computed(() => props.physicalMeasurements.map((measurement): AssessmentTableRow => {
  const assessment = measurement.physicalAssessment;

  return {
    date: formatDate(measurement.createdAt),
    assessment: {
      bodyMassIndex: assessment.bodyMassIndex.toFixed(1),
      waistToHipRatio: assessment.waistToHipRatio.toFixed(2),
      bodyFatPercentage: assessment.bodyFatPercentage.toFixed(1),
      fatMass: assessment.fatMass.toFixed(1),
      fatFreeMass: assessment.fatFreeMass.toFixed(1),
      fatFreeMassIndex: assessment.fatFreeMassIndex.toFixed(1),
      skeletalMuscleMass: assessment.skeletalMuscleMass.toFixed(1),
      endomorphy: assessment.endomorphy.toFixed(1),
      mesomorphy: assessment.mesomorphy.toFixed(1),
      ectomorphy: assessment.ectomorphy.toFixed(1),
      gripStrength: assessment.gripStrength.toFixed(1),
    },
  };
}));
</script>

<template>
  <div class="overflow-hidden rounded-xl bg-white shadow-sm ring-1 ring-gray-900/5">
    <div class="p-6">
      <h3 class="text-base font-semibold text-gray-900">
        Métricas calculadas
      </h3>
    </div>
    <div class="overflow-x-auto">
      <table
        v-if="tableData.length > 0"
        class="min-w-full divide-y divide-gray-300"
      >
        <thead>
          <tr class="bg-gray-50">
            <th
              scope="col"
              class="sticky left-0 z-10 w-1/4 bg-gray-50 py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900"
            >
              Métrica
            </th>
            <th
              v-for="row in tableData"
              :key="row.date"
              scope="col"
              class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
            >
              {{ row.date }}
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200">
          <tr
            v-for="(label, key) in assessmentColumnLabels"
            :key="key"
            class="hover:bg-gray-50"
          >
            <td class="sticky left-0 z-10 w-1/4 border-r border-gray-200 bg-white px-6 py-4 text-sm font-medium text-gray-900">
              <div class="flex items-center gap-3">
                <p>
                  {{ label.label }}
                  <span
                    v-if="label.unit"
                    class="text-gray-500"
                  >
                    [{{ label.unit }}]
                  </span>
                </p>
                <BaseTooltip :content="physicalAssessmentMetrics[key].description">
                  <Info class="size-4 shrink-0 text-gray-400 outline-none hover:text-gray-600" />
                </BaseTooltip>
              </div>
            </td>
            <td
              v-for="row in tableData"
              :key="row.date"
              class="whitespace-nowrap p-4 text-right text-sm text-gray-500"
            >
              {{ row.assessment[key] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
