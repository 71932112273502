<script setup lang="ts">
import { ref, computed, nextTick } from 'vue';
import type { SurveyResponse, BodyType } from '@/types/body-composition-survey';

const props = defineProps<{
  response: Partial<SurveyResponse>
}>();

const emit = defineEmits<{
  (e: 'next', data: Partial<SurveyResponse>): void
  (e: 'back'): void
}>();

const formData = ref({
  bodyType: props.response.bodyType || '',
});

const showError = ref(false);

const bodyTypeDescriptions = {
  male: {
    skinny: 'Complexión delgada, poca grasa corporal y poca masa muscular. ' +
      'Dificultad para ganar peso.',
    skinny_fat: 'Apariencia delgada con ropa pero con acumulación de grasa en el abdomen. ' +
      'Poca masa muscular.',
    bulky: 'Complexión robusta con bastante masa muscular. ' +
      'Puede tener grasa pero naturalmente te ves grande.',
    overweight: 'Acumulación significativa de grasa corporal de forma natural, especialmente en el abdomen. ' +
      'Puede tener masa muscular pero está cubierta por grasa.',
  },
  female: {
    skinny: 'Complexión delgada, poca grasa corporal y estructura fina. ' +
      'Dificultad para ganar peso.',
    skinny_fat: 'Apariencia delgada con ropa pero con acumulación de grasa en caderas y abdomen. ' +
      'Poca tonificación muscular.',
    bulky: 'Estructura corporal más ancha y robusta. ' +
      'Tendencia natural a desarrollar más músculo.',
    overweight: 'Acumulación significativa de grasa corporal de forma natural, distribuida principalmente en caderas, muslos y abdomen. ' +
      'Forma corporal más redondeada.',
  },
};

const bodyTypes = computed(() => {
  const sex = props.response.sex || 'male';
  const descriptions = bodyTypeDescriptions[sex as 'male' | 'female'];

  return [
    {
      value: 'skinny',
      title: 'Delgado',
      description: descriptions.skinny,
    },
    {
      value: 'skinny_fat',
      title: 'Delgado con Grasa',
      description: descriptions.skinny_fat,
    },
    {
      value: 'bulky',
      title: 'Robusto',
      description: descriptions.bulky,
    },
    {
      value: 'overweight',
      title: 'Sobrepeso',
      description: descriptions.overweight,
    },
  ];
});

function getImagePath(bodyType: BodyType): string {
  const sex = props.response.sex || 'male';

  return `/images/body-types/${sex}/${bodyType}.png`;
}

function handleSubmit() {
  if (!formData.value.bodyType) {
    showError.value = true;

    return;
  }

  showError.value = false;
  emit('next', {
    bodyType: formData.value.bodyType as BodyType,
  });
}

function handleBodyTypeSelect(value: BodyType) {
  formData.value.bodyType = value;
  // Wait for the next tick to ensure DOM is updated
  nextTick(() => {
    const buttonsContainer = document.querySelector('.navigation-buttons');
    if (buttonsContainer) {
      buttonsContainer.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  });
}
</script>

<template>
  <div class="space-y-8">
    <div class="text-center">
      <h2 class="text-3xl font-bold text-gray-900">
        Tu tipo de cuerpo
      </h2>
      <p class="mt-3 text-lg text-gray-500">
        Selecciona la opción que mejor represente tu tipo de cuerpo.
      </p>
    </div>

    <form
      class="space-y-8 sm:space-y-12"
      @submit.prevent="handleSubmit"
    >
      <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div
          v-for="type in bodyTypes"
          :key="type.value"
          role="button"
          tabindex="0"
          :class="[
            'relative flex cursor-pointer flex-col items-center space-y-2 rounded-lg p-4',
            formData.bodyType === type.value
              ? 'border-2 border-black bg-gray-50 font-medium text-gray-900'
              : 'bg-gray-50 hover:bg-gray-100',
            { 'border-2 border-red-300': showError && !formData.bodyType }
          ]"
          @click="handleBodyTypeSelect(type.value)"
          @keypress.enter="handleBodyTypeSelect(type.value)"
        >
          <div class="w-full sm:w-3/4 md:w-full">
            <div class="mb-1 aspect-square w-full overflow-hidden rounded-lg bg-gray-100">
              <img
                :src="getImagePath(type.value)"
                :alt="type.title"
                class="size-full object-cover"
              >
            </div>
          </div>
          <div class="w-full space-y-2">
            <h3
              class="text-lg text-gray-900"
              :class="{ 'font-medium text-gray-900': formData.bodyType === type.value }"
            >
              {{ type.title }}
            </h3>
            <p
              class="text-sm text-gray-600"
              :class="{ 'text-gray-900': formData.bodyType === type.value }"
            >
              {{ type.description }}
            </p>
          </div>
        </div>
      </div>

      <!-- Navigation Buttons -->
      <div class="navigation-buttons grid grid-cols-1 gap-3 pt-4 sm:grid-cols-2 sm:gap-4">
        <button
          type="button"
          class="flex justify-center rounded-lg border border-gray-300 bg-white px-4 py-3 text-sm font-medium text-gray-700 shadow-sm transition duration-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          @click="emit('back')"
        >
          Atrás
        </button>
        <button
          type="submit"
          class="w-full rounded-lg bg-black px-4 py-3 text-white transition duration-300 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          :disabled="!formData.bodyType"
        >
          Continuar
        </button>
      </div>
    </form>
  </div>
</template>
