<script setup lang="ts">
import { ref, computed, nextTick } from 'vue';
import type { SurveyResponse } from '@/types/body-composition-survey';

const props = defineProps<{
  response: Partial<SurveyResponse>
}>();

const emit = defineEmits<{
  (e: 'next', data: Partial<SurveyResponse>): void
  (e: 'back'): void
}>();

const formData = ref({
  hasBodyFatMeasurement: null as boolean | null,
  bodyFatPercentage: props.response.bodyFatPercentage || '',
  bodyFatMeasurementMethod: props.response.bodyFatMeasurementMethod || '',
  estimatedBodyFatOption: props.response.estimatedBodyFatOption || '',
});

const showEstimationOptions = computed(() => formData.value.hasBodyFatMeasurement === false);
const showMeasurementInputs = computed(() => formData.value.hasBodyFatMeasurement === true);
const showError = ref(false);

const bodyFatOptions = computed(() => {
  const sex = props.response.sex || 'male';

  if (sex === 'male') {
    return [
      { value: '10_or_less', label: 'Opción A', description:
        'Músculos muy definidos y venas visibles. Abdominales marcados.' },
      { value: '15', label: 'Opción B', description:
        'Músculos definidos y algo de vascularidad. Abdominales visibles.' },
      { value: '20', label: 'Opción C', description:
        'Músculos visibles con capa fina de grasa' },
      { value: '25', label: 'Opción D', description:
        'Músculos visibles pero suaves' },
      { value: '30', label: 'Opción E', description:
        'Músculos poco visibles, grasa notable' },
      { value: '35_or_more', label: 'Opción F', description:
        'Grasa corporal predominante' },
    ];
  }

  return [
    { value: '20_or_less', label: 'Opción A', description:
      'Músculos muy definidos. Aspecto atlético y fibroso.' },
    { value: '25', label: 'Opción B', description:
      'Forma tonificada, músculos suaves' },
    { value: '30', label: 'Opción C', description:
      'Curvas suaves, algo de definición' },
    { value: '35', label: 'Opción D', description:
      'Forma redondeada, músculos no visibles' },
    { value: '40', label: 'Opción E', description:
      'Curvas pronunciadas, más grasa visible' },
    { value: '45_or_more', label: 'Opción F', description:
      'Grasa corporal predominante' },
  ];
});

function getImagePath(option: string): string {
  const sex = props.response.sex || 'male';

  return `/images/body-fat/${sex}/${option}.png`;
}

function handleSubmit() {
  if (formData.value.hasBodyFatMeasurement === null) return;

  emit('next', {
    hasBodyFatMeasurement: formData.value.hasBodyFatMeasurement,
    ...(formData.value.hasBodyFatMeasurement ?
      {
        bodyFatPercentage: Number(formData.value.bodyFatPercentage),
        bodyFatMeasurementMethod: formData.value.bodyFatMeasurementMethod,
      } :
      {
        estimatedBodyFatOption: formData.value.estimatedBodyFatOption,
      }),
  });
}

function handleOptionSelect(value: string) {
  formData.value.estimatedBodyFatOption = value;
  // Wait for the next tick to ensure DOM is updated
  nextTick(() => {
    const buttonsContainer = document.querySelector('.navigation-buttons');
    if (buttonsContainer) {
      buttonsContainer.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  });
}
</script>

<template>
  <div class="space-y-8">
    <div class="text-center">
      <h2 class="text-3xl font-bold text-gray-900">
        Evaluación de Grasa Corporal
      </h2>
    </div>

    <form
      class="space-y-8 sm:space-y-12"
      @submit.prevent="handleSubmit"
    >
      <!-- Measurement Method Selection -->
      <div>
        <label class="mb-2 block text-lg font-medium text-gray-900 sm:text-xl">
          ¿Has tenido alguna medición de tu porcentaje de grasa corporal usando métodos como DEXA, InBody o similar?
        </label>
        <div class="grid grid-cols-1 gap-3 sm:grid-cols-2 sm:gap-4">
          <div
            role="button"
            tabindex="0"
            :class="[
              'flex cursor-pointer items-center justify-center rounded-lg p-4 text-center',
              formData.hasBodyFatMeasurement === true
                ? 'border-2 border-black bg-gray-50 font-medium text-gray-900'
                : 'bg-gray-50 hover:bg-gray-100',
              { 'border-2 border-red-300': showError && formData.hasBodyFatMeasurement === null }
            ]"
            @click="formData.hasBodyFatMeasurement = true"
            @keypress.enter="formData.hasBodyFatMeasurement = true"
          >
            <span :class="{ 'font-medium text-gray-900': formData.hasBodyFatMeasurement === true }">
              Sí
            </span>
          </div>
          <div
            role="button"
            tabindex="0"
            :class="[
              'flex cursor-pointer items-center justify-center rounded-lg p-4 text-center',
              formData.hasBodyFatMeasurement === false
                ? 'border-2 border-black bg-gray-50 font-medium text-gray-900'
                : 'bg-gray-50 hover:bg-gray-100',
              { 'border-2 border-red-300': showError && formData.hasBodyFatMeasurement === null }
            ]"
            @click="formData.hasBodyFatMeasurement = false"
            @keypress.enter="formData.hasBodyFatMeasurement = false"
          >
            <span :class="{ 'font-medium text-gray-900': formData.hasBodyFatMeasurement === false }">
              No
            </span>
          </div>
        </div>
      </div>

      <!-- Measured Body Fat Details -->
      <template v-if="showMeasurementInputs">
        <div>
          <label
            for="measurementMethod"
            class="mb-2 block text-xl font-medium text-gray-900"
          >
            ¿Qué método se utilizó?
          </label>
          <select
            id="measurementMethod"
            v-model="formData.bodyFatMeasurementMethod"
            required
            class="mt-1 w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">
              Selecciona un método
            </option>
            <option value="dexa">
              DEXA Scan
            </option>
            <option value="anthropometry">
              Antropometría
            </option>
            <option value="inbody">
              InBody
            </option>
            <option value="other_bioimpedance">
              Otra bioimpedancia o pesa inteligente
            </option>
            <option value="other">
              Otra manera
            </option>
          </select>
        </div>

        <div>
          <label
            for="bodyFatPercentage"
            class="mb-2 block text-xl font-medium text-gray-900"
          >
            ¿Cuál es tu porcentaje de grasa corporal medido?
          </label>
          <input
            id="bodyFatPercentage"
            v-model="formData.bodyFatPercentage"
            type="number"
            min="1"
            max="100"
            step="0.1"
            required
            placeholder="Ingresa el porcentaje"
            class="mt-1 w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          >
        </div>
      </template>

      <!-- Body Fat Estimation -->
      <div v-if="showEstimationOptions">
        <label class="mb-1 block text-xl font-medium text-gray-900">
          Selecciona la opción que mejor represente tu porcentaje de grasa corporal
        </label>
        <label class="mb-4 block text-gray-700">
          Ignora los músculos, fíjate en la grasa.
        </label>

        <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
          <div
            v-for="option in bodyFatOptions"
            :key="option.value"
            role="button"
            tabindex="0"
            :class="[
              'flex w-full cursor-pointer flex-col rounded-lg p-4 text-left',
              formData.estimatedBodyFatOption === option.value
                ? 'border-2 border-black bg-gray-50 font-medium text-gray-900'
                : 'bg-gray-50 hover:bg-gray-100',
              { 'border-2 border-red-300': showError && !formData.estimatedBodyFatOption }
            ]"
            @click="handleOptionSelect(option.value)"
            @keypress.enter="handleOptionSelect(option.value)"
          >
            <div class="flex flex-col space-y-3">
              <div class="relative aspect-square w-full">
                <img
                  :src="getImagePath(option.value)"
                  :alt="`Ejemplo ${option.label} composición corporal`"
                  class="size-full rounded-lg object-cover"
                >
              </div>
              <p
                class="text-sm text-gray-600"
                :class="{ 'text-gray-900': formData.estimatedBodyFatOption === option.value }"
              >
                {{ option.description }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Navigation Buttons -->
      <div class="navigation-buttons grid grid-cols-1 gap-3 pt-4 sm:grid-cols-2 sm:gap-4">
        <button
          type="button"
          class="flex justify-center rounded-lg border border-gray-300 bg-white px-4 py-3 text-sm font-medium text-gray-700 shadow-sm transition duration-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          @click="emit('back')"
        >
          Atrás
        </button>
        <button
          type="submit"
          class="w-full rounded-lg bg-black px-4 py-3 text-white transition duration-300 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          :disabled="formData.hasBodyFatMeasurement === null ||
            (formData.hasBodyFatMeasurement && (!formData.bodyFatPercentage || !formData.bodyFatMeasurementMethod)) ||
            (!formData.hasBodyFatMeasurement && !formData.estimatedBodyFatOption)"
        >
          Continuar
        </button>
      </div>
    </form>
  </div>
</template>
