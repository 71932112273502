<script setup lang="ts">
import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { format } from 'date-fns';
import { ArrowRight } from 'lucide-vue-next';
import { physicalMeasurementsApi } from '@/api/physical-measurements';
import { BaseLink } from '@/components';
import type { PhysicalMeasurement, Trainee, Measuree } from '@/types';

const props = defineProps<{
  physicalMeasurements: PhysicalMeasurement[];
  trainees: Trainee[];
  measurees: Measuree[];
}>();

const queryParams = computed(() => ({
  q: {
    'userable_id_in': [
      ...props.trainees.map((trainee) => trainee.id),
      ...props.measurees.map((measuree) => measuree.id),
    ],
  },
}));

const {
  data: physicalMeasurements,
  isLoading,
  error,
} = useQuery({
  queryKey: ['physical-measurements', queryParams],
  queryFn: () => physicalMeasurementsApi.getAll(queryParams.value),
  initialData: props.physicalMeasurements,
});

const sortedPhysicalMeasurements = computed(() => {
  if (!physicalMeasurements.value) return [];

  return [...physicalMeasurements.value].sort((a, b) => {
    const dateA = new Date(a.measuredAt).getTime();
    const dateB = new Date(b.measuredAt).getTime();

    return dateB - dateA;
  });
});

function formatDate(date: string) {
  return format(new Date(date), 'dd/MM/yyyy HH:mm');
}

function getUserableName(userableId: number, userableType: string) {
  if (userableType === 'Trainee') {
    return props.trainees.find((trainee) => trainee.id === userableId)?.fullName ?? '-';
  }

  return props.measurees.find((measuree) => measuree.id === userableId)?.fullName ?? '-';
}

function measurementLink(physicalMeasurement: PhysicalMeasurement) {
  const userableId = physicalMeasurement.userableId;
  const userableType = physicalMeasurement.userableType.toLowerCase();

  return `/${userableType}s/${userableId}/physical_measurements/${physicalMeasurement.id}`;
}

function editMeasurementLink(physicalMeasurement: PhysicalMeasurement) {
  const userableId = physicalMeasurement.userableId;
  const userableType = physicalMeasurement.userableType.toLowerCase();

  return `/${userableType}s/${userableId}/physical_measurements/${physicalMeasurement.id}/edit`;
}
</script>

<template>
  <div class="w-full space-y-6">
    <h1 class="text-2xl font-semibold">
      Mediciones
    </h1>
    <div
      v-if="isLoading"
      class="flex items-center justify-center"
    >
      Cargando...
    </div>

    <div
      v-else-if="error"
      class="rounded-md bg-red-50 p-4"
    >
      <div class="flex">
        <div class="shrink-0">
          <span class="inline-block size-5 text-red-400">!</span>
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-red-800">
            Error al cargar los datos
          </h3>
          <div class="mt-2 text-sm text-red-700">
            <p>{{ error }}</p>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else-if="sortedPhysicalMeasurements.length === 0"
      class="rounded-lg border-2 border-dashed border-gray-300 p-12 text-center"
    >
      <h3 class="mt-2 text-sm font-medium text-gray-900">
        No hay mediciones todavía
      </h3>
    </div>

    <div
      v-else
      class="overflow-hidden rounded-lg border border-gray-200 bg-white shadow"
    >
      <ul
        role="list"
        class="divide-y divide-gray-200"
      >
        <li
          v-for="measurement in sortedPhysicalMeasurements"
          :key="measurement.id"
          class="flex items-center justify-between gap-x-6 p-5"
        >
          <div class="min-w-0">
            <div class="flex items-start gap-x-3">
              <p class="text-sm font-medium leading-6 text-gray-900">
                {{ getUserableName(measurement.userableId, measurement.userableType) }}
              </p>
            </div>
            <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
              <p class="truncate">
                Fecha: {{ formatDate(measurement.measuredAt) }}
              </p>
            </div>
          </div>
          <div class="flex flex-col items-end gap-1">
            <BaseLink
              :href="measurementLink(measurement)"
              variant="secondary"
            >
              Ver <ArrowRight class="size-4" />
            </BaseLink>
            <BaseLink
              :href="editMeasurementLink(measurement)"
              variant="secondary"
            >
              Editar <ArrowRight class="size-4" />
            </BaseLink>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
