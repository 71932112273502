<script setup lang="ts">
import { computed } from 'vue';
import { X } from 'lucide-vue-next';
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';
import type { SurveyResponse, SurveyResult } from '@/types/body-composition-survey';

const props = defineProps<{
  isOpen: boolean
  explanationType: 'strength' | 'cardio' | 'calories' | 'protein' | null
  result: SurveyResult | null
  response: Partial<SurveyResponse>
}>();

const emit = defineEmits<{
  (e: 'close'): void
}>();

const explanation = computed(() => {
  if (!props.result || !props.explanationType) return { title: '', content: '' };

  const titles = {
    strength: '¿Por qué el entrenamiento de fuerza?',
    cardio: '¿Por qué el cardio?',
    calories: '¿Por qué es importante el balance calórico?',
    protein: '¿Por qué necesitas proteína?',
  };

  let content = '';
  const muscleStatus = props.result.muscleStatus;
  const quadrant = props.result.quadrant;
  const visceralFatProbability = props.result.visceralFatProbability;

  switch (props.explanationType) {
    case 'strength':
      if (muscleStatus === 'under_muscled') {
        content = `El entrenamiento de fuerza es crucial para ti porque actualmente tienes baja masa muscular.
            Desarrollar músculo no solo mejorará tu composición corporal, sino que también:
            • Acelerará tu metabolismo
            • Mejorará tu salud ósea
            • Aumentará tu fuerza funcional
            • Reducirá el riesgo de lesiones
            • Mejorará tu resistencia a la insulina`;
      } else if (quadrant === 4) {
        content = `Aunque tu composición corporal es saludable, el entrenamiento de fuerza te ayudará a:
            • Incrementar tu masa muscular para mejor salud a largo plazo
            • Prevenir la sarcopenia (pérdida de músculo por edad)
            • Mantener un metabolismo alto
            • Mejorar tu rendimiento físico
            • Fortalecer huesos y articulaciones
            • Reducir el riesgo de lesiones`;
      } else {
        content = `El entrenamiento de fuerza es fundamental para:
            • Mantener tu masa muscular actual
            • Mejorar tu fuerza y rendimiento
            • Mantener un metabolismo saludable
            • Prevenir la pérdida de masa muscular con la edad
            • Mejorar tu postura y reducir dolores`;
      }
      break;

    case 'cardio':
      if (visceralFatProbability >= 0.7) {
        content = `El cardio es especialmente importante para ti debido a tu alto riesgo de grasa visceral.
            El entrenamiento cardiovascular:
            • Incrementa tu gasto calórico para reducir grasa
            • Mejora la salud cardiovascular
            • Aumenta la sensibilidad a la insulina
            • Reduce el riesgo de enfermedades metabólicas
            • Mejora tu capacidad aeróbica`;
      } else {
        content = `El cardio es importante para mantener una buena salud general:
            • Incrementa tu gasto calórico diario
            • Mejora la salud cardiovascular
            • Aumenta tu resistencia
            • Reduce el estrés
            • Mejora la calidad del sueño`;
      }
      break;

    case 'calories': {
      let strategy;
      if (props.result.nourishmentStatus === 'overnourished') {
        strategy = 'Déficit controlado para pérdida de grasa sostenible';
      } else if (muscleStatus === 'under_muscled') {
        strategy = 'Superávit moderado para ganancia muscular óptima';
      } else if (quadrant === 4 && visceralFatProbability < 0.7) {
        strategy = 'Superávit ligero para optimizar ganancia muscular';
      } else {
        strategy = 'Rango de mantenimiento para sostener composición actual';
      }

      content = `El balance calórico es el factor más importante para cambiar tu composición corporal.

Tu metabolismo basal (BMR) es la cantidad de calorías que tu cuerpo necesita para funciones básicas como respirar, mantener la temperatura corporal y el funcionamiento de órganos.${props.result.bmr ? `\n\nTu BMR estimado es: ${Math.round(props.result.bmr)} calorías` : ''}

El gasto total diario (TDEE) incluye:
• Tu metabolismo basal
• La actividad física diaria
• El efecto térmico de los alimentos
• El ejercicio${props.result.tdee ? `\n\nTu TDEE estimado es: ${Math.round(props.result.tdee)} calorías` : ''}

Basado en tus características y objetivos:
• Estrategia: ${strategy}
• Rango calórico recomendado: ${Math.round(props.result.totalCalorieRangeMin)}-${Math.round(props.result.totalCalorieRangeMax)} calorías

Este rango está diseñado para:
• Optimizar tu composición corporal
• Mantener energía para entrenar
• Asegurar una progresión sostenible
• Permitir adherencia a largo plazo`;
      break;
    }

    case 'protein':
      if (muscleStatus === 'under_muscled') {
        content = `La proteína es especialmente importante para ti porque necesitas desarrollar masa muscular.
            Un consumo adecuado de proteína:
            • Proporciona los bloques de construcción para el músculo
            • Mejora la recuperación post-entrenamiento
            • Ayuda a preservar masa muscular en déficit calórico
            • Aumenta la saciedad
            • Mejora la composición corporal

Tu rango de proteína recomendado es: ${Math.round(props.result.totalProteinRangeMin)}-${Math.round(props.result.totalProteinRangeMax)} gramos por día`;
      } else {
        content = `La proteína es esencial para:
            • Mantener tu masa muscular
            • Optimizar la recuperación
            • Mantener un sistema inmune fuerte
            • Regular el apetito
            • Mantener huesos y articulaciones sanos

Tu rango de proteína recomendado es: ${Math.round(props.result.totalProteinRangeMin)}-${Math.round(props.result.totalProteinRangeMax)} gramos por día`;
      }
      break;
  }

  return {
    title: titles[props.explanationType],
    content,
  };
});

const explanationTitle = computed(() => explanation.value.title);
const explanationContent = computed(() => explanation.value.content);
</script>

<template>
  <Dialog
    as="div"
    class="relative z-50"
    :open="isOpen"
    @close="emit('close')"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />

    <div class="fixed inset-0 overflow-y-auto">
      <div class="flex min-h-full items-center justify-center p-4">
        <DialogPanel class="mx-auto w-full max-w-2xl rounded-xl bg-white p-6 shadow-xl">
          <div class="mb-6 flex items-center justify-between">
            <DialogTitle class="text-2xl font-semibold text-gray-900">
              {{ explanationTitle }}
            </DialogTitle>
            <button
              type="button"
              class="rounded-lg p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
              @click="emit('close')"
            >
              <span class="sr-only">Cerrar</span>
              <X class="size-6" />
            </button>
          </div>

          <div class="space-y-4 text-gray-600">
            <p
              v-for="(paragraph, index) in explanationContent.split('\n')"
              :key="index"
              class="whitespace-pre-line text-base"
              :class="{ 'ml-4': paragraph.trim().startsWith('•') }"
            >
              {{ paragraph }}
            </p>
          </div>

          <div class="mt-8 flex justify-end">
            <button
              type="button"
              class="rounded-lg bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              @click="emit('close')"
            >
              Entendido
            </button>
          </div>
        </DialogPanel>
      </div>
    </div>
  </Dialog>
</template>
