/* eslint-disable max-len,vue/max-len */
export const DEFAULT_VALUES = {
  goal: `- Aumentar peso corporal en base a masa muscular de manera paulatina
- Disminuir peso corporal en base a masa grasa y mantener masa muscular
- Mantener una alimentación saludable que contribuya a una buena salud general y cardiovascular
- Lograr una recomposición corporal disminuyendo masa grasa y aumentando masa muscular de manera paulatina
- Mejorar rendimiento deportivo para competir en Ironman 2026
- Adquirir nuevos hábitos de alimentación saludables y equilibrados`,
  strategy: `Para lograr tu objetivo de aumentar masa muscular, es fundamental que consumas más calorías de las que tu cuerpo gasta. Esto le proporciona a tu organismo el sustrato necesario para fomentar la síntesis proteica muscular.

En tu caso, avanzaremos gradualmente desde tu alimentación actual, donde te encuentras en déficit calórico, hasta alcanzar un equilibrio con las calorías que gastas. Después de este plan, evaluaremos realizar un aumento calórico para lograr nuestro objetivo final.

Debemos optimizar la distribución de los macronutrientes: ajustar el consumo de proteínas a un nivel adecuado para la síntesis muscular y aumentar los carbohidratos diarios, ya que son fundamentales para la formación de nuevo tejido.

Para mejorar tu rendimiento deportivo, necesitamos aportar energía suficiente a tu cuerpo según tu actividad física diaria. Esto se logra con una adecuada distribución de macronutrientes, principalmente: las proteínas ayudarán a tu recuperación muscular y reparación de tejidos; los carbohidratos serán el sustrato principal que te dará energía durante el entrenamiento y ayudará a recuperar las reservas musculares posterior a tu ejercicio.

En tu caso, aumentaremos gradualmente tus calorías totales hasta alcanzar los requerimientos nutricionales necesarios según tu actividad física actual, mejorando así la energía disponible para tu deporte y mejorando la calidad de los alimentos, lo que beneficia tu salud.

Para lograr tu objetivo de disminuir masa grasa, es esencial mantener un déficit calórico, consumiendo menos calorías de las que tu cuerpo gasta. Esto permite que el organismo utilice las reservas de grasa como fuente de energía, lo cual es fundamental para alcanzar nuestro objetivo. Esto siempre en base a la actividad física que realizas actualmente.

Para lograr tu objetivo, debemos proporcionar un aporte nutricional cercano a tu gasto calórico diario según tu actividad física actual y con una distribución estratégica y equilibrada de macronutrientes (proteínas, carbohidratos y grasas saludables) que permita reducir masa grasa y desarrollar masa muscular.

Para lograr tu objetivo, nos debemos enfocar en mejorar la calidad cómo también la cantidad nutricional de tu alimentación para lograr una alimentación saludable, que me entregue los nutrientes necesarios. Esto siempre en base a la actividad física que realizas actualmente.`,
  currentDiet: `Según lo conversado en la consulta, tu alimentación consiste en:

| Comida    | Detalle  |
| --------- | -------- |
| Desayuno  | ...      |
| Almuerzo  | ...      |
| Once      | ...      |
| Cena      | ...      |`,
  dietaryTargets: `| Meta           | Cantidad     |
| -------------- | ------------ |
| Calorías       | 2000 kcal    |
| Proteínas      | 150 g        |
| Carbohidratos  | 100 g/día    |
| Grasa          | 50 g/día     |`,
  objectiveDescription: 'Nuestro primer objetivo nutricional será aumentar el aporte calórico de tu tiempo de comida ' +
  '**“Colación pre-entrenamiento” y “Desayuno”**. Debes agregar alimentos ricos en carbohidratos para tu pre-entreno ' +
  'y aumentar las proteínas en tu desayuno (los destacados en amarillo).' +
  `¡Me vas contando cómo toleras el pre-entrenamiento, si te acomoda o no!.

- ¡Debes enviarme una foto de todos tus pre-entrenamientos y desayunos que cumplas durante la semana! ¡Éxito!`,
  mealRecommendations: `### Desayuno:

**Opciones de “carbohidratos” (Elige 1):**

- 2 rebanadas pan de molde integral
- ½ marraqueta (1 diente)
- 1 ½ pan pita integral
- 5 galletas de arroz tamaño estándar
- 4 cucharadas avena integral

**Opciones de “proteínas” (Elige 1):**

- 3-4 huevos
- 2 huevos + 2-3 láminas de pechuga de pavo
- 80-100g pasta de pollo casera (tipo “ave pimentón”)

**Opciones de “grasas saludables” (Elige 1):**

- 1/4 palta
- 1 cdita de mantequilla de maní
- 10 unidades de frutos secos
- 1 cucharada de semillas

**Opciones de “Lácteos descremados” (Elige 1):**

- 1 taza (200 ml) de leche descremada sin lactosa
- 2 rebanadas queso fresco light sin lactosa

### Colaciones:

**Opciones de “Lácteos proteicos” (Elige 1):**

- 1 yogurt protein Loncoleche
- 1 yogurt protein Plus Colún **sin azúcar añadida** (tipo “sachet”)
- 1 yogurt Skyr proteínas Danone

**Opciones de “Frutas” (Elige 1):**

- 1 manzana o naranja o pera o durazno
- ½ plátano
- 2 unidades de kiwi o mandarina
- 1 taza mix de frutos rojos

### Almuerzos/Cenas

Ensaladas variadas, libre cantidad

-

**Opciones de “Proteínas” (Elige 1):**

- 200g de pollo o pavo o vacuno o cerdo o pescado (pesaje en cocido)
- 2 latas de atún al agua
- 3-4 huevos

**Opciones de “Carbohidratos” (Elige1):**

- 1 taza de arroz o fideos o quinoa o cus cús o choclo (en cocido)

*Priorizar opciones integrales (arroz o fideos integrales, quinoa, etc)*

- 1 1/2 unidad de papa (ideal con cáscara)
- 3/4 taza de camote
- 1 1/2 fajita integral XL

**Opciones de “Grasas saludables” (Elige 1):**

- 1 cdita aceite de oliva o vegetal
- 1/4 palta
- 7 aceitunas
- 1 cucharada de semillas
`,
};
