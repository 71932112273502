<script setup lang="ts">
import { computed } from 'vue';
import type { TimeSlot } from '@/types/cal';

const props = defineProps<{
  selectedDate: Date;
  availableSlots: TimeSlot[];
  selectedSlot: TimeSlot | null;
}>();

const emit = defineEmits<{
  (e: 'select', slot: TimeSlot): void;
}>();

function formatTime(isoString: string): string {
  const date = new Date(isoString);

  return date.toLocaleTimeString('es-CL', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
}

function getLocalHour(isoString: string): number {
  return new Date(isoString).getHours();
}

const morningSlots = computed(() =>
  props.availableSlots
    .filter(s => getLocalHour(s.time || '') < 12)
    .sort((a, b) => new Date(a.time || '').getTime() - new Date(b.time || '').getTime()),
);

const afternoonSlots = computed(() =>
  props.availableSlots
    .filter(s => getLocalHour(s.time || '') >= 12)
    .sort((a, b) => new Date(a.time || '').getTime() - new Date(b.time || '').getTime()),
);
</script>

<template>
  <div class="flex flex-col">
    <h4 class="mb-4 text-lg font-medium text-gray-900">
      {{ new Date(selectedDate).toLocaleDateString('es-CL', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' }) }}
    </h4>
    <div class="grid grid-cols-2 gap-8">
      <template v-if="morningSlots.length > 0">
        <div>
          <h5 class="mb-3 text-base font-medium text-gray-700">
            Horario a.m.
          </h5>
          <div class="space-y-1.5">
            <button
              v-for="slot in morningSlots"
              :key="slot.time"
              class="w-full rounded-lg px-4 py-3 text-left text-base font-medium transition-all focus:outline-none focus:ring-2 focus:ring-black"
              :class="[
                selectedSlot?.time === slot.time
                  ? 'bg-black text-white hover:bg-gray-800'
                  : 'bg-white text-gray-900 ring-1 ring-gray-200 hover:bg-gray-50 hover:ring-gray-300'
              ]"
              @click="emit('select', slot)"
            >
              {{ formatTime(slot.time || '') }}
            </button>
          </div>
        </div>
      </template>
      <template v-if="afternoonSlots.length > 0">
        <div>
          <h5 class="mb-3 text-base font-medium text-gray-700">
            Horario p.m.
          </h5>
          <div class="space-y-1.5">
            <button
              v-for="slot in afternoonSlots"
              :key="slot.time"
              class="w-full rounded-lg px-4 py-3 text-left text-base font-medium transition-all focus:outline-none focus:ring-2 focus:ring-black"
              :class="[
                selectedSlot?.time === slot.time
                  ? 'bg-black text-white hover:bg-gray-800'
                  : 'bg-white text-gray-900 ring-1 ring-gray-200 hover:bg-gray-50 hover:ring-gray-300'
              ]"
              @click="emit('select', slot)"
            >
              {{ formatTime(slot.time || '') }}
            </button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
