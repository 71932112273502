<script setup lang="ts">
import { ref, computed } from 'vue';
import { useUrlSearchParams } from '@vueuse/core';
import { useQuery } from '@tanstack/vue-query';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue';
import type { FollowUpMeetingSurvey, ChatMessage, ChatParticipant, Trainee } from '@/types';
import type { WorkoutPlan, Workout, Routine } from '@/types/extended';
import { format, isAfter, isThisWeek, startOfToday, parseISO, setYear, isToday } from 'date-fns';
import { es } from 'date-fns/locale';
import { traineesApi } from '@/api';
import { trackEvent } from '@/utils/analytics';
import { BaseLink, BaseInput } from '@/components';
import TraineeInfo from '@/components/trainee-info.vue';
import { ArrowRight } from 'lucide-vue-next';
import ChatGroupMessages from './chat-group-messages.vue';
import NotesList from './notes-list.vue';
import RoutinesCalendar from '../routines-calendar.vue';
import ExerciseExecutions from './exercise-executions.vue';
import TraineeStats from './trainee-stats.vue';
import SurveysList from './surveys-list.vue';
import LatestWorkouts from './latest-workouts.vue';
import TraineeNutritionPlans from './nutrition-plans.vue';
import TraineeCalBookings from './cal-bookings.vue';
import TraineePhysicalMeasurements from './physical-measurements.vue';
import TraineeMealChecks from './meal-checks.vue';

const props = defineProps<{
  trainees: Trainee[],
  workouts: Workout[],
  routines: Routine[],
  chatMessages: ChatMessage[],
  chatParticipants: ChatParticipant[],
}>();

const searchParams = useUrlSearchParams('history');
const selectedTraineeId = ref<number | null>(
  searchParams.traineeId ? Number(searchParams.traineeId) : null,
);

const { data: selectedTrainee } = useQuery({
  queryKey: ['trainee', selectedTraineeId],
  queryFn: () => traineesApi.get(selectedTraineeId.value!),
  enabled: computed(() => selectedTraineeId.value !== null),
});

const workoutPlan = computed<WorkoutPlan | undefined>(() =>
  selectedTrainee.value?.workoutPlans[0],
);

const selectedTab = ref('routines');

const tabGroups = [
  {
    name: 'Entrenamiento',
    tabs: [
      {
        name: 'routines',
        label: 'Rutinas',
      },
      {
        name: 'stats',
        label: 'Estadísticas',
      },
      {
        name: 'messages',
        label: 'Mensajes',
      },
      {
        name: 'exercise_executions',
        label: 'Historial',
      },
    ],
  },
  {
    name: 'Nutrición',
    tabs: [
      {
        name: 'nutrition_plans',
        label: 'Planes',
      },
      {
        name: 'measurements',
        label: 'Mediciones',
      },
      {
        name: 'meal_checks',
        label: 'Reportes',
      },
    ],
  },
  {
    name: 'Otros',
    tabs: [
      {
        name: 'cal_bookings',
        label: 'Reuniones',
      },
      {
        name: 'surveys',
        label: 'Formularios',
      },
      {
        name: 'notes',
        label: 'Notas',
      },
    ],
  },
];

const allTabs = tabGroups.flatMap(group => group.tabs);

function normalizeText(text: string): string {
  return text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

const searchQuery = ref('');

const filteredTrainees = computed(() => {
  const query = normalizeText(searchQuery.value);
  if (!query) return props.trainees;

  return props.trainees.filter(trainee =>
    normalizeText(trainee.fullName).includes(query),
  );
});

function selectTrainee(traineeId: number) {
  selectedTab.value = 'routines';
  selectedTraineeId.value = traineeId;
  searchParams.traineeId = traineeId.toString();
  trackEvent('select_trainee_click', { traineeId });
}

function clearTraineeSelection() {
  selectedTraineeId.value = null;
  delete searchParams.traineeId;
}

function handleTraineeClick(traineeId: number) {
  if (traineeId === selectedTraineeId.value) {
    clearTraineeSelection();
  } else {
    selectTrainee(traineeId);
  }
}

function tabChanged(index: number) {
  trackEvent('trainee_tab_click', { tab: allTabs[index].name });
}

function onFollowUpSurveySubmitted(newSurvey: FollowUpMeetingSurvey) {
  if (selectedTrainee.value) {
    selectedTrainee.value.followUpMeetingSurveys.push(newSurvey);
  }
}

function isBirthdayInCurrentWeek(birthday: string | null): boolean {
  if (!birthday) return false;

  const today = new Date();
  const birthdayDate = parseISO(birthday);
  const thisYearBirthday = setYear(birthdayDate, today.getFullYear());

  if (isAfter(startOfToday(), thisYearBirthday)) {
    return false;
  }

  return isThisWeek(thisYearBirthday);
}

function formatBirthdayMessage(birthday: string | null): string {
  if (!birthday) return '';

  const birthdayDate = parseISO(birthday);
  const thisYearBirthday = setYear(birthdayDate, new Date().getFullYear());

  if (isToday(thisYearBirthday)) {
    return `🎂 ¡El cumpleaños de ${selectedTrainee.value?.fullName.split(' ')[0]} es hoy!`;
  }

  const formattedBirthday = format(thisYearBirthday, "d 'de' MMMM", { locale: es });

  return `🎂 ¡El cumpleaños de ${selectedTrainee.value?.fullName.split(' ')[0]} es el ${formattedBirthday}!`;
}

</script>

<template>
  <div class="w-full">
    <div class="mt-4 flex gap-6">
      <!-- Trainees sidebar -->
      <div class="flex w-48 flex-col gap-4">
        <h3 class="text-2xl font-medium text-gray-900">
          Trainees
        </h3>
        <div class="flex flex-col gap-3">
          <BaseInput
            v-model="searchQuery"
            type="text"
            placeholder="Buscar trainee"
            name="searchQuery"
            class="w-full"
          />
          <div class="flex max-h-[calc(100vh-200px)] flex-col gap-1 overflow-y-auto">
            <button
              v-for="trainee in filteredTrainees"
              :key="trainee.id"
              class="flex w-full cursor-pointer items-center justify-between rounded-lg border p-3 text-sm transition-all hover:bg-blue-50"
              :class="{ 'border-2 border-blue-600 bg-blue-100': selectedTrainee?.id === trainee.id }"
              @click="handleTraineeClick(trainee.id)"
            >
              <span class="truncate font-medium text-gray-900">
                {{ trainee.fullName }}
                <span v-if="isBirthdayInCurrentWeek(trainee.birthdate)">🎂</span>
              </span>
            </button>
          </div>
        </div>
      </div>

      <!-- Main content -->
      <div
        v-if="selectedTrainee"
        class="flex-1 rounded-xl border bg-white p-6 shadow-sm"
      >
        <div class="flex flex-col gap-6">
          <!-- Birthday alert -->
          <div
            v-if="isBirthdayInCurrentWeek(selectedTrainee.birthdate)"
            class="rounded-lg bg-blue-50 p-4 text-blue-700 ring-1 ring-blue-700/10"
          >
            {{ formatBirthdayMessage(selectedTrainee.birthdate) }}
          </div>

          <!-- Trainee header -->
          <div class="flex items-start justify-between">
            <div class="flex flex-col gap-1">
              <h3 class="text-2xl font-semibold text-gray-900">
                {{ selectedTrainee?.fullName }}
              </h3>
              <p class="text-sm text-gray-500">
                {{ selectedTrainee?.email }}
              </p>
            </div>
            <BaseLink
              :href="`/trainees/${selectedTrainee.id}/edit`"
            >
              Editar <ArrowRight class="size-4" />
            </BaseLink>
          </div>

          <TraineeInfo :trainee="selectedTrainee" />

          <!-- Tab navigation -->
          <TabGroup @change="tabChanged">
            <div class="grid grid-cols-1 gap-6 sm:grid-cols-3">
              <div
                v-for="(group) in tabGroups"
                :key="group.name"
                class="flex flex-col gap-2"
              >
                <h4 class="font-medium text-gray-500">
                  {{ group.name }}
                </h4>
                <TabList class="grid grid-cols-2 gap-2">
                  <Tab
                    v-for="tab in group.tabs"
                    :key="tab.name"
                    v-slot="{ selected }"
                  >
                    <div
                      class="cursor-pointer truncate rounded-lg bg-gray-50 px-3 py-2 text-sm font-medium transition-colors"
                      :class="[
                        selected
                          ? 'bg-gray-500 text-white'
                          : 'text-gray-600 hover:bg-gray-100 hover:text-gray-500'
                      ]"
                    >
                      {{ tab.label }}
                    </div>
                  </Tab>
                </TabList>
              </div>
            </div>

            <!-- Tab panels -->
            <TabPanels class="mt-6">
              <TabPanel>
                <div class="flex flex-col gap-6">
                  <div class="flex flex-col gap-y-2">
                    <BaseLink
                      :href="`/workout_plans/${workoutPlan?.id}/routines`"
                      class="w-min"
                    >
                      Ver rutinas por semana
                      <ArrowRight class="size-4" />
                    </BaseLink>
                  </div>
                  <routines-calendar
                    :routines="selectedTrainee.routines"
                  />
                </div>
              </TabPanel>
              <TabPanel>
                <trainee-stats
                  :key="selectedTrainee.id"
                  :routines="selectedTrainee.routines"
                  :trainee-id="selectedTrainee.id"
                />
              </TabPanel>
              <TabPanel>
                <chat-group-messages
                  v-if="selectedTrainee.trainerChatGroup"
                  :chat-group="selectedTrainee.trainerChatGroup"
                  :trainee="selectedTrainee"
                />
              </TabPanel>
              <TabPanel>
                <exercise-executions
                  :key="selectedTrainee.id"
                  :trainee-id="selectedTrainee.id"
                />
              </TabPanel>
              <TabPanel>
                <trainee-nutrition-plans
                  :key="selectedTrainee.id"
                  :trainee-id="selectedTrainee.id"
                />
              </TabPanel>
              <TabPanel>
                <trainee-physical-measurements
                  :key="selectedTrainee.id"
                  :trainee="selectedTrainee"
                />
              </TabPanel>
              <TabPanel>
                <trainee-meal-checks
                  :key="selectedTrainee.id"
                  :trainee="selectedTrainee"
                />
              </TabPanel>
              <TabPanel>
                <TraineeCalBookings
                  :key="selectedTrainee.id"
                  :trainee="selectedTrainee"
                />
              </TabPanel>
              <TabPanel>
                <surveys-list
                  :key="selectedTrainee.id"
                  :initial-survey="selectedTrainee.initialSurveySubmission"
                  :pre-meeting-survey="selectedTrainee.preMeetingSurvey"
                  :follow-up-meeting-surveys="selectedTrainee.followUpMeetingSurveys"
                  :trainee-id="selectedTrainee.id"
                  @survey-submitted="onFollowUpSurveySubmitted"
                />
              </TabPanel>
              <TabPanel>
                <notes-list :trainee-id="selectedTrainee.id" />
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
      </div>

      <!-- Loading state -->
      <div
        v-else-if="selectedTraineeId"
        class="flex flex-1 items-center justify-center rounded-xl border bg-white p-6 shadow-sm"
      >
        <div class="flex flex-col items-center gap-2">
          <div class="size-6 animate-spin rounded-full border-2 border-gray-300 border-t-gray-900" />
          <p class="text-sm text-gray-500">
            Cargando...
          </p>
        </div>
      </div>

      <!-- Latest workouts -->
      <div
        v-else
        class="flex flex-1 flex-col gap-4"
      >
        <h3 class="text-2xl font-medium text-gray-900">
          Últimas 24 horas
        </h3>
        <LatestWorkouts
          :workouts="props.workouts"
          :routines="props.routines"
          :trainees="props.trainees"
          :chat-messages="props.chatMessages"
          :chat-participants="props.chatParticipants"
          class="rounded-xl border bg-white p-6 shadow-sm"
        />
      </div>
    </div>
  </div>
</template>
