<script setup lang="ts">
import { computed, ref } from 'vue';
import { format } from 'date-fns';
import type { PhysicalMeasurement } from '@/types/extended';
import type { ApexOptions } from 'apexcharts';
import { BaseSelect } from '@/components';
import BaseAssessmentChart from './base-assessment-chart.vue';

const props = defineProps<{
  physicalMeasurements: PhysicalMeasurement[];
}>();

const selectedGirth = ref('relaxedArm');

const girthOptions = [
  { id: 'relaxedArm', name: 'Brazo Relajado' },
  { id: 'flexedArm', name: 'Brazo Flexionado' },
  { id: 'waist', name: 'Cintura' },
  { id: 'hip', name: 'Cadera' },
  { id: 'thigh', name: 'Muslo' },
  { id: 'calf', name: 'Pantorrilla' },
];

function formatDate(date: string): string {
  return format(new Date(date), 'dd/MM/yyyy');
}

const chartData = computed(() => {
  const dates = props.physicalMeasurements.map((pm) => formatDate(pm.createdAt));
  const assessments = props.physicalMeasurements.map((pm) => pm.physicalAssessment);

  return {
    dates,
    correctedGirths: {
      relaxedArm: assessments.map((assessment) => assessment.correctedRelaxedArmGirth),
      flexedArm: assessments.map((assessment) => assessment.correctedFlexedArmGirth),
      waist: assessments.map((assessment) => assessment.correctedWaistGirth),
      hip: assessments.map((assessment) => assessment.correctedHipGirth),
      thigh: assessments.map((assessment) => assessment.correctedThighGirth),
      calf: assessments.map((assessment) => assessment.correctedCalfGirth),
    },
  };
});

const chartOptions = computed<ApexOptions>(() => ({
  chart: {
    type: 'bar' as const,
    toolbar: { show: false },
    fontFamily: 'Inter var, sans-serif',
  },
  xaxis: {
    categories: chartData.value.dates,
  },
  yaxis: {
    title: { text: 'cm' },
    decimalsInFloat: 1,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
      borderRadius: 5,
      borderRadiusApplication: 'end',
    },
  },
  dataLabels: {
    formatter: (val: number) => val.toFixed(1),
  },
}));

const chartSeries = computed(() => [{
  name: girthOptions.find((opt) => opt.id === selectedGirth.value)?.name || '',
  data: chartData.value.correctedGirths[selectedGirth.value as keyof typeof chartData.value.correctedGirths],
}]);
</script>

<template>
  <BaseAssessmentChart
    title="Perímetros corregidos"
    metric-key="correctedGirths"
    :chart-options="chartOptions"
    :chart-series="chartSeries"
  >
    <template #header-right>
      <BaseSelect
        v-model="selectedGirth"
        name="girth"
        class="w-48"
        :options="girthOptions"
      />
    </template>
  </BaseAssessmentChart>
</template>
