<script setup lang="ts">
import { ref, computed } from 'vue';
import { AlertTriangle } from 'lucide-vue-next';
import type { TimeSlot } from '@/types/cal';
import { BaseSelect } from '@/components';
import PhoneInput from '@/components/shared/phone-input.vue';
import type { ContactFormData } from '../types';


interface Props {
  selectedSlot: TimeSlot;
  initialData: ContactFormData | null;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'continue', formData: ContactFormData): void;
  (e: 'back'): void;
}>();

const AVAILABLE_COMMUNES = [
  'Las Condes',
  'Vitacura',
  'Lo Barnechea',
  'Providencia',
  'La Reina',
  'Ñuñoa',
  'Otra comuna',
];

const PARKING_OPTIONS = [
  { id: 'visitor', name: 'En el estacionamiento de visitas' },
  { id: 'street', name: 'En la calle muy cerca' },
  { id: 'no_free', name: 'No tengo estacionamiento gratis cerca' },
  { id: 'other', name: 'Otro (comentar en instrucciones especiales)' },
] as const;

const DOCUMENT_TYPES = [
  { id: 'rut', name: 'RUT' },
  { id: 'passport', name: 'Pasaporte' },
] as const;

const firstName = ref(props.initialData?.firstName || '');
const lastName = ref(props.initialData?.lastName || '');
const email = ref(props.initialData?.email || '');
const phoneNumber = ref(props.initialData?.phoneNumber || '');
const commune = ref(props.initialData?.commune || '');
const address = ref(props.initialData?.address || '');
const specialInstructions = ref(props.initialData?.specialInstructions || '');
const documentType = ref(props.initialData?.documentType || '');
const documentValue = ref(props.initialData?.documentValue || '');
const parkingOption = ref(props.initialData?.parkingOption || '');
const isDocumentValueTouched = ref(false);

function formatRut(rut: string): string {
  // Remove all non-digits and 'k'/'K'
  const cleaned = rut.replace(/[^0-9kK]/g, '');
  if (cleaned.length < 2) return cleaned;

  // Split into body and verification digit
  const body = cleaned.slice(0, -1);
  const dv = cleaned.slice(-1);

  return `${body}-${dv}`;
}

function isValidRut(rut: string): boolean {
  // First clean the input
  const cleaned = rut.replace(/[^0-9kK]/g, '');
  if (cleaned.length < 2) return false;

  // Split into body and verification digit
  const rutDigits = cleaned.slice(0, -1);
  const dv = cleaned.slice(-1).toLowerCase();

  // Validate basic format
  if (rutDigits.length < 7 || rutDigits.length > 8) return false;

  let sum = 0;
  let multiplier = 2;

  for (let i = rutDigits.length - 1; i >= 0; i--) {
    sum += parseInt(rutDigits[i]) * multiplier;
    multiplier = multiplier === 7 ? 2 : multiplier + 1;
  }

  const expectedDv = 11 - (sum % 11);
  const calculatedDv = expectedDv === 11 ? '0' : expectedDv === 10 ? 'k' : expectedDv.toString();

  return calculatedDv === dv;
}

function handleDocumentValueBlur() {
  isDocumentValueTouched.value = true;
  if (documentType.value === 'rut' && isValidRut(documentValue.value)) {
    documentValue.value = formatRut(documentValue.value);
  }
}

const showRutError = computed(() => documentType.value === 'rut' &&
         documentValue.value !== '' &&
         isDocumentValueTouched.value &&
         !isValidRut(documentValue.value));

function handleSubmit() {
  emit('continue', {
    firstName: firstName.value,
    lastName: lastName.value,
    email: email.value,
    phoneNumber: phoneNumber.value,
    commune: commune.value,
    address: address.value,
    specialInstructions: specialInstructions.value,
    documentType: documentType.value,
    documentValue: documentValue.value,
    parkingOption: parkingOption.value,
  });
}
</script>

<template>
  <form
    class="rounded-xl border border-gray-200 bg-white p-6 shadow-sm md:p-8"
    @submit.prevent="handleSubmit"
  >
    <h3 class="mb-6 text-center text-2xl font-semibold text-gray-900">
      Completa tus datos
    </h3>

    <div class="space-y-6">
      <div class="grid gap-4 sm:grid-cols-2">
        <div>
          <label
            for="firstName"
            class="mb-1 block text-lg font-medium text-gray-900"
          >
            Nombre
          </label>
          <input
            id="firstName"
            v-model="firstName"
            type="text"
            required
            placeholder="Ej: Juan"
            class="mt-1 w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
          >
        </div>

        <div>
          <label
            for="lastName"
            class="mb-1 block text-lg font-medium text-gray-900"
          >
            Apellido
          </label>
          <input
            id="lastName"
            v-model="lastName"
            type="text"
            required
            placeholder="Ej: Pérez"
            class="mt-1 w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
          >
        </div>
      </div>

      <div class="grid gap-4 sm:grid-cols-2">
        <div>
          <label
            for="email"
            class="mb-1 block text-lg font-medium text-gray-900"
          >
            Email
          </label>
          <input
            id="email"
            v-model="email"
            type="email"
            required
            placeholder="Ej: juan@email.com"
            class="mt-1 w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
          >
        </div>

        <div>
          <label
            for="phone"
            class="mb-1 block text-lg font-medium text-gray-900"
          >
            Teléfono (WhatsApp)
          </label>
          <PhoneInput
            id="phone"
            v-model="phoneNumber"
            required
          />
        </div>
      </div>

      <div>
        <label
          for="commune"
          class="mb-1 block text-lg font-medium text-gray-900"
        >
          Comuna
        </label>
        <BaseSelect
          id="commune"
          v-model="commune"
          name="commune"
          :options="AVAILABLE_COMMUNES.map((commune, index) => ({ id: index, name: commune }))"
          by="name"
          class="mt-1"
        />
        <div
          v-if="commune === 'Otra comuna'"
          class="mt-2 flex items-start gap-2 rounded-md bg-yellow-50 p-3"
        >
          <AlertTriangle class="size-5 shrink-0 text-yellow-600" />
          <p class="text-sm text-yellow-800">
            Por ahora solo operamos en la zona oriente de Santiago. Esperamos pronto aumentar nuestra cobertura.
          </p>
        </div>
      </div>

      <div
        v-if="commune && commune !== 'Otra comuna'"
        class="space-y-6"
      >
        <div>
          <label
            for="address"
            class="mb-1 block text-lg font-medium text-gray-900"
          >
            Dirección completa (incluye número, depto, piso, etc.)
          </label>
          <input
            id="address"
            v-model="address"
            type="text"
            required
            placeholder="Ej: Av. Apoquindo 1234, Depto 567"
            class="mt-1 w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
          >
        </div>

        <div>
          <label
            for="parkingOption"
            class="mb-1 block text-lg font-medium text-gray-900"
          >
            Dónde podemos estacionar
          </label>
          <BaseSelect
            id="parkingOption"
            v-model="parkingOption"
            name="parkingOption"
            :options="PARKING_OPTIONS"
            by="id"
            label-by="name"
            class="mt-1"
            required
          />
          <div
            v-if="parkingOption === 'no_free'"
            class="mt-2 flex items-start gap-2 rounded-md bg-red-50 p-3"
          >
            <AlertTriangle class="size-5 shrink-0 text-red-600" />
            <p class="text-sm text-red-800">
              Lamentablemente necesitamos poder estacionar cerca del lugar de medición. No podremos atenderte si es que eso no es posible.
            </p>
          </div>
        </div>

        <div>
          <label
            for="specialInstructions"
            class="mb-1 block text-lg font-medium text-gray-900"
          >
            Instrucciones especiales
          </label>
          <input
            id="specialInstructions"
            v-model="specialInstructions"
            type="text"
            placeholder="Ej: Timbre 123, Edificio B, Portería por calle lateral"
            class="mt-1 w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
          >
        </div>
      </div>

      <div class="grid gap-4 sm:grid-cols-2">
        <div>
          <label
            for="documentType"
            class="mb-1 block text-lg font-medium text-gray-900"
          >
            Tipo de documento
          </label>
          <BaseSelect
            id="documentType"
            v-model="documentType"
            name="documentType"
            :options="DOCUMENT_TYPES"
            by="id"
            label-by="name"
            class="mt-1"
            required
          />
        </div>

        <div>
          <label
            for="documentValue"
            class="mb-1 block text-lg font-medium text-gray-900"
          >
            Número de documento
          </label>
          <input
            id="documentValue"
            v-model="documentValue"
            type="text"
            required
            :placeholder="documentType === 'rut' ? 'Ej: 12345678-9' : 'Ej: AB123456'"
            class="mt-1 w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
            :class="{ 'border-red-500': showRutError }"
            @blur="handleDocumentValueBlur"
          >
          <p
            v-if="showRutError"
            class="mt-1 text-sm text-red-600"
          >
            RUT inválido. Formato correcto: 12345678-9
          </p>
        </div>
      </div>

      <div class="pt-4">
        <button
          type="submit"
          :disabled="
            commune === 'Otra comuna' ||
              !commune ||
              !address ||
              !firstName ||
              !lastName ||
              !email ||
              !phoneNumber ||
              parkingOption === 'no_free' ||
              showRutError
          "
          class="w-full rounded-lg bg-black px-4 py-3 text-white transition duration-300 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
        >
          Continuar al pago
        </button>
      </div>
    </div>
  </form>
</template>
