<script setup lang="ts">
import { ref } from 'vue';
import type { SurveyResponse } from '@/types/body-composition-survey';

const props = defineProps<{
  response: Partial<SurveyResponse>
}>();

const emit = defineEmits<{
  (e: 'next', data: Partial<SurveyResponse>): void
}>();

const formData = ref({
  sex: props.response.sex || '',
  age: props.response.age || '',
  height: props.response.height || '',
  weight: props.response.weight || '',
});

const showGenderError = ref(false);

function handleSubmit() {
  if (!formData.value.sex) {
    showGenderError.value = true;

    return;
  }

  emit('next', {
    sex: formData.value.sex as 'male' | 'female',
    age: Number(formData.value.age),
    height: Number(formData.value.height),
    weight: Number(formData.value.weight),
  });
}
</script>

<template>
  <!-- Form Section -->
  <div class="rounded-xl border border-gray-200 bg-white p-6 shadow-sm md:p-8">
    <h3 class="mb-6 text-center text-2xl font-semibold text-gray-900">
      Información básica
    </h3>

    <form
      class="space-y-6"
      @submit.prevent="handleSubmit"
    >
      <!-- Sex Selection -->
      <div>
        <label class="mb-1 block text-lg font-medium text-gray-900">
          Género
        </label>
        <div class="grid grid-cols-2 gap-4">
          <div
            role="button"
            tabindex="0"
            :class="[
              'flex cursor-pointer items-center justify-center rounded-lg p-4 text-center',
              formData.sex === 'male'
                ? 'border-2 border-black bg-gray-50 font-medium text-gray-900'
                : 'bg-gray-50 hover:bg-gray-100',
              { 'border-2 border-red-300': showGenderError && !formData.sex }
            ]"
            @click="formData.sex = 'male'; showGenderError = false"
            @keypress.enter="formData.sex = 'male'; showGenderError = false"
          >
            <span
              class="text-base"
              :class="{ 'font-medium text-gray-900': formData.sex === 'male' }"
            >
              Hombre
            </span>
          </div>
          <div
            role="button"
            tabindex="0"
            :class="[
              'flex cursor-pointer items-center justify-center rounded-lg p-4 text-center',
              formData.sex === 'female'
                ? 'border-2 border-black bg-gray-50 font-medium text-gray-900'
                : 'bg-gray-50 hover:bg-gray-100',
              { 'border-2 border-red-300': showGenderError && !formData.sex }
            ]"
            @click="formData.sex = 'female'; showGenderError = false"
            @keypress.enter="formData.sex = 'female'; showGenderError = false"
          >
            <span
              class="text-base"
              :class="{ 'font-medium text-gray-900': formData.sex === 'female' }"
            >
              Mujer
            </span>
          </div>
        </div>
        <p
          v-if="showGenderError && !formData.sex"
          class="mt-2 text-sm text-red-600"
        >
          Por favor, selecciona tu género para continuar
        </p>
      </div>

      <!-- Age Input -->
      <div>
        <label
          for="age"
          class="mb-1 block text-lg font-medium text-gray-900"
        >
          ¿Cuál es tu edad?
        </label>
        <input
          id="age"
          v-model="formData.age"
          type="number"
          min="0"
          max="120"
          required
          placeholder="Ingresa tu edad"
          class="mt-1 w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
        >
      </div>

      <!-- Height Input -->
      <div>
        <label
          for="height"
          class="mb-1 block text-lg font-medium text-gray-900"
        >
          ¿Cuál es tu altura? (cm)
        </label>
        <input
          id="height"
          v-model="formData.height"
          type="number"
          min="0"
          max="300"
          required
          placeholder="Ingresa tu altura en centímetros"
          class="mt-1 w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
        >
      </div>

      <!-- Weight Input -->
      <div>
        <label
          for="weight"
          class="mb-1 block text-lg font-medium text-gray-900"
        >
          ¿Cuál es tu peso? (kg)
        </label>
        <input
          id="weight"
          v-model="formData.weight"
          type="number"
          min="0"
          max="500"
          required
          placeholder="Ingresa tu peso en kilogramos"
          class="mt-1 w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
        >
      </div>

      <!-- Submit Button -->
      <div class="pt-4">
        <button
          type="submit"
          class="w-full rounded-lg bg-black px-4 py-3 text-white transition duration-300 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
        >
          Continuar
        </button>
      </div>
    </form>
  </div>
</template>
