<script setup lang="ts">
import { PieChart } from 'lucide-vue-next';
import type { SurveyResult } from '@/types/body-composition-survey';

defineProps<{
  result: SurveyResult
}>();

const quadrantDescriptions = {
  1: 'Sobrenutrido y Baja Masa Muscular',
  2: 'Sobrenutrido y Masa Muscular Adecuada',
  3: 'Nutrición Adecuada y Baja Masa Muscular',
  4: 'Nutrición Adecuada y Masa Muscular Adecuada',
};

const quadrantAnalysis = {
  one: 'Tus resultados indican un exceso de grasa corporal con masa muscular por debajo de lo óptimo.',
  two: 'Tus resultados indican un exceso de grasa corporal, aunque mantienes una masa muscular adecuada.',
  three: 'Tus resultados indican una nutrición balanceada, pero tu masa muscular está por debajo de lo óptimo.',
  four: 'Tus resultados indican una composición corporal balanceada, con niveles adecuados de grasa y músculo.',
};
</script>

<template>
  <div class="rounded-xl border border-gray-200 bg-white p-4 shadow-sm md:p-8">
    <div class="flex items-center gap-2 text-gray-900">
      <PieChart class="size-5 md:size-6" />
      <h3 class="text-xl font-semibold md:text-2xl">
        Composición Corporal
      </h3>
    </div>
    <p class="mt-2 text-sm text-gray-600 md:text-base">
      Este análisis evalúa tu composición corporal en base a dos factores: nivel de nutrición y cantidad de masa muscular.
      {{ quadrantAnalysis[result.quadrant] }}
    </p>
    <div class="mt-6 md:mt-8">
      <div class="grid h-[300px] grid-cols-2 grid-rows-2 gap-3 md:h-[350px] md:gap-4">
        <!-- Quadrant 1 (Top Left) -->
        <div
          class="relative flex flex-col items-center justify-center rounded-lg border p-1 text-center transition-colors md:p-6"
          :class="{
            'border-blue-500 bg-blue-50': result.quadrant === 'one',
            'border-gray-200 bg-gray-50': result.quadrant !== 'one'
          }"
        >
          <h4
            class="mb-1 text-base font-medium md:mb-2 md:text-xl"
            :class="{
              'text-blue-900': result.quadrant === 'one',
              'text-gray-900': result.quadrant !== 'one'
            }"
          >
            Cuadrante 1
          </h4>
          <p
            class="text-sm md:text-base"
            :class="{
              'text-blue-600': result.quadrant === 'one',
              'text-gray-600': result.quadrant !== 'one'
            }"
          >
            {{ quadrantDescriptions[1] }}
          </p>
          <div
            v-if="result.quadrant === 'one'"
            class="absolute -right-1 -top-1 flex size-5 items-center justify-center rounded-full bg-blue-500 text-white shadow-sm md:-right-2 md:-top-2 md:size-6"
          >
            <svg
              class="size-3 md:size-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
        </div>

        <!-- Quadrant 2 (Top Right) -->
        <div
          class="relative flex flex-col items-center justify-center rounded-lg border p-1 text-center transition-colors md:p-6"
          :class="{
            'border-blue-500 bg-blue-50': result.quadrant === 'two',
            'border-gray-200 bg-gray-50': result.quadrant !== 'two'
          }"
        >
          <h4
            class="mb-1 text-base font-medium md:mb-2 md:text-xl"
            :class="{
              'text-blue-900': result.quadrant === 'two',
              'text-gray-900': result.quadrant !== 'two'
            }"
          >
            Cuadrante 2
          </h4>
          <p
            class="text-sm md:text-base"
            :class="{
              'text-blue-600': result.quadrant === 'two',
              'text-gray-600': result.quadrant !== 'two'
            }"
          >
            {{ quadrantDescriptions[2] }}
          </p>
          <div
            v-if="result.quadrant === 'two'"
            class="absolute -right-1 -top-1 flex size-5 items-center justify-center rounded-full bg-blue-500 text-white shadow-sm md:-right-2 md:-top-2 md:size-6"
          >
            <svg
              class="size-3 md:size-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
        </div>

        <!-- Quadrant 3 (Bottom Left) -->
        <div
          class="relative flex flex-col items-center justify-center rounded-lg border p-1 text-center transition-colors md:p-6"
          :class="{
            'border-blue-500 bg-blue-50': result.quadrant === 'three',
            'border-gray-200 bg-gray-50': result.quadrant !== 'three'
          }"
        >
          <h4
            class="mb-1 text-base font-medium md:mb-2 md:text-xl"
            :class="{
              'text-blue-900': result.quadrant === 'three',
              'text-gray-900': result.quadrant !== 'three'
            }"
          >
            Cuadrante 3
          </h4>
          <p
            class="text-sm md:text-base"
            :class="{
              'text-blue-600': result.quadrant === 'three',
              'text-gray-600': result.quadrant !== 'three'
            }"
          >
            {{ quadrantDescriptions[3] }}
          </p>
          <div
            v-if="result.quadrant === 'three'"
            class="absolute -right-1 -top-1 flex size-5 items-center justify-center rounded-full bg-blue-500 text-white shadow-sm md:-right-2 md:-top-2 md:size-6"
          >
            <svg
              class="size-3 md:size-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
        </div>

        <!-- Quadrant 4 (Bottom Right) -->
        <div
          class="relative flex flex-col items-center justify-center rounded-lg border p-1 text-center transition-colors md:p-6"
          :class="{
            'border-blue-500 bg-blue-50': result.quadrant === 'four',
            'border-gray-200 bg-gray-50': result.quadrant !== 'four'
          }"
        >
          <h4
            class="mb-1 text-base font-medium md:mb-2 md:text-xl"
            :class="{
              'text-blue-900': result.quadrant === 'four',
              'text-gray-900': result.quadrant !== 'four'
            }"
          >
            Cuadrante 4
          </h4>
          <p
            class="text-sm md:text-base"
            :class="{
              'text-blue-600': result.quadrant === 'four',
              'text-gray-600': result.quadrant !== 'four'
            }"
          >
            {{ quadrantDescriptions[4] }}
          </p>
          <div
            v-if="result.quadrant === 'four'"
            class="absolute -right-1 -top-1 flex size-5 items-center justify-center rounded-full bg-blue-500 text-white shadow-sm md:-right-2 md:-top-2 md:size-6"
          >
            <svg
              class="size-3 md:size-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
