<script setup lang="ts">
import { ref } from 'vue';
import { Home } from 'lucide-vue-next';
import type { Trainer, Nutritionist } from '@/types/';
import { trainerSelectionApi } from '@/api';
import BaseModal from '@/components/base-modal.vue';

const props = defineProps<{
  trainers: Trainer[];
  selectedTrainer: Trainer;
  nutritionist: Nutritionist;
  leadId: number;
}>();

const isModalOpen = ref(false);
const isTrainerChosen = ref(false);
const currentTrainer = ref<Trainer>(props.selectedTrainer);
const temporarySelectedTrainer = ref<Trainer>(props.selectedTrainer);

function handleTrainerClick(trainer: Trainer) {
  temporarySelectedTrainer.value = trainer;
}

function confirmSelection() {
  currentTrainer.value = temporarySelectedTrainer.value;
  isModalOpen.value = false;
  isTrainerChosen.value = true;

  trainerSelectionApi.create({
    trainerId: temporarySelectedTrainer.value.id,
    leadId: props.leadId,
  });
}

function getFirstName(fullName: string): string {
  return fullName.split(' ')[0];
}

function formatLastName(fullName: string) {
  const names = fullName.split(' ');
  if (names.length > 1) {
    return `${names[0]} ${names[names.length - 1][0]}.`;
  }

  return fullName;
}
</script>

<template>
  <div class="flex w-full flex-col space-y-4 rounded-xl bg-gradient-to-br from-blue-100/90 via-blue-50/60 to-gray-100/80 p-5 shadow-sm">
    <h2 class="text-center text-2xl font-semibold">
      Tu equipo
    </h2>
    <div
      class="flex flex-col justify-evenly gap-4 md:flex-row"
    >
      <div class="flex flex-col items-center space-y-2 md:w-1/2">
        <div class="shrink-0">
          <img
            :src="currentTrainer.avatarUrl"
            :alt="getFirstName(currentTrainer.fullName)"
            class="size-20 rounded-full border object-cover"
          >
        </div>
        <div class="text-center">
          <span class="text-xs font-medium uppercase tracking-wider text-gray-600">Entrenador</span>
          <h3 class="text-base font-medium text-gray-900">
            {{ getFirstName(currentTrainer.fullName) }}
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            {{ currentTrainer.title }}
          </p>
        </div>
      </div>

      <div class="flex flex-col items-center space-y-2 md:w-1/2">
        <div class="shrink-0">
          <img
            :src="nutritionist.avatarUrl"
            :alt="getFirstName(nutritionist.fullName)"
            class="size-20 rounded-full border object-cover"
          >
        </div>
        <div class="text-center">
          <span class="text-xs font-medium uppercase tracking-wider text-gray-600">Nutricionista</span>
          <h3 class="text-base font-medium text-gray-900">
            {{ getFirstName(nutritionist.fullName) }}
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            {{ nutritionist.title }}
          </p>
        </div>
      </div>
    </div>

    <div class="flex justify-center">
      <button
        class="mt-4 text-sm text-gray-600 hover:text-gray-900"
        @click="isModalOpen = true"
      >
        Ver más opciones
      </button>
    </div>

    <div class="p-4">
      <div class="mt-2 flex items-center gap-x-3">
        <div class="shrink-0">
          <div class="rounded-lg p-2">
            <Home class="size-5 text-gray-600" />
          </div>
        </div>
        <div>
          <h3 class="text-base font-medium text-gray-900">
            Mediciones a domicilio
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            Vamos a tu hogar u oficina a medir tu composición corporal todos los meses
            (por ahora solo zona oriente de Santiago).
          </p>
        </div>
      </div>
    </div>

    <BaseModal
      :open="isModalOpen"
      title="Elige a tus profesionales"
      size="lg"
      @close="isModalOpen = false"
    >
      <div class="flex flex-col space-y-6">
        <!-- Trainers Section -->
        <div>
          <h3 class="mb-4 text-lg font-medium">
            Entrenadores disponibles
          </h3>
          <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            <div
              v-for="trainer in trainers"
              :key="trainer.id"
              class="cursor-pointer rounded-lg border-2 bg-white p-4"
              :class="temporarySelectedTrainer?.id === trainer.id ? 'border-black' : 'border-gray-200'"
              @click="handleTrainerClick(trainer)"
            >
              <div class="flex items-center space-x-3">
                <img
                  :src="trainer.avatarUrl"
                  :alt="trainer.fullName"
                  class="size-16 rounded-full object-cover"
                >
                <div class="grow">
                  <h4 class="font-medium">
                    {{ formatLastName(trainer.fullName) }}
                  </h4>
                  <p class="text-sm text-gray-500">
                    {{ trainer.title }}
                  </p>
                </div>
                <div
                  v-if="temporarySelectedTrainer?.id === trainer.id"
                  class="shrink-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="size-5 text-black"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
              </div>
              <p class="mt-3 text-sm text-gray-600">
                {{ trainer.bio }}
              </p>
            </div>
          </div>
        </div>

        <!-- Nutritionist Section -->
        <div>
          <h3 class="mb-4 text-lg font-medium">
            Nutricionistas disponibles
          </h3>
          <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            <div
              class="rounded-lg border-2 border-black bg-white p-4"
            >
              <div class="flex items-center space-x-3">
                <img
                  :src="nutritionist.avatarUrl"
                  :alt="nutritionist.fullName"
                  class="size-16 rounded-full object-cover"
                >
                <div class="grow">
                  <h4 class="font-medium">
                    {{ formatLastName(nutritionist.fullName) }}
                  </h4>
                </div>
                <div class="shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="size-5 text-black"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
              </div>
              <p class="mt-3 text-sm text-gray-600">
                {{ nutritionist.bio }}
              </p>
            </div>
          </div>
        </div>

        <!-- Action Button -->
        <div class="mt-6 flex justify-center">
          <button
            class="rounded-lg bg-black px-4 py-2 text-white hover:bg-gray-800"
            @click="confirmSelection"
          >
            Elegir profesionales
          </button>
        </div>
      </div>
    </BaseModal>
  </div>
</template>
