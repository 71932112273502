<script setup lang="ts">
import { computed } from 'vue';
import { format } from 'date-fns';
import type { PhysicalMeasurement } from '@/types/extended';

const props = defineProps<{
  physicalMeasurements: PhysicalMeasurement[];
}>();

function formatDate(date: string): string {
  return format(new Date(date), 'dd/MM/yyyy');
}

// Helper to calculate average skinfold
function calculateAverageSkinfold(measurement: PhysicalMeasurement, prefix: string): number | null {
  const values = [
    measurement[`${prefix}` as keyof PhysicalMeasurement],
    measurement[`${prefix}2` as keyof PhysicalMeasurement],
    measurement[`${prefix}3` as keyof PhysicalMeasurement],
  ] as (number | undefined)[];

  const validValues = values.filter((v): v is number => v !== undefined && v !== null);
  if (validValues.length === 0) return null;

  return validValues.reduce((a, b) => a + b, 0) / validValues.length;
}

type MeasurementTableRow = {
  date: string;
  measurements: Record<string, string | undefined>;
};

const measurementColumnLabels = {
  weight: { label: 'Peso', unit: 'kg' },
  height: { label: 'Altura', unit: 'cm' },
  relaxedArmCircumference: { label: 'Perímetro Brazo Relajado', unit: 'cm' },
  flexedArmCircumference: { label: 'Perímetro Brazo Flexionado', unit: 'cm' },
  waistCircumference: { label: 'Perímetro Cintura', unit: 'cm' },
  hipCircumference: { label: 'Perímetro Cadera', unit: 'cm' },
  thighCircumference: { label: 'Perímetro Muslo', unit: 'cm' },
  calfCircumference: { label: 'Perímetro Pantorrilla', unit: 'cm' },
  tricepsSkinfold: { label: 'Pliegue Tríceps', unit: 'mm' },
  subscapularSkinfold: { label: 'Pliegue Subescapular', unit: 'mm' },
  bicepsSkinfold: { label: 'Pliegue Bíceps', unit: 'mm' },
  iliacCrestSkinfold: { label: 'Pliegue Cresta Ilíaca', unit: 'mm' },
  suprailiacSkinfold: { label: 'Pliegue Suprailiaco', unit: 'mm' },
  abdominalSkinfold: { label: 'Pliegue Abdominal', unit: 'mm' },
  thighSkinfold: { label: 'Pliegue Muslo', unit: 'mm' },
  calfSkinfold: { label: 'Pliegue Pantorrilla', unit: 'mm' },
  humerusBreadth: { label: 'Diámetro Húmero', unit: 'cm' },
  femurBreadth: { label: 'Diámetro Fémur', unit: 'cm' },
  gripStrength: { label: 'Fuerza de Agarre', unit: 'kg' },
};

const tableData = computed(() => props.physicalMeasurements.map((measurement): MeasurementTableRow => ({
  date: formatDate(measurement.createdAt),
  measurements: {
    weight: measurement.weight?.toFixed(1),
    height: measurement.height?.toFixed(1),
    relaxedArmCircumference: measurement.relaxedArmCircumference?.toFixed(1),
    flexedArmCircumference: measurement.flexedArmCircumference?.toFixed(1),
    waistCircumference: measurement.waistCircumference?.toFixed(1),
    hipCircumference: measurement.hipCircumference?.toFixed(1),
    thighCircumference: measurement.thighCircumference?.toFixed(1),
    calfCircumference: measurement.calfCircumference?.toFixed(1),
    tricepsSkinfold: calculateAverageSkinfold(measurement, 'tricepsSkinfold')?.toFixed(1),
    subscapularSkinfold: calculateAverageSkinfold(measurement, 'subscapularSkinfold')?.toFixed(1),
    bicepsSkinfold: calculateAverageSkinfold(measurement, 'bicepsSkinfold')?.toFixed(1),
    iliacCrestSkinfold: calculateAverageSkinfold(measurement, 'iliacCrestSkinfold')?.toFixed(1),
    suprailiacSkinfold: calculateAverageSkinfold(measurement, 'suprailiacSkinfold')?.toFixed(1),
    abdominalSkinfold: calculateAverageSkinfold(measurement, 'abdominalSkinfold')?.toFixed(1),
    thighSkinfold: calculateAverageSkinfold(measurement, 'thighSkinfold')?.toFixed(1),
    calfSkinfold: calculateAverageSkinfold(measurement, 'calfSkinfold')?.toFixed(1),
    humerusBreadth: measurement.humerusBreadth?.toFixed(1),
    femurBreadth: measurement.femurBreadth?.toFixed(1),
    gripStrength: measurement.gripStrength?.toFixed(1),
  },
})));
</script>

<template>
  <div class="overflow-hidden rounded-xl bg-white shadow-sm ring-1 ring-gray-900/5">
    <div class="p-6">
      <h3 class="text-base font-semibold text-gray-900">
        Mediciones
      </h3>
    </div>
    <div class="overflow-x-auto">
      <table
        v-if="tableData.length > 0"
        class="min-w-full divide-y divide-gray-300"
      >
        <thead>
          <tr class="bg-gray-50">
            <th
              scope="col"
              class="sticky left-0 z-10 w-1/4 bg-gray-50 px-6 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Medición
            </th>
            <th
              v-for="row in tableData"
              :key="row.date"
              scope="col"
              class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
            >
              {{ row.date }}
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200">
          <tr
            v-for="(label, key) in measurementColumnLabels"
            :key="key"
            class="hover:bg-gray-50"
          >
            <td class="sticky left-0 z-10 w-1/4 border-r border-gray-200 bg-white py-4 pl-6 pr-3 text-sm font-medium text-gray-900 hover:bg-gray-50">
              <p>
                {{ label.label }}
                <span
                  v-if="label.unit"
                  class="ml-1 text-gray-500"
                >
                  [{{ label.unit }}]
                </span>
              </p>
            </td>
            <td
              v-for="row in tableData"
              :key="row.date"
              class="whitespace-nowrap p-4 text-right text-sm text-gray-500"
            >
              {{ row.measurements[key] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
