<script setup lang="ts">
import { ref } from 'vue';
import { BaseButton, BaseModal } from '@/components';
import { routinesApi } from '@/api';
import type { Routine } from '@/types/extended';
import { useMutation } from '@tanstack/vue-query';
import { AlertTriangle } from 'lucide-vue-next';

const props = defineProps<{
  isOpen: boolean;
  routines: Routine[];
}>();

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'deleted'): void;
}>();

const errorMessage = ref('');

const { isError, isPending, isSuccess, mutate } = useMutation({
  mutationFn: async () => Promise.all(
    props.routines.map(routine =>
      routinesApi.destroy(routine.id),
    ),
  ),
  onSuccess: () => {
    emit('deleted');
  },
  onError: (error) => {
    errorMessage.value = `Ocurrió un error al eliminar las rutinas: ${error.message}`;
  },
});

function deleteAllRoutines() {
  errorMessage.value = '';
  mutate();
}
</script>

<template>
  <BaseModal
    :open="isOpen"
    title="Eliminar todas las rutinas"
    @close="emit('close')"
  >
    <template #default="{ close }">
      <div
        v-if="!isPending && !isSuccess"
        class="flex flex-col gap-4"
      >
        <p>
          ¿Estás seguro de que quieres eliminar todas las rutinas de esta semana?
        </p>
        <div class="flex items-center gap-2 rounded-md bg-amber-50 p-3 text-amber-800">
          <AlertTriangle class="size-5 shrink-0" />
          <p class="text-sm font-medium">
            Esta acción no se puede deshacer
          </p>
        </div>
      </div>
      <p v-if="isPending">
        Eliminando rutinas...
      </p>
      <p v-if="isSuccess">
        ¡Rutinas eliminadas exitosamente!
      </p>
      <p v-if="isError">
        {{ errorMessage }}
      </p>
      <div class="flex justify-end gap-4">
        <BaseButton
          v-if="!isPending && !isSuccess"
          variant="secondary"
          @click="close"
        >
          Cancelar
        </BaseButton>
        <BaseButton
          v-if="!isPending && !isSuccess"
          variant="destructive"
          @click="deleteAllRoutines"
        >
          Eliminar
        </BaseButton>
        <BaseButton
          v-if="isSuccess"
          variant="secondary"
          @click="close"
        >
          Cerrar
        </BaseButton>
      </div>
    </template>
  </BaseModal>
</template>
