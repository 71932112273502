<script setup lang="ts">
import { ref } from 'vue';
import type { SurveyResponse, ExerciseFrequency } from '@/types/body-composition-survey';

const props = defineProps<{
  response: Partial<SurveyResponse>
}>();

const emit = defineEmits<{
  (e: 'next', data: Partial<SurveyResponse>): void
  (e: 'back'): void
}>();

const formData = ref({
  exerciseFrequency: props.response.exerciseFrequency || '',
});

const options: { value: ExerciseFrequency; title: string; description: string }[] = [
  {
    value: 'no_exercise',
    title: 'No entreno actualmente',
    description: 'No realizo ejercicio de forma regular',
  },
  {
    value: '1_to_2',
    title: '1-2 veces por semana',
    description: 'Realizo algunas sesiones de ejercicio ocasionalmente',
  },
  {
    value: '3_to_4',
    title: '3-4 veces por semana',
    description: 'Entreno regularmente durante la semana',
  },
  {
    value: '5_plus',
    title: '5 o más veces por semana',
    description: 'Entreno casi todos los días',
  },
];

const showError = ref(false);

function handleSubmit() {
  emit('next', {
    exerciseFrequency: formData.value.exerciseFrequency as ExerciseFrequency,
  });
}
</script>

<template>
  <div class="space-y-8">
    <div class="text-center">
      <h2 class="text-3xl font-bold text-gray-900">
        Nivel de Actividad
      </h2>
      <p class="mt-3 text-lg text-gray-500">
        ¿Con qué frecuencia realizas ejercicio actualmente?
      </p>
    </div>

    <form
      class="space-y-12"
      @submit.prevent="handleSubmit"
    >
      <div class="space-y-4">
        <div
          v-for="option in options"
          :key="option.value"
          role="button"
          tabindex="0"
          :class="[
            'flex w-full cursor-pointer flex-col rounded-lg p-4 text-left',
            formData.exerciseFrequency === option.value
              ? 'border-2 border-black bg-gray-50 text-gray-900 font-medium'
              : 'bg-gray-50 hover:bg-gray-100',
            { 'border-2 border-red-300': showError && !formData.exerciseFrequency }
          ]"
          @click="formData.exerciseFrequency = option.value"
          @keypress.enter="formData.exerciseFrequency = option.value"
        >
          <h3
            class="mb-1 text-lg text-gray-900"
            :class="{ 'text-gray-900 font-medium': formData.exerciseFrequency === option.value }"
          >
            {{ option.title }}
          </h3>
          <p
            class="text-sm text-gray-600"
            :class="{ 'text-gray-900': formData.exerciseFrequency === option.value }"
          >
            {{ option.description }}
          </p>
        </div>
      </div>

      <!-- Navigation Buttons -->
      <div class="grid grid-cols-2 gap-4">
        <button
          type="button"
          class="flex justify-center rounded-lg border border-gray-300 bg-white px-4 py-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition duration-300"
          @click="emit('back')"
        >
          Atrás
        </button>
        <button
          type="submit"
          class="w-full px-4 py-3 text-white transition duration-300 bg-black rounded-lg hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          :disabled="!formData.exerciseFrequency"
        >
          Continuar
        </button>
      </div>
    </form>
  </div>
</template>
