<script setup lang="ts">
import { computed } from 'vue';
import { formatDate } from '@/utils/format-date';
import type { Workout } from '@/types/extended';

const props = defineProps<{
  workouts: Workout[],
}>();

const filteredWorkouts = computed(() => props.workouts.filter(workout => workout.perceivedExertion !== null));

const options = computed(() => ({
  chart: {
    id: 'rpe_chart',
  },
  xaxis: {
    categories: filteredWorkouts.value?.map(
      (workout) => `${workout?.routine?.name} ${formatDate(workout.endedAt || new Date())}`,
    ) ?? [],
  },
  yaxis: {
    min: 1,
    max: 10,
  },
  annotations: {
    yaxis: [
      {
        y: 1,
        y2: 3,
        fillColor: '#ADD8E6',
        opacity: 0.2,
        borderWidth: 0,
      },
      {
        y: 8,
        y2: 10,
        fillColor: '#FFA07A',
        opacity: 0.2,
        borderWidth: 0,
      },
    ],
  },
  markers: {
    size: 4,
  },
  stroke: {
    width: 5,
    curve: 'smooth',
  },
}));

const series = computed(() => [
  {
    name: 'RPE',
    data: filteredWorkouts.value?.map((workout) => workout.perceivedExertion) ?? [],
  },
]);

</script>

<template>
  <div>
    <h1>RPE</h1>
    <apexchart
      width="100%"
      type="line"
      :options="options"
      :series="series"
    />
  </div>
</template>
