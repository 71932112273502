<script setup lang="ts">
import {
  Dumbbell,
  Timer,
  Apple,
  Egg,
  Target,
  Check,
  ChevronRight,
  Info,
} from 'lucide-vue-next';
import { ref } from 'vue';
import BaseModal from '../../base-modal.vue';
import type { SurveyResult } from '../../../types/body-composition-survey';

interface Props {
  result: SurveyResult;
}

const props = defineProps<Props>();
const isCustomPlan = ref(false);
const selectedPlan = ref('');
const isDetailsModalOpen = ref(false);

const planOptions = [
  { value: 'gain_muscle', label: 'Aumentar de peso y masa muscular' },
  { value: 'lose_weight', label: 'Bajar de peso' },
  { value: 'recomp', label: 'Bajar grasa y aumentar músculo' },
  { value: 'performance', label: 'Mejorar rendimiento deportivo' },
  { value: 'rehab', label: 'Recuperarme de una lesión' },
  { value: 'other', label: 'Otro' },
];

function getMainObjective() {
  switch (props.result.quadrant) {
  case 'one':
    return 'Desarrollar masa muscular mientras reduces grasa corporal';
  case 'two':
    return 'Reducir grasa corporal mientras mantienes tu masa muscular actual';
  case 'three':
    return 'Desarrollar masa muscular manteniendo un peso saludable';
  case 'four':
    return props.result.visceralFatProbability >= 0.7 ?
      'Reducir grasa corporal manteniendo masa muscular' :
      'Incrementar masa muscular manteniendo una composición corporal saludable';
  default:
    return '';
  }
}
</script>

<template>
  <div class="flex w-full flex-col">
    <div
      v-if="isCustomPlan"
      class="space-y-6"
    >
      <div class="relative rounded-lg border border-gray-200">
        <select
          v-model="selectedPlan"
          class="w-full appearance-none rounded-lg border-0 bg-white py-3.5 pl-4 pr-10 text-sm text-gray-900 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-100"
        >
          <option
            value=""
            disabled
          >
            Selecciona tu objetivo
          </option>
          <option
            v-for="option in planOptions"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3">
          <ChevronRight class="size-4 rotate-90 text-gray-400" />
        </div>
      </div>

      <div class="rounded-xl bg-blue-50/50 p-4">
        <div class="flex items-start gap-3">
          <Check class="mt-1 size-5 shrink-0 text-blue-600" />
          <p class="text-sm leading-relaxed text-gray-600">
            Tu equipo de salud te ayudará a definir e implementar lo que necesitas para lograr tu objetivo.
          </p>
        </div>
      </div>
    </div>

    <div
      v-else
      class="space-y-5"
    >
      <div class="flex flex-col gap-4">
        <div class="rounded-xl bg-gradient-to-br from-blue-100/90 via-blue-50/60 to-gray-100/80 p-5 shadow-sm">
          <h2 class="mb-6 text-center text-2xl font-semibold">
            Plan sugerido
          </h2>
          <div class="flex items-center gap-2">
            <Target class="size-4 shrink-0 text-blue-600" />
            <span class="text-xs font-medium uppercase tracking-wider text-gray-600">Objetivo</span>
          </div>
          <p class="mt-2 text-lg font-medium text-gray-800">
            {{ getMainObjective() }}
          </p>
          <button
            class="mt-4 flex w-full items-center justify-between rounded-lg border border-gray-200 "
            @click="isDetailsModalOpen = true"
          >
            <span class="text-sm text-gray-700">Ver detalles del plan</span>
            <ChevronRight class="size-4 text-gray-400" />
          </button>
        </div>
      </div>
    </div>
  </div>

  <BaseModal
    :open="isDetailsModalOpen"
    title="Detalles de tu plan"
    @close="isDetailsModalOpen = false"
  >
    <template #default="{ close }">
      <div class="space-y-8">
        <div class="rounded-xl bg-gradient-to-br from-gray-100/90 via-gray-50/60 to-gray-100/80 p-4 shadow-sm">
          <div class="flex items-start gap-3">
            <Check class="mt-1 size-5 shrink-0 text-gray-600" />
            <p class="text-sm leading-relaxed text-gray-600">
              Tu equipo de salud te ayudará a refinar y ajustar tu plan en base a tu información, mediciones y objetivos.
              Si quieres seguir otro plan, puedes hablarlo con tu entrenador y nutricionista.
            </p>
          </div>
        </div>

        <div class="space-y-4">
          <span class="text-xs font-medium uppercase tracking-wider text-gray-500">Entrenamiento</span>
          <div class="space-y-4">
            <div class="rounded-lg bg-gray-100/80 p-4 shadow-sm">
              <div class="mb-2 flex items-center gap-2">
                <Dumbbell class="size-4 text-blue-600" />
                <p class="text-sm font-medium text-gray-700">
                  Fuerza
                </p>
              </div>
              <div class="space-y-1">
                <p class="text-base font-medium text-gray-900">
                  {{ result.strengthFrequency }}
                </p>
                <p class="text-sm text-gray-600">
                  Enfoque: {{ result.strengthFocus }}
                </p>
                <p class="text-sm text-gray-600">
                  {{ result.strengthSets }}
                </p>
              </div>
            </div>

            <div class="rounded-lg bg-gray-100/80 p-4 shadow-sm">
              <div class="mb-2 flex items-center gap-2">
                <Timer class="size-4 text-blue-600" />
                <p class="text-sm font-medium text-gray-700">
                  Cardio
                </p>
              </div>
              <div class="space-y-1">
                <p class="text-base font-medium text-gray-900">
                  {{ result.cardioFrequency }} ({{ result.cardioDuration }})
                </p>
                <p class="text-sm text-gray-600">
                  Tipo: {{ result.cardioType }}
                </p>
                <p
                  v-if="result.cardioNote"
                  class="text-sm text-gray-600"
                >
                  {{ result.cardioNote }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="space-y-4">
          <span class="text-xs font-medium uppercase tracking-wider text-gray-500">Nutrición</span>
          <div class="space-y-4">
            <div class="rounded-lg bg-gray-100/80 p-4 shadow-sm">
              <div class="mb-2 flex items-center gap-2">
                <Apple class="size-4 text-purple-600" />
                <p class="text-sm font-medium text-gray-700">
                  Estrategia nutricional
                </p>
              </div>
              <p class="text-base font-medium text-gray-900">
                {{ {
                  deficit: 'Déficit calórico',
                  maintenance: 'Mantenimiento',
                  surplus: 'Superávit calórico'
                }[result.nutritionStrategy] }}
              </p>
              <p class="text-base font-medium text-gray-900">
                {{ Math.round(result.totalCalorieRangeMin) }}-{{ Math.round(result.totalCalorieRangeMax) }}
                <span class="text-sm font-normal text-gray-500">kcal/día</span>
              </p>
            </div>

            <div class="rounded-lg bg-gray-100/80 p-4 shadow-sm">
              <div class="mb-2 flex items-center gap-2">
                <Egg class="size-4 text-orange-600" />
                <p class="text-sm font-medium text-gray-700">
                  Proteína diaria
                </p>
              </div>
              <p class="text-base font-medium text-gray-900">
                {{ Math.round(result.totalProteinRangeMin) }}-{{ Math.round(result.totalProteinRangeMax) }}
                <span class="text-sm font-normal text-gray-500">g/día</span>
              </p>
            </div>
          </div>
        </div>

        <div class="flex justify-end">
          <button
            class="rounded-lg bg-gray-900 px-5 py-2.5 text-sm font-medium text-white shadow-sm transition-all hover:bg-gray-800 hover:shadow-md"
            @click="close"
          >
            Cerrar
          </button>
        </div>
      </div>
    </template>
  </BaseModal>
</template>
