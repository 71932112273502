<script setup lang="ts">
import { computed } from 'vue';
import { AlertTriangle, X } from 'lucide-vue-next';
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';

const props = defineProps<{
  isOpen: boolean
  riskType: 'overnourished' | 'under_muscled' | 'visceral_fat' | null
}>();

const emit = defineEmits<{
  (e: 'close'): void
}>();

const healthRisks = {
  overnourished: [
    'Mayor riesgo de enfermedades cardiovasculares',
    'Mayor riesgo de diabetes tipo 2',
    'Aumento de riesgo de muerte temprana de entre 20% y 100%',
    'Aumento del riesgo de hipertensión',
    'Mayor riesgo de apnea del sueño',
    'Mayor riesgo de ciertos tipos de cáncer',
  ],
  under_muscled: [
    'Metabolismo más lento y mayor dificultad para mantener buena salud metabólica',
    'Mayor riesgo de osteoporosis y pérdida ósea',
    'Aumento de riesgo de muerte temprana de entre 130% y 140%',
    'Mayor riesgo de sarcopenia y discapacidad en edad avanzada',
    'Mayor riesgo de lesiones y caídas',
    'Recuperación más lenta de enfermedades o cirugías',
  ],
  visceral_fat: [
    'Mayor riesgo de enfermedades cardiovasculares',
    'Mayor riesgo de diabetes tipo 2',
    'Alteración del metabolismo y resistencia a la insulina',
    'Mayor riesgo de hipertensión arterial',
    'Inflamación sistémica crónica',
  ],
};

const selectedRiskTitle = computed(() => {
  switch (props.riskType) {
  case 'overnourished':
    return 'Riesgos por Exceso de Grasa Corporal';
  case 'under_muscled':
    return 'Riesgos por Baja Masa Muscular';
  case 'visceral_fat':
    return 'Riesgos por Grasa Visceral';
  default:
    return '';
  }
});

const selectedRiskList = computed(() => {
  if (!props.riskType) return [];
  return healthRisks[props.riskType];
});
</script>

<template>
  <Dialog
    as="div"
    class="relative z-50"
    :open="isOpen"
    @close="emit('close')"
  >
    <div class="fixed inset-0 bg-black/30" aria-hidden="true" />

    <div class="fixed inset-0 overflow-y-auto">
      <div class="flex min-h-full items-center justify-center p-4">
        <DialogPanel class="mx-auto w-full max-w-2xl rounded-xl bg-white p-6 shadow-xl">
          <div class="mb-6 flex items-center justify-between">
            <DialogTitle class="text-2xl font-semibold text-gray-900">
              {{ selectedRiskTitle }}
            </DialogTitle>
            <button
              type="button"
              class="rounded-lg p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
              @click="emit('close')"
            >
              <span class="sr-only">Cerrar</span>
              <X class="h-6 w-6" />
            </button>
          </div>

          <div class="space-y-4">
            <div
              v-for="(risk, index) in selectedRiskList"
              :key="index"
              class="flex items-start space-x-3 rounded-lg bg-gray-50 p-4"
            >
              <AlertTriangle class="mt-0.5 h-5 w-5 flex-shrink-0 text-yellow-500" />
              <p class="text-gray-700">{{ risk }}</p>
            </div>
          </div>

          <div class="mt-8 flex justify-end">
            <button
              type="button"
              class="rounded-lg bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              @click="emit('close')"
            >
              Entendido
            </button>
          </div>
        </DialogPanel>
      </div>
    </div>
  </Dialog>
</template>
