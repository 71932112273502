<script setup lang="ts">
import { computed } from 'vue';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { ArrowRight, ExternalLink } from 'lucide-vue-next';
import type { Measurer, Trainee, PhysicalMeasurement, Measuree } from '@/types';
import { BackButton, BaseLink } from '@/components';

const props = defineProps<{
  physicalMeasurement: PhysicalMeasurement;
  userable: Trainee | Measuree;
  measurer: Measurer;
}>();

const formattedDate = computed(() => {
  const date = new Date(props.physicalMeasurement.createdAt);

  return format(date, "d 'de' MMMM 'a las' HH:mm", { locale: es });
});

// eslint-disable-next-line complexity
const measurementGroups = computed(() => [
  {
    title: 'Medidas básicas',
    measurements: [
      { label: 'Peso', value: props.physicalMeasurement.weight, unit: 'kg' },
      { label: 'Altura', value: props.physicalMeasurement.height, unit: 'cm' },
    ],
  },
  {
    title: 'Circunferencias',
    measurements: [
      { label: 'Brazo relajado', value: props.physicalMeasurement.relaxedArmCircumference, unit: 'cm' },
      { label: 'Brazo flexionado', value: props.physicalMeasurement.flexedArmCircumference, unit: 'cm' },
      { label: 'Cintura', value: props.physicalMeasurement.waistCircumference, unit: 'cm' },
      { label: 'Cadera', value: props.physicalMeasurement.hipCircumference, unit: 'cm' },
      { label: 'Muslo', value: props.physicalMeasurement.thighCircumference, unit: 'cm' },
      { label: 'Pantorrilla', value: props.physicalMeasurement.calfCircumference, unit: 'cm' },
    ],
  },
  {
    title: 'Pliegues cutáneos',
    measurements: [
      { label: 'Tríceps', value: props.physicalMeasurement.tricepsSkinfold, unit: 'mm' },
      ...(props.physicalMeasurement.tricepsSkinfold2 ? [
        { label: 'Tríceps 2', value: props.physicalMeasurement.tricepsSkinfold2, unit: 'mm' },
      ] : []),
      ...(props.physicalMeasurement.tricepsSkinfold3 ? [
        { label: 'Tríceps 3', value: props.physicalMeasurement.tricepsSkinfold3, unit: 'mm' },
      ] : []),
      { label: 'Subescapular', value: props.physicalMeasurement.subscapularSkinfold, unit: 'mm' },
      ...(props.physicalMeasurement.subscapularSkinfold2 ? [
        { label: 'Subescapular 2', value: props.physicalMeasurement.subscapularSkinfold2, unit: 'mm' },
      ] : []),
      ...(props.physicalMeasurement.subscapularSkinfold3 ? [
        { label: 'Subescapular 3', value: props.physicalMeasurement.subscapularSkinfold3, unit: 'mm' },
      ] : []),
      { label: 'Bíceps', value: props.physicalMeasurement.bicepsSkinfold, unit: 'mm' },
      ...(props.physicalMeasurement.bicepsSkinfold2 ? [
        { label: 'Bíceps 2', value: props.physicalMeasurement.bicepsSkinfold2, unit: 'mm' },
      ] : []),
      ...(props.physicalMeasurement.bicepsSkinfold3 ? [
        { label: 'Bíceps 3', value: props.physicalMeasurement.bicepsSkinfold3, unit: 'mm' },
      ] : []),
      { label: 'Cresta ilíaca', value: props.physicalMeasurement.iliacCrestSkinfold, unit: 'mm' },
      ...(props.physicalMeasurement.iliacCrestSkinfold2 ? [
        { label: 'Cresta ilíaca 2', value: props.physicalMeasurement.iliacCrestSkinfold2, unit: 'mm' },
      ] : []),
      ...(props.physicalMeasurement.iliacCrestSkinfold3 ? [
        { label: 'Cresta ilíaca 3', value: props.physicalMeasurement.iliacCrestSkinfold3, unit: 'mm' },
      ] : []),
      { label: 'Suprailíaco', value: props.physicalMeasurement.suprailiacSkinfold, unit: 'mm' },
      { label: 'Abdominal', value: props.physicalMeasurement.abdominalSkinfold, unit: 'mm' },
      ...(props.physicalMeasurement.abdominalSkinfold2 ? [
        { label: 'Abdominal 2', value: props.physicalMeasurement.abdominalSkinfold2, unit: 'mm' },
      ] : []),
      ...(props.physicalMeasurement.abdominalSkinfold3 ? [
        { label: 'Abdominal 3', value: props.physicalMeasurement.abdominalSkinfold3, unit: 'mm' },
      ] : []),
      { label: 'Muslo', value: props.physicalMeasurement.thighSkinfold, unit: 'mm' },
      ...(props.physicalMeasurement.thighSkinfold2 ? [
        { label: 'Muslo 2', value: props.physicalMeasurement.thighSkinfold2, unit: 'mm' },
      ] : []),
      ...(props.physicalMeasurement.thighSkinfold3 ? [
        { label: 'Muslo 3', value: props.physicalMeasurement.thighSkinfold3, unit: 'mm' },
      ] : []),
      { label: 'Pantorrilla', value: props.physicalMeasurement.calfSkinfold, unit: 'mm' },
      ...(props.physicalMeasurement.calfSkinfold2 ? [
        { label: 'Pantorrilla 2', value: props.physicalMeasurement.calfSkinfold2, unit: 'mm' },
      ] : []),
      ...(props.physicalMeasurement.calfSkinfold3 ? [
        { label: 'Pantorrilla 3', value: props.physicalMeasurement.calfSkinfold3, unit: 'mm' },
      ] : []),
    ],
  },
  {
    title: 'Diámetros óseos',
    measurements: [
      { label: 'Húmero', value: props.physicalMeasurement.humerusBreadth, unit: 'cm' },
      { label: 'Fémur', value: props.physicalMeasurement.femurBreadth, unit: 'cm' },
    ],
  },
  {
    title: 'Fuerza',
    measurements: [
      { label: 'Fuerza de agarre', value: props.physicalMeasurement.gripStrength, unit: 'kg' },
    ],
  },
]);

const editMeasurementLink = computed(() => {
  const userableType = props.userable.userableType.toLowerCase();

  return `/${userableType}s/${props.userable.id}/physical_measurements/${props.physicalMeasurement.id}/edit`;
});

const physicalAssessmentsLink = computed(() => {
  const userableType = props.userable.userableType.toLowerCase();
  if (props.userable.slug) {
    return `/${userableType}s/${props.userable.slug}/physical_assessments`;
  }

  return `/${userableType}s/${props.userable.id}/physical_assessments`;
});
</script>

<template>
  <div class="mb-20 flex flex-col gap-4">
    <BackButton :href="`/physical_measurements`" />
    <div class="flex items-center justify-between">
      <h2 class="text-2xl font-bold">
        {{ userable.fullName }} - Medición física
      </h2>
    </div>

    <div class="flex justify-between gap-2">
      <div class="mb-4 flex flex-col gap-2">
        <p><strong>Fecha de medición:</strong> {{ formattedDate }}</p>
        <p><strong>Medición hecha por:</strong> {{ measurer.fullName }}</p>
      </div>

      <BaseLink :href="editMeasurementLink">
        Editar <ArrowRight class="size-4" />
      </BaseLink>
    </div>

    <div>
      <BaseLink
        :href="physicalAssessmentsLink"
        target="_blank"
        class="flex items-center gap-x-2"
      >
        Ir al reporte de composición corporal <ExternalLink class="size-4" />
      </BaseLink>
    </div>

    <div class="grid grid-cols-2 gap-4">
      <div
        v-for="group in measurementGroups"
        :key="group.title"
        class="rounded-lg bg-gray-100 p-4"
      >
        <h3 class="mb-4 text-lg font-semibold">
          {{ group.title }}
        </h3>
        <div class="space-y-2">
          <div
            v-for="measurement in group.measurements"
            :key="measurement.label"
            class="flex items-center justify-between"
          >
            <span class="text-gray-700">{{ measurement.label }}:</span>
            <span class="font-medium">{{ measurement.value }} {{ measurement.unit }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
