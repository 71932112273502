<script setup lang="ts">
import { computed, ref } from 'vue';
import { ChevronDown, ChevronRight } from 'lucide-vue-next';
import type { Trainee, Measuree } from '@/types';
import type { HealthCategory } from './physical-assessment-metrics';
import SubscribeButton from './subscribe-button.vue';

type ObjectiveInfo = {
  value: number;
  percentile: number | null;
  isHigherBetter: boolean;
} | null;

const props = withDefaults(defineProps<{
  title: string;
  currentValue: number | null;
  decimalPlaces?: number;
  healthCategory: HealthCategory | null;
  recommendation: string | null;
  importance: string | null;
  userable: Trainee | Measuree;
  unit?: string;
  objective?: ObjectiveInfo;
}>(), {
  decimalPlaces: 1,
  unit: undefined,
  objective: undefined,
});

const isExpanded = ref(false);

function toggle() {
  isExpanded.value = !isExpanded.value;
}

const statusColors = computed(() => {
  if (!props.healthCategory) return {};

  switch (props.healthCategory.id) {
  case 'optimal':
    return 'bg-green-100 text-green-700';
  case 'moderate':
    return 'bg-yellow-100 text-yellow-700';
  case 'unhealthy':
    return 'bg-red-100 text-red-700';
  default:
    return '';
  }
});

// eslint-disable-next-line complexity
const objectiveText = computed(() => {
  if (!props.objective || !props.healthCategory || props.healthCategory.id === 'optimal') return null;

  const nextState = props.healthCategory.id === 'unhealthy' ? 'moderado' : 'óptimo';
  const value = `${props.objective.value.toFixed(1)}${props.unit ? props.unit : ''}`;
  let text = `Para que tu ${props.title.toLowerCase()} esté en un estado ${nextState}, debes llegar a ${value}.`;

  const { percentile, isHigherBetter } = props.objective;

  if (percentile !== null) {
    const betterThanPercent = isHigherBetter ? 100 - percentile : percentile;

    text += ` Si llegas a este valor, estarás en el mejor ${betterThanPercent}% de la población.`;
  }

  return text;
});
</script>
<template>
  <div
    v-if="currentValue && healthCategory"
    class="rounded-b-lg rounded-t-none bg-white p-3 shadow-sm ring-1 ring-gray-900/5 sm:rounded-l-none sm:rounded-r-lg sm:p-6"
  >
    <div class="space-y-4">
      <h3 class="mb-4 text-lg font-semibold text-gray-900">
        Análisis
      </h3>
      <div class="space-y-2">
        <div class="flex items-baseline justify-between">
          <span class="text-sm font-medium text-gray-500">{{ title }} actual</span>
          <span class="px-2 py-1 text-base font-semibold text-gray-900">
            {{ currentValue.toFixed(decimalPlaces) }}{{ unit ? unit : '' }}
          </span>
        </div>
        <div class="flex items-baseline justify-between">
          <span class="text-sm font-medium text-gray-500">Estado</span>
          <span
            :class="[
              'rounded-full px-2 py-1 text-sm font-medium',
              statusColors
            ]"
          >
            {{ healthCategory.label }}
          </span>
        </div>
        <div
          v-if="objective && healthCategory.id !== 'optimal'"
          class="flex items-baseline justify-between"
        >
          <span class="text-sm font-medium text-gray-500">Tu próximo objetivo</span>
          <span class="px-2 py-1 text-sm font-medium ">
            {{ objective.value.toFixed(1) }}{{ unit ? unit : '' }}
          </span>
        </div>
      </div>

      <div
        v-if="recommendation || objectiveText"
        class="rounded-md bg-slate-100 p-3 text-sm text-slate-900"
      >
        <p class="font-medium">
          Recomendación
        </p>
        <p
          v-if="recommendation"
          class="mt-1"
        >
          {{ recommendation }}
        </p>
        <p
          v-if="objectiveText"
          class="mt-2"
        >
          {{ objectiveText }}
        </p>
      </div>

      <div
        v-if="importance"
        class="overflow-hidden rounded-xl"
      >
        <button
          type="button"
          class="flex w-full items-center justify-between rounded-xl px-4 py-3 text-left hover:bg-gray-50"
          @click="toggle"
        >
          <span class="text-sm text-gray-900">
            ¿Por qué es importante?
          </span>
          <ChevronDown
            v-if="isExpanded"
            class="size-5 text-gray-500"
          />
          <ChevronRight
            v-else
            class="size-5 text-gray-500"
          />
        </button>
        <div
          v-show="isExpanded"
          class="p-3"
        >
          <p class="prose prose-sm whitespace-pre-wrap text-gray-600">
            {{ importance }}
          </p>
        </div>
      </div>

      <SubscribeButton
        v-if="userable.userableType === 'Measuree' && healthCategory.id !== 'optimal'"
        :health-category="healthCategory"
        :userable="userable"
      />
    </div>
  </div>
</template>
