<script setup lang="ts">
import { ref } from 'vue';
import { Calendar, UserRound, CreditCard, CheckCircle, MessageCircle, XCircle } from 'lucide-vue-next';
import type { TimeSlot } from '@/types/cal';
import { calApi } from '@/api/cal';
import { measureesApi } from '@/api/measurees';
import type { ContactFormData } from './types';
import SelectSlotStep from './steps/select-slot-step.vue';
import ContactInfoStep from './steps/contact-info-step.vue';
import PaymentStep from './steps/payment-step.vue';

const PARKING_OPTIONS = [
  { id: 'visitor', name: 'En el estacionamiento de visitas' },
  { id: 'street', name: 'En la calle muy cerca' },
  { id: 'no_free', name: 'No tengo estacionamiento gratis cerca' },
  { id: 'other', name: 'Otro (comentar en instrucciones especiales)' },
] as const;

const props = defineProps<{
  preferenceId: string;
  amount: number;
  eventTypeId: number;
}>();

type Step = 'select-slot' | 'contact-info' | 'payment' | 'completed' | 'error';

const currentStep = ref<Step>('select-slot');
const selectedSlot = ref<TimeSlot | null>(null);
const contactInfo = ref<ContactFormData | null>(null);
const isCreatingBooking = ref(false);

const steps = [
  {
    id: 'select-slot',
    name: 'Horario',
    icon: Calendar,
  },
  {
    id: 'contact-info',
    name: 'Datos',
    icon: UserRound,
  },
  {
    id: 'payment',
    name: 'Pago',
    icon: CreditCard,
  },
] as const;

function getStepStatus(stepId: Step): 'upcoming' | 'current' | 'complete' {
  const stepIndex = steps.findIndex(step => step.id === stepId);
  const currentIndex = steps.findIndex(step => step.id === currentStep.value);

  if (currentStep.value === 'completed') {
    return 'complete';
  }

  if (stepIndex < currentIndex) {
    return 'complete';
  }

  if (stepIndex === currentIndex) {
    return 'current';
  }

  return 'upcoming';
}

function canNavigateToStep(stepId: Step): boolean {
  const stepIndex = steps.findIndex(step => step.id === stepId);
  const currentIndex = steps.findIndex(step => step.id === currentStep.value);

  return stepIndex < currentIndex;
}

function handleStepClick(stepId: Step): void {
  if (canNavigateToStep(stepId)) {
    currentStep.value = stepId;
  }
}

function handleSlotContinue(): void {
  currentStep.value = 'contact-info';
}

function handleContactInfoContinue(formData: ContactFormData): void {
  contactInfo.value = formData;
  currentStep.value = 'payment';
}

function handleBack(step: Step): void {
  if (step === 'select-slot') {
    selectedSlot.value = null;
  }
  currentStep.value = step;
}

// eslint-disable-next-line max-statements, complexity
async function handlePaymentComplete(): Promise<void> {
  if (!selectedSlot.value || !contactInfo.value) return;

  isCreatingBooking.value = true;

  try {
    const measureeResponse = await measureesApi.create({
      firstName: contactInfo.value.firstName,
      lastName: contactInfo.value.lastName,
      email: contactInfo.value.email,
      phoneNumber: contactInfo.value.phoneNumber,
      address: contactInfo.value.address,
      commune: contactInfo.value.commune,
      specialInstructions: contactInfo.value.specialInstructions,
      documentType: contactInfo.value.documentType,
      documentValue: contactInfo.value.documentValue,
    });

    if (measureeResponse.success) {
      const bookingData = {
        start: selectedSlot.value.startTime || selectedSlot.value.time || '',
        lengthInMinutes: 30,
        eventTypeId: props.eventTypeId,
        attendee: {
          name: `${contactInfo.value.firstName} ${contactInfo.value.lastName}`,
          email: contactInfo.value.email,
          timeZone: getTimeZone(),
          phoneNumber: contactInfo.value.phoneNumber,
          language: 'es',
        },
        location: `${contactInfo.value.address}, ${contactInfo.value.commune}${contactInfo.value.parkingOption ? ` (${PARKING_OPTIONS.find(opt => opt.id === contactInfo.value.parkingOption)?.name})` : ''}`,
        metadata: {
          specialInstructions: contactInfo.value.specialInstructions,
          parkingOption: contactInfo.value.parkingOption,
          address: {
            street: contactInfo.value.address,
            commune: contactInfo.value.commune,
          },
        },
      };

      const bookingResponse = await calApi.createBooking(bookingData);

      if (bookingResponse.success === false) {
        throw new Error(bookingResponse.error);
      }

      currentStep.value = 'completed';
    }
  } catch (error) {
    console.error('Error:', error);
    currentStep.value = 'error';
  } finally {
    isCreatingBooking.value = false;
  }
}

function getTimeZone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

const whatsappNumber = '+56964098412';
const defaultMessage = 'Hola! Necesito ayuda con mi medición';
const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(defaultMessage)}`;
</script>

<template>
  <div class="flex flex-col space-y-8">
    <!-- Steps -->
    <nav class="mx-auto w-full max-w-2xl px-4">
      <div class="relative">
        <!-- Progress Line Container -->
        <div
          class="absolute top-4 h-0.5 w-full bg-gray-200 md:top-5"
          style="left: 20px; right: 20px; width: calc(100% - 40px);"
        >
          <div
            class="h-full bg-black transition-all duration-200"
            :style="{
              width: `${(steps.findIndex(step => step.id === currentStep) / (steps.length - 1)) * 100}%`,
            }"
          />
        </div>

        <!-- Steps List -->
        <div class="relative flex justify-between">
          <div
            v-for="step in steps"
            :key="step.id"
            class="flex flex-col items-center"
          >
            <button
              type="button"
              class="group flex flex-col items-center focus:outline-none"
              :class="[
                canNavigateToStep(step.id) ? 'cursor-pointer' : 'cursor-default',
              ]"
              :disabled="!canNavigateToStep(step.id)"
              @click="handleStepClick(step.id)"
            >
              <div
                class="mb-2 flex size-8 items-center justify-center rounded-full transition-all duration-200 md:size-10"
                :class="[
                  getStepStatus(step.id) === 'complete'
                    ? 'bg-black text-white ring-4 ring-black/10 group-hover:ring-black/20'
                    : getStepStatus(step.id) === 'current'
                      ? 'bg-black text-white ring-4 ring-black/10'
                      : 'bg-white text-gray-400 ring-2 ring-gray-200',
                ]"
              >
                <component
                  :is="step.icon"
                  class="size-4 md:size-5"
                  aria-hidden="true"
                />
              </div>
              <span
                class="text-center text-xs font-medium md:text-sm"
                :class="[
                  getStepStatus(step.id) === 'complete'
                    ? 'text-black'
                    : getStepStatus(step.id) === 'current'
                      ? 'text-black'
                      : 'text-gray-500',
                ]"
              >
                {{ step.name }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </nav>

    <!-- Step Content -->
    <div class="mx-auto w-full max-w-2xl">
      <SelectSlotStep
        v-if="currentStep === 'select-slot'"
        v-model:selected-slot="selectedSlot"
        :event-type-id="eventTypeId"
        @continue="handleSlotContinue"
      />

      <ContactInfoStep
        v-if="currentStep === 'contact-info' && selectedSlot"
        :selected-slot="selectedSlot"
        :initial-data="contactInfo"
        @continue="handleContactInfoContinue"
        @back="handleBack('select-slot')"
      />

      <PaymentStep
        v-if="currentStep === 'payment' && selectedSlot && contactInfo"
        :selected-slot="selectedSlot"
        :contact-info="contactInfo"
        :preference-id="preferenceId"
        :amount="amount"
        @back="handleBack('contact-info')"
        @back-to-slot="handleBack('select-slot')"
        @complete="handlePaymentComplete"
      />

      <!-- Completed Step -->
      <div
        v-if="currentStep === 'completed'"
        class="rounded-xl border border-gray-200 bg-white p-6 shadow-sm md:p-8"
      >
        <div class="flex flex-col items-center space-y-6">
          <div class="flex size-16 items-center justify-center rounded-full bg-green-100">
            <CheckCircle class="size-8 text-green-600" />
          </div>

          <div class="space-y-2">
            <h2 class="text-center text-2xl font-bold text-gray-900">
              ¡Reserva completada!
            </h2>
            <p class="text-base text-gray-600">
              Te enviaremos un recordatorio 12 horas antes de tu medición.
            </p>
          </div>

          <!-- Selected Slot Section -->
          <div class="mx-6 mb-8 flex items-start justify-start gap-4 rounded-lg border border-gray-100 bg-white p-4 shadow-sm">
            <div class="shrink-0">
              <div class="flex size-10 items-center justify-center rounded-full bg-black text-white">
                <Calendar class="size-5" />
              </div>
            </div>
            <div class="flex-1">
              <div class="flex items-center justify-between">
                <h4 class="text-lg font-medium text-gray-900">
                  Medición antropométrica
                </h4>
              </div>
              <div class="mt-2 space-y-1">
                <p class="text-base capitalize text-gray-600">
                  {{ new Date(selectedSlot.time || selectedSlot.startTime || '').toLocaleDateString('es-CL', {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long'
                  }) }}
                </p>
                <p class="text-base font-medium text-gray-900">
                  {{ new Date(selectedSlot.time || selectedSlot.startTime || '').toLocaleTimeString('es-CL', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                  }) }} hrs
                </p>
                <div class="text-base text-gray-600">
                  {{ contactInfo.address }}, {{ contactInfo.commune }}
                </div>
              </div>
            </div>
          </div>
          <a
            href="https://www.kap.so/"
            target="_blank"
            rel="noopener noreferrer"
            class="inline-flex items-center gap-2 rounded-lg bg-black px-4 py-2 text-sm font-medium text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
          >
            Conocer más de Kapso
          </a>
        </div>
      </div>

      <!-- Error Step -->
      <div
        v-if="currentStep === 'error'"
        class="rounded-xl border border-gray-200 bg-white p-6 shadow-sm md:p-8"
      >
        <div class="flex flex-col items-center space-y-6 text-center">
          <div class="flex size-16 items-center justify-center rounded-full bg-red-100">
            <XCircle class="size-8 text-red-600" />
          </div>

          <div class="space-y-2">
            <h2 class="text-2xl font-bold text-gray-900">
              Hubo un problema al agendar tu medición
            </h2>
            <p class="text-base text-gray-600">
              No te preocupes, nuestro equipo se pondrá en contacto contigo pronto para coordinar tu medición.
            </p>
          </div>

          <div class="flex flex-col items-center space-y-4">
            <a
              :href="whatsappUrl"
              target="_blank"
              rel="noopener noreferrer"
              class="inline-flex items-center gap-2 rounded-lg bg-[#25D366] px-4 py-2 text-sm font-medium text-white hover:bg-[#128C7E] focus:outline-none focus:ring-2 focus:ring-[#25D366] focus:ring-offset-2"
            >
              <MessageCircle class="size-5" />
              Contactar soporte
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Floating WhatsApp Button -->
    <a
      v-if="currentStep !== 'completed'"
      :href="whatsappUrl"
      target="_blank"
      rel="noopener noreferrer"
      class="fixed bottom-6 right-4 z-50 inline-flex items-center gap-2 rounded-full bg-[#25D366] p-4 text-white shadow-lg transition-all hover:bg-[#128C7E] focus:outline-none focus:ring-2 focus:ring-[#25D366] focus:ring-offset-2 md:bottom-4 md:right-4"
    >
      <MessageCircle class="size-6" />
    </a>

    <!-- Loading Overlay -->
    <div
      v-if="isCreatingBooking"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black/50"
    >
      <div class="rounded-lg bg-white p-6 shadow-xl">
        <div class="flex flex-col items-center space-y-4">
          <div class="size-8 animate-spin rounded-full border-4 border-gray-200 border-t-black" />
          <p class="text-sm font-medium text-gray-900">
            Creando tu reserva...
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
