<script setup lang="ts">
import { ref, computed } from 'vue';
import { format, startOfWeek, endOfWeek, eachDayOfInterval, isSameDay, addWeeks, subWeeks } from 'date-fns';
import { useQuery, useQueryClient, useMutation } from '@tanstack/vue-query';
import type { Measurer, Trainee, Measuree } from '@/types';
import type { PhysicalMeasurementBooking } from '@/types/physical-measurement-booking';
import { BaseSelect, BaseButton, BaseModal } from '@/components';
import { physicalMeasurementBookingsApi } from '@/api/physical-measurement-bookings';
import CalendarWeek from '@/components/measurements/calendar-week.vue';

type Props = {
  measurers: Measurer[];
  trainees: Trainee[];
  measurees: Measuree[];
};

const props = defineProps<Props>();
const queryClient = useQueryClient();

const selectedMeasurerId = ref<string>('');
const selectedAttendeeId = ref<string>('');
const selectedDate = ref<Date | null>(new Date());

const weekStartDate = ref<Date>(new Date());

const { data: bookings } = useQuery({
  queryKey: ['physical-measurement-bookings'],
  queryFn: () => physicalMeasurementBookingsApi.getAll(),
});

const attendees = computed(() => [
  ...props.trainees.map(t => ({
    id: t.id,
    name: t.fullName || 'Cliente sin nombre',
  })),
  ...props.measurees.map(m => ({
    id: m.id,
    name: m.fullName || 'Cliente sin nombre',
  })),
]);

const currentWeek = computed(() => {
  const start = startOfWeek(weekStartDate.value, { weekStartsOn: 1 });

  return eachDayOfInterval({ start, end: endOfWeek(start, { weekStartsOn: 1 }) });
});

const availableSlots = computed(() => {
  if (!bookings.value) return {};

  const slots: Record<string, PhysicalMeasurementBooking[]> = {};
  bookings.value.forEach(booking => {
    const date = format(new Date(booking.calBooking.startTime), 'yyyyMMdd');
    if (!slots[date]) slots[date] = [];
    slots[date].push(booking);
  });

  return slots;
});

const activeFilters = computed(() => {
  const filters: string[] = [];

  if (selectedMeasurerId.value) {
    const measurer = props.measurers.find(m => m.id.toString() === selectedMeasurerId.value);
    if (measurer) filters.push(`Medidor: ${measurer.fullName}`);
  }

  if (selectedAttendeeId.value) {
    const attendee = attendees.value.find(a => a.id.toString() === selectedAttendeeId.value);
    if (attendee) filters.push(`Cliente: ${attendee.name}`);
  }

  return filters;
});

const filteredBookings = computed(() => {
  if (!bookings.value) return [];

  let filtered = [...bookings.value];

  if (selectedDate.value) {
    filtered = filtered.filter((booking) =>
      isSameDay(new Date(booking.calBooking.startTime), selectedDate.value!),
    );
  }

  if (selectedMeasurerId.value) {
    filtered = filtered.filter(
      (booking) => booking.calBooking.organizerId.toString() === selectedMeasurerId.value,
    );
  }

  if (selectedAttendeeId.value) {
    filtered = filtered.filter(
      (booking) => booking.calBooking.attendeeId.toString() === selectedAttendeeId.value,
    );
  }

  return filtered.sort((a, b) =>
    new Date(a.calBooking.startTime).getTime() - new Date(b.calBooking.startTime).getTime(),
  );
});

const selectedBooking = ref<PhysicalMeasurementBooking | null>(null);
const isDetailsModalOpen = ref(false);

const updateBookingMutation = useMutation({
  mutationFn: ({ id, data }: { id: number; data: Partial<PhysicalMeasurementBooking> }) =>
    physicalMeasurementBookingsApi.update(id, data),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['physical-measurement-bookings'] });
  },
});

function getClientName(booking: PhysicalMeasurementBooking) {
  const attendee = attendees.value.find(a => a.id === booking.calBooking.attendeeId);

  return attendee?.name || 'Cliente sin nombre';
}

function getMeasurerName(booking: PhysicalMeasurementBooking) {
  const measurer = props.measurers.find(m => m.id === booking.calBooking.organizerId);

  return measurer?.fullName || 'Medidor sin nombre';
}

async function updatePaymentStatus(bookingId: number, status: 'pending' | 'paid' | 'cancelled') {
  updateBookingMutation.mutate({
    id: bookingId,
    data: { paymentStatus: status },
  });
}

function getPaymentStatusClass(status: string): string {
  return {
    pending: 'bg-yellow-100 text-yellow-800',
    paid: 'bg-green-100 text-green-800',
    cancelled: 'bg-red-100 text-red-800',
  }[status] || '';
}

function getParkingOptionLabel(option: string): string {
  return {
    street: 'Calle',
    building: 'Edificio',
    noParking: 'Sin estacionamiento',
  }[option] || '';
}

function clearFilters() {
  selectedMeasurerId.value = '';
  selectedAttendeeId.value = '';
}

function handleWeekNavigation(direction: 'prev' | 'next') {
  weekStartDate.value = direction === 'prev' ?
    subWeeks(weekStartDate.value, 1) :
    addWeeks(weekStartDate.value, 1);
}

function openDetailsModal(booking: PhysicalMeasurementBooking) {
  selectedBooking.value = booking;
  isDetailsModalOpen.value = true;
}
</script>

<template>
  <div class="flex flex-col gap-8 p-6">
    <div class="flex flex-col gap-4">
      <h1 class="text-2xl font-bold">
        Mediciones
      </h1>

      <!-- Filters -->
      <div class="flex flex-col gap-4">
        <!-- Filter Controls -->
        <div class="grid grid-cols-2 gap-4 sm:flex sm:flex-row sm:gap-4">
          <div class="flex flex-col gap-1">
            <label
              for="measurer"
              class="text-sm font-medium text-gray-700"
            >
              Medidor
            </label>
            <BaseSelect
              v-model="selectedMeasurerId"
              :options="measurers.map(m => ({ id: m.id.toString(), name: m.fullName || 'Medidor sin nombre' }))"
              placeholder="Seleccionar medidor"
              class="w-full sm:w-56"
              name="measurer"
              by="id"
            />
          </div>
          <div class="flex flex-col gap-1">
            <label
              for="attendee"
              class="text-sm font-medium text-gray-700"
            >
              Cliente
            </label>
            <BaseSelect
              v-model="selectedAttendeeId"
              :options="attendees.map(a => ({ id: a.id.toString(), name: a.name }))"
              placeholder="Seleccionar cliente"
              class="w-full sm:w-56"
              name="attendee"
              by="id"
            />
          </div>
        </div>

        <!-- Clear Filters Button -->
        <div v-if="activeFilters.length > 0" class="flex justify-end">
          <BaseButton
            variant="secondary"
            size="sm"
            @click="clearFilters"
          >
            Limpiar filtros
          </BaseButton>
        </div>

        <!-- Active Filters -->
        <div
          v-if="activeFilters.length > 0"
          class="flex flex-wrap gap-2"
        >
          <span
            v-for="filter in activeFilters"
            :key="filter"
            class="inline-flex items-center rounded-full bg-gray-100 px-3 py-1 text-sm font-medium text-gray-800"
          >
            {{ filter }}
          </span>
        </div>
      </div>
    </div>

    <!-- Calendar Navigation -->
    <CalendarWeek
      v-model:selectedDate="selectedDate"
      :current-week="currentWeek"
      :available-slots="availableSlots"
      @navigate="handleWeekNavigation"
    />

    <!-- Bookings Table -->
    <div class="overflow-x-auto">
      <table class="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th class="px-4 py-3 text-left text-sm font-medium text-gray-500">Hora</th>
            <th class="px-4 py-3 text-left text-sm font-medium text-gray-500">Cliente</th>
            <th class="px-4 py-3 text-left text-sm font-medium text-gray-500">Medidor</th>
            <th class="px-4 py-3 text-left text-sm font-medium text-gray-500">Estado</th>
            <th class="px-4 py-3 text-left text-sm font-medium text-gray-500">Estacionamiento</th>
            <th class="px-4 py-3 text-left text-sm font-medium text-gray-500">Acciones</th>
            <th class="w-10 px-4 py-3 text-left text-sm font-medium text-gray-500" aria-label="Ver detalles" />
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200">
          <tr
            v-for="booking in filteredBookings"
            :key="booking.id"
            class="hover:bg-gray-50"
          >
            <td class="whitespace-nowrap px-4 py-3 text-sm">
              {{ format(new Date(booking.calBooking.startTime), 'HH:mm') }}
            </td>
            <td class="px-4 py-3 text-sm">
              {{ getClientName(booking) }}
              <div v-if="booking.specialInstructions" class="mt-1 text-xs text-gray-500">
                {{ booking.specialInstructions }}
              </div>
            </td>
            <td class="px-4 py-3 text-sm">{{ getMeasurerName(booking) }}</td>
            <td class="px-4 py-3 text-sm">
              <span
                class="inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium"
                :class="getPaymentStatusClass(booking.paymentStatus)"
              >
                {{
                  booking.paymentStatus === 'pending'
                    ? 'Pendiente'
                    : booking.paymentStatus === 'paid'
                      ? 'Pagado'
                      : 'Cancelado'
                }}
              </span>
            </td>
            <td class="px-4 py-3 text-sm">{{ getParkingOptionLabel(booking.parkingOption) }}</td>
            <td class="px-4 py-3 text-sm">
              <button
                v-if="booking.paymentStatus === 'pending'"
                type="button"
                class="text-sm font-medium text-blue-600 hover:text-blue-500"
                @click="updatePaymentStatus(booking.id, 'paid')"
              >
                Marcar como pagado
              </button>
            </td>
            <td class="w-10 px-4 py-3 text-center text-sm">
              <button
                type="button"
                class="rounded p-1 hover:bg-gray-100"
                @click="openDetailsModal(booking)"
              >
                ...
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Details Modal -->
    <BaseModal
      :open="isDetailsModalOpen"
      title="Detalles de la medición"
      @close="isDetailsModalOpen = false"
    >
      <div v-if="selectedBooking" class="grid gap-6">
        <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div>
            <p class="font-medium text-gray-500">ID</p>
            <p>{{ selectedBooking.id }}</p>
          </div>
          <div>
            <p class="font-medium text-gray-500">Fecha y hora</p>
            <p>{{ format(new Date(selectedBooking.calBooking.startTime), 'PPpp') }}</p>
          </div>
          <div>
            <p class="font-medium text-gray-500">Cliente</p>
            <p>{{ getClientName(selectedBooking) }}</p>
          </div>
          <div>
            <p class="font-medium text-gray-500">Medidor</p>
            <p>{{ getMeasurerName(selectedBooking) }}</p>
          </div>
          <div>
            <p class="font-medium text-gray-500">Estado de pago</p>
            <p>
              <span
                class="inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium"
                :class="getPaymentStatusClass(selectedBooking.paymentStatus)"
              >
                {{
                  selectedBooking.paymentStatus === 'pending'
                    ? 'Pendiente'
                    : selectedBooking.paymentStatus === 'paid'
                      ? 'Pagado'
                      : 'Cancelado'
                }}
              </span>
            </p>
          </div>
          <div>
            <p class="font-medium text-gray-500">Estacionamiento</p>
            <p>{{ getParkingOptionLabel(selectedBooking.parkingOption) }}</p>
          </div>
          <div>
            <p class="font-medium text-gray-500">Recordatorio enviado</p>
            <p>{{ selectedBooking.reminderSent ? 'Sí' : 'No' }}</p>
          </div>
          <div>
            <p class="font-medium text-gray-500">Fecha de creación</p>
            <p>{{ format(new Date(selectedBooking.createdAt), 'PPpp') }}</p>
          </div>
          <div>
            <p class="font-medium text-gray-500">Última actualización</p>
            <p>{{ format(new Date(selectedBooking.updatedAt), 'PPpp') }}</p>
          </div>
          <div v-if="selectedBooking.specialInstructions" class="col-span-1 sm:col-span-2">
            <p class="font-medium text-gray-500">Instrucciones especiales</p>
            <p>{{ selectedBooking.specialInstructions }}</p>
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>
