import type { ExerciseExecution } from '@/types';
import { api } from './axios-wrapper';

export const exerciseExecutionsApi = {
  async getAll({
    traineeId,
    exerciseIds,
    startDate,
    endDate,
  }: {
    traineeId: number;
    exerciseIds?: number[];
    count?: number;
    startDate?: string;
    endDate?: string;
  }) {
    const queryParams: Record<string, unknown> = {
      'trainee_id_eq': traineeId,
      's': ['execution_date desc'],
    };

    if (exerciseIds?.length) {
      queryParams.exercise_id_in = exerciseIds;
    }

    if (startDate) {
      queryParams.execution_date_gteq = startDate;
    }

    if (endDate) {
      queryParams.execution_date_lteq = endDate;
    }

    const response = await api({
      method: 'get',
      url: '/api/internal/exercise_executions',
      params: {
        q: queryParams,
      },
    });

    return response.data as ExerciseExecution[];
  },
};
