<script setup lang="ts">
import { computed } from 'vue';
import { format } from 'date-fns';
import type { PhysicalMeasurement } from '@/types/extended';
import type { ApexOptions } from 'apexcharts';
import BaseAssessmentChart from './base-assessment-chart.vue';

const props = defineProps<{
  physicalMeasurements: PhysicalMeasurement[];
}>();

function formatDate(date: string): string {
  return format(new Date(date), 'dd/MM/yyyy');
}

const chartData = computed(() => {
  const dates = props.physicalMeasurements.map((pm) => formatDate(pm.createdAt));
  const assessments = props.physicalMeasurements.map((pm) => pm.physicalAssessment);

  return {
    dates,
    bodyMassIndex: assessments.map((assessment) => assessment.bodyMassIndex),
  };
});

const chartOptions = computed<ApexOptions>(() => ({
  chart: {
    type: 'line' as const,
    toolbar: { show: false },
    fontFamily: 'Inter var, sans-serif',
  },
  stroke: { curve: 'smooth', width: 3 },
  xaxis: {
    categories: chartData.value.dates,
  },
  yaxis: {
    title: { text: 'kg/m²' },
    decimalsInFloat: 1,
  },
  dataLabels: {
    enabled: true,
    formatter: (value: number) => `${value.toFixed(1)}`,
    offsetY: -6,
    background: {
      enabled: false,
    },
  },
}));

const chartSeries = computed(() => [{
  name: 'IMC',
  data: chartData.value.bodyMassIndex,
}]);
</script>

<template>
  <BaseAssessmentChart
    title="Índice de masa corporal (IMC)"
    metric-key="bodyMassIndex"
    :chart-options="chartOptions"
    :chart-series="chartSeries"
  />
</template>
