<script setup lang="ts">
import { ref, computed } from 'vue';
import { useMutation } from '@tanstack/vue-query';
import { BaseSelect, BaseInput, BaseButton, CalEmbed } from '@/components';
import type { Trainee, TraineeAttributes } from '@/types';
import type { Lead } from '@/types/extended';
import { api } from '@/api/fetch-wrapper';

interface Props {
  calUrl: string
  lead: Lead
  trainee: Trainee
  hasRecentMeasurement: boolean
  view: 'mobile' | 'desktop'
}

const props = defineProps<Props>();

const AVAILABLE_COMMUNES = [
  'Las Condes',
  'Vitacura',
  'Lo Barnechea',
  'Providencia',
  'La Reina',
  'Ñuñoa',
  'Otra comuna',
];

const commune = ref('');
const address = ref('');
const specialInstructions = ref('');
const isFromOtherCountry = computed(() => props.lead.country !== 'CL');
const isFromOtherCommune = computed(() => commune.value === 'Otra comuna');
const showCommuneInput = ref(!isFromOtherCountry.value);
const showAddressInput = computed(() => commune.value !== '' && !isFromOtherCommune.value);
const showCalendar = ref(false);

const { mutate: updateTraineeMutation, isPending, error } = useMutation({
  mutationFn: (data: Partial<TraineeAttributes>) => api.patch({
    url: `/api/v1/trainees/${props.trainee.id}`,
    body: data,
  }),
  onSuccess: () => {
    showCalendar.value = true;
  },
});

async function handleSubmit() {
  if (!commune.value || !address.value) return;

  const fullAddress = specialInstructions.value ?
    `${address.value} (${specialInstructions.value}), ${commune.value}` :
    `${address.value}, ${commune.value}`;

  updateTraineeMutation({
    address: fullAddress,
  });
}
</script>

<template>
  <div
    v-if="hasRecentMeasurement"
    class="flex flex-col items-center justify-center gap-8"
  >
    <p class="text-left text-gray-500">
      ¡Genial! Ya tienes una medición reciente, así que podemos usarlas como punto de partida para armar tu plan.
    </p>
    <a
      class="flex cursor-pointer items-center justify-center gap-x-2 rounded-lg bg-black px-4 py-3 text-center text-white transition duration-300 hover:bg-gray-800"
      href="/onboarding/schedule_nutritionist"
    >
      Continuar con nutricionista
    </a>
  </div>
  <div
    v-else-if="isFromOtherCountry"
    class="flex flex-col items-center justify-center gap-8"
  >
    <p class="text-left text-gray-500">
      Por ahora no podemos medir en tu país. ¡Esperamos poder hacerlo pronto!
    </p>
    <a
      class="flex cursor-pointer items-center justify-center gap-x-2 rounded-lg bg-black px-4 py-3 text-center text-white transition duration-300 hover:bg-gray-800"
      href="/onboarding/schedule_nutritionist"
    >
      Ir a agendar con nutricionista
    </a>
  </div>
  <div
    v-else
    class="flex flex-col space-y-6"
  >
    <div
      v-if="error"
      class="text-sm text-red-600"
    >
      {{ error }}
    </div>

    <form
      v-if="showCommuneInput && !showCalendar"
      class="flex flex-col space-y-6"
      @submit.prevent="handleSubmit"
    >
      <div class="flex flex-col space-y-2">
        <label
          :for="`comuna-${view}`"
          class="text-sm font-medium text-gray-700"
        >
          ¿En qué comuna vives?
        </label>
        <BaseSelect
          v-model="commune"
          name="commune"
          :options="AVAILABLE_COMMUNES.map((commune, index) => ({ id: index, name: commune }))"
          by="name"
        />
      </div>

      <div
        v-if="showAddressInput"
        class="flex flex-col space-y-2"
      >
        <label class="text-sm font-medium text-gray-700">
          ¿Cuál es tu dirección completa? (incluye número, depto, piso, etc.)
        </label>
        <BaseInput
          v-model="address"
          type="text"
          name="address"
          class="bg-gray-100"
          placeholder="Ej: Av. Apoquindo 1234, Depto 567"
          required
        />
      </div>

      <div
        v-if="showAddressInput"
        class="flex flex-col space-y-2"
      >
        <label class="text-sm font-medium text-gray-700">
          Instrucciones especiales (opcional)
        </label>
        <BaseInput
          v-model="specialInstructions"
          type="text"
          name="specialInstructions"
          class="bg-gray-100"
          placeholder="Ej: Timbre 123, Edificio B, Portería por calle lateral"
        />
      </div>

      <BaseButton
        v-if="!isFromOtherCommune && showAddressInput"
        type="submit"
        :disabled="!commune || !address || isPending"
        class="flex cursor-pointer items-center justify-center gap-x-2 rounded-lg bg-black px-4 py-2 text-center text-white transition duration-300 hover:bg-gray-800 disabled:cursor-not-allowed disabled:opacity-50"
      >
        {{ isPending ? 'Guardando...' : 'Siguiente' }}
      </BaseButton>
    </form>

    <div
      v-if="isFromOtherCommune"
      class="flex flex-col items-center justify-center gap-8"
    >
      <p class="text-left text-gray-500">
        Lo sentimos, por ahora no tenemos disponibilidad para realizar mediciones en tu comuna.
        ¡Esperamos poder tener cobertura en tu comuna pronto!
      </p>
      <a
        class="flex cursor-pointer items-center justify-center gap-x-2 rounded-lg bg-black px-4 py-3 text-center text-white transition duration-300 hover:bg-gray-800"
        href="/onboarding/schedule_nutritionist"
      >
        Ir a agendar con nutricionista
      </a>
    </div>

    <cal-embed
      v-if="showCalendar"
      :cal-url="props.calUrl"
      next-url="/onboarding/schedule_nutritionist"
      :view="props.view"
    />
  </div>
</template>
