<script setup lang="ts">
import { ref } from 'vue';
import { Maximize2 } from 'lucide-vue-next';
import BaseModal from './base-modal.vue';

interface ChartData {
  type: 'line' | 'bar' | 'scatter' | 'pie';
  title: string;
  series: Array<{
    name: string;
    data: number[];
  }>;
  xaxis: {
    categories: string[];
  };
  yaxis?: {
    min?: number;
    max?: number;
    title?: string;
  };
  annotations?: {
    yaxis?: Array<{
      y: number;
      y2?: number;
      fillColor: string;
      opacity: number;
      borderWidth: number;
    }>;
  };
}

const props = defineProps<{
  chart: ChartData;
}>();

const isModalOpen = ref(false);
const chartId = ref(`chart-${Math.random().toString(36).substr(2, 9)}`);

// Enhanced chart options with better styling
const options = {
  chart: {
    id: chartId.value,
    toolbar: {
      show: true,
    },
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 800,
    },
  },
  title: {
    text: props.chart.title,
    align: 'center',
    style: {
      fontSize: '16px',
      fontWeight: 600,
    },
  },
  stroke: {
    width: 2, // Thinner lines
    curve: 'smooth',
  },
  xaxis: {
    ...props.chart.xaxis,
    labels: {
      style: {
        fontSize: '12px',
      },
    },
  },
  yaxis: {
    ...props.chart.yaxis,
    labels: {
      formatter: (value: number) => {
        // Format numbers to max 1 decimal place
        return Number(value).toFixed(1);
      },
      style: {
        fontSize: '12px',
      },
    },
  },
  annotations: props.chart.annotations || {},
  grid: {
    borderColor: '#f1f1f1',
    strokeDashArray: 4,
  },
  markers: {
    size: 4,
    strokeWidth: 0,
  },
  tooltip: {
    y: {
      formatter: (value: number) => {
        // Format tooltip numbers to max 1 decimal place
        return Number(value).toFixed(1);
      },
    },
  },
};
</script>

<template>
  <div>
    <div class="relative rounded-lg border border-gray-200 bg-white p-4">
      <!-- Expand button -->
      <button
        class="absolute right-6 top-6 z-10 rounded-lg bg-white/80 p-1.5 text-gray-500 shadow-sm hover:bg-white hover:text-gray-700"
        @click="isModalOpen = true"
      >
        <Maximize2 class="size-5" />
      </button>

      <!-- Chart -->
      <apexchart
        :id="chartId"
        width="100%"
        height="300px"
        :type="chart.type"
        :options="options"
        :series="chart.series"
      />
    </div>

    <!-- Modal -->
    <BaseModal
      :open="isModalOpen"
      :title="chart.title"
      size="lg"
      @close="isModalOpen = false"
    >
      <apexchart
        :id="`${chartId}-modal`"
        height="600px"
        width="100%"
        :type="chart.type"
        :options="options"
        :series="chart.series"
      />
    </BaseModal>
  </div>
</template>
