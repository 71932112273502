<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { TrendingUp } from 'lucide-vue-next';

const features = [
  'Entrenador personal online (kinesiólogo)',
  'Nutricionista online',
  'Mediciones de composición corporal a domicilio',
  'Accountability y seguimiento constante',
  'Plan de entrenamiento y nutrición',
  'Chat y videollamadas con tu equipo',
];

const visibleFeatures = ref<number[]>([]);
const showDescription = ref(false);
const showLogo = ref(false);

// Compute visible features array to avoid v-if with v-for
const visibleFeaturesList = computed(() =>
  features.filter((_, index) => visibleFeatures.value.includes(index)),
);

onMounted(() => {
  // Show logo first (after 1 second)
  setTimeout(() => {
    showLogo.value = true;
  }, 1000);

  // Show description after logo (after 2.5 seconds)
  setTimeout(() => {
    showDescription.value = true;
  }, 2500);

  // Show features one by one (start after 4 seconds, then 1.5 seconds between each feature)
  features.forEach((_, index) => {
    setTimeout(() => {
      visibleFeatures.value = [...visibleFeatures.value, index];
    }, 4000 + (index * 1500)); // 1.5 seconds between each feature
  });
});
</script>

<template>
  <div class="flex flex-col items-center space-y-8 py-12">
    <!-- Logo -->
    <Transition
      enter-active-class="transition duration-700 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
    >
      <img
        v-if="showLogo"
        :src="`/images/kapso-logo-light.png`"
        alt="Kapso Logo"
        class="h-16"
      >
    </Transition>

    <!-- Description -->
    <Transition
      enter-active-class="transition duration-700 ease-out"
      enter-from-class="opacity-0 translate-y-4"
      enter-to-class="opacity-100 translate-y-0"
    >
      <div
        v-if="showDescription"
        class="max-w-2xl text-center"
      >
        <p class="text-lg text-gray-600">
          Con Kapso puedes tener un equipo de profesionales que te ayudan a mejorar tu composición corporal.
        </p>
      </div>
    </Transition>

    <!-- Features List -->
    <div class="w-full max-w-2xl space-y-4">
      <TransitionGroup
        enter-active-class="transition duration-500 ease-out"
        enter-from-class="opacity-0 -translate-x-4"
        enter-to-class="opacity-100 translate-x-0"
      >
        <div
          v-for="(feature, index) in visibleFeaturesList"
          :key="index"
          class="flex items-start"
        >
          <TrendingUp class="mr-3 size-6 text-blue-500" />
          <span class="text-gray-600">{{ feature }}</span>
        </div>
      </TransitionGroup>
    </div>

    <!-- Loading indicator at the bottom -->
    <div class="mt-8 flex items-center space-x-3">
      <div class="size-4 animate-spin rounded-full border-2 border-gray-300 border-t-blue-500" />
      <span class="text-sm text-gray-500">Calculando tus resultados...</span>
    </div>
  </div>
</template>
