<script setup lang="ts">
import { streamText, tool } from 'ai';
import MarkdownIt from 'markdown-it';
import hljs from 'highlight.js';
import algoliasearch from 'algoliasearch/lite';
import { z } from 'zod';
import { createOpenAI } from '@ai-sdk/openai';
import { ref, nextTick } from 'vue';
import { Send, Bot, User, Check, XCircle } from 'lucide-vue-next';
import { traineesApi } from '@/api/trainees';
import { routinesApi } from '@/api/routines';
import { nutritionPlansApi } from '@/api/nutrition-plans';
import { exerciseExecutionsApi } from '@/api/exercise-executions';
import type { Exercise, Trainee, Routine } from '@/types/extended';
import type { NutritionPlan } from '@/types/';
import type { ExerciseExecution } from '@/types/exercise-execution';
import ExerciseCard from './exercise-card.vue';
import TraineeCard from './trainee-card.vue';
import ChartDisplay from './chart-display.vue';


const props = defineProps<{
  muscleGroups: string[],
  openaiApiKey: string,
}>();

const openai = createOpenAI({ apiKey: props.openaiApiKey });

// Add type for messages
interface Message {
  role: 'user' | 'assistant' | 'system';
  content: string;
}

const messages = ref<Array<{ role: 'user' | 'assistant', content: string }>>([]);
const newMessage = ref('');
const isThinking = ref(false);
const steps = ref<Array<{
  text: string;
  toolCalls: { name: string }[];
  toolResults: unknown[];
}>>([]);
const chatContainer = ref<HTMLElement | null>(null);
const currentStreamingText = ref('');
const selectedExercises = ref<Exercise[]>([]);
const selectedTrainees = ref<Trainee[]>([]);
const selectedRoutines = ref<Routine[]>([]);
const selectedNutritionPlans = ref<NutritionPlan[]>([]);
const selectedExerciseExecutions = ref<ExerciseExecution[]>([]);

// Add new ref for tool history
const toolHistory = ref<Array<{
  toolName: string;
  status: 'success' | 'error';
  timestamp: Date;
  messageIndex: number;
  results?: unknown;
  params?: any;
}>>([]);

// Initialize with a system message to set context
const conversationHistory = ref<Message[]>([
  {
    role: 'system',
    content: `You are an assistant called Kap that helps trainers find and understand exercises, and look up information about trainees, their routines, nutrition plans, chat messages with trainer and nutritionist, and more.
    You can search for exercises and provide detailed information about them.
    You can also search for trainees and get detailed information about specific ones, including their workout routines.
    Communicate in Spanish.
    Current date: ${new Date().toLocaleDateString('es-ES', { dateStyle: 'full' })}.

Available muscle groups are:
${props.muscleGroups.map(group => `- ${group}`).join('\n')}

When searching for exercises by muscle group, use these exact names to ensure accurate results.
When searching for trainees and searching by first name or last name, and any of those can have accents, search with both accent and without accent to ensure accurate results.

For trainees:
1. First use searchTrainees to find trainees by name, email, or country
2. Then use getTraineeDetails to get detailed information about specific trainees
3. Use getTraineeRoutines to see their workout routines
4. The detailed information will be shown in cards on the right side of the screen

Important notes about chat messages:
1. When showing trainee chat messages, by default you only show the last 2 months
2. Always mention this default timeframe when showing chat messages
3. Inform users they can request different date ranges by specifying startDate and endDate
4. When users ask about older messages, guide them to use the date parameters

Example responses:
- "Aquí están los mensajes de los últimos 2 meses. Si necesitas ver mensajes más antiguos, puedo buscar en un rango de fechas específico."
- "Veo que quieres mensajes más antiguos. ¿De qué período específico te gustaría verlos?"`,
  },
]);

// Define the exercise search parameters schema based on your model attributes
const ExerciseSearchParamsSchema = z.object({
  query: z.string().optional().describe('Search exercises by name or description'),
  muscleGroups: z.array(z.string()).optional()
    .describe('Filter by muscle groups'),
  equipment: z.array(z.string()).optional()
    .describe('Filter by equipment'),
  kind: z.array(z.string()).optional()
    .describe('Filter by exercise type'),
  exerciseId: z.array(z.string()).optional()
    .describe('Filter by exercise ID'),
  movementPattern: z.array(z.string()).optional()
    .describe('Filter by movement pattern'),
});

// Add after ExerciseSearchParams schema
const TraineeSearchParamsSchema = z.object({
  emailCont: z.string().optional().describe('Search trainees by email (contains)'),
  firstNameCont: z.string().optional().describe('Search trainees by first name (contains)'),
  lastNameCont: z.string().optional().describe('Search trainees by last name (contains)'),
  countryEq: z.string().optional().describe('Filter by country code'),
});

// Add after TraineeSearchParamsSchema
const TraineeIdSchema = z.object({
  id: z.number().describe('The ID of the trainee to get details for'),
  startDate: z.string().optional().describe('Start date for chat messages (ISO format). If not provided, defaults to 2 months ago'),
  endDate: z.string().optional().describe('End date for chat messages (ISO format). If not provided, defaults to today'),
});

// Update TraineeRoutinesSchema to include optional date range
const TraineeRoutinesSchema = z.object({
  traineeId: z.number().describe('The ID of the trainee to get routines for'),
  startDate: z.string().optional().describe('Start date for routines (ISO format). If not provided, defaults to 2 weeks ago'),
  endDate: z.string().optional().describe('End date for routines (ISO format). If not provided, defaults to today'),
});

// Add after TraineeRoutinesSchema
const RoutineIdSchema = z.object({
  id: z.number().describe('The ID of the routine to get details for'),
});

// Add after other tool schemas
const NutritionPlanIdSchema = z.object({
  id: z.number().describe('The ID of the nutrition plan to get details for'),
});

const TraineeNutritionPlansSchema = z.object({
  traineeId: z.number().describe('The ID of the trainee to get nutrition plans for'),
});

// Add after other tool schemas
const ExerciseExecutionsSchema = z.object({
  traineeId: z.number().describe('The ID of the trainee to get exercise executions for'),
  exerciseIds: z.array(z.number()).optional().describe('Optional list of exercise IDs to filter by'),
  count: z.number().optional().describe('Number of executions to return (default: 12)'),
  startDate: z.string().optional().describe('Start date for executions (ISO format)'),
  endDate: z.string().optional().describe('End date for executions (ISO format)'),
});

// Add chart ref and type
interface ChartData {
  type: 'line' | 'bar' | 'scatter' | 'pie';
  title: string;
  series: Array<{
    name: string;
    data: number[];
  }>;
  xaxis: {
    categories: string[];
  };
  yaxis?: {
    min?: number;
    max?: number;
    title?: string;
  };
  annotations?: {
    yaxis?: Array<{
      y: number;
      y2?: number;
      fillColor: string;
      opacity: number;
      borderWidth: number;
    }>;
  };
}

const currentChart = ref<ChartData | null>(null);
const chartKey = ref(0);

// Add plotChart tool
const tools = {
  searchExercises: tool({
    description: `Busca ejercicios en la base de datos usando varios filtros.

PARAMS:
- query: Busca por nombre o descripción
- muscleGroups: Lista de grupos musculares para filtrar
- equipment: Lista de equipamiento para filtrar
- kind: Lista de tipos de ejercicio para filtrar
- exerciseId: Lista de IDs de ejercicios
- movementPattern: Lista de patrones de movimiento

RETORNA:
- id
- name: Nombre del ejercicio
- difficulty: Nivel de dificultad
- kind: Tipo de ejercicio
- muscleGroups: Lista de grupos musculares trabajados
- equipment: Lista de equipamiento necesario
- movementPattern: Patrón de movimiento asociado
- femalePosterUrl: URL de la imagen de demostración
- femaleVideoUrl: URL del video de demostración
- exerciseInstructions: Lista de instrucciones paso a paso`,
    parameters: ExerciseSearchParamsSchema,
    execute: async (params) => {
      isThinking.value = true;
      try {
        const index = searchClient.initIndex(algoliaIndex);

        // Build Algolia search params
        const searchParams: any = {
          facetFilters: [],
        };

        if (params.muscleGroups?.length) {
          searchParams.facetFilters.push(params.muscleGroups.map(g => `muscle_groups:${g}`));
        }
        if (params.equipment?.length) {
          searchParams.facetFilters.push(params.equipment.map(e => `equipment:${e}`));
        }
        if (params.kind?.length) {
          searchParams.facetFilters.push(params.kind.map(k => `kind:${k}`));
        }
        if (params.exerciseId?.length) {
          searchParams.facetFilters.push(params.exerciseId.map(id => `exercise_id:${id}`));
        }
        if (params.movementPattern?.length) {
          searchParams.facetFilters.push(params.movementPattern.map(p => `movement_pattern:${p}`));
        }

        const results = await index.search(params.query || '', searchParams);

        // Transform results to match our Exercise interface
        const exercises = results.hits.map(hit => ({
          id: hit.exercise_id,
          name: hit.name,
          difficulty: hit.difficulty,
          kind: hit.kind,
          femalePosterUrl: hit.female_poster_url,
          malePosterUrl: hit.male_poster_url,
          muscleGroups: Array.isArray(hit.muscle_groups)
            ? hit.muscle_groups.map((name: string) => ({ name }))
            : hit.muscle_groups ? [{ name: hit.muscle_groups }] : [],
          equipment: Array.isArray(hit.equipment)
            ? hit.equipment.map((name: string) => ({ name }))
            : hit.equipment ? [{ name: hit.equipment }] : [],
          movementPattern: hit.movement_pattern,
          exerciseInstructions: Array.isArray(hit.exercise_instructions)
            ? hit.exercise_instructions.map((desc: string, i: number) => ({
                description: desc,
                position: i + 1
              }))
            : []
        }));

        selectedExercises.value = exercises;
        return exercises;
      } catch (error) {
        console.error('Algolia search error:', error);
        throw new Error('Failed to search exercises');
      } finally {
        isThinking.value = false;
      }
    },
  }),

  searchTrainees: tool({
    description: `Busca alumnos en la base de datos. Retorna información básica. Usa getTraineeDetails para obtener más detalles.

PARÁMETROS:
- emailCont: Busca alumnos que contengan este texto en su email
- firstNameCont: Busca por nombre (búsqueda parcial)
- lastNameCont: Busca por apellido (búsqueda parcial)
- countryEq: Filtra por código de país exacto (ej: 'CL', 'MX', 'AR')

RETORNA:
- id
- email: Correo electrónico
- fullName: Nombre completo
- humanCountry: País en formato legible
- platform: Plataforma que usa ('android' o 'ios')
- equipment: Equipamiento disponible
- birthdate: Fecha de nacimiento
- longTermGoal: Objetivo a largo plazo
- shortTermGoal: Objetivo a corto plazo`,
    parameters: TraineeSearchParamsSchema,
    execute: async (params) => {
      isThinking.value = true;
      try {
        const trainees = await traineesApi.search(params);
        selectedTrainees.value = [];

        return trainees;
      } catch (error) {
        console.error('Search error:', error);
        throw new Error('Failed to fetch trainees');
      }
    },
  }),

  getTraineeDetails: tool({
    description: `Obtiene información detallada de un alumno específico por ID.

PARÁMETROS:
- id: ID del alumno
- startDate: (Opcional) Fecha inicial para mensajes de chat (formato ISO)
- endDate: (Opcional) Fecha final para mensajes de chat (formato ISO)

RETORNA (además de lo básico):
- workoutPlans: Planes de entrenamiento asignados
- routines: Rutinas asociadas a sus planes
- initialSurveySubmission: Respuestas de la encuesta inicial
- preMeetingSurvey: Respuestas de la encuesta pre-reunión
- followUpMeetingSurveys: Respuestas de las encuestas de seguimiento
- trainerChatGroup: Grupo de chat con el entrenador (por defecto muestra los últimos 2 meses, pero se puede especificar un rango de fechas diferente)`,
    parameters: TraineeIdSchema,
    execute: async (params) => {
      isThinking.value = true;
      try {
        const trainee = await traineesApi.get(params.id);

        if (trainee.trainerChatGroup?.chatMessages) {
          const endDate = params.endDate ? new Date(params.endDate) : new Date();
          const startDate = params.startDate ?
            new Date(params.startDate) :
            new Date(endDate.getTime() - (60 * 24 * 60 * 60 * 1000)); // 60 days ago

          trainee.trainerChatGroup.chatMessages = trainee.trainerChatGroup.chatMessages.filter(
            message => {
              const messageDate = new Date(message.createdAt);

              return messageDate >= startDate && messageDate <= endDate;
            },
          );

          // Add metadata about the date range to help the assistant communicate it
          trainee.trainerChatGroup.messagesPeriod = {
            start: startDate.toISOString(),
            end: endDate.toISOString(),
            isDefaultRange: !params.startDate && !params.endDate,
          };
        }

        selectedTrainees.value = [...selectedTrainees.value, trainee];

        return trainee;
      } catch (error) {
        console.error('Get trainee details error:', error);
        throw new Error('Failed to fetch trainee details');
      }
    },
  }),

  getTraineeRoutines: tool({
    description: `Obtiene las rutinas de entrenamiento de un alumno específico. Por defecto retorna las rutinas de las últimas dos semanas.

PARÁMETROS:
- traineeId: Identificador único del alumno
- startDate: (Opcional) Fecha inicial en formato ISO. Si no se especifica, usa 2 semanas atrás
- endDate: (Opcional) Fecha final en formato ISO. Si no se especifica, usa la fecha actual

RETORNA:
- id
- name: Nombre de la rutina
- scheduledAt: Fecha y hora programada
- isCompleted: Estado de completitud
- durationInMinutes: Duración en minutos
- equipmentNames: Lista de equipamiento necesario
- isPublished: Si la rutina está publicada
- isOptional: Si la rutina es opcional
- noteForTrainer: Notas para el entrenador
- noteForTrainee: Notas para el alumno`,
    parameters: TraineeRoutinesSchema,
    execute: async (params) => {
      isThinking.value = true;
      try {
        const trainee = await traineesApi.get(params.traineeId);
        if (!trainee.workoutPlans?.[0]?.id) {
          throw new Error('No workout plan found for trainee');
        }

        const workoutPlanId = trainee.workoutPlans[0].id;
        const endDate = params.endDate ? new Date(params.endDate) : new Date();
        const startDate = params.startDate ?
          new Date(params.startDate) :
          new Date(endDate.getTime() - (14 * 24 * 60 * 60 * 1000));

        const routines = await routinesApi.getAll({
          q: {
            'workout_plan_id_eq': workoutPlanId,
            'scheduled_at_gteq': startDate.toISOString(),
            'scheduled_at_lteq': endDate.toISOString(),
            's': ['scheduled_at desc'],
          },
        });

        selectedRoutines.value = routines;

        return routines;
      } catch (error) {
        console.error('Get trainee routines error:', error);
        throw new Error('Failed to fetch trainee routines');
      }
    },
  }),

  getRoutineDetails: tool({
    description: `Obtiene información detallada de una rutina específica por ID.

PARÁMETROS:
- id: Identificador único de la rutina

RETORNA:
- id
- name: Nombre de la rutina
- scheduledAt: Fecha y hora programada
- isCompleted: Estado de completitud
- durationInMinutes: Duración en minutos
- equipmentNames: Lista de equipamiento necesario
- isPublished: Si la rutina está publicada
- isOptional: Si la rutina es opcional
- noteForTrainer: Notas para el entrenador
- noteForTrainee: Notas para el alumno
- routinePhases: Fases de la rutina, cada una con:
  - name: Nombre de la fase
  - sets: Número de series
  - routinePhaseExercises: Ejercicios en la fase, cada uno con:
    - exercise: Detalles del ejercicio
    - duration: Duración específica
    - kind: Tipo de ejercicio en esta fase
    - rest: Tiempo de descanso
    - routineExerciseSets: Sets del ejercicio, cada uno con:
      - setNumber: Número de set
      - repetitions: Repeticiones
      - weight: Peso
      - comment: Comentarios específicos`,
    parameters: RoutineIdSchema,
    execute: async (params) => {
      isThinking.value = true;
      try {
        const routine = await routinesApi.get(params.id);
        selectedRoutines.value = [routine];

        return routine;
      } catch (error) {
        console.error('Get routine details error:', error);
        throw new Error('Failed to fetch routine details');
      }
    },
  }),

  getNutritionPlanDetails: tool({
    description: `Obtiene información detallada de un plan nutricional específico por ID.

PARÁMETROS:
- id: Identificador único del plan nutricional

RETORNA:
- id: Identificador único
- traineeId: ID del alumno
- nutritionistId: ID del nutricionista
- startAt: Fecha de inicio
- endAt: Fecha de finalización
- externalUrl: URL externa del plan (opcional)
- nutritionObjectives: Lista de objetivos nutricionales
- trainee: Detalles del alumno
- nutritionist: Detalles del nutricionista`,
    parameters: NutritionPlanIdSchema,
    execute: async (params) => {
      isThinking.value = true;
      try {
        const nutritionPlan = await nutritionPlansApi.get(params.id);
        selectedNutritionPlans.value = [nutritionPlan];

        return nutritionPlan;
      } catch (error) {
        console.error('Get nutrition plan details error:', error);
        throw new Error('Failed to fetch nutrition plan details');
      }
    },
  }),

  getTraineeNutritionPlans: tool({
    description: `Obtiene todos los planes nutricionales de un alumno específico.

PARÁMETROS:
- traineeId: Identificador único del alumno

RETORNA:
Lista de planes nutricionales, cada uno con:
- id: Identificador único
- traineeId: ID del alumno
- nutritionistId: ID del nutricionista
- startAt: Fecha de inicio
- endAt: Fecha de finalización
- externalUrl: URL externa del plan (opcional)
- nutritionObjectives: Lista de objetivos nutricionales
- trainee: Detalles del alumno
- nutritionist: Detalles del nutricionista`,
    parameters: TraineeNutritionPlansSchema,
    execute: async (params) => {
      isThinking.value = true;
      try {
        const nutritionPlans = await nutritionPlansApi.getAll();
        const traineePlans = nutritionPlans.filter(plan => plan.traineeId === params.traineeId);
        selectedNutritionPlans.value = traineePlans;

        return traineePlans;
      } catch (error) {
        console.error('Get trainee nutrition plans error:', error);
        throw new Error('Failed to fetch trainee nutrition plans');
      }
    },
  }),

  getExerciseExecutions: tool({
    description: `Obtiene el historial de ejecuciones de ejercicios de un alumno.
Por defecto retorna las últimas 12 ejecuciones.

PARÁMETROS:
- traineeId: ID del alumno
- exerciseIds: (Opcional) IDs de ejercicios para filtrar
- count: (Opcional) Número de ejecuciones (default: 12)
- startDate: (Opcional) Fecha inicial (ISO)
- endDate: (Opcional) Fecha final (ISO)

RETORNA:
Lista de ejecuciones ordenadas por fecha (recientes primero):
- exerciseName: Nombre del ejercicio
- executionDate: Fecha
- sets: Número de series
- totalRepetitions: Total repeticiones
- totalVolume: Volumen total
- maxWeight: Peso máximo
- estimatedOneRepetitionMax: 1RM estimado
- bestSetWeight/Repetitions: Mejor serie`,
    parameters: ExerciseExecutionsSchema,
    execute: async (params) => {
      isThinking.value = true;

      try {
        const executions = await exerciseExecutionsApi.getAll({
          traineeId: params.traineeId,
          exerciseIds: params.exerciseIds,
          count: params.count || 12, // Default to 12 if not specified
          startDate: params.startDate,
          endDate: params.endDate,
        });

        selectedExerciseExecutions.value = executions;

        // Add metadata to help the assistant communicate the limits
        return {
          executions,
          metadata: {
            count: params.count || 12,
            isDefaultLimit: !params.count && !params.startDate && !params.endDate,
            startDate: params.startDate,
            endDate: params.endDate,
          },
        };
      } catch (error) {
        console.error('Get exercise executions error:', error);
        throw new Error('Failed to fetch exercise executions');
      } finally {
        isThinking.value = false;
      }
    },
  }),

  plotChart: tool({
    description: `Genera gráficos usando ApexCharts. Nunca digas la URL de de la imagen.

PARÁMETROS:
- type: 'line', 'bar', 'scatter', 'pie'
- title: Título del gráfico
- series: Array de series, cada una con { name: string, data: number[] }
- xaxis: { categories: string[] }
- yaxis: (Opcional) { min?: number, max?: number, title?: string }
- annotations: (Opcional) Anotaciones para marcar rangos en el eje Y`,
    parameters: z.object({
      type: z.enum(['line', 'bar', 'scatter', 'pie']),
      title: z.string(),
      series: z.array(z.object({
        name: z.string(),
        data: z.array(z.number()),
      })),
      xaxis: z.object({
        categories: z.array(z.string()),
      }),
      yaxis: z.object({
        min: z.number().optional(),
        max: z.number().optional(),
        title: z.string().optional(),
      }).optional(),
      annotations: z.object({
        yaxis: z.array(z.object({
          y: z.number(),
          y2: z.number().optional(),
          fillColor: z.string(),
          opacity: z.number(),
          borderWidth: z.number(),
        })).optional(),
      }).optional(),
    }),
    execute: async (params) => {
      currentChart.value = JSON.parse(JSON.stringify(params));
      chartKey.value++;

      return { success: true };
    },
  }),
};

function scrollToBottom() {
  nextTick(() => {
    if (chatContainer.value) {
      chatContainer.value.scrollTop = chatContainer.value.scrollHeight;
    }
  });
}

// Add these helper functions before sendMessage
function addMessage(role: 'user' | 'assistant', content: string) {
  // Only add to messages for display
  messages.value.push({ role, content });

  // Only add to conversation history if it's a user message
  // Assistant messages are added after processing is complete
  if (role === 'user') {
    conversationHistory.value.push({ role, content });
  }
}

function addToolToHistory(toolCalls: any[], toolResults: any[]) {
  if (toolCalls.length > 0) {
    toolHistory.value.push({
      toolName: toolCalls[0].toolName,
      status: toolResults.length > 0 ? 'success' : 'error',
      timestamp: new Date(),
      messageIndex: messages.value.length - 1,
      results: toolResults[0],
      params: toolCalls[0].args,
    });
  }
}

async function processAssistantResponse(result: any) {
  let assistantResponse = '';
  for await (const textPart of result.textStream) {
    currentStreamingText.value += textPart;
    assistantResponse += textPart;
    if (messages.value.length > 0) {
      messages.value[messages.value.length - 1].content = currentStreamingText.value;
    }
    scrollToBottom();
  }

  return assistantResponse;
}

// Add this after the existing refs
const selectedModel = ref<'gpt-4o-mini' | 'gpt-4o'>('gpt-4o-mini');

// Update the sendMessage function to use the selected model
async function sendMessage() {
  if (!newMessage.value.trim()) return;

  const userMessage = newMessage.value;
  addMessage('user', userMessage);
  newMessage.value = '';
  scrollToBottom();

  messages.value.push({ role: 'assistant', content: '' });
  currentStreamingText.value = '';

  const result = await streamText({
    model: openai(selectedModel.value),
    messages: conversationHistory.value,
    maxSteps: 10,
    onStepFinish({ text: stepText, toolCalls, toolResults }) {
      isThinking.value = false;
      steps.value.push({
        text: stepText,
        toolCalls,
        toolResults,
      });
      addToolToHistory(toolCalls, toolResults);
      scrollToBottom();
    },
    tools,
  });

  const assistantResponse = await processAssistantResponse(result);
  // Add to conversation history after processing is complete
  conversationHistory.value.push({ role: 'assistant', content: assistantResponse });
  steps.value = [];
}

// Initialize markdown-it with configuration
const md = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true,
  breaks: true,
  highlight(str, lang) {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return `<pre class="hljs"><code>${
          hljs.highlight(str, { language: lang, ignoreIllegals: true }).value
        }</code></pre>`;
      } catch (__) {}
    }

    return `<pre class="hljs"><code>${md.utils.escapeHtml(str)}</code></pre>`;
  },
});

// Configure linkify-it options
md.linkify.set({
  fuzzyEmail: false,
});

// Update formatMarkdown function to use markdown-it
function formatMarkdown(text: string) {
  if (!text) return '';

  return md.render(text);
}

// Add after other const declarations
const searchClient = algoliasearch(
  'L3P44YCS6E',
  '635845362136cd45adea7d7d709a3410'
);
const algoliaIndex = `Exercise_${import.meta.env.MODE}`;
</script>

<template>
  <div class="flex h-screen">
    <!-- Chat Section -->
    <div class="flex flex-1 flex-col bg-white">
      <!-- Header -->
      <div class="border-b bg-white px-4 py-3">
        <div class="flex items-center gap-4">
          <select
            v-model="selectedModel"
            class="rounded-md border border-gray-300 bg-white px-3 py-1.5 text-sm shadow-sm focus:border-teal-500 focus:outline-none focus:ring-1 focus:ring-teal-500"
          >
            <option value="gpt-4o">
              GPT-4o
            </option>
            <option value="gpt-4o-mini">
              GPT-4o-mini
            </option>
          </select>
          <h1 class="text-xl font-semibold text-gray-800">
            Kap AI
          </h1>
        </div>
      </div>

      <!-- Chat Messages -->
      <div
        ref="chatContainer"
        class="flex-1 overflow-y-auto"
      >
        <div class="mx-auto max-w-3xl">
          <!-- Welcome Message -->
          <template v-if="messages.length === 0">
            <div class="px-4 py-20 text-center">
              <h2 class="mb-3 text-4xl font-bold text-gray-800">
                Kap AI
              </h2>
              <p class="text-gray-600">
                Pregúntame sobre trainees, ejercicios, historial de ejercicios y entrenamientos, mensajes de chat y más. Puedo hacer gráficos!
              </p>
            </div>
          </template>

          <!-- Messages -->
          <div
            v-for="(message, index) in messages"
            :key="index"
            class="group w-full transition-all duration-200"
            :class="message.role === 'assistant' ? 'bg-gray-50' : 'bg-white'"
          >
            <div class="mx-auto max-w-3xl px-4 py-6">
              <div class="flex gap-6">
                <!-- Avatar -->
                <div
                  class="size-8 shrink-0 rounded-sm"
                  :class="message.role === 'assistant' ? 'bg-teal-500' : 'bg-blue-500'"
                >
                  <span class="flex h-full items-center justify-center text-white">
                    <Bot
                      v-if="message.role === 'assistant'"
                      class="size-5"
                    />
                    <User
                      v-else
                      class="size-5"
                    />
                  </span>
                </div>

                <!-- Message Content -->
                <div class="min-w-0 flex-1">
                  <div
                    class="prose prose-sm max-w-none text-gray-800"
                    :class="{
                      'border-r-2 border-gray-600':
                        message.role === 'assistant' &&
                        index === messages.length - 1 &&
                        message.content !== currentStreamingText
                    }"
                    v-html="formatMarkdown(message.content)"
                  />

                  <!-- Tool Calls History -->
                  <div
                    v-if="message.role === 'assistant' && toolHistory.filter(t => t.messageIndex === index).length > 0"
                    class="mt-4 space-y-2"
                  >
                    <div
                      v-for="(action, actionIndex) in toolHistory.filter(t => t.messageIndex === index)"
                      :key="actionIndex"
                      class="flex flex-col gap-2 rounded border border-gray-200 bg-white p-2 text-sm"
                    >
                      <div class="flex items-center gap-2">
                        <span
                          class="flex size-5 shrink-0 items-center justify-center rounded-full"
                          :class="action.status === 'success' ? 'bg-green-100' : 'bg-red-100'"
                        >
                          <Check
                            v-if="action.status === 'success'"
                            class="size-4 text-green-600"
                          />
                          <XCircle
                            v-else
                            class="size-4 text-red-600"
                          />
                        </span>
                        <div class="min-w-0 flex-1">
                          <p class="font-medium text-gray-900">
                            {{
                              action.toolName === 'searchExercises' ? 'Búsqueda de ejercicios' :
                              action.toolName === 'searchTrainees' ? 'Búsqueda de alumnos' :
                              action.toolName === 'getTraineeDetails' ? 'Detalles de alumno' :
                              action.toolName === 'getTraineeRoutines' ? 'Rutinas de alumno' :
                              action.toolName === 'getRoutineDetails' ? 'Detalles de rutina' :
                              action.toolName === 'getNutritionPlanDetails' ? 'Detalles del plan nutricional' :
                              action.toolName === 'getTraineeNutritionPlans' ? 'Planes nutricionales de alumno' :
                              action.toolName === 'getExerciseExecutions' ? 'Historial de ejercicios' :
                              action.toolName === 'plotChart' ? 'Gráfico' :
                              'Acción'
                            }}
                          </p>
                          <p class="text-xs text-gray-500">
                            {{ new Date(action.timestamp).toLocaleTimeString('es-ES') }}
                          </p>
                        </div>
                      </div>
                      <!-- Parameters Used -->
                      <div
                        v-if="action.params"
                        class="mt-1 rounded bg-gray-50 p-2 font-mono text-xs"
                      >
                        <div
                          v-for="(value, key) in action.params"
                          :key="key"
                          class="text-gray-600"
                        >
                          <span class="font-medium text-gray-700">{{ key }}:</span>
                          {{ typeof value === 'object' ? JSON.stringify(value) : value }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Thinking Indicator -->
          <div
            v-if="isThinking"
            class="w-full bg-gray-50 py-4"
          >
            <div class="mx-auto max-w-3xl px-4">
              <div class="flex items-center gap-2 pl-14 text-gray-500">
                <div class="size-2 animate-bounce rounded-full bg-gray-500" />
                <div class="size-2 animate-bounce rounded-full bg-gray-500 delay-150" />
                <div class="size-2 animate-bounce rounded-full bg-gray-500 delay-300" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Message Input -->
      <div class="border-t bg-white p-4">
        <div class="mx-auto max-w-3xl">
          <form
            class="relative flex gap-3"
            @submit.prevent="sendMessage"
          >
            <input
              v-model="newMessage"
              type="text"
              placeholder="Pregúntame lo que quieras, pero recuerda darme el suficiente contexto..."
              class="flex-1 rounded-lg border border-gray-200 px-4 py-3 text-gray-800 placeholder:text-gray-400 focus:border-teal-500 focus:outline-none focus:ring-1 focus:ring-teal-500"
              :disabled="isThinking"
            >
            <button
              type="submit"
              :disabled="!newMessage.trim() || isThinking"
              class="absolute right-2 top-1/2 -translate-y-1/2 rounded-lg bg-transparent p-1 text-gray-400 transition-colors hover:text-gray-600 disabled:text-gray-300"
            >
              <Send class="size-6" />
            </button>
          </form>
        </div>
      </div>
    </div>

    <!-- Side Panel -->
    <div
      v-if="selectedExercises.length > 0 || selectedTrainees.length > 0 || selectedRoutines.length > 0 || selectedNutritionPlans.length > 0 || currentChart"
      class="w-96 overflow-y-auto border-l border-gray-200 bg-gray-50 p-4"
    >
      <div class="space-y-4">
        <!-- Chart -->
        <template v-if="currentChart">
          <h3 class="mb-3 font-medium text-gray-900">
            {{ currentChart.title }}
          </h3>
          <ChartDisplay
            :key="chartKey"
            :chart="currentChart"
          />
        </template>

        <!-- Exercise Cards -->
        <template v-if="selectedExercises.length > 0">
          <h3 class="mb-3 font-medium text-gray-900">
            Ejercicios encontrados
          </h3>
          <ExerciseCard
            v-for="exercise in selectedExercises"
            :key="exercise.id"
            :exercise="exercise"
          />
        </template>

        <!-- Trainee Cards -->
        <template v-if="selectedTrainees.length > 0">
          <h3 class="mb-3 font-medium text-gray-900">
            Trainees encontrados
          </h3>
          <TraineeCard
            v-for="trainee in selectedTrainees"
            :key="trainee.id"
            :trainee="trainee"
          />
        </template>

        <!-- Routine Cards -->
        <template v-if="selectedRoutines.length > 0">
          <h3 class="mb-3 font-medium text-gray-900">
            Rutinas encontradas
          </h3>
          <div
            v-for="routine in selectedRoutines"
            :key="routine.id"
            class="mb-4 overflow-hidden rounded-lg border border-gray-200 bg-white p-4 shadow"
          >
            <div class="mb-2">
              <h4 class="text-lg font-semibold text-gray-900">
                {{ routine.name }}
              </h4>
              <p class="text-sm text-gray-600">
                {{ new Date(routine.scheduledAt).toLocaleDateString('es-ES', { dateStyle: 'full' }) }}
              </p>
            </div>
            <div class="space-y-2">
              <p class="text-sm text-gray-600">
                <span class="font-medium">Duración:</span> {{ routine.durationInMinutes }} minutos
              </p>
              <p class="text-sm text-gray-600">
                <span class="font-medium">Estado:</span>
                <span :class="routine.isCompleted ? 'text-green-600' : 'text-yellow-600'">
                  {{ routine.isCompleted ? 'Completada' : 'Pendiente' }}
                </span>
              </p>
              <div v-if="routine.equipmentNames">
                <p class="text-sm font-medium text-gray-900">
                  Equipamiento:
                </p>
                <p class="text-sm text-gray-600">
                  {{ Array.isArray(routine.equipmentNames) ? routine.equipmentNames.join(', ') : routine.equipmentNames }}
                </p>
              </div>
            </div>
          </div>
        </template>

        <!-- Nutrition Plan Cards -->
        <template v-if="selectedNutritionPlans.length > 0">
          <h3 class="mb-3 font-medium text-gray-900">
            Planes nutricionales encontrados
          </h3>
          <div
            v-for="plan in selectedNutritionPlans"
            :key="plan.id"
            class="mb-4 overflow-hidden rounded-lg border border-gray-200 bg-white p-4 shadow"
          >
            <div class="mb-2">
              <h4 class="text-lg font-semibold text-gray-900">
                Plan Nutricional
              </h4>
              <p class="text-sm text-gray-600">
                {{ new Date(plan.startAt).toLocaleDateString('es-ES', { dateStyle: 'full' }) }} -
                {{ new Date(plan.endAt).toLocaleDateString('es-ES', { dateStyle: 'full' }) }}
              </p>
            </div>
            <div class="space-y-2">
              <div v-if="plan.nutritionObjectives && plan.nutritionObjectives.length > 0">
                <p class="text-sm font-medium text-gray-900">
                  Objetivos nutricionales:
                </p>
                <ul class="mt-1 list-inside list-disc text-sm text-gray-600">
                  <li
                    v-for="objective in plan.nutritionObjectives"
                    :key="objective.id"
                  >
                    {{ objective.description }}
                  </li>
                </ul>
              </div>
              <div
                v-if="plan.externalUrl"
                class="mt-2"
              >
                <a
                  :href="plan.externalUrl"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-sm text-teal-600 hover:text-teal-700"
                >
                  Ver plan detallado
                </a>
              </div>
            </div>
          </div>
        </template>

        <!-- Exercise Executions -->
        <template v-if="selectedExerciseExecutions.length > 0">
          <h3 class="mb-3 font-medium text-gray-900">
            Historial de ejercicios
          </h3>
          <div class="space-y-3">
            <div
              v-for="execution in selectedExerciseExecutions"
              :key="execution.id"
              class="overflow-hidden rounded-lg border border-gray-200 bg-white shadow-sm transition-all hover:shadow-md"
            >
              <!-- Header -->
              <div class="border-b border-gray-100 bg-gray-50 px-4 py-3">
                <div class="flex items-center justify-between">
                  <h4 class="font-medium text-gray-900">
                    {{ execution.exerciseName }}
                  </h4>
                  <time
                    :datetime="execution.executionDate"
                    class="text-sm text-gray-500"
                  >
                    {{ new Date(execution.executionDate).toLocaleDateString('es-ES', {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric'
                    }) }}
                  </time>
                </div>
              </div>

              <!-- Content -->
              <div class="p-4">
                <div class="grid grid-cols-2 gap-4">
                  <!-- Main Stats -->
                  <div class="space-y-2">
                    <div class="flex items-baseline justify-between">
                      <span class="text-sm font-medium text-gray-500">Series</span>
                      <span class="font-medium text-gray-900">{{ execution.sets }}</span>
                    </div>
                    <div class="flex items-baseline justify-between">
                      <span class="text-sm font-medium text-gray-500">Total reps</span>
                      <span class="font-medium text-gray-900">{{ execution.totalRepetitions }}</span>
                    </div>
                    <div class="flex items-baseline justify-between">
                      <span class="text-sm font-medium text-gray-500">Volumen</span>
                      <span class="font-medium text-gray-900">{{ execution.totalVolume?.toFixed(1) }} kg</span>
                    </div>
                  </div>

                  <!-- Performance Stats -->
                  <div class="space-y-2">
                    <div class="flex items-baseline justify-between">
                      <span class="text-sm font-medium text-gray-500">Mejor serie</span>
                      <span class="font-medium text-gray-900">
                        {{ execution.bestSetWeight?.toFixed(1) }} kg × {{ execution.bestSetRepetitions }}
                      </span>
                    </div>
                    <div class="flex items-baseline justify-between">
                      <span class="text-sm font-medium text-gray-500">Peso máx</span>
                      <span class="font-medium text-gray-900">{{ execution.maxWeight?.toFixed(1) }} kg</span>
                    </div>
                    <div class="flex items-baseline justify-between">
                      <span class="text-sm font-medium text-gray-500">1RM est.</span>
                      <span class="font-medium text-gray-900">{{ execution.estimatedOneRepetitionMax?.toFixed(1) }} kg</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style>
/* Base markdown styles */
.prose {
  @apply text-gray-900 leading-normal;
}

.prose h1 {
  @apply text-2xl font-bold mb-4 mt-6;
}

.prose h2 {
  @apply text-xl font-bold mb-3 mt-5;
}

.prose h3 {
  @apply text-lg font-semibold mb-2 mt-4;
}

.prose p {
  @apply mb-4;
}

.prose ul {
  @apply list-disc list-inside mb-4;
}

.prose ol {
  @apply list-decimal list-inside mb-4;
}

.prose li {
  @apply mb-1;
}

.prose code:not(pre code) {
  @apply bg-gray-100 px-1.5 py-0.5 rounded text-sm font-mono text-gray-800;
}

.prose pre {
  @apply mb-4 rounded-lg overflow-x-auto;
}

.prose pre code {
  @apply block p-4 text-sm;
}

.prose a {
  @apply text-teal-600 hover:text-teal-700 underline;
}

.prose blockquote {
  @apply border-l-4 border-gray-200 pl-4 italic my-4;
}

.prose table {
  @apply w-full mb-4 border-collapse;
}

.prose table th {
  @apply bg-gray-50 border border-gray-200 px-4 py-2 text-left font-semibold;
}

.prose table td {
  @apply border border-gray-200 px-4 py-2;
}

/* Highlight.js theme customization */
.hljs {
  @apply bg-gray-50;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-title,
.hljs-section,
.hljs-doctag,
.hljs-name,
.hljs-strong {
  @apply font-semibold;
}

.hljs-comment {
  @apply text-gray-500;
}

.hljs-string,
.hljs-title,
.hljs-section,
.hljs-built_in,
.hljs-literal,
.hljs-type,
.hljs-addition,
.hljs-tag,
.hljs-quote,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class {
  @apply text-teal-600;
}

.hljs-meta,
.hljs-subst,
.hljs-symbol,
.hljs-regexp,
.hljs-attribute,
.hljs-deletion,
.hljs-variable,
.hljs-template-variable,
.hljs-link,
.hljs-bullet {
  @apply text-purple-600;
}

.hljs-emphasis {
  @apply italic;
}
</style>
