<script setup lang="ts">
import { ref, computed } from 'vue';
import { useForm } from 'vee-validate';
import { format } from 'date-fns';
import { toTypedSchema } from '@vee-validate/yup';
import * as yup from 'yup';
import { useMutation } from '@tanstack/vue-query';
import { AxiosError } from 'axios';
import type { Trainee } from '@/types/extended';
import type { TraineeAttributes } from '@/types';
import { traineesApi } from '@/api';
import { BaseInput, BaseButton, BaseLabel, BaseSelect } from '@/components';
import { useBeforeUnloadWarning } from '@/composables/before-unload-warning';
import BackButton from './back-button.vue';

interface Props {
  trainee: Trainee;
}

const props = defineProps<Props>();

const traineeSchema = toTypedSchema(
  yup.object().shape({
    equipment: yup.string().nullable(),
    shortTermGoal: yup.string().nullable(),
    longTermGoal: yup.string().nullable(),
    platform: yup.string().oneOf(['android', 'ios']).nullable(),
    birthdate: yup.string().nullable(),
    sex: yup.string().oneOf(['male', 'female']).nullable(),
  }),
);

function getInitialValues(traineeAttributes: TraineeAttributes) {
  return {
    equipment: traineeAttributes.equipment,
    shortTermGoal: traineeAttributes.shortTermGoal,
    longTermGoal: traineeAttributes.longTermGoal,
    platform: traineeAttributes.platform,
    birthdate: traineeAttributes.birthdate ? format(new Date(traineeAttributes.birthdate), "yyyy-MM-dd'T'HH:mm") : null,
    sex: traineeAttributes.sex,
  };
}

const initialValues = ref<Partial<TraineeAttributes>>(getInitialValues(props.trainee));

const { handleSubmit, values, resetForm } = useForm({
  validationSchema: traineeSchema,
  initialValues: initialValues.value,
  keepValuesOnUnmount: true,
});

const errorMessage = ref<string | null>(null);

const RESET_MUTATION_TIMEOUT = 1000;

const { mutate, isIdle, isPending, isError, isSuccess, reset: resetMutation } = useMutation({
  mutationFn: (attributes: Partial<TraineeAttributes>) =>
    traineesApi.update(props.trainee.id, attributes),
  onSuccess: (trainee: Trainee) => {
    const newTraineeAttributes = getInitialValues(trainee);
    resetForm({ values: newTraineeAttributes });
    errorMessage.value = null;
    initialValues.value = newTraineeAttributes;
    setTimeout(() => {
      resetMutation();
    }, RESET_MUTATION_TIMEOUT);
  },
  onError: (error) => {
    if (error instanceof AxiosError && error.response?.data?.errors) {
      errorMessage.value = error.response.data.errors.join(', ');
    } else if (error instanceof AxiosError && error.response?.data.detail) {
      errorMessage.value = error.response.data.detail;
    } else {
      errorMessage.value = 'hubo un error';
    }
  },
});

const onSubmit = handleSubmit((data: Partial<TraineeAttributes>) => {
  mutate(data);
});

const isFormChanged = computed(() => JSON.stringify(values) !== JSON.stringify(initialValues.value));

const buttonText = computed(() => {
  if (!isFormChanged.value) return 'Sin cambios';
  if (isIdle.value) return 'Guardar';
  if (isPending.value) return 'Guardando...';
  if (isError.value) return 'Error';
  if (isSuccess.value) return 'Guardado';

  return 'Guardar';
});

const buttonClass = computed(() => {
  if (!isFormChanged.value) return 'bg-gray-300 text-gray-500';
  if (isIdle.value) return 'bg-black text-white hover:bg-gray-800';
  if (isPending.value) return 'bg-black text-white cursor-not-allowed';
  if (isError.value) return 'bg-red-500 text-white';
  if (isSuccess.value) return 'bg-green-500 text-white hover:bg-green-600';

  return 'bg-black text-white hover:bg-gray-800';
});

const isSubmitDisabled = computed(() => !isFormChanged.value || isPending.value);

useBeforeUnloadWarning(isFormChanged);
</script>

<template>
  <div class="flex flex-col gap-6">
    <BackButton :href="`/trainees?traineeId=${trainee.id}`" />
    <h1 class="text-2xl font-bold">
      Editar trainee - {{ trainee.fullName }}
    </h1>
    <form
      v-if="isIdle"
      class="flex flex-col gap-4"
      @submit="onSubmit"
    >
      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Plataforma"
          name="platform"
        />
        <BaseSelect
          v-model="values.platform"
          name="platform"
          :options="[{ name: 'Android', id: 'android' }, { name: 'iOS', id: 'ios' }]"
        />
      </div>

      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Fecha de nacimiento"
          name="birthdate"
        />
        <BaseInput
          v-model="values.birthdate"
          name="birthdate"
          type="datetime-local"
        />
      </div>

      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Sexo"
          name="sex"
        />
        <BaseSelect
          v-model="values.sex"
          name="sex"
          :options="[{ name: 'Masculino', id: 'male' }, { name: 'Femenino', id: 'female' }]"
        />
      </div>

      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Equipamiento"
          name="equipment"
        />
        <BaseInput
          id="equipment"
          v-model="values.equipment"
          name="equipment"
          type="text"
          placeholder="Equipamiento del trainee"
        />
      </div>

      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Objetivo a corto plazo"
          name="shortTermGoal"
        />
        <BaseInput
          id="shortTermGoal"
          v-model="values.shortTermGoal"
          name="shortTermGoal"
          type="text"
          placeholder="Objetivo a corto plazo del trainee"
        />
      </div>

      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Objetivo a largo plazo"
          name="longTermGoal"
        />
        <BaseInput
          id="longTermGoal"
          v-model="values.longTermGoal"
          name="longTermGoal"
          type="text"
          placeholder="Objetivo a largo plazo del trainee"
        />
      </div>

      <div class="flex justify-end">
        <BaseButton
          type="button"
          :class="buttonClass"
          :disabled="isSubmitDisabled"
          @click="onSubmit"
        >
          {{ buttonText }}
        </BaseButton>
      </div>

      <p
        v-if="errorMessage"
        class="text-sm text-red-500"
      >
        {{ errorMessage }}
      </p>
    </form>

    <div v-if="isPending">
      <p>Actualizando trainee...</p>
    </div>

    <div
      v-else-if="isError"
      class="flex flex-col gap-6"
    >
      <p>
        Error: {{ errorMessage }}
      </p>
      <BaseButton
        variant="secondary"
        @click="resetMutation"
      >
        Volver a intentar
      </BaseButton>
    </div>

    <div v-else-if="isSuccess">
      <p>Trainee actualizado con éxito</p>
    </div>
  </div>
</template>
