<script setup lang="ts">
import { computed } from 'vue';
import { ArrowRight } from 'lucide-vue-next';
import { format } from 'date-fns';
import { useQuery } from '@tanstack/vue-query';
import { nutritionPlansApi } from '@/api';
import { BaseLink } from '@/components';

const props = defineProps<{
  traineeId: number;
}>();

const nutritionPlanQueryParams = computed(() => ({
  q: {
    'trainee_id_eq': props.traineeId,
    s: ['start_at desc'],
  },
}));

const { data: nutritionPlans } = useQuery({
  queryKey: ['trainee-nutrition-plans', nutritionPlanQueryParams],
  queryFn: () => nutritionPlansApi.getAll(nutritionPlanQueryParams.value),
});
</script>

<template>
  <div class="flex flex-col gap-y-4">
    <h2 class="text-lg font-semibold">
      Planes de nutrición
    </h2>
    <div class="overflow-hidden rounded-lg border border-gray-200 bg-white shadow">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th class="px-6 py-3 text-left text-sm font-semibold text-gray-900">
              Fecha de inicio
            </th>
            <th class="px-6 py-3 text-left text-sm font-semibold text-gray-900">
              Fecha de fin
            </th>
            <th class="relative px-6 py-3">
              <span class="sr-only">Ver</span>
            </th>
          </tr>
        </thead>
        <tbody
          v-if="nutritionPlans?.length"
          class="divide-y divide-gray-200"
        >
          <tr
            v-for="plan in nutritionPlans"
            :key="plan.id"
            class="hover:bg-gray-50"
          >
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
              {{ format(new Date(plan.startAt), 'dd/MM/yyyy') }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
              {{ format(new Date(plan.endAt), 'dd/MM/yyyy') }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-right text-sm">
              <BaseLink :href="`/nutrition_plans/${plan.id}`">
                Ver
                <ArrowRight class="ml-1 size-4" />
              </BaseLink>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              colspan="3"
              class="px-6 py-4 text-center text-sm text-gray-900"
            >
              No hay planes de nutrición
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
