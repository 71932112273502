<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { exercisesApi } from '@/api/exercises';
import type { Exercise } from '@/types/extended';
import ExerciseVideo from './exercise-video.vue';

interface MinimalExercise {
  id: number;
  name: string;
  difficulty: string;
  kind: string;
  femalePosterUrl?: string;
  malePosterUrl?: string;
  muscleGroups?: { name: string }[];
  equipment?: { name: string }[];
  movementPattern?: string;
  exerciseInstructions?: { description: string; position: number }[];
}

const props = defineProps<{
  exercise: MinimalExercise;
}>();

const fullExercise = ref<Exercise | null>(null);
const isLoading = ref(true);
const hasError = ref(false);

async function loadExerciseDetails() {
  try {
    isLoading.value = true;
    hasError.value = false;
    fullExercise.value = await exercisesApi.get(props.exercise.id);
  } catch (error) {
    console.error('Error loading exercise details:', error);
    hasError.value = true;
  } finally {
    isLoading.value = false;
  }
}

onMounted(() => {
  loadExerciseDetails();
});
</script>

<template>
  <div class="overflow-hidden rounded-lg border border-gray-200 bg-white shadow-sm">
    <!-- Loading State -->
    <div
      v-if="isLoading"
      class="flex h-48 items-center justify-center bg-gray-50"
    >
      <div class="text-sm text-gray-500">
        Cargando detalles...
      </div>
    </div>

    <!-- Error State -->
    <div
      v-else-if="hasError"
      class="flex h-48 items-center justify-center bg-red-50"
    >
      <div class="text-sm text-red-600">
        Error al cargar los detalles
      </div>
    </div>

    <!-- Exercise Content -->
    <template v-else>
      <!-- Exercise Image -->
      <div class="aspect-video bg-gray-100">
        <ExerciseVideo
          v-if="fullExercise"
          :exercise="fullExercise"
        />
      </div>

      <!-- Exercise Info -->
      <div class="p-4">
        <h3 class="font-medium text-gray-900">
          {{ fullExercise?.name || exercise.name }}
        </h3>

        <div class="mt-2 space-y-2 text-sm">
          <!-- Tags -->
          <div class="flex flex-wrap gap-1">
            <span class="rounded-full bg-blue-50 px-2 py-1 text-xs text-blue-600">
              {{ fullExercise?.difficulty || exercise.difficulty }}
            </span>
            <span class="rounded-full bg-purple-50 px-2 py-1 text-xs text-purple-600">
              {{ fullExercise?.kind || exercise.kind }}
            </span>
          </div>

          <!-- Muscle Groups -->
          <div
            v-if="fullExercise?.muscleGroups?.length || exercise.muscleGroups?.length"
            class="space-y-1"
          >
            <div class="text-xs font-medium text-gray-500">
              Grupos Musculares
            </div>
            <div class="flex flex-wrap gap-1">
              <span
                v-for="muscle in fullExercise?.muscleGroups || exercise.muscleGroups"
                :key="muscle.name"
                class="rounded-full bg-gray-100 px-2 py-1 text-xs text-gray-600"
              >
                {{ muscle.name }}
              </span>
            </div>
          </div>

          <!-- Equipment -->
          <div
            v-if="fullExercise?.equipment || exercise.equipment?.length"
            class="space-y-1"
          >
            <div class="text-xs font-medium text-gray-500">
              Equipamiento
            </div>
            <div class="flex flex-wrap gap-1">
              <span
                v-for="item in fullExercise?.equipment || exercise.equipment"
                :key="item.name"
                class="rounded-full bg-gray-100 px-2 py-1 text-xs text-gray-600"
              >
                {{ item.name }}
              </span>
            </div>
          </div>

          <!-- Movement Pattern -->
          <div
            v-if="fullExercise?.movementPattern?.name || exercise.movementPattern"
            class="space-y-1"
          >
            <div class="text-xs font-medium text-gray-500">
              Patrón de Movimiento
            </div>
            <div class="rounded-full bg-gray-100 px-2 py-1 text-xs text-gray-600">
              {{ fullExercise?.movementPattern?.name || exercise.movementPattern }}
            </div>
          </div>

          <!-- Instructions -->
          <div
            v-if="fullExercise?.exerciseInstructions?.length || exercise.exerciseInstructions?.length"
            class="space-y-1"
          >
            <div class="text-xs font-medium text-gray-500">
              Instrucciones
            </div>
            <ol class="ml-4 list-decimal text-xs text-gray-600">
              <li
                v-for="instruction in fullExercise?.exerciseInstructions || exercise.exerciseInstructions"
                :key="instruction.position"
              >
                {{ instruction.description }}
              </li>
            </ol>
          </div>

          <!-- Videos -->
          <div
            v-if="fullExercise?.femaleVideoUrl || fullExercise?.maleVideoUrl"
            class="space-y-1"
          >
            <div class="text-xs font-medium text-gray-500">
              Videos
            </div>
            <div class="flex gap-2">
              <a
                v-if="fullExercise.femaleVideoUrl"
                :href="fullExercise.femaleVideoUrl"
                target="_blank"
                rel="noopener noreferrer"
                class="text-xs text-teal-600 hover:text-teal-700"
              >
                Video (F)
              </a>
              <a
                v-if="fullExercise.maleVideoUrl"
                :href="fullExercise.maleVideoUrl"
                target="_blank"
                rel="noopener noreferrer"
                class="text-xs text-teal-600 hover:text-teal-700"
              >
                Video (M)
              </a>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
