export function useBodyComposition() {
  function probabilityToText(probability: number): string {
    if (probability >= 0.8) return 'Muy Alta';
    if (probability >= 0.6) return 'Alta';
    if (probability >= 0.4) return 'Moderada';
    if (probability >= 0.2) return 'Baja';

    return 'Muy Baja';
  }

  return {
    probabilityToText,
  };
}
