<script setup lang="ts">
import { computed } from 'vue';
import { isToday, isTomorrow, isAfter, endOfTomorrow, isBefore, isWithinInterval } from 'date-fns';
import type { CalBooking, Trainee, Measuree } from '@/types';
import CalBookingCard from './cal-booking-card.vue';

const props = defineProps<{
  bookings: CalBooking[]
  trainees: Trainee[]
  measurees: Measuree[]
}>();

function isBookingInProgress(booking: CalBooking): boolean {
  const now = new Date();

  return isWithinInterval(now, {
    start: new Date(booking.startTime),
    end: new Date(booking.endTime),
  });
}

function isBookingPast(booking: CalBooking): boolean {
  return isBefore(new Date(booking.endTime), new Date());
}

function getNextBooking(): CalBooking | undefined {
  return props.bookings
    .filter((booking: CalBooking) => {
      const startTime = new Date(booking.startTime);

      return isToday(startTime) && !isBookingInProgress(booking) && !isBookingPast(booking);
    })
    .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())[0];
}

function getTodayBookings(): {
  inProgress: CalBooking[],
  next: CalBooking | undefined,
  later: CalBooking[],
  past: CalBooking[]
  } {
  const todayBookings = props.bookings.filter((booking: CalBooking) =>
    isToday(new Date(booking.startTime)),
  );
  const nextBooking = getNextBooking();

  return {
    inProgress: todayBookings.filter(isBookingInProgress),
    next: nextBooking,
    later: todayBookings.filter((booking: CalBooking) =>
      !isBookingInProgress(booking) &&
      !isBookingPast(booking) &&
      booking.id !== nextBooking?.id,
    ),
    past: todayBookings.filter(isBookingPast),
  };
}

const nextBooking = computed(() => getNextBooking());

function getTomorrowBookings(): CalBooking[] {
  return props.bookings.filter((booking: CalBooking) => isTomorrow(new Date(booking.startTime)));
}

function getFutureBookings(): CalBooking[] {
  return props.bookings.filter((booking: CalBooking) => isAfter(new Date(booking.startTime), endOfTomorrow()));
}
</script>

<template>
  <div class="flex flex-col gap-8">
    <h1 class="text-3xl font-bold">
      Agenda
    </h1>

    <div class="flex flex-col gap-6">
      <h2 class="text-2xl font-bold">
        Hoy
      </h2>
      <div
        v-if="getTodayBookings().inProgress.length > 0 || getTodayBookings().next ||
          getTodayBookings().later.length > 0 || getTodayBookings().past.length > 0"
        class="grid grid-cols-1 gap-6"
      >
        <!-- Past Bookings -->
        <div
          v-if="getTodayBookings().past.length > 0"
          class="space-y-4"
        >
          <h3 class="text-lg font-semibold text-gray-500">
            Completadas hoy
          </h3>
          <CalBookingCard
            v-for="booking in getTodayBookings().past"
            :key="booking.id"
            :booking="booking"
            :trainees="trainees"
            :measurees="measurees"
            variant="muted"
          />
        </div>

        <!-- In Progress Bookings -->
        <div
          v-if="getTodayBookings().inProgress.length > 0"
          class="space-y-4"
        >
          <h3 class="text-lg font-semibold text-green-700">
            En progreso
          </h3>
          <CalBookingCard
            v-for="booking in getTodayBookings().inProgress"
            :key="booking.id"
            :booking="booking"
            :trainees="trainees"
            :measurees="measurees"
            :is-in-progress="true"
            variant="highlight"
          />
        </div>

        <!-- Next Booking -->
        <template v-if="nextBooking">
          <div class="space-y-4">
            <h3 class="text-lg font-semibold text-blue-700">
              Próxima cita
            </h3>
            <CalBookingCard
              :booking="nextBooking"
              :trainees="trainees"
              :measurees="measurees"
              :is-next="true"
              variant="highlight"
            />
          </div>
        </template>

        <!-- Later Today Bookings -->
        <div
          v-if="getTodayBookings().later.length > 0"
          class="space-y-4"
        >
          <h3 class="text-lg font-semibold text-gray-700">
            Más tarde hoy
          </h3>
          <CalBookingCard
            v-for="booking in getTodayBookings().later"
            :key="booking.id"
            :booking="booking"
            :trainees="trainees"
            :measurees="measurees"
          />
        </div>
      </div>
      <div
        v-else
        class="rounded-lg border border-gray-300 bg-white p-4 text-center text-sm text-gray-500"
      >
        No hay citas programadas para hoy
      </div>
    </div>

    <div class="flex flex-col gap-4">
      <h2 class="text-lg font-semibold">
        Mañana
      </h2>
      <div
        v-if="getTomorrowBookings().length > 0"
        class="grid grid-cols-1 gap-4"
      >
        <CalBookingCard
          v-for="booking in getTomorrowBookings()"
          :key="booking.id"
          :booking="booking"
          :trainees="trainees"
          :measurees="measurees"
        />
      </div>
      <div
        v-else
        class="rounded-lg border border-gray-300 bg-white p-4 text-center text-sm text-gray-500"
      >
        No hay citas programadas para mañana
      </div>
    </div>

    <div class="flex flex-col gap-4">
      <h2 class="text-lg font-semibold">
        Más adelante
      </h2>
      <div
        v-if="getFutureBookings().length > 0"
        class="grid grid-cols-1 gap-4"
      >
        <CalBookingCard
          v-for="booking in getFutureBookings()"
          :key="booking.id"
          :booking="booking"
          :trainees="trainees"
          :measurees="measurees"
        />
      </div>
      <div
        v-else
        class="rounded-lg border border-gray-300 bg-white p-4 text-center text-sm text-gray-500"
      >
        No hay citas programadas para después de mañana
      </div>
    </div>
  </div>
</template>
