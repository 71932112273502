import type { Measuree, MeasureeAttributes } from '@/types';
import { api } from './axios-wrapper';

type CreateMeasureePayload = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address: string;
  commune: string;
  specialInstructions?: string;
  documentType: 'rut' | 'passport';
  documentValue: string;
};

export const measureesApi = {
  async create(data: CreateMeasureePayload) {
    const response = await api({
      method: 'post',
      url: '/api/internal/measurees',
      data: { measuree: data },
    });

    return response.data as { success: boolean };
  },

  async update(id: number, attributes: Partial<MeasureeAttributes>) {
    const response = await api({
      method: 'put',
      url: `/api/internal/measurees/${id}`,
      data: { measuree: attributes },
    });

    return response.data as Measuree;
  },
};
