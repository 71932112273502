<script setup lang="ts">
import { computed, ref } from 'vue';
import { format } from 'date-fns';
import { useQuery } from '@tanstack/vue-query';
import type { NutritionPlan } from '@/types/extended';
import { BackButton, BaseLink, BaseButton, MarkdownRenderer } from '@/components';
import { Pencil, ExternalLink, Copy, CopyCheck, SquareStack } from 'lucide-vue-next';
import CollapsibleSection from '@/components/shared/collapsible-section.vue';
import { nutritionPlansApi } from '@/api/nutrition-plans';
import NutritionPlanCalendar from './nutrition-plan-calendar.vue';
import CloneNutritionPlanModal from './clone-nutrition-plan-modal.vue';

const props = defineProps<{
  nutritionPlan: NutritionPlan;
}>();

const { data: nutritionPlan } = useQuery({
  queryKey: ['nutrition-plan', props.nutritionPlan.id],
  queryFn: () => nutritionPlansApi.get(props.nutritionPlan.id),
  initialData: props.nutritionPlan,
});

const allMeals = computed(() =>
  props.nutritionPlan.nutritionObjectives
    .flatMap((objective) => objective.meals),
);

const allMealChecks = computed(() =>
  allMeals.value.flatMap((meal) => meal.mealChecks),
);

const showPlanDetails = computed(() =>
  nutritionPlan.value.goal ||
  nutritionPlan.value.strategy ||
  nutritionPlan.value.currentDiet ||
  nutritionPlan.value.dietaryTargets,
);

const sortedNutritionObjectives = computed(() =>
  [...props.nutritionPlan.nutritionObjectives].sort((a, b) =>
    new Date(b.startAt).getTime() - new Date(a.startAt).getTime(),
  ),
);

const isCloneModalOpen = ref(false);

function handleCloneModalClose(isSuccess: boolean) {
  isCloneModalOpen.value = false;
  if (isSuccess) {
    window.location.reload();
  }
}

function formatDate(date: string) {
  return format(new Date(date), 'dd/MM/yyyy');
}

const isCopied = ref(false);

function copyPublicUrl() {
  if (nutritionPlan.value.publicUrl) {
    navigator.clipboard.writeText(nutritionPlan.value.publicUrl);
    isCopied.value = true;
    setTimeout(() => {
      isCopied.value = false;
    // eslint-disable-next-line no-magic-numbers
    }, 2000);
  }
}
</script>

<template>
  <div class="container mx-auto px-4 py-8">
    <div class="mb-6 flex flex-col gap-4">
      <BackButton href="/nutrition_plans" />
      <div class="flex items-center justify-between">
        <h1 class="text-2xl font-semibold">
          Plan de nutrición {{ nutritionPlan.trainee?.fullName }}
        </h1>
        <div class="flex flex-row gap-4">
          <BaseButton
            v-if="nutritionPlan.publicUrl"
            variant="outline"
            @click="copyPublicUrl"
          >
            <CopyCheck
              v-if="isCopied"
              class="size-4"
            />
            <Copy
              v-else
              class="size-4"
            />
            Copiar link para compartir
          </BaseButton>
          <BaseLink
            v-if="nutritionPlan.externalUrl"
            :href="nutritionPlan.externalUrl"
            target="_blank"
          >
            <ExternalLink class="size-4" />
            Ver plan completo
          </BaseLink>
          <BaseLink
            :href="`/nutrition_plans/${nutritionPlan.id}/edit`"
          >
            <Pencil class="size-4" />
            <span>
              Editar
            </span>
          </BaseLink>
          <BaseButton
            variant="outline"
            @click="isCloneModalOpen = true"
          >
            <SquareStack class="size-4" />
            <span>
              Clonar
            </span>
          </BaseButton>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-8">
      <div class="space-y-8">
        <!-- Plan details -->
        <div class="overflow-hidden rounded-lg border border-gray-200 bg-white shadow">
          <div class="px-4 py-5 sm:p-6">
            <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
              <div>
                <dt class="text-sm font-medium text-gray-500">
                  Trainee
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ nutritionPlan.trainee?.fullName }}
                </dd>
              </div>
              <div>
                <dt class="text-sm font-medium text-gray-500">
                  Nutricionista
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ nutritionPlan.nutritionist?.fullName }}
                </dd>
              </div>
              <div>
                <dt class="text-sm font-medium text-gray-500">
                  Periodo
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ formatDate(nutritionPlan.startAt) }} -
                  {{ formatDate(nutritionPlan.endAt) }}
                </dd>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="showPlanDetails"
          class="overflow-hidden rounded-lg border border-gray-200 bg-white shadow"
        >
          <div class="px-4 py-5 sm:p-6">
            <div class="flex flex-col gap-4">
              <div v-if="nutritionPlan.goal">
                <dt class="text-lg font-semibold">
                  Objetivo general
                </dt>
                <dd class="prose prose-sm mt-1 text-sm text-gray-900">
                  <MarkdownRenderer :markdown="nutritionPlan.goal" />
                </dd>
              </div>
              <div v-if="nutritionPlan.strategy">
                <dt class="text-lg font-semibold">
                  Estrategia nutricional
                </dt>
                <dd class="prose prose-sm mt-1 text-sm text-gray-900">
                  <MarkdownRenderer :markdown="nutritionPlan.strategy" />
                </dd>
              </div>
              <div v-if="nutritionPlan.currentDiet">
                <dt class="text-lg font-semibold">
                  Dieta actual
                </dt>
                <dd class="prose prose-sm mt-1 text-sm text-gray-900">
                  <MarkdownRenderer :markdown="nutritionPlan.currentDiet" />
                </dd>
              </div>
              <div v-if="nutritionPlan.dietaryTargets">
                <dt class="text-lg font-semibold">
                  Objetivos dietéticos
                </dt>
                <dd class="prose prose-sm mt-1 text-sm text-gray-900">
                  <MarkdownRenderer :markdown="nutritionPlan.dietaryTargets" />
                </dd>
              </div>
            </div>
          </div>
        </div>

        <!-- Nutrition objectives -->
        <div
          v-for="(objective, index) in sortedNutritionObjectives"
          :key="objective.id"
          class="space-y-4"
        >
          <CollapsibleSection
            :title="`Objetivo ${sortedNutritionObjectives.length - index} - ${formatDate(objective.startAt)}`"
            is-expanded
          >
            <MarkdownRenderer
              :markdown="objective.description"
              class="mb-6 max-w-none text-gray-600"
            />

            <!-- Meals -->
            <div class="space-y-4">
              <CollapsibleSection
                v-for="meal in objective.meals"
                :key="meal.id"
                :title="meal.name"
                is-expanded
              >
                <MarkdownRenderer
                  :markdown="meal.recommendations"
                  class="text-gray-600"
                />
              </CollapsibleSection>
            </div>
          </CollapsibleSection>
        </div>
      </div>

      <div>
        <NutritionPlanCalendar
          :meals="allMeals"
          :meal-checks="allMealChecks"
          :start-at="nutritionPlan.startAt"
          :end-at="nutritionPlan.endAt"
        />
      </div>
    </div>

    <CloneNutritionPlanModal
      :nutrition-plan="nutritionPlan"
      :is-open="isCloneModalOpen"
      @close="handleCloneModalClose"
    />
  </div>
</template>
