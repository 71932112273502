<script setup lang="ts">
import { computed } from 'vue';
import { Check } from 'lucide-vue-next';
import type { PhysicalMeasurement } from '@/types/extended';
import type { Trainee, Measuree } from '@/types';
import { bodyCompositionQuadrants } from './physical-assessment-metrics';
import SubscribeButton from './subscribe-button.vue';

const props = defineProps<{
  physicalMeasurements: PhysicalMeasurement[];
  userable: Trainee | Measuree;
}>();

const {
  descriptions: quadrantDescriptions,
  analysis: quadrantAnalysis,
  recommendations: quadrantRecommendations,
} = bodyCompositionQuadrants;

// eslint-disable-next-line max-statements, complexity
const currentQuadrant = computed(() => {
  if (props.physicalMeasurements.length === 0 || !props.userable.sex) return null;

  const latestAssessment = props.physicalMeasurements[props.physicalMeasurements.length - 1]
    .physicalAssessment;
  const fatMassPercentage = (latestAssessment.fatMass /
    (latestAssessment.fatMass + latestAssessment.fatFreeMass)) * 100;
  const fatFreeMassIndex = latestAssessment.fatFreeMassIndex;

  const fatMassThreshold = 15.5;
  const fatFreeMassThreshold = 25.0;

  const isHighFatMass = fatMassPercentage >= fatMassThreshold;
  const isLowFatFreeMass = fatFreeMassIndex <= fatFreeMassThreshold;

  if (isHighFatMass && isLowFatFreeMass) return 'first';
  if (isHighFatMass && !isLowFatFreeMass) return 'second';
  if (!isHighFatMass && isLowFatFreeMass) return 'third';

  return 'fourth';
});

const currentAnalysis = computed(() => {
  if (!currentQuadrant.value) return null;

  return quadrantAnalysis[currentQuadrant.value];
});

const currentRecommendations = computed(() => {
  if (!currentQuadrant.value) return null;

  return quadrantRecommendations[currentQuadrant.value];
});
</script>

<template>
  <div class="rounded-xl border border-gray-200 bg-white p-4 shadow-sm md:p-8">
    <h3 class="text-xl font-semibold text-gray-900 md:text-2xl">
      Tu cuadrante
    </h3>
    <p class="mt-2 text-sm text-gray-600 md:text-base">
      Este análisis evalúa tu composición corporal en base a dos factores: cantidad de masa grasa y cantidad de masa
      muscular. {{ currentAnalysis }}
    </p>
    <div class="my-6 md:mt-8">
      <div class="grid h-[300px] grid-cols-2 grid-rows-2 gap-3 md:h-[350px] md:gap-4">
        <!-- Quadrant 1 (Top Left) -->
        <div
          class="relative flex flex-col items-center justify-center rounded-lg border p-1 text-center transition-colors md:p-6"
          :class="{
            'border-yellow-500 bg-yellow-50': currentQuadrant === 'first',
            'border-gray-200 bg-gray-50': currentQuadrant !== 'first'
          }"
        >
          <h4
            class="mb-1 text-base font-medium md:mb-2 md:text-xl"
            :class="{
              'text-yellow-900': currentQuadrant === 'first',
              'text-gray-900': currentQuadrant !== 'first'
            }"
          >
            Cuadrante 1
          </h4>
          <p
            class="text-sm md:text-base"
            :class="{
              'text-yellow-600': currentQuadrant === 'first',
              'text-gray-600': currentQuadrant !== 'first'
            }"
          >
            {{ quadrantDescriptions['first'] }}
          </p>
          <div
            v-if="currentQuadrant === 'first'"
            class="absolute -right-1 -top-1 flex size-5 items-center justify-center rounded-full bg-yellow-500 text-white shadow-sm md:-right-2 md:-top-2 md:size-6"
          >
            <Check
              class="size-3 md:size-4"
              stroke="currentColor"
            />
          </div>
        </div>

        <!-- Quadrant 2 (Top Right) -->
        <div
          class="relative flex flex-col items-center justify-center rounded-lg border p-1 text-center transition-colors md:p-6"
          :class="{
            'border-yellow-500 bg-yellow-50': currentQuadrant === 'second',
            'border-gray-200 bg-gray-50': currentQuadrant !== 'second'
          }"
        >
          <h4
            class="mb-1 text-base font-medium md:mb-2 md:text-xl"
            :class="{
              'text-yellow-900': currentQuadrant === 'second',
              'text-gray-900': currentQuadrant !== 'second'
            }"
          >
            Cuadrante 2
          </h4>
          <p
            class="text-sm md:text-base"
            :class="{
              'text-yellow-600': currentQuadrant === 'second',
              'text-gray-600': currentQuadrant !== 'second'
            }"
          >
            {{ quadrantDescriptions['second'] }}
          </p>
          <div
            v-if="currentQuadrant === 'second'"
            class="absolute -right-1 -top-1 flex size-5 items-center justify-center rounded-full bg-yellow-500 text-white shadow-sm md:-right-2 md:-top-2 md:size-6"
          >
            <Check
              class="size-3 md:size-4"
              stroke="currentColor"
            />
          </div>
        </div>

        <!-- Quadrant 3 (Bottom Left) -->
        <div
          class="relative flex flex-col items-center justify-center rounded-lg border p-1 text-center transition-colors md:p-6"
          :class="{
            'border-yellow-500 bg-yellow-50': currentQuadrant === 'third',
            'border-gray-200 bg-gray-50': currentQuadrant !== 'third'
          }"
        >
          <h4
            class="mb-1 text-base font-medium md:mb-2 md:text-xl"
            :class="{
              'text-yellow-900': currentQuadrant === 'third',
              'text-gray-900': currentQuadrant !== 'third'
            }"
          >
            Cuadrante 3
          </h4>
          <p
            class="text-sm md:text-base"
            :class="{
              'text-yellow-600': currentQuadrant === 'third',
              'text-gray-600': currentQuadrant !== 'third'
            }"
          >
            {{ quadrantDescriptions['third'] }}
          </p>
          <div
            v-if="currentQuadrant === 'third'"
            class="absolute -right-1 -top-1 flex size-5 items-center justify-center rounded-full bg-yellow-500 text-white shadow-sm md:-right-2 md:-top-2 md:size-6"
          >
            <Check
              class="size-3 md:size-4"
              stroke="currentColor"
            />
          </div>
        </div>

        <!-- Quadrant 4 (Bottom Right) -->
        <div
          class="relative flex flex-col items-center justify-center rounded-lg border p-1 text-center transition-colors md:p-6"
          :class="{
            'border-green-500 bg-green-50': currentQuadrant === 'fourth',
            'border-gray-200 bg-gray-50': currentQuadrant !== 'fourth'
          }"
        >
          <h4
            class="mb-1 text-base font-medium md:mb-2 md:text-xl"
            :class="{
              'text-green-900': currentQuadrant === 'fourth',
              'text-gray-900': currentQuadrant !== 'fourth'
            }"
          >
            Cuadrante 4
          </h4>
          <p
            class="text-sm md:text-base"
            :class="{
              'text-green-600': currentQuadrant === 'fourth',
              'text-gray-600': currentQuadrant !== 'fourth'
            }"
          >
            {{ quadrantDescriptions['fourth'] }}
          </p>
          <div
            v-if="currentQuadrant === 'fourth'"
            class="absolute -right-1 -top-1 flex size-5 items-center justify-center rounded-full bg-green-500 text-white shadow-sm md:-right-2 md:-top-2 md:size-6"
          >
            <Check
              class="size-3 md:size-4"
              stroke="currentColor"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mb-8 text-left">
      <p class="text-xs italic text-gray-500">
        * La masa grasa y la masa muscular se consideran óptimas cuando están dentro del 25% mejor de la población.
      </p>
    </div>

    <!-- Recommendations Section -->
    <div
      v-if="currentRecommendations"
      class="mb-8"
    >
      <h4 class="text-base font-semibold text-slate-900 md:text-lg">
        Recomendaciones
      </h4>
      <ul class="mt-3 space-y-1">
        <li
          v-for="(recommendation, index) in currentRecommendations"
          :key="index"
          class="prose prose-sm max-w-none list-inside list-disc text-slate-700 md:text-base"
        >
          {{ recommendation }}
        </li>
      </ul>
    </div>

    <SubscribeButton
      v-if="userable.userableType === 'Measuree'"
      :userable="userable"
    />
  </div>
</template>
