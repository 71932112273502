import { api } from './axios-wrapper';

type ProcessPaymentPayload = {
  transactionAmount: number;
  token: string;
  installments: number;
  paymentMethodId: string;
  payer: {
    email: string;
    identification: {
      type: string;
      number: string;
    };
    firstName: string;
  };
};

export const paymentsApi = {
  async processPayment(data: ProcessPaymentPayload) {
    const response = await api({
      method: 'post',
      url: '/api/internal/payments',
      data: { payment: data },
    });

    return response.data as { success: boolean };
  },
};
