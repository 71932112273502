import type { NutritionPlan, NutritionPlanAttributes } from '@/types';
import type { NutritionPlan as NutritionPlanExtended } from '@/types/extended';
import { api } from './fetch-wrapper';

export const nutritionPlansApi = {
  async getAll(params: Record<string, unknown> = {}): Promise<NutritionPlanExtended[]> {
    const response = await api.get({ url: '/api/internal/nutrition_plans', params });

    return response as NutritionPlanExtended[];
  },

  async get(id: number): Promise<NutritionPlanExtended> {
    const response = await api.get({ url: `/api/internal/nutrition_plans/${id}` });

    return response as NutritionPlanExtended;
  },

  async create(attributes: Partial<NutritionPlanAttributes>): Promise<NutritionPlan> {
    const response = await api.post({
      url: '/api/internal/nutrition_plans',
      body: { nutritionPlan: attributes },
    });

    return response as NutritionPlan;
  },

  async update(id: number, attributes: Partial<NutritionPlanAttributes>): Promise<NutritionPlan> {
    const response = await api.patch({
      url: `/api/internal/nutrition_plans/${id}`,
      body: { nutritionPlan: attributes },
    });

    return response as NutritionPlan;
  },

  async clone({
    nutritionPlanId,
    startAt,
  }: {
    nutritionPlanId: number;
    startAt: string;
  }) {
    const path = `/api/internal/nutrition_plans/${nutritionPlanId}/clone`;

    const response = await api.post({
      url: path,
      body: { startAt },
    });

    return response as NutritionPlan;
  },
};
