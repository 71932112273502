<script setup lang="ts">
import { ref, computed, toRef } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { format, startOfDay, endOfDay } from 'date-fns';
import { AlertTriangle, Check } from 'lucide-vue-next';
import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/yup';
import * as yup from 'yup';
import type { NamedResource } from '@/types';
import type { NutritionPlan, NutritionPlanAttributes, NutritionObjectiveAttributes } from '@/types/extended';
import { nutritionPlansApi } from '@/api';
import { BaseButton, BaseInput, BaseLabel, BaseSelect, MarkdownEditor } from '@/components';
import NutritionObjectiveForm from './nutrition-objective-form.vue';
import { DEFAULT_VALUES } from './nutrition-plan-default-values';

const props = defineProps<{
  nutritionPlan?: NutritionPlan;
  trainees: NamedResource[];
  nutritionists: NamedResource[];
}>();

const queryClient = useQueryClient();

const isEditing = computed(() => props.nutritionPlan !== undefined);

const nutritionPlan = toRef(() => props.nutritionPlan);

const nutritionPlanSchema = toTypedSchema(
  yup.object().shape({
    traineeId: yup.number().required('El trainee es obligatorio'),
    nutritionistId: yup.number().required('El nutricionista es obligatorio'),
    startAt: yup.string().required('La fecha de inicio es obligatoria'),
    endAt: yup
      .string()
      .required('La fecha de fin es obligatoria')
      .test('is-after-start', 'La fecha de fin debe ser posterior a la fecha de inicio', function (value) {
        const { startAt } = this.parent;
        if (!startAt || !value) return true;

        return new Date(value) > new Date(startAt);
      }),
    externalUrl: yup.string().nullable().url('La URL debe ser válida'),
    goal: yup.string().nullable(),
    strategy: yup.string().nullable(),
    currentDiet: yup.string().nullable(),
    dietaryTargets: yup.string().nullable(),
    nutritionObjectivesAttributes: yup.array().of(
      yup.object().shape({
        id: yup.number().nullable(),
        startAt: yup.string().required('La fecha de inicio es obligatoria'),
        description: yup.string().required('La descripción es obligatoria'),
        _destroy: yup.boolean(),
        mealsAttributes: yup.array().of(
          yup.object().shape({
            id: yup.number().nullable(),
            name: yup.string().required('El nombre es obligatorio'),
            recommendations: yup.string().required('Las recomendaciones son obligatorias'),
            _destroy: yup.boolean(),
          }),
        ),
      }),
    ),
  }),
);

function copyNutritionPlanAttributes(attributes: NutritionPlanAttributes) {
  return {
    traineeId: attributes.traineeId,
    nutritionistId: attributes.nutritionistId,
    startAt: format(new Date(attributes.startAt), "yyyy-MM-dd'T'HH:mm"),
    endAt: format(new Date(attributes.endAt), "yyyy-MM-dd'T'HH:mm"),
    externalUrl: attributes.externalUrl,
    goal: attributes.goal,
    strategy: attributes.strategy,
    currentDiet: attributes.currentDiet,
    dietaryTargets: attributes.dietaryTargets,
    nutritionObjectivesAttributes: attributes.nutritionObjectivesAttributes?.map(objective => ({
      ...objective,
      startAt: format(new Date(objective.startAt), "yyyy-MM-dd'T'HH:mm"),
      mealsAttributes: objective.mealsAttributes?.map(meal => ({
        ...meal,
      })),
    })),
  };
}

const initialValues = ref<NutritionPlanAttributes>({
  traineeId: nutritionPlan.value?.traineeId,
  nutritionistId: nutritionPlan.value?.nutritionistId,
  startAt: format(startOfDay(new Date(nutritionPlan.value?.startAt ?? new Date())), "yyyy-MM-dd'T'HH:mm"),
  // eslint-disable-next-line no-magic-numbers
  endAt: format(endOfDay(new Date(nutritionPlan.value?.endAt ?? new Date())), "yyyy-MM-dd'T'HH:mm"),
  externalUrl: nutritionPlan.value?.externalUrl,
  goal: nutritionPlan.value?.goal ?? DEFAULT_VALUES.goal,
  strategy: nutritionPlan.value?.strategy ?? DEFAULT_VALUES.strategy,
  currentDiet: nutritionPlan.value?.currentDiet ?? DEFAULT_VALUES.currentDiet,
  dietaryTargets: nutritionPlan.value?.dietaryTargets ?? DEFAULT_VALUES.dietaryTargets,
  nutritionObjectivesAttributes: nutritionPlan.value?.nutritionObjectives.map(objective => ({
    id: objective.id,
    startAt: objective.startAt,
    description: objective.description,
    mealsAttributes: objective.meals.map(meal => ({
      id: meal.id,
      name: meal.name,
      recommendations: meal.recommendations,
    })),
  })) ?? [],
});

const { handleSubmit, values, setFieldValue } = useForm<NutritionPlanAttributes>({
  validationSchema: nutritionPlanSchema,
  initialValues: initialValues.value,
  keepValuesOnUnmount: true,
});

function addNutritionObjective() {
  setFieldValue('nutritionObjectivesAttributes', [
    ...(values.nutritionObjectivesAttributes ?? []),
    {
      startAt: format(startOfDay(new Date()), "yyyy-MM-dd'T'HH:mm"),
      description: DEFAULT_VALUES.objectiveDescription,
      mealsAttributes: [],
    },
  ]);
}

function removeNutritionObjective(index: number) {
  const objective = values.nutritionObjectivesAttributes![index];
  if (objective.id) {
    setFieldValue(`nutritionObjectivesAttributes.${index}._destroy`, true);
  } else {
    const newObjectives = values.nutritionObjectivesAttributes!.filter((_, i) => i !== index);
    setFieldValue('nutritionObjectivesAttributes', newObjectives);
  }
}

function updateNutritionObjective(index: number, objective: NutritionObjectiveAttributes) {
  setFieldValue(`nutritionObjectivesAttributes.${index}`, objective);
}

const isFormChanged = computed(() => {
  const currentValues = copyNutritionPlanAttributes(values);
  const initialFormValues = copyNutritionPlanAttributes(initialValues.value);

  return JSON.stringify(currentValues) !== JSON.stringify(initialFormValues);
});

const {
  isError,
  isPending,
  isIdle,
  isSuccess,
  mutate: saveNutritionPlan,
  error: saveNutritionPlanError,
  reset: resetMutation,
} = useMutation({
  mutationFn: (data: NutritionPlanAttributes) => (props.nutritionPlan === undefined ?
    nutritionPlansApi.create(data) :
    nutritionPlansApi.update(props.nutritionPlan.id, data)),
  onSuccess: (updatedNutritionPlan: { id: number}) => {
    queryClient.invalidateQueries({ queryKey: ['nutrition-plans'] });
    setTimeout(() => {
      window.location.href = `/nutrition_plans/${updatedNutritionPlan.id}`;
    // eslint-disable-next-line no-magic-numbers
    }, 1000);
  },
});

const buttonText = computed(() => {
  if (!isFormChanged.value) return 'Sin cambios';
  if (isIdle.value || isError.value) return 'Guardar';
  if (isPending.value) return 'Guardando...';
  if (isSuccess.value) return 'Guardado';

  return 'Guardar';
});

// eslint-disable-next-line complexity
const buttonClass = computed(() => {
  if (!isFormChanged.value) return 'bg-gray-300 text-gray-500';
  if (isIdle.value) return 'bg-black text-white hover:bg-gray-800';
  if (isPending.value || isError.value) return 'bg-gray-300 text-gray-500 cursor-not-allowed';
  if (isSuccess.value) return 'bg-green-500 text-white hover:bg-green-600';

  return 'bg-black text-white hover:bg-gray-800';
});

const isSubmitDisabled = computed(() => !isFormChanged.value || isPending.value || isSuccess.value);

const onSubmit = handleSubmit((data: NutritionPlanAttributes) => {
  saveNutritionPlan(data);
});

function handleBack() {
  history.back();
}

function handleResetMutation() {
  resetMutation();
}
</script>

<template>
  <div class="container mx-auto px-4 py-8">
    <div class="flex items-center justify-between">
      <div class="mb-6 flex flex-col gap-4">
        <BaseButton
          variant="link"
          @click="handleBack"
        />
        <h1 class="text-2xl font-semibold">
          {{ isEditing ? 'Editar' : 'Crear' }} plan de nutrición
        </h1>
      </div>
      <BaseButton
        type="submit"
        :disabled="isSubmitDisabled"
        :class="buttonClass"
        @click="onSubmit"
      >
        {{ buttonText }}
      </BaseButton>
    </div>

    <form
      v-if="isIdle"
      class="space-y-8"
      @submit.prevent="onSubmit"
    >
      <!-- Basic information -->
      <div class="rounded-lg border border-gray-200 bg-white shadow">
        <div class="px-4 py-5 sm:p-6">
          <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
            <div class="flex flex-col gap-1">
              <BaseLabel
                label="Trainee"
                name="traineeId"
                required
              />
              <BaseSelect
                :model-value="values.traineeId"
                name="traineeId"
                :options="trainees"
                @update:model-value="setFieldValue('traineeId', Number($event))"
              />
            </div>
            <div class="flex flex-col gap-1">
              <BaseLabel
                label="Nutricionista"
                name="nutritionistId"
                required
              />
              <BaseSelect
                :model-value="values.nutritionistId"
                name="nutritionistId"
                :options="nutritionists"
                @update:model-value="setFieldValue('nutritionistId', Number($event))"
              />
            </div>
            <div class="flex flex-col gap-1">
              <BaseLabel
                label="Fecha de inicio"
                name="startAt"
                required
              />
              <BaseInput
                v-model="values.startAt"
                name="startAt"
                type="datetime-local"
              />
            </div>
            <div class="flex flex-col gap-1">
              <BaseLabel
                label="Fecha de fin"
                name="endAt"
                required
              />
              <BaseInput
                v-model="values.endAt"
                name="endAt"
                type="datetime-local"
              />
            </div>
            <div class="sm:col-span-2">
              <BaseLabel
                label="URL externa"
                name="externalUrl"
              />
              <BaseInput
                v-model="values.externalUrl"
                name="externalUrl"
                type="text"
                placeholder="https://www.notion.so/gokapso/plan-de-alimentacion"
              />
            </div>
            <div class="sm:col-span-2">
              <BaseLabel
                label="Objetivo general"
                name="goal"
              />
              <MarkdownEditor
                :model-value="values.goal"
                name="goal"
                placeholder="Describe el objetivo general del plan de nutrición"
                @update:model-value="setFieldValue('goal', $event)"
              />
            </div>
            <div class="sm:col-span-2">
              <BaseLabel
                label="Cómo se va a lograr"
                name="strategy"
              />
              <MarkdownEditor
                :model-value="values.strategy"
                name="strategy"
                placeholder="Describe la estrategia para lograr el objetivo"
                @update:model-value="setFieldValue('strategy', $event)"
              />
            </div>
            <div class="sm:col-span-2">
              <BaseLabel
                label="Dieta actual"
                name="currentDiet"
              />
              <MarkdownEditor
                :model-value="values.currentDiet"
                name="currentDiet"
                placeholder="Describe la dieta actual del trainee"
                @update:model-value="setFieldValue('currentDiet', $event)"
              />
            </div>
            <div class="sm:col-span-2">
              <BaseLabel
                label="Metas"
                name="dietaryTargets"
              />
              <MarkdownEditor
                :model-value="values.dietaryTargets"
                name="dietaryTargets"
                placeholder="Define las metas nutricionales"
                @update:model-value="setFieldValue('dietaryTargets', $event)"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Nutrition objectives -->
      <div class="space-y-4">
        <div class="flex items-center justify-between">
          <h2 class="text-lg font-medium text-gray-900">
            Objetivos nutricionales
          </h2>
        </div>

        <template
          v-for="(objective, objectiveIndex) in values.nutritionObjectivesAttributes"
          :key="objective.id"
        >
          <NutritionObjectiveForm
            v-if="!objective._destroy"
            :model-value="values.nutritionObjectivesAttributes[objectiveIndex]"
            :index="objectiveIndex"
            @update:model-value="updateNutritionObjective(objectiveIndex, $event)"
            @remove="removeNutritionObjective(objectiveIndex)"
          />
        </template>

        <BaseButton
          type="button"
          variant="secondary"
          @click="addNutritionObjective"
        >
          Agregar objetivo
        </BaseButton>
      </div>

      <!-- Submit button -->
      <div class="flex justify-end">
        <BaseButton
          type="submit"
          :disabled="isSubmitDisabled"
          :class="buttonClass"
        >
          {{ buttonText }}
        </BaseButton>
      </div>
    </form>

    <div
      v-else-if="isError"
      class="flex flex-col gap-4"
    >
      <div
        class="mt-4 flex flex-col gap-4 rounded-md bg-red-50 p-4"
      >
        <div class="flex max-w-full">
          <AlertTriangle class="shrink-0 text-red-400" />
          <div class="ml-3">
            <h3 class="text-sm font-medium text-red-800">
              Error al {{ isEditing ? 'actualizar' : 'crear' }} el plan
            </h3>
            <div class="mt-2 text-sm text-red-700">
              <p>{{ saveNutritionPlanError }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end">
        <BaseButton
          type="button"
          @click="handleResetMutation"
        >
          Volver al formulario
        </BaseButton>
      </div>
    </div>

    <div
      v-else-if="isSuccess"
      class="mt-4 rounded-md bg-green-50 p-4"
    >
      <div class="flex">
        <div class="shrink-0">
          <Check class="text-green-400" />
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-green-800">
            Plan {{ isEditing ? 'actualizado' : 'creado' }} exitosamente
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>
