<script setup lang="ts">
import { computed } from 'vue';
import { format } from 'date-fns';
import type { ApexOptions } from 'apexcharts';
import type { Trainee, Measuree } from '@/types';
import type { PhysicalMeasurement } from '@/types/extended';
import { physicalAssessmentMetrics, type BasicRanges } from './physical-assessment-metrics';
import BaseAssessmentChart from './base-assessment-chart.vue';
import HealthStatusPanel from './health-status-panel.vue';

const props = defineProps<{
  physicalMeasurements: PhysicalMeasurement[];
  userable: Trainee | Measuree;
}>();

function formatDate(date: string): string {
  return format(new Date(date), 'dd/MM/yyyy');
}

const chartData = computed(() => {
  const dates = props.physicalMeasurements.map((pm) => formatDate(pm.createdAt));
  const assessments = props.physicalMeasurements.map((pm) => pm.physicalAssessment);

  return {
    dates,
    percentages: assessments.map((assessment) => ({
      fatMass: (assessment.fatMass / (assessment.fatMass + assessment.fatFreeMass)) * 100,
    })),
  };
});

const currentFatPercentage = computed(() => {
  if (props.physicalMeasurements.length === 0) return null;

  const assessment = props.physicalMeasurements[props.physicalMeasurements.length - 1].physicalAssessment;

  return (assessment.fatMass / (assessment.fatMass + assessment.fatFreeMass)) * 100;
});

/* eslint-disable no-magic-numbers,complexity */
const healthCategory = computed(() => {
  if (!currentFatPercentage.value || !props.userable.sex) return null;

  const ranges = physicalAssessmentMetrics.fatMassPercentage.detailedRanges![props.userable.sex] as BasicRanges;
  const percentage = currentFatPercentage.value;

  if (props.userable.sex === 'male') {
    if (percentage < 15.5) return ranges.optimal;
    if (percentage < 20.2) return ranges.moderate;

    return ranges.unhealthy;
  }

  if (percentage < 25.0) return ranges.optimal;
  if (percentage < 32.3) return ranges.moderate;

  return ranges.unhealthy;
});

const objective = computed(() => {
  if (!currentFatPercentage.value || !props.userable.sex || !healthCategory.value) return null;
  if (healthCategory.value.id === 'optimal') return null;

  const isMale = props.userable.sex === 'male';
  const isUnhealthy = healthCategory.value.id === 'unhealthy';

  const objectives = {
    male: {
      unhealthy: { value: 20.2, percentile: 50, isHigherBetter: false },
      default: { value: 15.5, percentile: 25, isHigherBetter: false },
    },
    female: {
      unhealthy: { value: 32.3, percentile: 50, isHigherBetter: false },
      default: { value: 25.0, percentile: 25, isHigherBetter: false },
    },
  };

  if (isMale) {
    return isUnhealthy ? objectives.male.unhealthy : objectives.male.default;
  }

  return isUnhealthy ? objectives.female.unhealthy : objectives.female.default;
});
/* eslint-enable no-magic-numbers */

const recommendation = computed(() => {
  if (!healthCategory.value) return null;

  return physicalAssessmentMetrics.fatMassPercentage.recommendations?.[healthCategory.value.id];
});

const chartOptions = computed<ApexOptions>(() => ({
  chart: {
    type: 'line' as const,
    toolbar: { show: false },
    fontFamily: 'Inter var, sans-serif',
  },
  stroke: { curve: 'smooth', width: 3 },
  xaxis: {
    categories: chartData.value.dates,
  },
  yaxis: {
    title: { text: '%' },
    decimalsInFloat: 1,
  },
  dataLabels: {
    enabled: true,
    formatter: (value: number) => `${value.toFixed(1)}%`,
    offsetY: -6,
    background: {
      enabled: false,
    },
  },
}));

const chartSeries = computed(() => [
  {
    name: 'Masa Grasa',
    data: chartData.value.percentages.map((p) => p.fatMass),
  },
]);
</script>

<template>
  <div class="col-span-2 grid grid-cols-1 sm:grid-cols-2">
    <BaseAssessmentChart
      title="Porcentaje de grasa"
      metric-key="fatMassPercentage"
      :chart-options="chartOptions"
      :chart-series="chartSeries"
      class="rounded-b-none sm:rounded-b-lg sm:rounded-r-none"
    />

    <HealthStatusPanel
      title="Porcentaje de grasa"
      :userable="userable"
      unit="%"
      :current-value="currentFatPercentage"
      :health-category="healthCategory"
      :recommendation="recommendation ?? null"
      :importance="physicalAssessmentMetrics.fatMassPercentage.importance ?? null"
      :objective="objective"
      status-color-scheme="success-warning"
    />
  </div>
</template>
