import type { CalBooking } from '@/types/extended';
import { api } from './fetch-wrapper';

export const calBookingsApi = {
  async getAll(queryParams: Record<string, unknown> = {}): Promise<CalBooking[]> {
    const response = await api.get({ url: '/api/internal/cal_bookings', params: queryParams });

    return response as CalBooking[];
  },
};
