<script setup lang="ts">
import { ref, computed } from 'vue';
import type { SurveyResponse, WaistEstimationOption } from '@/types/body-composition-survey';

const props = defineProps<{
  response: Partial<SurveyResponse>
}>();

const emit = defineEmits<{
  (e: 'next', data: Partial<SurveyResponse>): void
  (e: 'back'): void
}>();

const formData = ref({
  hasWaistMeasurement: props.response.hasWaistMeasurement || null as boolean | null,
  waistCircumference: props.response.waistCircumference || '',
  estimatedWaistOption: props.response.estimatedWaistOption || '',
});

const showMeasurementInput = computed(() => formData.value.hasWaistMeasurement === true);
const showEstimationOptions = computed(() => formData.value.hasWaistMeasurement === false);

const estimationOptions: { value: WaistEstimationOption; title: string; description: string }[] = [
  {
    value: 'very_slim',
    title: 'Muy Delgada',
    description: 'Cintura muy definida, abdomen plano y firme.',
  },
  {
    value: 'slim',
    title: 'Delgada',
    description: 'Cintura definida, abdomen mayormente plano con ligera acumulación de grasa.',
  },
  {
    value: 'moderate',
    title: 'Moderada',
    description: 'Cintura menos definida, algo de acumulación de grasa visible en el abdomen.',
  },
  {
    value: 'large',
    title: 'Grande',
    description: 'Cintura poco definida, acumulación notable de grasa abdominal.',
  },
  {
    value: 'very_large',
    title: 'Muy Grande',
    description: 'Cintura no definida, acumulación significativa de grasa abdominal.',
  },
];

const showError = ref(false);

function handleSubmit() {
  emit('next', {
    hasWaistMeasurement: formData.value.hasWaistMeasurement as boolean,
    ...(formData.value.hasWaistMeasurement ?
      { waistCircumference: Number(formData.value.waistCircumference) } :
      { estimatedWaistOption: formData.value.estimatedWaistOption }),
  });
}
</script>

<template>
  <div class="space-y-8">
    <div class="text-center">
      <h2 class="text-3xl font-bold text-gray-900">
        Medición de Cintura
      </h2>
      <p class="mt-3 text-lg text-gray-500">
        Vamos a evaluar tu circunferencia de cintura
      </p>
    </div>

    <form
      class="space-y-12"
      @submit.prevent="handleSubmit"
    >
      <!-- Measurement Method Selection -->
      <div>
        <label class="mb-1 block text-xl font-medium text-gray-900">
          ¿Tienes una cinta métrica para medir tu cintura ahora mismo o sabes cuánto mide?
        </label>
        <div class="grid grid-cols-2 gap-4">
          <div
            role="button"
            tabindex="0"
            :class="[
              'flex cursor-pointer items-center justify-center rounded-lg p-4 text-center',
              formData.hasWaistMeasurement === true
                ? 'border-2 border-black bg-gray-50 font-medium text-gray-900'
                : 'bg-gray-50 hover:bg-gray-100',
              { 'border-2 border-red-300': showError && formData.hasWaistMeasurement === null }
            ]"
            @click="formData.hasWaistMeasurement = true"
            @keypress.enter="formData.hasWaistMeasurement = true"
          >
            <span :class="{ 'font-medium text-gray-900': formData.hasWaistMeasurement === true }">
              Sí
            </span>
          </div>
          <div
            role="button"
            tabindex="0"
            :class="[
              'flex cursor-pointer items-center justify-center rounded-lg p-4 text-center',
              formData.hasWaistMeasurement === false
                ? 'border-2 border-black bg-gray-50 font-medium text-gray-900'
                : 'bg-gray-50 hover:bg-gray-100',
              { 'border-2 border-red-300': showError && formData.hasWaistMeasurement === null }
            ]"
            @click="formData.hasWaistMeasurement = false"
            @keypress.enter="formData.hasWaistMeasurement = false"
          >
            <span :class="{ 'font-medium text-gray-900': formData.hasWaistMeasurement === false }">
              No
            </span>
          </div>
        </div>
      </div>

      <!-- Measurement Input -->
      <template v-if="showMeasurementInput">
        <div class="mt-8 grid grid-cols-1 gap-6 md:grid-cols-2">
          <div class="rounded-lg border border-gray-200 bg-gray-50 p-4">
            <h3 class="mb-3 text-lg font-medium text-gray-800">
              Instrucciones de Medición
            </h3>
            <ul class="space-y-2 text-base text-gray-700">
              <li>• Usa una cinta métrica flexible</li>
              <li>• Quítate la ropa gruesa alrededor de la cintura</li>
              <li>• Localiza la parte superior de tus huesos de la cadera y la parte inferior de tus costillas</li>
              <li>• Envuelve la cinta alrededor de tu abdomen en el punto medio entre estos puntos</li>
              <li>• Asegúrate que la cinta esté ajustada pero no comprima la piel</li>
              <li>• Mide después de exhalar normalmente</li>
            </ul>
          </div>

          <div class="flex items-center justify-center">
            <img
              :src="`/images/waist-measurement.png`"
              alt="Instrucciones de medición de cintura"
              class="w-full max-w-[250px] rounded-lg shadow-lg md:max-w-xs"
            >
          </div>
        </div>

        <div>
          <label
            for="waistCircumference"
            class="mb-4 block text-xl font-medium text-gray-900"
          >
            ¿Cuál es tu circunferencia de cintura? (cm)
          </label>
          <input
            id="waistCircumference"
            v-model="formData.waistCircumference"
            type="number"
            min="40"
            max="200"
            step="0.1"
            required
            placeholder="Ingresa tu medida en centímetros"
            class="mt-1 w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          >
        </div>
      </template>

      <!-- Estimation Options -->
      <template v-if="showEstimationOptions">
        <div>
          <label class="mb-4 block text-xl font-medium text-gray-900">
            ¿De qué tamaño dirías que es tu cintura ahora mismo, estando de pie y con el abdomen relajado?
          </label>
          <div class="space-y-4">
            <div
              v-for="option in estimationOptions"
              :key="option.value"
              role="button"
              tabindex="0"
              :class="[
                'flex w-full cursor-pointer flex-col rounded-lg p-4 text-left',
                formData.estimatedWaistOption === option.value
                  ? 'border-2 border-black bg-gray-50 font-medium text-gray-900'
                  : 'bg-gray-50 hover:bg-gray-100',
                { 'border-2 border-red-300': showError && !formData.estimatedWaistOption }
              ]"
              @click="formData.estimatedWaistOption = option.value"
              @keypress.enter="formData.estimatedWaistOption = option.value"
            >
              <h3
                class="mb-1 text-lg text-gray-900"
                :class="{ 'font-medium text-gray-900': formData.estimatedWaistOption === option.value }"
              >
                Cintura {{ option.title }}
              </h3>
              <p
                class="text-sm text-gray-600"
                :class="{ 'text-gray-900': formData.estimatedWaistOption === option.value }"
              >
                {{ option.description }}
              </p>
            </div>
          </div>
        </div>
      </template>

      <!-- Navigation Buttons -->
      <div class="grid grid-cols-2 gap-4">
        <button
          type="button"
          class="flex justify-center rounded-lg border border-gray-300 bg-white px-4 py-3 text-sm font-medium text-gray-700 shadow-sm transition duration-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          @click="emit('back')"
        >
          Atrás
        </button>
        <button
          type="submit"
          class="w-full rounded-lg bg-black px-4 py-3 text-white transition duration-300 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          :disabled="formData.hasWaistMeasurement === null ||
            (formData.hasWaistMeasurement && !formData.waistCircumference) ||
            (!formData.hasWaistMeasurement && !formData.estimatedWaistOption)"
        >
          Continuar
        </button>
      </div>
    </form>
  </div>
</template>
