<script setup lang="ts">
interface Props {
  href: string
}

const props = defineProps<Props>();
</script>

<template>
  <a
    :href="props.href"
    class="inline-flex items-center justify-center gap-1 whitespace-nowrap rounded-md px-2 py-1 text-sm font-normal text-slate-900 underline-offset-4 ring-offset-white transition-colors hover:underline focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:text-slate-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300"
  >
    <slot name="icon" />
    <slot />
  </a>
</template>
