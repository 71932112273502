<script setup lang="ts">
import { computed } from 'vue';
import { format, isToday, isTomorrow } from 'date-fns';
import { ArrowRight } from 'lucide-vue-next';
import type { CalBooking, Trainee, Measuree } from '@/types';
import { BaseLink } from '@/components';

type Props = {
  booking: CalBooking
  trainees: Trainee[]
  measurees: Measuree[]
  isInProgress?: boolean
  isNext?: boolean
  variant?: 'default' | 'highlight' | 'muted'
}

const props = withDefaults(defineProps<Props>(), {
  isInProgress: false,
  isNext: false,
  variant: 'default',
});

function getStatusClass(status: string, isInProgress = false, isNext = false) {
  if (isInProgress) return 'bg-green-100 text-green-800';
  if (isNext) return 'bg-blue-100 text-blue-800';

  return (status === 'accepted' ?
    'bg-green-100 text-green-800' :
    'bg-red-100 text-red-800');
}

function getStatusText(status: string, isInProgress = false, isNext = false) {
  if (isInProgress) return 'En progreso';
  if (isNext) return 'Próxima';

  return {
    accepted: 'Aceptada',
    pending: 'Pendiente',
    cancelled: 'Cancelada',
    rejected: 'Rechazada',
  }[status];
}

const userable = computed(() => {
  if (props.booking.attendeeType === 'Trainee') {
    return props.trainees.find(
      (u: Trainee) => u.id === props.booking.attendeeId,
    );
  }

  return props.measurees.find(
    (u: Measuree) => u.id === props.booking.attendeeId,
  );
});

const cardClasses = computed(() => {
  switch (props.variant) {
  case 'highlight':
    return 'border-2 border-blue-300 bg-white shadow-md';
  case 'muted':
    return 'border border-gray-200 bg-gray-50';
  default:
    return 'border border-gray-300 bg-white shadow-sm';
  }
});

function formatDateTime(date: string) {
  if (isToday(date) || isTomorrow(date)) {
    return format(new Date(date), 'HH:mm');
  }

  return format(new Date(date), 'dd/MM/yyyy HH:mm');
}
</script>

<template>
  <div
    class="overflow-hidden rounded-lg p-4"
    :class="cardClasses"
  >
    <div class="flex flex-col gap-3">
      <div class="flex items-center justify-between">
        <h3
          class="font-medium"
          :class="variant === 'muted' ? 'text-gray-700' : 'text-gray-900'"
        >
          {{ userable?.fullName ?? '-' }}
        </h3>

        <BaseLink
          :href="`/${booking.attendeeType.toLowerCase()}s/${booking.attendeeId}`"
          class="inline-flex items-center gap-1 text-sm font-medium"
          :class="{
            'text-gray-500': variant === 'muted',
            'text-blue-600': isNext,
            'text-green-600': isInProgress,
            'text-gray-600': !isNext && !isInProgress && variant !== 'muted'
          }"
        >
          Ver <ArrowRight class="size-4" />
        </BaseLink>
      </div>
      <div class="flex flex-col gap-2 text-sm text-gray-500">
        <div class="flex items-start gap-2">
          <span class="font-medium">Hora:</span>
          <span>
            {{ formatDateTime(booking.startTime) }}
          </span>
        </div>
        <div class="flex items-start gap-2">
          <span class="font-medium">Dirección:</span>
          <span>{{ userable?.address ?? '-' }}</span>
        </div>
        <div class="flex items-start gap-2">
          <span class="font-medium">Evento:</span>
          <span>{{ booking.title }}</span>
        </div>
      </div>
      <span
        class="inline-flex w-24 items-center justify-center rounded-full px-2.5 py-1 text-xs font-medium"
        :class="getStatusClass(booking.status, isInProgress, isNext)"
      >
        {{ getStatusText(booking.status, isInProgress, isNext) }}
      </span>
      <span
        class="inline-flex w-32 items-center justify-center rounded-full px-2.5 py-1 text-xs font-medium"
        :class="booking.attendeeType === 'Trainee' ? 'bg-amber-100 text-amber-700' : 'bg-gray-100 text-gray-800'"
      >
        {{ booking.attendeeType === 'Trainee' ? 'Con suscripción' : 'Sin suscripción' }}
      </span>
    </div>
  </div>
</template>
