<script setup lang="ts">
import { computed } from 'vue';
import { trackEvent } from '@/utils/analytics';
import type { TallySubmissionPayload, Trainer } from '@/types/';
import { preMeetingSurveysApi } from '@/api';
import { useTallyForm } from '@/composables/tally-form';

const props = defineProps<{
  trainer: Trainer;
  email: string;
  redirectPath: string;
  traineeId: number;
  tallyFormId: string;
}>();

async function handleSubmission(payload: TallySubmissionPayload) {
  await preMeetingSurveysApi.create(props.traineeId, payload);

  window.location.href = props.redirectPath;
}

function captureSubmission(payload: TallySubmissionPayload) {
  trackEvent('pre_meeting_survey_form_submitted', { ...payload, traineeId: props.traineeId });
}

function handleTallyMessage(e: MessageEvent) {
  if (e?.data?.includes('Tally.FormSubmitted')) {
    const payload = JSON.parse(e.data).payload as TallySubmissionPayload;
    captureSubmission(payload);
    handleSubmission(payload);
  }
}

useTallyForm(handleTallyMessage);

const tallyFormUrl = computed(
  // eslint-disable-next-line max-len, vue/max-len
  () => `https://tally.so/embed/${props.tallyFormId}?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1&email=${props.email}`,
);
</script>

<template>
  <div class="touch-pan-y overflow-auto">
    <iframe
      :data-tally-src="tallyFormUrl"
      loading="lazy"
      class="size-full"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      title="Kapso"
    />
  </div>
</template>
