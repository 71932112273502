<script setup lang="ts">
import { ref, computed } from 'vue';
import { useMutation } from '@tanstack/vue-query';
import { LoaderCircle } from 'lucide-vue-next';
import type { AxiosError } from 'axios';
import { api } from '@/api/axios-wrapper';

interface ApiError {
  error: string
}

interface CheckEmailResponse {
  requiresPassword: boolean
  message?: string
}

interface SignInResponse {
  redirectTo: string
}

const email = ref('');
const password = ref('');
const showPassword = ref(false);

const checkEmailMutation = useMutation({
  mutationFn: async (emailValue: string) => {
    const response = await api.post<CheckEmailResponse>('/users/check_email', { email: emailValue });

    return response.data;
  },
  onSuccess: (data) => {
    if (data.requiresPassword) {
      showPassword.value = true;
    }
  },
});

const signInMutation = useMutation({
  mutationFn: async ({ emailValue, passwordValue }: { emailValue: string; passwordValue: string }) => {
    const formData = new FormData();
    formData.append('user[email]', emailValue);
    formData.append('user[password]', passwordValue);

    const response = await api.post<SignInResponse>('/users/sign_in', formData);

    return response.data;
  },
  onSuccess: (data) => {
    window.location.href = data.redirectTo;
  },
});

async function handleSubmit() {
  if (showPassword.value === false) {
    await checkEmailMutation.mutateAsync(email.value);
  } else {
    await signInMutation.mutateAsync({ emailValue: email.value, passwordValue: password.value });
  }
}

const isPending = computed(() => checkEmailMutation.isPending.value || signInMutation.isPending.value);

const error = computed(() => {
  const checkEmailError = checkEmailMutation.error.value as AxiosError<ApiError> | null;
  const signInError = signInMutation.error.value as AxiosError<ApiError> | null;
  const currentError = checkEmailError || signInError;

  if (currentError?.response?.data?.error) {
    return currentError.response.data.error;
  }

  return currentError ? 'Ocurrió un error. Por favor intenta de nuevo.' : '';
});

const message = computed(() => {
  if (checkEmailMutation.isSuccess.value && !showPassword.value) {
    return checkEmailMutation.data.value?.message;
  }

  return '';
});
</script>

<template>
  <form
    class="flex flex-col gap-4"
    @submit.prevent="handleSubmit"
  >
    <div class="flex flex-col gap-2">
      <label
        for="email"
        class="text-sm font-medium text-gray-700"
      >
        Correo electrónico
      </label>
      <input
        id="email"
        v-model="email"
        type="email"
        name="email"
        required
        autocomplete="email"
        :disabled="showPassword"
        class="mt-1 block w-full rounded border bg-white px-3 py-2 shadow-sm focus:border-black focus:ring-black sm:text-sm"
      >
    </div>

    <div
      v-if="showPassword"
      class="flex flex-col gap-2"
    >
      <label
        for="password"
        class="text-sm font-medium text-gray-700"
      >
        Contraseña
      </label>
      <input
        id="password"
        v-model="password"
        type="password"
        name="password"
        required
        autocomplete="current-password"
        class="mt-1 block w-full rounded border bg-white px-3 py-2 shadow-sm focus:border-black focus:ring-black sm:text-sm"
      >
    </div>

    <div
      v-if="message"
      class="rounded-md bg-green-50 p-4"
    >
      <div class="flex">
        <div class="shrink-0">
          <svg
            class="size-5 text-green-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div class="ml-3">
          <p class="text-sm font-medium text-green-800">
            {{ message }}
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="error"
      class="rounded-md bg-red-50 p-4"
    >
      <div class="flex">
        <div class="shrink-0">
          <svg
            class="size-5 text-red-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div class="ml-3">
          <p class="text-sm font-medium text-red-800">
            {{ error }}
          </p>
        </div>
      </div>
    </div>

    <button
      type="submit"
      :disabled="isPending || signInMutation.isSuccess.value"
      class="flex w-full justify-center rounded-md border border-transparent bg-black px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 disabled:opacity-50"
    >
      <template v-if="isPending">
        <LoaderCircle class="size-5 animate-spin" />
        Procesando...
      </template>
      <template v-else-if="signInMutation.isSuccess.value">
        Iniciando sesión...
      </template>
      <template v-else>
        {{ showPassword ? 'Iniciar sesión' : 'Continuar' }}
      </template>
    </button>
  </form>
</template>
