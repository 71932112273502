<script setup lang="ts">
import { computed } from 'vue';
import { format } from 'date-fns';
import type { ApexOptions } from 'apexcharts';
import type { Trainee, Measuree } from '@/types';
import type { PhysicalMeasurement } from '@/types/extended';
import { physicalAssessmentMetrics, type BasicRanges } from './physical-assessment-metrics';
import BaseAssessmentChart from './base-assessment-chart.vue';
import HealthStatusPanel from './health-status-panel.vue';

const props = defineProps<{
  physicalMeasurements: PhysicalMeasurement[];
  userable: Trainee | Measuree;
}>();

function formatDate(date: string): string {
  return format(new Date(date), 'dd/MM/yyyy');
}

const chartData = computed(() => {
  const dates = props.physicalMeasurements.map((pm) => formatDate(pm.createdAt));
  const assessments = props.physicalMeasurements.map((pm) => pm.physicalAssessment);

  return {
    dates,
    waistToHipRatio: assessments.map((assessment) => assessment.waistToHipRatio),
  };
});

const currentRatio = computed(() => {
  if (props.physicalMeasurements.length === 0) return null;

  return props.physicalMeasurements[props.physicalMeasurements.length - 1].physicalAssessment.waistToHipRatio;
});

/* eslint-disable no-magic-numbers,complexity */
const healthCategory = computed(() => {
  if (!currentRatio.value || !props.userable.sex) return null;

  const ranges = physicalAssessmentMetrics.waistToHipRatio.detailedRanges![props.userable.sex] as BasicRanges;
  const ratio = currentRatio.value;

  if (props.userable.sex === 'male') {
    if (ratio < 0.90) return ranges.optimal;
    if (ratio < 1.00) return ranges.moderate;

    return ranges.unhealthy;
  }

  if (ratio < 0.80) return ranges.optimal;
  if (ratio < 0.86) return ranges.moderate;

  return ranges.unhealthy;
});

const objective = computed(() => {
  if (!currentRatio.value || !props.userable.sex || !healthCategory.value) return null;

  if (healthCategory.value.id === 'optimal') return null;

  const isMale = props.userable.sex === 'male';
  const isUnhealthy = healthCategory.value.id === 'unhealthy';

  const objectives = {
    male: {
      unhealthy: { value: 1.00, percentile: null, isHigherBetter: false },
      default: { value: 0.90, percentile: null, isHigherBetter: false },
    },
    female: {
      unhealthy: { value: 0.86, percentile: null, isHigherBetter: false },
      default: { value: 0.80, percentile: null, isHigherBetter: false },
    },
  };

  if (isMale) {
    return isUnhealthy ? objectives.male.unhealthy : objectives.male.default;
  }

  return isUnhealthy ? objectives.female.unhealthy : objectives.female.default;
});
/* eslint-enable no-magic-numbers */

const recommendation = computed(() => {
  if (!healthCategory.value) return null;

  return physicalAssessmentMetrics.waistToHipRatio.recommendations?.[healthCategory.value.id];
});

const chartOptions = computed<ApexOptions>(() => ({
  chart: {
    type: 'line' as const,
    toolbar: { show: false },
    fontFamily: 'Inter var, sans-serif',
  },
  stroke: { curve: 'smooth', width: 3 },
  xaxis: {
    categories: chartData.value.dates,
  },
  yaxis: {
    title: { text: 'Ratio' },
    decimalsInFloat: 2,
  },
  dataLabels: {
    enabled: true,
    formatter: (value: number) => `${value.toFixed(2)}`,
    offsetY: -6,
    background: {
      enabled: false,
    },
  },
}));

const chartSeries = computed(() => [{
  name: 'ICC',
  data: chartData.value.waistToHipRatio,
}]);
</script>

<template>
  <div class="col-span-2 grid grid-cols-1 sm:grid-cols-2">
    <BaseAssessmentChart
      title="Índice cintura-cadera (ICC)"
      metric-key="waistToHipRatio"
      :chart-options="chartOptions"
      :chart-series="chartSeries"
      class="rounded-b-none sm:rounded-b-lg sm:rounded-r-none"
    />

    <HealthStatusPanel
      title="Índice cintura-cadera (ICC)"
      :userable="userable"
      :current-value="currentRatio"
      :decimal-places="2"
      :health-category="healthCategory"
      :recommendation="recommendation ?? null"
      :importance="physicalAssessmentMetrics.waistToHipRatio.importance ?? null"
      :objective="objective"
      status-color-scheme="success-warning"
    />
  </div>
</template>
