<script setup lang="ts">
import { computed } from 'vue';
import { format } from 'date-fns';
import type { ApexOptions } from 'apexcharts';
import type { Trainee, Measuree } from '@/types';
import type { PhysicalMeasurement } from '@/types/extended';
import { physicalAssessmentMetrics, type AgeRanges } from './physical-assessment-metrics';
import BaseAssessmentChart from './base-assessment-chart.vue';
import HealthStatusPanel from './health-status-panel.vue';

const props = defineProps<{
  physicalMeasurements: PhysicalMeasurement[];
  userable: Trainee | Measuree;
}>();

function calculateAge(birthdate: string): number {
  return new Date().getFullYear() - new Date(birthdate).getFullYear();
}

function formatDate(date: string): string {
  return format(new Date(date), 'dd/MM/yyyy');
}

const chartData = computed(() => {
  const dates = props.physicalMeasurements.map((pm) => formatDate(pm.createdAt));
  const assessments = props.physicalMeasurements.map((pm) => pm.physicalAssessment);

  return {
    dates,
    gripStrength: assessments.map((assessment) => assessment.gripStrength),
  };
});

const currentStrength = computed(() => {
  if (props.physicalMeasurements.length === 0) return null;

  return props.physicalMeasurements[props.physicalMeasurements.length - 1].physicalAssessment.gripStrength;
});

/* eslint-disable no-magic-numbers, complexity, max-statements */
function getGripStrengthThresholds(age: number, sex: 'male' | 'female'): { p50: number; p75: number } {
  if (sex === 'male') {
    if (age <= 30) return { p50: 50.5, p75: 57.0 };
    if (age <= 40) return { p50: 49.5, p75: 56.0 };
    if (age <= 50) return { p50: 47.4, p75: 53.1 };
    if (age <= 60) return { p50: 44.2, p75: 50.0 };
    if (age <= 70) return { p50: 38.6, p75: 43.6 };
    if (age <= 80) return { p50: 31.6, p75: 36.4 };

    return { p50: 25.0, p75: 29.0 }; // 90+
  }

  // Female thresholds
  if (age <= 30) return { p50: 30.6, p75: 34.3 };
  if (age <= 40) return { p50: 30.7, p75: 34.5 };
  if (age <= 50) return { p50: 28.7, p75: 32.6 };
  if (age <= 60) return { p50: 26.5, p75: 30.4 };
  if (age <= 70) return { p50: 23.5, p75: 26.6 };
  if (age <= 80) return { p50: 18.5, p75: 22.5 };

  return { p50: 14.0, p75: 17.0 }; // 90+
}

function getAgeGroup(age: number): keyof AgeRanges {
  if (age <= 40) return 'young';
  if (age <= 60) return 'middle';

  return 'senior';
}

const healthCategory = computed(() => {
  if (!currentStrength.value || !props.userable.sex || !props.userable.birthdate) return null;

  const ranges = physicalAssessmentMetrics.gripStrength.detailedRanges![props.userable.sex];
  const age = calculateAge(props.userable.birthdate);
  const strength = currentStrength.value;
  const thresholds = getGripStrengthThresholds(age, props.userable.sex);

  // Type assertion since we know this metric uses AgeRanges
  const ageRanges = ranges as AgeRanges;
  const ageGroup = getAgeGroup(age);
  const groupRanges = ageRanges[ageGroup];

  if (strength > thresholds.p75) return groupRanges.optimal;
  if (strength > thresholds.p50) return groupRanges.moderate;

  return groupRanges.unhealthy;
});

const recommendation = computed(() => {
  if (!healthCategory.value) return null;

  return physicalAssessmentMetrics.gripStrength.recommendations?.[healthCategory.value.id];
});

const objective = computed(() => {
  if (!currentStrength.value || !props.userable.sex || !props.userable.birthdate || !healthCategory.value) return null;

  if (healthCategory.value.id === 'optimal') return null;

  const age = calculateAge(props.userable.birthdate);
  const thresholds = getGripStrengthThresholds(age, props.userable.sex);

  const isUnhealthy = healthCategory.value.id === 'unhealthy';

  const objectives = {
    unhealthy: { value: thresholds.p50, percentile: 50, isHigherBetter: true },
    healthy: { value: thresholds.p75, percentile: 75, isHigherBetter: true },
  };

  return isUnhealthy ? objectives.unhealthy : objectives.healthy;
});
/* eslint-enable no-magic-numbers, complexity */

const chartOptions = computed<ApexOptions>(() => ({
  chart: {
    type: 'line' as const,
    toolbar: { show: false },
    fontFamily: 'Inter var, sans-serif',
  },
  stroke: { curve: 'smooth', width: 3 },
  xaxis: {
    categories: chartData.value.dates,
  },
  yaxis: {
    title: { text: 'kg' },
    decimalsInFloat: 1,
  },
  dataLabels: {
    enabled: true,
    formatter: (value: number) => `${value.toFixed(1)}`,
    offsetY: -6,
    background: {
      enabled: false,
    },
  },
}));

const chartSeries = computed(() => [{
  name: 'Fuerza',
  data: chartData.value.gripStrength,
}]);
</script>

<template>
  <div class="col-span-2 grid grid-cols-1 sm:grid-cols-2">
    <BaseAssessmentChart
      title="Fuerza de agarre"
      metric-key="gripStrength"
      :chart-options="chartOptions"
      :chart-series="chartSeries"
      class="rounded-b-none sm:rounded-b-lg sm:rounded-r-none"
    />

    <HealthStatusPanel
      title="Fuerza de agarre"
      :userable="userable"
      :current-value="currentStrength"
      unit=" kg"
      :health-category="healthCategory"
      :recommendation="recommendation ?? null"
      :importance="physicalAssessmentMetrics.gripStrength.importance ?? null"
      :objective="objective"
      status-color-scheme="success-warning"
    />
  </div>
</template>
