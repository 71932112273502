<script setup lang="ts">
import { ref } from 'vue';
import type { SurveyResponse } from '@/types/body-composition-survey';

const props = defineProps<{
  response: Partial<SurveyResponse>
}>();

const emit = defineEmits<{
  (e: 'next', data: Partial<SurveyResponse>): void
  (e: 'back'): void
}>();

const formData = ref({
  firstName: props.response.firstName || '',
  lastName: props.response.lastName || '',
  email: props.response.email || '',
  country: props.response.country || '',
  referralSource: props.response.referralSource || '',
});

const countries = [
  // Priority countries first
  { code: 'CL', name: 'Chile' },
  { code: 'MX', name: 'México' },
  { code: 'CO', name: 'Colombia' },
  { code: 'US', name: 'Estados Unidos' },
  { code: 'AR', name: 'Argentina' },
  // Rest of countries
  { code: 'BO', name: 'Bolivia' },
  { code: 'BR', name: 'Brasil' },
  { code: 'CA', name: 'Canadá' },
  { code: 'CR', name: 'Costa Rica' },
  { code: 'CU', name: 'Cuba' },
  { code: 'DO', name: 'República Dominicana' },
  { code: 'EC', name: 'Ecuador' },
  { code: 'SV', name: 'El Salvador' },
  { code: 'GT', name: 'Guatemala' },
  { code: 'HT', name: 'Haití' },
  { code: 'HN', name: 'Honduras' },
  { code: 'NI', name: 'Nicaragua' },
  { code: 'PA', name: 'Panamá' },
  { code: 'PY', name: 'Paraguay' },
  { code: 'PE', name: 'Perú' },
  { code: 'PR', name: 'Puerto Rico' },
  { code: 'UY', name: 'Uruguay' },
  { code: 'VE', name: 'Venezuela' },
];

const referralSources = [
  { value: 'linkedin', label: 'LinkedIn' },
  { value: 'instagram', label: 'Instagram' },
  { value: 'twitter', label: 'X / Twitter' },
  { value: 'google', label: 'Google' },
  { value: 'referral', label: 'Me lo envió alguien' },
  { value: 'other', label: 'Otro' },
];

function handleSubmit() {
  emit('next', {
    firstName: formData.value.firstName,
    lastName: formData.value.lastName,
    email: formData.value.email,
    country: formData.value.country,
    referralSource: formData.value.referralSource,
  });
}
</script>

<template>
  <div class="space-y-8">
    <div class="text-center">
      <h2 class="text-3xl font-bold text-gray-900">
        Información demográfica
      </h2>
      <p class="mt-3 text-lg text-gray-500">
        Por favor, completa algunos datos para recibir tus resultados.
      </p>
    </div>

    <form
      class="space-y-5"
      @submit.prevent="handleSubmit"
    >
      <!-- Name Fields Container -->
      <div>
        <label
          for="firstName"
          class="mb-1 block text-lg font-medium text-gray-900"
        >
          Primer nombre
        </label>
        <input
          id="firstName"
          v-model="formData.firstName"
          type="text"
          required
          placeholder="Ingresa tu nombre"
          class="mt-1 w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        >
      </div>

      <!-- Country Field -->
      <div>
        <label
          for="country"
          class="mb-1 block text-lg font-medium text-gray-900"
        >
          País
        </label>
        <select
          id="country"
          v-model="formData.country"
          required
          class="mt-1 w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        >
          <option value="">
            Selecciona un país
          </option>
          <option
            v-for="country in countries"
            :key="country.code"
            :value="country.code"
          >
            {{ country.name }}
          </option>
        </select>
      </div>

      <!-- Email Field -->
      <div>
        <label
          for="email"
          class="mb-1 block text-lg font-medium text-gray-900"
        >
          Correo electrónico
        </label>
        <input
          id="email"
          v-model="formData.email"
          type="email"
          required
          placeholder="Ingresa tu correo electrónico"
          class="mt-1 w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        >
      </div>

      <!-- Referral Source Field -->
      <div>
        <label
          for="referralSource"
          class="mb-1 block text-lg font-medium text-gray-900"
        >
          ¿Cómo llegaste acá?
        </label>
        <select
          id="referralSource"
          v-model="formData.referralSource"
          required
          class="mt-1 w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        >
          <option value="">
            Selecciona una opción
          </option>
          <option
            v-for="source in referralSources"
            :key="source.value"
            :value="source.value"
          >
            {{ source.label }}
          </option>
        </select>
      </div>

      <!-- Navigation Buttons -->
      <div class="grid grid-cols-1 gap-3 pt-4 sm:grid-cols-2 sm:gap-4">
        <button
          type="button"
          class="flex justify-center rounded-lg border border-gray-300 bg-white px-4 py-3 text-sm font-medium text-gray-700 shadow-sm transition duration-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          @click="emit('back')"
        >
          Atrás
        </button>
        <button
          type="submit"
          class="w-full rounded-lg bg-black px-4 py-3 text-white transition duration-300 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
        >
          Ver resultados
        </button>
      </div>
    </form>
  </div>
</template>
