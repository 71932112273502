<script setup lang="ts">
import { ref } from 'vue';
import { ChevronDown, ChevronRight } from 'lucide-vue-next';

const props = withDefaults(defineProps<{
  title: string;
  isExpanded?: boolean;
}>(), {
  isExpanded: false,
});

const isExpanded = ref(props.isExpanded);

function toggle() {
  isExpanded.value = !isExpanded.value;
}
</script>

<template>
  <div class="overflow-hidden rounded-xl border border-gray-200 bg-white shadow">
    <button
      type="button"
      class="flex w-full items-center justify-between px-4 py-3 text-left hover:bg-gray-50"
      @click="toggle"
    >
      <span class="text-lg font-medium text-gray-900">
        {{ title }}
      </span>
      <ChevronDown
        v-if="isExpanded"
        class="size-5 text-gray-500"
      />
      <ChevronRight
        v-else
        class="size-5 text-gray-500"
      />
    </button>
    <div
      v-show="isExpanded"
      class="px-4 py-5"
    >
      <slot />
    </div>
  </div>
</template>
