<script setup lang="ts">
import { ref, onMounted } from 'vue';
import type { SurveyResponse, SurveyResult } from '@/types/body-composition-survey';
import { leadsApi } from '@/api';
import QuadrantAnalysis from './results/quadrant-analysis.vue';
import RiskAnalysis from './results/risk-analysis.vue';
import ActionPlan from './results/action-plan.vue';
import KapsoCTA from './results/kapso-cta.vue';
import RiskModal from './results/risk-modal.vue';
import ExplanationModal from './results/explanation-modal.vue';
import PersonalSummary from './results/personal-summary.vue';
import LoadingFeatures from './results/loading-features.vue';

defineOptions({
  name: 'SurveyResults',
});

const props = defineProps<{
  response?: Partial<SurveyResponse>
  result?: SurveyResult
}>();

const encodedEmail = encodeURIComponent(props.response?.email || '');

// State for modals and loading
const isHealthRiskModalOpen = ref(false);
const selectedRiskType = ref<'overnourished' | 'under_muscled' | 'visceral_fat' | null>(null);
const isExplanationModalOpen = ref(false);
const selectedExplanation = ref<'strength' | 'cardio' | 'calories' | 'protein' | null>(null);
const isLoading = ref(!props.result);
const isSaving = ref(false);
const error = ref<string | null>(null);
const result = ref<SurveyResult | null>(props.result || null);

// eslint-disable-next-line max-statements
onMounted(async () => {
  document.cookie = `lead_email=${encodedEmail}; path=/`;

  if (props.result) return;

  try {
    isSaving.value = true;

    // Send survey response to backend and get results
    const lead = await leadsApi.create(props.response);
    const responses = lead.bodyCompositionSurveyResponses;
    const lastResponse = responses[responses.length - 1];
    result.value = lastResponse.bodyCompositionSurveyResult;

    // Update the URL with the token using History API
    if (result.value?.token) {
      // Remove trailing slash from pathname if it exists
      const basePath = window.location.pathname.replace(/\/$/, '');
      const newUrl = `${basePath}/${result.value.token}`;
      window.history.replaceState({}, '', newUrl);
    }
  } catch (e) {
    console.error('Error saving survey:', e);
    error.value = 'Hubo un error al guardar tus resultados, pero puedes continuar viéndolos.';
  } finally {
    isSaving.value = false;
  }

  // Increased delay to allow for feature animations (14 seconds total)
  setTimeout(() => {
    // reload the page
    window.location.reload();
  }, 14000);
});

function openHealthRiskModal(type: 'overnourished' | 'under_muscled' | 'visceral_fat') {
  selectedRiskType.value = type;
  isHealthRiskModalOpen.value = true;
}

function openExplanationModal(type: 'strength' | 'cardio' | 'calories' | 'protein') {
  selectedExplanation.value = type;
  isExplanationModalOpen.value = true;
}
</script>

<template>
  <div class="space-y-6 md:space-y-6">
    <div class="text-center md:px-0">
      <h2 class="text-2xl font-bold text-gray-900 md:text-4xl">
        Tu evaluación de composición corporal
      </h2>
      <!-- Error message -->
      <p
        v-if="error"
        class="mt-2 text-sm text-red-600"
      >
        {{ error }}
      </p>
    </div>

    <template v-if="result && !isLoading">
      <!-- Personal Summary -->
      <PersonalSummary :response="response" />

      <!-- Top Row: Quadrant Analysis (2/3) and Risk Probabilities (1/3) -->
      <div class="flex flex-col gap-6 md:grid md:grid-cols-3 md:gap-8 md:px-0">
        <QuadrantAnalysis
          class="md:col-span-2"
          :result="result"
        />
        <RiskAnalysis
          :result="result"
          :response="response"
          @open-risk-modal="openHealthRiskModal"
        />
      </div>

      <ActionPlan
        :result="result"
        :response="response"
        @open-explanation-modal="openExplanationModal"
      />

      <KapsoCTA
        :email="encodedEmail"
      />

      <!-- Personal Details -->
      <div class="border-t border-gray-200 pt-6">
        <p class="text-sm text-gray-500">
          Se ha enviado una copia de tus resultados a {{ response.email }}
        </p>
      </div>
    </template>

    <!-- Loading State -->
    <template v-else>
      <LoadingFeatures />
    </template>

    <!-- Modals -->
    <RiskModal
      v-if="result"
      :is-open="isHealthRiskModalOpen"
      :risk-type="selectedRiskType"
      @close="isHealthRiskModalOpen = false"
    />

    <ExplanationModal
      v-if="result"
      :is-open="isExplanationModalOpen"
      :explanation-type="selectedExplanation"
      :result="result"
      :response="response"
      @close="isExplanationModalOpen = false"
    />
  </div>
</template>
