import type { PostHog } from 'posthog-js';

// Create a function to get analytics that checks if PostHog is ready
function getAnalytics(): PostHog | null {
  return window.posthog || null;
}

// Export a function instead of the direct reference
export function trackEvent(eventName: string, properties?: Record<string, any>) {
  const analytics = getAnalytics();

  if (!analytics) return;

  analytics.capture(eventName, properties);
}
