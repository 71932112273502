<script setup lang="ts">
import { computed } from 'vue';
import { format } from 'date-fns';
import type { Trainee, Measuree } from '@/types';
import type { PhysicalMeasurement } from '@/types/extended';
import { BaseImage } from '@/components';
import FatMassPercentageChart from './fat-mass-percentage-chart.vue';
import BodyCompositionQuadrants from './body-composition-quadrants.vue';
import FatAndFatFreeMassChart from './fat-and-fat-free-mass-chart.vue';
import BodyMassIndexChart from './body-mass-index-chart.vue';
import FatFreeMassIndexChart from './fat-free-mass-index-chart.vue';
import WaistToHipRatioChart from './waist-to-hip-ratio-chart.vue';
import SomatotypeChart from './somatotype-chart.vue';
import StrengthChart from './strength-chart.vue';
import SkinfoldsChart from './skinfolds-chart.vue';
import CorrectedGirthsChart from './corrected-girths-chart.vue';
import AssessmentMetricsTable from './assessment-metrics-table.vue';
import MeasurementsTable from './measurements-table.vue';

const props = defineProps<{
  userable: Trainee | Measuree;
  physicalMeasurements: PhysicalMeasurement[];
}>();

// Latest report for summary cards
const latestPhysicalMeasurement = computed(() => props.physicalMeasurements[props.physicalMeasurements.length - 1]);
const latestAssessment = computed(() => latestPhysicalMeasurement.value?.physicalAssessment);
const latestFatMassPercentage = computed(() => {
  const assessment = latestAssessment.value;

  return (assessment.fatMass / (assessment.fatMass + assessment.fatFreeMass)) * 100;
});

function formatDate(date: string): string {
  return format(new Date(date), 'dd/MM/yyyy');
}

// Helper to calculate change since last measurement
function calculateChange(current: number, previous: number): string {
  const change = ((current - previous) / previous) * 100;
  const sign = change > 0 ? '+' : '';

  return `${sign}${change.toFixed(1)}%`;
}

// Calculate changes for summary cards
const changes = computed(() => {
  // eslint-disable-next-line no-magic-numbers
  if (props.physicalMeasurements.length < 2) return null;

  const current = latestPhysicalMeasurement.value;
  const previous = props.physicalMeasurements[props.physicalMeasurements.length - 2];

  return {
    weight: calculateChange(current.weight!, previous.weight!),
    fatMass: calculateChange(current.physicalAssessment.fatMass, previous.physicalAssessment.fatMass),
    fatFreeMass: calculateChange(
      current.physicalAssessment.fatFreeMass,
      previous.physicalAssessment.fatFreeMass,
    ),
    strength: calculateChange(
      current.physicalAssessment.gripStrength,
      previous.physicalAssessment.gripStrength,
    ),
  };
});
</script>

<template>
  <div class="mb-48 min-h-screen bg-gradient-to-b from-gray-50 to-white px-4 py-8 md:px-6 lg:px-8">
    <div class="mx-auto max-w-6xl">
      <div class="mb-8 flex justify-start border-b border-gray-200 pb-4 sm:mb-12">
        <BaseImage
          src="kapso-logo-light.svg"
          alt="Logo de Kapso"
          class="h-8 md:h-10"
        />
      </div>
      <!-- Header -->
      <div class="mb-8 text-center sm:mb-10">
        <h1 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl md:text-4xl">
          Reporte de composición corporal
        </h1>
        <p class="mt-4 text-base font-medium text-gray-600 sm:text-2xl">
          {{ userable.fullName }}
        </p>
        <p
          v-if="latestPhysicalMeasurement"
          class="mt-3 text-sm text-gray-500 sm:text-base"
        >
          Última medición: {{ formatDate(latestPhysicalMeasurement.createdAt) }}
        </p>
      </div>

      <div class="space-y-6 sm:space-y-8">
        <!-- Summary Cards -->
        <div
          v-if="latestAssessment"
          class="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"
        >
          <!-- Weight Card -->
          <div class="overflow-hidden rounded-xl bg-white p-3 shadow-sm ring-1 ring-gray-900/5 sm:p-6">
            <div class="mb-2 text-sm font-medium uppercase tracking-wider text-gray-500">
              Peso
            </div>
            <div
              v-if="latestPhysicalMeasurement.weight"
              class="flex items-baseline"
            >
              <span class="text-3xl font-bold text-gray-900">
                {{ latestPhysicalMeasurement.weight.toFixed(1) }}
              </span>
              <span class="ml-1 text-xl font-medium text-gray-500">kg</span>
            </div>
            <div
              v-if="changes"
              class="mt-2 inline-flex items-center rounded-xl bg-gray-100 px-2 py-1 text-sm text-gray-600"
            >
              {{ changes.weight }}
            </div>
          </div>

          <!-- Fat Mass Card -->
          <div class="overflow-hidden rounded-xl bg-white p-3 shadow-sm ring-1 ring-gray-900/5 sm:p-6">
            <div class="mb-2 text-sm font-medium uppercase tracking-wider text-gray-500">
              Porcentaje de grasa
            </div>
            <div class="flex items-baseline">
              <span class="text-3xl font-bold text-gray-900">
                {{ latestFatMassPercentage.toFixed(1) }}
              </span>
              <span class="ml-1 text-xl font-medium text-gray-500">%</span>
            </div>
            <div
              v-if="changes"
              class="mt-2 inline-flex items-center rounded-full bg-gray-100 px-2 py-1 text-sm text-gray-600"
            >
              {{ changes.fatMass }}
            </div>
          </div>

          <!-- Fat Free Mass Card -->
          <div class="overflow-hidden rounded-xl bg-white p-3 shadow-sm ring-1 ring-gray-900/5 sm:p-6">
            <div class="mb-2 text-sm font-medium uppercase tracking-wider text-gray-500">
              Masa Libre de Grasa
            </div>
            <div class="flex items-baseline">
              <span class="text-3xl font-bold text-gray-900">
                {{ latestAssessment.fatFreeMass.toFixed(1) }}
              </span>
              <span class="ml-1 text-xl font-medium text-gray-500">kg</span>
            </div>
            <div
              v-if="changes"
              class="mt-2 inline-flex items-center rounded-full bg-gray-100 px-2 py-1 text-sm text-gray-600"
            >
              {{ changes.fatFreeMass }}
            </div>
          </div>

          <!-- Grip Strength Card -->
          <div class="overflow-hidden rounded-xl bg-white p-3 shadow-sm ring-1 ring-gray-900/5 sm:p-6">
            <div class="mb-2 text-sm font-medium uppercase tracking-wider text-gray-500">
              Fuerza de Agarre
            </div>
            <div class="flex items-baseline">
              <span class="text-3xl font-bold text-gray-900">
                {{ latestAssessment.gripStrength.toFixed(1) }}
              </span>
              <span class="ml-1 text-xl font-medium text-gray-500">kg</span>
            </div>
            <div
              v-if="changes"
              class="mt-2 inline-flex  items-center rounded-full bg-gray-100 px-2 py-1 text-sm text-gray-600"
            >
              {{ changes.strength }}
            </div>
          </div>
        </div>

        <!-- Charts -->
        <div class="space-y-8">
          <!-- Body Composition Group -->
          <div>
            <h4 class="mb-4 text-lg font-medium text-gray-900">
              Composición corporal
            </h4>
            <BodyCompositionQuadrants
              :physical-measurements="physicalMeasurements"
              :userable="userable"
              class="mb-4 sm:mb-6"
            />
            <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6">
              <FatMassPercentageChart
                :physical-measurements="physicalMeasurements"
                :userable="userable"
              />
              <FatAndFatFreeMassChart :physical-measurements="physicalMeasurements" />
            </div>
          </div>

          <!-- Body Indices Group -->
          <div>
            <h4 class="mb-4 text-lg font-medium text-gray-900">
              Índices corporales
            </h4>
            <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6">
              <WaistToHipRatioChart
                :physical-measurements="physicalMeasurements"
                :userable="userable"
              />
              <FatFreeMassIndexChart
                :physical-measurements="physicalMeasurements"
                :userable="userable"
              />
              <BodyMassIndexChart :physical-measurements="physicalMeasurements" />
            </div>
          </div>

          <!-- Body Type and Performance Group -->
          <div>
            <h4 class="mb-4 text-lg font-medium text-gray-900">
              Tipo corporal y rendimiento
            </h4>
            <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6">
              <StrengthChart
                :physical-measurements="physicalMeasurements"
                :userable="userable"
              />
              <SomatotypeChart :physical-measurements="physicalMeasurements" />
            </div>
          </div>

          <!-- Skinfolds and Corrected Girths Group -->
          <div>
            <h4 class="mb-4 text-lg font-medium text-gray-900">
              Pliegues y perímetros
            </h4>
            <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6">
              <SkinfoldsChart :physical-measurements="physicalMeasurements" />
              <CorrectedGirthsChart :physical-measurements="physicalMeasurements" />
            </div>
          </div>
        </div>
      </div>

      <!-- Tables -->
      <div class="mt-8 space-y-6 sm:space-y-8">
        <AssessmentMetricsTable :physical-measurements="physicalMeasurements" />
        <MeasurementsTable :physical-measurements="physicalMeasurements" />
      </div>
    </div>
  </div>
</template>
