<script setup lang="ts">
import { computed } from 'vue';
import { format } from 'date-fns';
import { Target, Compass, Apple, Flag } from 'lucide-vue-next';
import type { NutritionPlan } from '@/types/extended';
import { MarkdownRenderer, BaseImage } from '@/components';
import CollapsibleSection from '@/components/shared/collapsible-section.vue';

const props = defineProps<{
  nutritionPlan: NutritionPlan;
}>();

const showPlanDetails = computed(() =>
  props.nutritionPlan.goal ||
  props.nutritionPlan.strategy ||
  props.nutritionPlan.currentDiet ||
  props.nutritionPlan.dietaryTargets,
);

const sortedNutritionObjectives = computed(() =>
  [...props.nutritionPlan.nutritionObjectives].sort((a, b) =>
    new Date(a.startAt).getTime() - new Date(b.startAt).getTime(),
  ),
);

function formatDate(date: string) {
  return format(new Date(date), 'dd/MM/yyyy');
}
</script>

<template>
  <div class="min-h-screen bg-gradient-to-b from-gray-50 to-white pb-16">
    <div class="mx-auto max-w-6xl px-2 py-8 sm:px-4 md:px-6 lg:px-8">
      <!-- Header -->
      <div class="mb-8 text-center sm:mb-10">
        <h1 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl md:text-4xl">
          Plan de nutrición
        </h1>
        <p class="mt-3 text-base text-gray-500 sm:text-lg">
          {{ nutritionPlan.trainee?.fullName }}
        </p>
      </div>

      <div class="space-y-8 sm:space-y-10">
        <!-- Plan details card -->
        <div class="overflow-hidden rounded-xl bg-white shadow-sm ring-1 ring-gray-900/5">
          <div class="p-3 sm:p-6 md:p-8">
            <div class="grid grid-cols-1 gap-4 sm:grid-cols-3 sm:gap-6">
              <div class="sm:col-span-1">
                <h3 class="text-sm font-medium uppercase tracking-wider text-gray-500">
                  Trainee
                </h3>
                <p class="mt-1 text-base font-medium text-gray-900 sm:mt-2">
                  {{ nutritionPlan.trainee?.fullName }}
                </p>
              </div>
              <div class="sm:col-span-1">
                <h3 class="text-sm font-medium uppercase tracking-wider text-gray-500">
                  Nutricionista
                </h3>
                <p class="mt-1 text-base font-medium text-gray-900 sm:mt-2">
                  {{ nutritionPlan.nutritionist?.fullName }}
                </p>
              </div>
              <div class="sm:col-span-1">
                <h3 class="text-sm font-medium uppercase tracking-wider text-gray-500">
                  Periodo
                </h3>
                <p class="mt-1 text-base font-medium text-gray-900 sm:mt-2">
                  {{ formatDate(nutritionPlan.startAt) }} -
                  {{ formatDate(nutritionPlan.endAt) }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Plan details -->
        <div
          v-if="showPlanDetails"
          class="grid gap-4 sm:grid-cols-2 sm:gap-6"
        >
          <!-- Goal Card -->
          <div
            v-if="nutritionPlan.goal"
            class="rounded-xl border border-blue-100 bg-blue-50 p-3 sm:p-4 md:p-6"
          >
            <div class="mb-3 flex items-center gap-2 sm:mb-4">
              <div class="flex size-8 items-center justify-center rounded-lg bg-blue-100 md:size-10">
                <Target class="size-4 text-blue-600 md:size-5" />
              </div>
              <h3 class="text-lg font-semibold text-gray-900">
                Tu objetivo
              </h3>
            </div>
            <div class="overflow-hidden break-words rounded-xl bg-white p-3 text-gray-600 sm:p-4">
              <MarkdownRenderer :markdown="nutritionPlan.goal" />
            </div>
          </div>

          <!-- Strategy Card -->
          <div
            v-if="nutritionPlan.strategy"
            class="rounded-xl border border-purple-100 bg-purple-50 p-3 sm:p-4 md:p-6"
          >
            <div class="mb-3 flex items-center gap-2 sm:mb-4">
              <div class="flex size-8 items-center justify-center rounded-lg bg-purple-100 md:size-10">
                <Compass class="size-4 text-purple-600 md:size-5" />
              </div>
              <h3 class="text-lg font-semibold text-gray-900">
                ¿Cómo lo vamos a lograr?
              </h3>
            </div>
            <div class="overflow-hidden break-words rounded-xl bg-white p-3 text-gray-600 sm:p-4">
              <MarkdownRenderer :markdown="nutritionPlan.strategy" />
            </div>
          </div>

          <!-- Current Diet Card -->
          <div
            v-if="nutritionPlan.currentDiet"
            class="rounded-xl border border-green-100 bg-green-50 p-3 sm:p-4 md:p-6"
          >
            <div class="mb-3 flex items-center gap-2 sm:mb-4">
              <div class="flex size-8 items-center justify-center rounded-lg bg-green-100 md:size-10">
                <Apple class="size-4 text-green-600 md:size-5" />
              </div>
              <h3 class="text-lg font-semibold text-gray-900">
                Tu alimentación actual
              </h3>
            </div>
            <div class="overflow-hidden break-words rounded-xl bg-white p-3 text-gray-600 sm:p-4">
              <MarkdownRenderer :markdown="nutritionPlan.currentDiet" />
            </div>
          </div>

          <!-- Dietary Targets Card -->
          <div
            v-if="nutritionPlan.dietaryTargets"
            class="rounded-xl border border-orange-100 bg-orange-50 p-3 sm:p-4 md:p-6"
          >
            <div class="mb-3 flex items-center gap-2 sm:mb-4">
              <div class="flex size-8 items-center justify-center rounded-lg bg-orange-100 md:size-10">
                <Flag class="size-4 text-orange-600 md:size-5" />
              </div>
              <h3 class="text-lg font-semibold text-gray-900">
                Nuestra meta
              </h3>
            </div>
            <div class="overflow-hidden break-words rounded-xl bg-white p-3 text-gray-600 sm:p-4">
              <MarkdownRenderer :markdown="nutritionPlan.dietaryTargets" />
            </div>
          </div>
        </div>

        <!-- Nutrition objectives -->
        <div class="space-y-4 sm:space-y-6">
          <div
            v-for="(objective, index) in sortedNutritionObjectives"
            :key="objective.id"
            class="overflow-hidden rounded-xl bg-white shadow-sm ring-1 ring-gray-900/5"
          >
            <CollapsibleSection
              :title="`Objetivo ${index + 1} - ${formatDate(objective.startAt)}`"
              class="p-3 sm:p-6 md:p-8"
            >
              <div class="space-y-4 sm:space-y-6">
                <div class="overflow-hidden break-words text-gray-600">
                  <MarkdownRenderer :markdown="objective.description" />
                </div>

                <!-- Meals -->
                <div class="mt-6 space-y-4 sm:mt-8">
                  <CollapsibleSection
                    v-for="meal in objective.meals"
                    :key="meal.id"
                    :title="meal.name"
                    is-expanded
                    class="rounded-lg bg-gray-50 p-3 sm:p-4"
                  >
                    <div class="overflow-hidden break-words pt-3 text-gray-600 sm:pt-4">
                      <MarkdownRenderer :markdown="meal.recommendations" />
                    </div>
                  </CollapsibleSection>
                </div>
              </div>
            </CollapsibleSection>
          </div>
        </div>

        <div class="overflow-hidden rounded-xl bg-white shadow-sm ring-1 ring-gray-900/5">
          <div class="flex flex-col gap-4 p-3 sm:p-6 md:p-8">
            <span class="text-lg font-medium text-gray-900">
              Información extra
            </span>
            <div class="flex flex-col items-center gap-6 sm:flex-row sm:items-start sm:gap-8">
              <div class="relative aspect-square w-full max-w-[200px] p-2 sm:w-48">
                <BaseImage
                  src="cup-measurement.png"
                  alt="Medida de una taza"
                  class="size-full object-contain"
                />
              </div>

              <div class="flex-1">
                <h3 class="mb-3 text-lg font-semibold text-gray-900 sm:text-base">
                  ¿Cuánto es una taza?
                </h3>
                <p class="text-base text-gray-600">
                  1 taza equivale a 200 ml o a una taza de aproximadamente 4 dedos de altura
                  (similar al tamaño de una taza estándar de casino).
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
