import { api } from '@/api/axios-wrapper';
import type { TraineeAttributes } from '@/types';
import type { Trainee } from '@/types/extended';

export interface TraineeSearchParams {
  emailCont?: string;
  firstNameCont?: string;
  lastNameCont?: string;
  countryEq?: string;
}

export const traineesApi = {
  async search(params: TraineeSearchParams) {
    const response = await api({
      method: 'get',
      url: '/api/internal/trainees',
      params: {
        'q[user_email_cont]': params.emailCont,
        'q[user_first_name_cont]': params.firstNameCont,
        'q[user_last_name_cont]': params.lastNameCont,
        'q[country_eq]': params.countryEq,
      },
    });

    return response.data as Trainee[];
  },

  async update(id: number, attributes: Partial<TraineeAttributes>) {
    const response = await api({
      method: 'put',
      url: `/api/internal/trainees/${id}`,
      data: { trainee: attributes },
    });

    return response.data as Trainee;
  },

  async get(id: number) {
    const response = await api({
      method: 'get',
      url: `/api/internal/trainees/${id}`,
    });

    return response.data as Trainee;
  },
};
