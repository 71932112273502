<script setup lang="ts">
import { computed } from 'vue';
import { formatDistanceToNow } from 'date-fns';
import { millisecondsInMinute } from 'date-fns/constants';
import { es } from 'date-fns/locale';
import type { ChatMessage, ChatParticipant, Trainee } from '@/types';
import type { Workout, Routine } from '@/types/extended';
import { BaseLink } from '@/components';

const props = defineProps<{
  workouts: Workout[],
  routines: Routine[],
  trainees: Trainee[],
  chatMessages: ChatMessage[],
  chatParticipants: ChatParticipant[],
}>();

const unfinishedWorkouts = computed(() =>
  props.workouts.filter(workout => !workout.endedAt) || [],
);

// eslint-disable-next-line no-magic-numbers
const fifteenMinutesAgo = new Date(Date.now() - 15 * millisecondsInMinute);

const recentlyUpdatedWorkouts = computed(() =>
  unfinishedWorkouts.value.filter(workout => {
    if (!workout.updatedAt) return false;

    return new Date(workout.updatedAt) > fifteenMinutesAgo;
  }),
);

const olderWorkouts = computed(() =>
  unfinishedWorkouts.value.filter(workout => {
    if (!workout.updatedAt) return true;

    return new Date(workout.updatedAt) <= fifteenMinutesAgo;
  }),
);

const finishedWorkouts = computed(() =>
  props.workouts.filter(workout => workout.endedAt) || [],
);

function getRelativeTime(date: string | null): string {
  if (!date) return '-';

  return formatDistanceToNow(new Date(date), { addSuffix: true, locale: es });
}

function getExpectedEndTime(workout: Workout): string {
  const routine = props.routines.find(r => r.id === workout.routineId);
  if (!workout.startedAt || !routine?.durationInMinutes) return '-';

  const startTime = new Date(workout.startedAt).getTime();
  const endTime = new Date(startTime + routine.durationInMinutes * millisecondsInMinute);

  return getRelativeTime(endTime.toISOString());
}

function getTraineeName(workout: Workout): string {
  return props.trainees.find(t => t.id === workout.traineeId)?.fullName || '';
}

function getRoutineName(workout: Workout): string {
  return props.routines.find(r => r.id === workout.routineId)?.name || '';
}

function getWorkoutPlanId(workout: Workout): number | undefined {
  const routine = props.routines.find(r => r.id === workout.routineId);

  return routine?.workoutPlanId;
}

// eslint-disable-next-line max-statements
function hasUnreadTraineeMessage(workout: Workout): boolean {
  if (!workout.startedAt) return false;

  const workoutStartTime = new Date(workout.startedAt).getTime();
  const chatParticipant = props.chatParticipants.find(cp => {
    const traineeChatParticipant = cp.userableId === workout.traineeId && cp.userableType === 'Trainee';

    return traineeChatParticipant;
  });
  if (!chatParticipant) return false;

  const messagesFromTraineeAfterStart = props.chatMessages
    .filter((m: ChatMessage) =>
      m.userableType === 'Trainee' &&
      m.userableId === workout.traineeId &&
      m.chatGroupId === chatParticipant.chatGroupId &&
      new Date(m.createdAt).getTime() > workoutStartTime,
    );
  if (messagesFromTraineeAfterStart.length === 0) return false;

  const messagesFromTrainerAfterTraineeMessage = props.chatMessages
    .filter((m: ChatMessage) =>
      m.userableType === 'Trainer' &&
      m.chatGroupId === chatParticipant.chatGroupId &&
      new Date(m.createdAt).getTime() > new Date(messagesFromTraineeAfterStart[0].createdAt).getTime(),
    );

  return messagesFromTrainerAfterTraineeMessage.length === 0;
}

function hasUnreadFinishedWorkout(workout: Workout): boolean {
  if (!workout.endedAt) return false;

  const workoutEndTime = new Date(workout.endedAt).getTime();

  const chatParticipant = props.chatParticipants.find(cp => {
    const traineeChatParticipant = cp.userableId === workout.traineeId && cp.userableType === 'Trainee';

    return traineeChatParticipant;
  });
  if (!chatParticipant) return false;

  const trainerMessagesAfterEnd = props.chatMessages
    .filter((m: ChatMessage) =>
      m.userableType === 'Trainer' &&
      m.chatGroupId === chatParticipant.chatGroupId &&
      new Date(m.createdAt).getTime() > workoutEndTime,
    );

  return trainerMessagesAfterEnd.length === 0;
}
</script>

<template>
  <div class="flex flex-col gap-6">
    <div
      v-if="unfinishedWorkouts.length > 0"
      class="flex flex-col gap-4"
    >
      <h3 class="text-lg font-semibold">
        Entrenamientos en progreso
      </h3>
      <div class="relative overflow-hidden rounded-lg border bg-white">
        <template v-if="recentlyUpdatedWorkouts.length > 0">
          <div class="border-b bg-green-100/50 px-4 py-2 text-sm font-medium text-green-800">
            Activos en los últimos 15 minutos
          </div>
          <table class="w-full table-fixed text-left text-sm">
            <thead>
              <tr class="border-b bg-gray-50/50 transition-colors">
                <th class="w-1/6 px-4 py-3 font-medium">
                  Trainee
                </th>
                <th class="w-1/6 px-4 py-3 font-medium">
                  Rutina
                </th>
                <th class="w-1/6 px-4 py-3 font-medium">
                  Inicio
                </th>
                <th class="w-1/6 px-4 py-3 font-medium">
                  Última vez activo
                </th>
                <th class="w-1/6 px-4 py-3 font-medium">
                  Fin esperado
                </th>
                <th class="w-1/6 px-4 py-3 font-medium">
                  Chat
                </th>
                <th class="w-1/6 font-medium">
                  Acciones
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="workout in recentlyUpdatedWorkouts"
                :key="workout.id"
                class="relative border-b transition-colors hover:bg-gray-50/50 [&:last-child]:border-none"
              >
                <td class="truncate px-4">
                  <div class="flex items-center">
                    <div class="size-2 shrink-0 animate-pulse rounded-full bg-green-500" />
                    <BaseLink
                      :href="`/trainees?traineeId=${workout.traineeId}`"
                      class="text-gray-600 hover:text-gray-900"
                    >
                      {{ getTraineeName(workout) }}
                    </BaseLink>
                  </div>
                </td>
                <td class="truncate">
                  <BaseLink
                    v-if="getWorkoutPlanId(workout)"
                    :href="`/workout_plans/${getWorkoutPlanId(workout)}/routines`"
                    class="text-gray-600 hover:text-gray-900"
                  >
                    {{ getRoutineName(workout) }}
                  </BaseLink>
                  <span v-else>
                    {{ getRoutineName(workout) }}
                  </span>
                </td>
                <td class="px-4 py-3">
                  {{ getRelativeTime(workout.startedAt) }}
                </td>
                <td class="px-4 py-3">
                  {{ getRelativeTime(workout.updatedAt) }}
                </td>
                <td class="px-4 py-3">
                  {{ getExpectedEndTime(workout) }}
                </td>
                <td class="px-4 py-3">
                  <div
                    v-if="hasUnreadTraineeMessage(workout)"
                    class="flex items-center gap-2 text-amber-600"
                  >
                    <span class="size-2 shrink-0 rounded-full bg-amber-500" />
                    <span class="text-sm">Mensaje sin responder</span>
                  </div>
                  <div
                    v-else
                    class="flex items-center gap-2 text-green-600"
                  >
                    <span class="size-2 shrink-0 rounded-full bg-green-500" />
                    <span class="text-sm">Sin mensajes</span>
                  </div>
                </td>
                <td>
                  <BaseLink
                    :href="`/workouts/${workout.id}`"
                    class="text-gray-600 hover:text-gray-900"
                  >
                    Ver
                  </BaseLink>
                </td>
              </tr>
            </tbody>
          </table>
        </template>

        <template v-if="olderWorkouts.length > 0">
          <div class="border-y bg-yellow-100/50 px-4 py-2 text-sm font-medium text-yellow-800">
            Sin actividad en los últimos 15 minutos
          </div>
          <table class="w-full table-fixed text-left text-sm">
            <thead>
              <tr class="border-b bg-gray-50/50 transition-colors">
                <th class="w-1/6 px-4 py-3 font-medium">
                  Trainee
                </th>
                <th class="w-1/6 px-4 py-3 font-medium">
                  Rutina
                </th>
                <th class="w-1/6 px-4 py-3 font-medium">
                  Inicio
                </th>
                <th class="w-1/6 px-4 py-3 font-medium">
                  Última vez activo
                </th>
                <th class="w-1/6 px-4 py-3 font-medium">
                  Fin esperado
                </th>
                <th class="w-1/6 px-4 py-3 font-medium">
                  Chat
                </th>
                <th class="w-1/6 font-medium">
                  Acciones
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="workout in olderWorkouts"
                :key="workout.id"
                class="relative border-b transition-colors hover:bg-gray-50/50 [&:last-child]:border-none"
              >
                <td class="truncate px-4">
                  <div class="flex items-center">
                    <div class="size-2 shrink-0 animate-pulse rounded-full bg-green-500" />
                    <BaseLink
                      :href="`/trainees?traineeId=${workout.traineeId}`"
                      class="text-gray-600 hover:text-gray-900"
                    >
                      {{ getTraineeName(workout) }}
                    </BaseLink>
                  </div>
                </td>
                <td class="truncate">
                  <BaseLink
                    v-if="getWorkoutPlanId(workout)"
                    :href="`/workout_plans/${getWorkoutPlanId(workout)}/routines`"
                    class="text-gray-600 hover:text-gray-900"
                  >
                    {{ getRoutineName(workout) }}
                  </BaseLink>
                  <span v-else>
                    {{ getRoutineName(workout) }}
                  </span>
                </td>
                <td class="px-4 py-3">
                  {{ getRelativeTime(workout.startedAt) }}
                </td>
                <td class="px-4 py-3">
                  {{ getRelativeTime(workout.updatedAt) }}
                </td>
                <td class="px-4 py-3">
                  {{ getExpectedEndTime(workout) }}
                </td>
                <td class="px-4 py-3">
                  <div
                    v-if="hasUnreadTraineeMessage(workout)"
                    class="flex items-center gap-2 text-amber-600"
                  >
                    <span class="size-2 shrink-0 rounded-full bg-amber-500" />
                    <span class="text-sm">Mensaje sin responder</span>
                  </div>
                  <div
                    v-else
                    class="flex items-center gap-2 text-green-600"
                  >
                    <span class="size-2 shrink-0 rounded-full bg-green-500" />
                    <span class="text-sm">Sin mensajes</span>
                  </div>
                </td>
                <td>
                  <BaseLink
                    :href="`/workouts/${workout.id}`"
                    class="text-gray-600 hover:text-gray-900"
                  >
                    Ver
                  </BaseLink>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </div>
    </div>

    <div
      v-if="finishedWorkouts.length > 0"
      class="flex flex-col gap-4"
    >
      <h3 class="text-lg font-semibold">
        Entrenamientos completados
      </h3>
      <div class="relative overflow-hidden rounded-lg border bg-white">
        <table class="w-full table-fixed text-left text-sm">
          <thead>
            <tr class="border-b bg-gray-50/50 transition-colors">
              <th class="w-1/6 px-4 py-3 font-medium">
                Trainee
              </th>
              <th class="w-1/6 px-4 py-3 font-medium">
                Rutina
              </th>
              <th class="w-1/6 px-4 py-3 font-medium">
                Inicio
              </th>
              <th class="w-1/6 px-4 py-3 font-medium">
                Fin
              </th>
              <th class="w-1/6 px-4 py-3 font-medium">
                Chat
              </th>
              <th class="w-1/6 font-medium">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="workout in finishedWorkouts"
              :key="workout.id"
              class="border-b transition-colors hover:bg-gray-50/50 [&:last-child]:border-none"
            >
              <td class="truncate px-4">
                <BaseLink
                  :href="`/trainees?traineeId=${workout.traineeId}`"
                  class="text-gray-600 hover:text-gray-900"
                >
                  {{ getTraineeName(workout) }}
                </BaseLink>
              </td>
              <td class="truncate">
                <BaseLink
                  v-if="getWorkoutPlanId(workout)"
                  :href="`/workout_plans/${getWorkoutPlanId(workout)}/routines`"
                  class="text-gray-600 hover:text-gray-900"
                >
                  {{ getRoutineName(workout) }}
                </BaseLink>
                <span v-else>
                  {{ getRoutineName(workout) }}
                </span>
              </td>
              <td class="px-4 py-3">
                {{ getRelativeTime(workout.startedAt) }}
              </td>
              <td class="px-4 py-3">
                {{ getRelativeTime(workout.endedAt) }}
              </td>
              <td class="px-4 py-3">
                <div
                  v-if="hasUnreadFinishedWorkout(workout)"
                  class="flex items-center gap-2 text-amber-600"
                >
                  <span class="size-2 shrink-0 rounded-full bg-amber-500" />
                  <span class="text-sm">Falta enviar mensaje</span>
                </div>
                <div
                  v-else
                  class="flex items-center gap-2 text-green-600"
                >
                  <span class="size-2 shrink-0 rounded-full bg-green-500" />
                  <span class="text-sm">Mensaje enviado</span>
                </div>
              </td>
              <td>
                <BaseLink
                  :href="`/workouts/${workout.id}`"
                  class="text-gray-600 hover:text-gray-900"
                >
                  Ver
                </BaseLink>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      v-if="!workouts.length"
      class="text-center text-gray-500"
    >
      No hay entrenamientos hoy
    </div>
  </div>
</template>
