<script setup lang="ts">
import { computed } from 'vue';
import MarkdownIt from 'markdown-it';

const props = defineProps<{
  markdown: string;
}>();

const md = new MarkdownIt({
  html: true,
});

const renderedMarkdown = computed(() => md.render(props.markdown || '<br/>'));
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    class="prose prose-sm max-w-none"
    v-html="renderedMarkdown"
  />
  <!-- eslint-enable vue/no-v-html -->
</template>
