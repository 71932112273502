// Third party imports
import { VueQueryPlugin } from '@tanstack/vue-query';
import { setDefaultOptions } from 'date-fns';
import { es } from 'date-fns/locale';
import { camelizeKeys } from 'humps';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import InlineSvg from 'vue-inline-svg';
import InstantSearch from 'vue-instantsearch/vue3/es';
import VueApexCharts from 'vue3-apexcharts';

// Base components
import { BaseButton, BaseInput, BackButton } from '@/components';

// Feature components
import AiAssistant from '@/components/ai-assistant.vue';
import BodyComposition from '@/components/onboarding/body-composition/index.vue';
import BodyCompositionResults from '@/components/onboarding/body-composition/results.vue';
import CalBookingsList from '@/components/cal-bookings-list.vue';
import CalEmbed from '@/components/cal-embed.vue';
import ChatWrapper from '@/components/chat-wrapper.vue';
import ChooseTrainer from '@/components/onboarding/choose-trainer.vue';
import Crm from '@/components/crm/crm.vue';
import ExerciseForm from '@/components/exercise-form.vue';
import ExerciseSearch from '@/components/exercise-search.vue';
import InitialSurvey from '@/components/onboarding/initial-survey.vue';
import LoginForm from '@/components/auth/login-form.vue';
import MercadopagoCheckout from '@/components/onboarding/mercadopago-checkout.vue';
import NutritionPlanDetails from '@/components/nutrition-plans/nutrition-plan-details.vue';
import NutritionPlanForm from '@/components/nutrition-plans/nutrition-plan-form.vue';
import NutritionPlansList from '@/components/nutrition-plans/nutrition-plans-list.vue';
import OnboardingSteps from '@/components/onboarding/onboarding-steps.vue';
import OnboardingSurvey from '@/components/onboarding/onboarding-survey.vue';
import PhysicalMeasurementDetails from '@/components/physical-measurements/physical-measurement-details.vue';
import PhysicalMeasurementForm from '@/components/physical-measurements/physical-measurement-form.vue';
import PhysicalMeasurementsList from '@/components/physical-measurements/physical-measurements-list.vue';
import PhysicalAssessmentsDashboard from '@/components/physical-assessments/physical-assessments-dashboard.vue';
// eslint-disable-next-line max-len, vue/max-len
import PhysicalMeasurementBookingsList from '@/components/physical-measurement-bookings/physical-measurement-bookings-list.vue';
import PublishedNutritionPlan from '@/components/nutrition-plans/published-nutrition-plan.vue';
import RebillCheckout from '@/components/onboarding/rebill-checkout.vue';
import RoutinesCalendar from '@/components/routines-calendar.vue';
import RoutinesSchedule from '@/components/routines-schedule.vue';
import ScheduleMeasurements from '@/components/onboarding/schedule-measurements.vue';
import TheLayout from '@/components/the-layout.vue';
import TraineeDetails from '@/components/trainee-details.vue';
import TraineeForm from '@/components/trainee-form.vue';
import TraineesDashboard from '@/components/trainees-dashboard/trainees-dashboard.vue';
import WeeklyRoutinesView from '@/components/weekly-routines-view.vue';
import WorkoutDetails from '@/components/workout-details.vue';
import PlanSummary from '@/components/onboarding/checkouts/plan-summary.vue';
import YourTeam from '@/components/onboarding/checkouts/your-team.vue';
import BuyMeasurement from '@/components/measurements/buy-measurement.vue';
import MeasureeDetails from '@/components/measuree-details.vue';

// Utils
import { EncoderPlugin } from '@/utils/encoder-plugin';

// Styles
import '../css/application.css';

setDefaultOptions({ locale: es });

// eslint-disable-next-line max-statements
document.addEventListener('DOMContentLoaded', () => {
  const pinia = createPinia();

  const app = createApp({
    components: {
      AiAssistant,
      BackButton,
      BaseButton,
      BaseInput,
      BodyComposition,
      BodyCompositionResults,
      CalBookingsList,
      CalEmbed,
      ChooseTrainer,
      ChatWrapper,
      Crm,
      ExerciseForm,
      ExerciseSearch,
      InitialSurvey,
      LoginForm,
      MercadopagoCheckout,
      NutritionPlanDetails,
      NutritionPlanForm,
      NutritionPlansList,
      OnboardingSteps,
      OnboardingSurvey,
      PhysicalMeasurementDetails,
      PhysicalMeasurementForm,
      PhysicalMeasurementsList,
      PhysicalAssessmentsDashboard,
      PhysicalMeasurementBookingsList,
      PublishedNutritionPlan,
      RebillCheckout,
      RoutinesCalendar,
      RoutinesSchedule,
      ScheduleMeasurements,
      TheLayout,
      TraineeDetails,
      TraineeForm,
      TraineesDashboard,
      WeeklyRoutinesView,
      WorkoutDetails,
      PlanSummary,
      YourTeam,
      BuyMeasurement,
      MeasureeDetails,
    },
  });

  app.config.globalProperties.$filters = {
    camelizeKeys,
  };
  app.component('InlineSvg', InlineSvg);

  app.use(pinia);
  app.use(VueQueryPlugin);
  app.use(InstantSearch);
  app.use(EncoderPlugin);
  app.use(VueApexCharts);

  app.mount('#vue-app');

  return app;
});
