<script setup lang="ts">
import { ref } from 'vue';
import { User, ChevronDown } from 'lucide-vue-next';
import type { SurveyResponse, BodyType } from '@/types/body-composition-survey';

const props = defineProps<{
  response: Partial<SurveyResponse>
}>();

const isExpanded = ref(false);

// Map body types to Spanish labels
const bodyTypeLabels: Record<BodyType, string> = {
  skinny: 'Delgado',
  skinny_fat: 'Delgado con grasa',
  bulky: 'Robusto',
  overweight: 'Sobrepeso',
};
</script>

<template>
  <div class="rounded-xl border border-gray-200 bg-white p-4 shadow-sm md:p-8">
    <!-- Header with Icon -->
    <button
      type="button"
      class="flex w-full items-center justify-between sm:cursor-default"
      @click="isExpanded = !isExpanded"
    >
      <div class="flex items-center gap-2 text-gray-900">
        <User class="size-5 md:size-6" />
        <h3 class="text-xl font-semibold md:text-2xl">
          Información personal
        </h3>
      </div>
      <ChevronDown
        class="size-5 transition-transform duration-200 sm:hidden"
        :class="{ 'rotate-180': isExpanded }"
      />
    </button>

    <!-- Content Grid -->
    <div
      class="mt-0 grid gap-8 overflow-hidden transition-all duration-200 ease-in-out sm:mt-8 sm:grid-cols-3"
      :class="{
        'mt-4 max-h-[1000px]': isExpanded,
        'max-h-0': !isExpanded,
        'sm:!mt-8 sm:!max-h-none': true
      }"
    >
      <!-- Personal Info -->
      <div class="space-y-2">
        <h4 class="text-sm font-medium text-gray-700 md:text-base">
          Información básica
        </h4>
        <div class="space-y-2">
          <div
            v-if="response.firstName || response.lastName"
            class="flex flex-col space-y-1 sm:flex-row sm:items-center sm:justify-between sm:space-y-0"
          >
            <span class="text-xs text-gray-600 md:text-sm">Nombre</span>
            <span class="truncate font-medium text-gray-900 md:text-base">
              {{ [response.firstName, response.lastName].filter(Boolean).join(' ') }}
            </span>
          </div>
          <div
            v-if="response.email"
            class="flex flex-col space-y-1 sm:flex-row sm:items-center sm:justify-between sm:space-y-0"
          >
            <span class="text-xs text-gray-600 md:text-sm">Email</span>
            <span class="break-all font-medium text-gray-900 sm:break-normal sm:text-right md:text-base">
              {{ response.email }}
            </span>
          </div>
        </div>
      </div>

      <!-- Body Measurements -->
      <div class="space-y-2">
        <h4 class="text-sm font-medium text-gray-700 md:text-base">
          Medidas corporales
        </h4>
        <div class="space-y-2">
          <div
            v-if="response.height"
            class="flex flex-col space-y-1 sm:flex-row sm:items-center sm:justify-between sm:space-y-0"
          >
            <span class="text-xs text-gray-600 md:text-sm">Altura</span>
            <span class="font-medium text-gray-900 md:text-base">{{ response.height }} cm</span>
          </div>
          <div
            v-if="response.weight"
            class="flex flex-col space-y-1 sm:flex-row sm:items-center sm:justify-between sm:space-y-0"
          >
            <span class="text-xs text-gray-600 md:text-sm">Peso</span>
            <span class="font-medium text-gray-900 md:text-base">{{ response.weight }} kg</span>
          </div>
          <div
            v-if="response.waistCircumference"
            class="flex flex-col space-y-1 sm:flex-row sm:items-center sm:justify-between sm:space-y-0"
          >
            <span class="text-xs text-gray-600 md:text-sm">Cintura</span>
            <span class="font-medium text-gray-900 md:text-base">{{ response.waistCircumference }} cm</span>
          </div>
        </div>
      </div>

      <!-- Body Composition -->
      <div class="space-y-2">
        <h4 class="text-sm font-medium text-gray-700 md:text-base">
          Composición corporal
        </h4>
        <div class="space-y-2">
          <div
            v-if="response.bodyFatPercentage"
            class="flex flex-col space-y-1 sm:flex-row sm:items-center sm:justify-between sm:space-y-0"
          >
            <span class="text-xs text-gray-600 md:text-sm">Grasa Corporal</span>
            <span class="font-medium text-gray-900 md:text-base">{{ response.bodyFatPercentage }}%</span>
          </div>
          <div
            v-if="response.bodyType"
            class="flex flex-col space-y-1 sm:flex-row sm:items-center sm:justify-between sm:space-y-0"
          >
            <span class="text-xs text-gray-600 md:text-sm">Tipo de Cuerpo</span>
            <span class="font-medium text-gray-900 md:text-base">{{ bodyTypeLabels[response.bodyType as BodyType] }}</span>
          </div>
          <div
            v-if="response.muscleMassKg"
            class="flex flex-col space-y-1 sm:flex-row sm:items-center sm:justify-between sm:space-y-0"
          >
            <span class="text-xs text-gray-600 md:text-sm">Masa Muscular</span>
            <span class="font-medium text-gray-900 md:text-base">{{ response.muscleMassKg }} kg</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Remove the custom transition class since we're using transition-all */
</style>
